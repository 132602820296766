/* tslint:disable */
// This file was automatically generated and should not be edited.
// Run 'yarn generate' to regenerate.
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    Date: { input: string; output: string };
    DateTime: { input: string; output: string };
    DateTimeOffset: { input: string; output: string };
    Decimal: { input: number; output: number };
    Guid: { input: string; output: string };
    Version: { input: string; output: string };
};

export type ActionResultQl = {
    __typename: 'ActionResultQL';
    id: Maybe<Scalars['Guid']['output']>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export enum ActionType {
    Added = 'ADDED',
    Deleted = 'DELETED',
    Unchanged = 'UNCHANGED',
    Updated = 'UPDATED',
}

export enum ArgumentTypes {
    Decimal = 'DECIMAL',
    Function = 'FUNCTION',
    MeasureIds = 'MEASURE_IDS',
    Variable = 'VARIABLE',
}

export enum ArrowDirection {
    Down = 'DOWN',
    None = 'NONE',
    Same = 'SAME',
    Up = 'UP',
}

export enum Arrows {
    Green = 'GREEN',
    None = 'NONE',
    Red = 'RED',
    Yellow = 'YELLOW',
}

export type Attachment = {
    href: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    isFile: InputMaybe<Scalars['Boolean']['input']>;
    isUrl: InputMaybe<Scalars['Boolean']['input']>;
    measureId: InputMaybe<Scalars['String']['input']>;
    missionId: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    taskId: InputMaybe<Scalars['String']['input']>;
    templateReportId: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentDeleteResult = {
    __typename: 'AttachmentDeleteResult';
    deletedObject: Maybe<AttachmentQl>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type AttachmentQl = {
    __typename: 'AttachmentQL';
    containerPath: Maybe<Scalars['String']['output']>;
    href: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    isFile: Scalars['Boolean']['output'];
    isUrl: Scalars['Boolean']['output'];
    measure: Maybe<MeasureQl>;
    measureId: Maybe<Scalars['Guid']['output']>;
    missionId: Maybe<Scalars['Guid']['output']>;
    name: Maybe<Scalars['String']['output']>;
    task: Maybe<TaskQl>;
    taskId: Maybe<Scalars['Guid']['output']>;
    templateReport: Maybe<TemplateReportQl>;
    templateReportId: Maybe<Scalars['Guid']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    uploadUri: Maybe<Scalars['String']['output']>;
    userId: Maybe<Scalars['Guid']['output']>;
    username: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type BatchQl = {
    __typename: 'BatchQL';
    batchId: Scalars['Guid']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    username: Maybe<Scalars['String']['output']>;
    utcBatchDate: Maybe<Scalars['DateTime']['output']>;
};

export enum ChartDisplay {
    ByFrequency = 'BY_FREQUENCY',
    Calculated = 'CALCULATED',
    Ftd = 'FTD',
    Qtd = 'QTD',
    Ytd = 'YTD',
    YtdVsFullYear = 'YTD_VS_FULL_YEAR',
}

export enum ChartType {
    Area = 'AREA',
    Composed = 'COMPOSED',
    Default = 'DEFAULT',
    Line = 'LINE',
    Pie = 'PIE',
    StackedBar = 'STACKED_BAR',
}

export type Comment = {
    id: InputMaybe<Scalars['String']['input']>;
    inReplyToId: InputMaybe<Scalars['String']['input']>;
    measureId: InputMaybe<Scalars['String']['input']>;
    missionId: InputMaybe<Scalars['String']['input']>;
    taskId: InputMaybe<Scalars['String']['input']>;
    text: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type CommentDeleteResult = {
    __typename: 'CommentDeleteResult';
    deletedObject: Maybe<CommentQl>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type CommentQl = {
    __typename: 'CommentQL';
    id: Maybe<Scalars['Guid']['output']>;
    inReplyTo: Maybe<CommentQl>;
    inReplyToId: Maybe<Scalars['Guid']['output']>;
    likes: Array<LikeQl>;
    measure: Maybe<MeasureQl>;
    measureId: Maybe<Scalars['Guid']['output']>;
    mission: Maybe<MissionQl>;
    missionId: Maybe<Scalars['Guid']['output']>;
    replies: Array<CommentQl>;
    task: Maybe<TaskQl>;
    text: Maybe<Scalars['String']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    userId: Maybe<Scalars['Guid']['output']>;
    username: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type Contributor = {
    financialYearId: Scalars['Guid']['input'];
    id: InputMaybe<Scalars['Guid']['input']>;
    teamId: InputMaybe<Scalars['Guid']['input']>;
    userId: Scalars['Guid']['input'];
    version: InputMaybe<Scalars['Version']['input']>;
};

export type ContributorDeleteResult = {
    __typename: 'ContributorDeleteResult';
    deletedObject: Maybe<ContributorQl>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type ContributorQl = {
    __typename: 'ContributorQL';
    financialYear: Maybe<FinancialYearQl>;
    financialYearId: Scalars['Guid']['output'];
    id: Maybe<Scalars['Guid']['output']>;
    team: Maybe<TeamQl>;
    teamId: Maybe<Scalars['Guid']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    userActive: Scalars['Boolean']['output'];
    userDisplayName: Maybe<Scalars['String']['output']>;
    userId: Scalars['Guid']['output'];
    userUsage: Maybe<UserUsageQl>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type ContributorQlUserUsageArgs = {
    usagePeriodEnd: Scalars['Date']['input'];
    usagePeriodStart: Scalars['Date']['input'];
};

export type Currency = {
    code: InputMaybe<Scalars['String']['input']>;
    descr: InputMaybe<Scalars['String']['input']>;
    symbol: InputMaybe<Scalars['String']['input']>;
};

export type CurrencyQl = {
    __typename: 'CurrencyQL';
    code: Scalars['String']['output'];
    descr: Maybe<Scalars['String']['output']>;
    symbol: Maybe<Scalars['String']['output']>;
};

export type DeleteResultQl = {
    __typename: 'DeleteResultQL';
    id: Maybe<Scalars['Guid']['output']>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type Division = {
    canUnpublishReport: InputMaybe<Scalars['Boolean']['input']>;
    code: InputMaybe<Scalars['String']['input']>;
    financialYearId: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    primaryColourHex: InputMaybe<Scalars['String']['input']>;
    purpose: InputMaybe<Scalars['String']['input']>;
    utcInactive: InputMaybe<Scalars['DateTime']['input']>;
    values: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
    vision: InputMaybe<Scalars['String']['input']>;
};

export type DivisionQl = {
    __typename: 'DivisionQL';
    canUnpublishReport: Scalars['Boolean']['output'];
    code: Maybe<Scalars['String']['output']>;
    financialYear: Maybe<FinancialYearQl>;
    financialYearId: Maybe<Scalars['Guid']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    name: Maybe<Scalars['String']['output']>;
    primaryColourHex: Maybe<Scalars['String']['output']>;
    purpose: Maybe<Scalars['String']['output']>;
    teams: Array<TeamQl>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcInactive: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    values: Maybe<Scalars['String']['output']>;
    version: Maybe<Scalars['Version']['output']>;
    vision: Maybe<Scalars['String']['output']>;
};

export type DottedTeamMission = {
    id: InputMaybe<Scalars['Guid']['input']>;
    missionGroupId: InputMaybe<Scalars['Guid']['input']>;
    missionId: Scalars['Guid']['input'];
    sequence: Scalars['Int']['input'];
    teamId: Scalars['Guid']['input'];
};

export type DottedTeamMissionQl = {
    __typename: 'DottedTeamMissionQL';
    id: Maybe<Scalars['Guid']['output']>;
    mission: Maybe<MissionQl>;
    missionGroupId: Maybe<Scalars['Guid']['output']>;
    missionId: Maybe<Scalars['Guid']['output']>;
    sequence: Scalars['Int']['output'];
    team: Maybe<TeamQl>;
    teamId: Maybe<Scalars['Guid']['output']>;
};

export type FinancialYear = {
    code: InputMaybe<Scalars['String']['input']>;
    endDate: InputMaybe<Scalars['DateTime']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    startDate: InputMaybe<Scalars['DateTime']['input']>;
    utcInactive: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type FinancialYearQl = {
    __typename: 'FinancialYearQL';
    code: Maybe<Scalars['String']['output']>;
    divisions: Array<Maybe<DivisionQl>>;
    endDate: Scalars['DateTime']['output'];
    id: Maybe<Scalars['Guid']['output']>;
    name: Maybe<Scalars['String']['output']>;
    startDate: Scalars['DateTime']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcInactive: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export enum FrequencyPeriods {
    Day = 'DAY',
    Month = 'MONTH',
    None = 'NONE',
    Quarter = 'QUARTER',
    Week = 'WEEK',
    Year = 'YEAR',
}

export type Like = {
    commentId: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    userId: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type LikeQl = {
    __typename: 'LikeQL';
    commentId: Maybe<Scalars['Guid']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    userId: Maybe<Scalars['Guid']['output']>;
    username: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type LinkedMeasure = {
    id: InputMaybe<Scalars['String']['input']>;
    measureId: Scalars['String']['input'];
    taskId: InputMaybe<Scalars['String']['input']>;
};

export type LinkedMeasureQl = {
    __typename: 'LinkedMeasureQL';
    id: Maybe<Scalars['Guid']['output']>;
    measure: Maybe<MeasureQl>;
    measureId: Scalars['Guid']['output'];
    task: Maybe<TaskQl>;
    taskId: Scalars['Guid']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Maybe<Scalars['DateTime']['output']>;
};

export type Measure = {
    chartDisplay: ChartDisplay;
    chartType: ChartType;
    currency: InputMaybe<Currency>;
    decimalPlaces: Scalars['Int']['input'];
    description: InputMaybe<Scalars['String']['input']>;
    frequencyNumber: Scalars['Int']['input'];
    frequencyPeriod: InputMaybe<FrequencyPeriods>;
    fullYearString: InputMaybe<Scalars['String']['input']>;
    fullYearTarget: InputMaybe<Scalars['Decimal']['input']>;
    greenRange: Scalars['Decimal']['input'];
    id: InputMaybe<Scalars['String']['input']>;
    isCustom: Scalars['Boolean']['input'];
    isFullYearTarget: Scalars['Boolean']['input'];
    isLinked: Scalars['Boolean']['input'];
    isStatusLimited: Scalars['Boolean']['input'];
    linkedFromMeasureId: InputMaybe<Scalars['String']['input']>;
    measureGroupId: InputMaybe<Scalars['String']['input']>;
    measureType: MeasureTypes;
    missionId: Scalars['String']['input'];
    multiplier: Multipliers;
    name: InputMaybe<Scalars['String']['input']>;
    phaseType: PhaseType;
    previousFYMeasureId: InputMaybe<Scalars['String']['input']>;
    sequence: Scalars['Int']['input'];
    showForecast: Scalars['Boolean']['input'];
    showFutureLook: Scalars['Boolean']['input'];
    statusType: StatusTypes;
    tags: Array<Tag>;
    targetType: TargetTypes;
    valueFormula: InputMaybe<Scalars['String']['input']>;
    valueType: ValueTypes;
    version: InputMaybe<Scalars['Version']['input']>;
    yellowRange: Scalars['Decimal']['input'];
    yellowStart: Scalars['Decimal']['input'];
};

export type MeasureAsOf = {
    asOfDate: InputMaybe<Scalars['Date']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    measureId: InputMaybe<Scalars['String']['input']>;
    values: InputMaybe<Array<SeriesValue>>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type MeasureAsOfQl = {
    __typename: 'MeasureAsOfQL';
    arrowColour: Arrows;
    arrowDirection: ArrowDirection;
    asOfDate: Scalars['Date']['output'];
    id: Scalars['Guid']['output'];
    measure: Maybe<MeasureQl>;
    measureId: Scalars['Guid']['output'];
    statusValue: Scalars['Decimal']['output'];
    sysEndTime: Scalars['DateTime']['output'];
    sysStartTime: Scalars['DateTime']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    values: Maybe<Array<SeriesValueQl>>;
    version: Maybe<Scalars['Version']['output']>;
};

export type MeasureGroup = {
    id: InputMaybe<Scalars['String']['input']>;
    missionId: Scalars['String']['input'];
    name: Scalars['String']['input'];
    sequence: Scalars['Int']['input'];
    version: InputMaybe<Scalars['Version']['input']>;
};

export type MeasureGroupDeleteResult = {
    __typename: 'MeasureGroupDeleteResult';
    deletedObject: Maybe<MeasureGroupQl>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type MeasureGroupQl = {
    __typename: 'MeasureGroupQL';
    id: Scalars['Guid']['output'];
    measure: Maybe<Array<MeasureQl>>;
    missionId: Scalars['Guid']['output'];
    name: Scalars['String']['output'];
    sequence: Scalars['Int']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type MeasurePeriodDataQl = {
    __typename: 'MeasurePeriodDataQL';
    actualFormatted: Maybe<Scalars['String']['output']>;
    arrowColour: Arrows;
    arrowDirection: ArrowDirection;
    forecastFormatted: Maybe<Scalars['String']['output']>;
    measureId: Maybe<Scalars['ID']['output']>;
    nextPeriod: Maybe<Scalars['String']['output']>;
    nextTargetFormatted: Maybe<Scalars['String']['output']>;
    noActualsAsOfDates: Array<Scalars['DateTime']['output']>;
    period: Maybe<Scalars['String']['output']>;
    previousPeriod: Maybe<Scalars['String']['output']>;
    statusValue: Scalars['Decimal']['output'];
    targetFormatted: Maybe<Scalars['String']['output']>;
};

export type MeasureQl = {
    __typename: 'MeasureQL';
    asOfForDate: Maybe<MeasureAsOfQl>;
    attachmentCount: Scalars['Int']['output'];
    attachments: Maybe<Array<AttachmentQl>>;
    chartDisplay: ChartDisplay;
    chartType: ChartType;
    commentCount: Scalars['Int']['output'];
    comments: Maybe<Array<CommentQl>>;
    currency: Maybe<CurrencyQl>;
    currencyCode: Maybe<Scalars['String']['output']>;
    decimalPlaces: Scalars['Int']['output'];
    description: Maybe<Scalars['String']['output']>;
    frequencyNumber: Scalars['Int']['output'];
    frequencyPeriod: FrequencyPeriods;
    fullYearString: Maybe<Scalars['String']['output']>;
    fullYearTarget: Maybe<Scalars['Decimal']['output']>;
    greenRange: Scalars['Decimal']['output'];
    group: Maybe<MeasureGroupQl>;
    id: Maybe<Scalars['Guid']['output']>;
    isCustom: Scalars['Boolean']['output'];
    isFullYearTarget: Scalars['Boolean']['output'];
    isLinked: Scalars['Boolean']['output'];
    isPhased: Scalars['Boolean']['output'];
    isStatusLimited: Scalars['Boolean']['output'];
    lastAsOf: Maybe<MeasureAsOfQl>;
    lastAttachment: Maybe<AttachmentQl>;
    lastComment: Maybe<CommentQl>;
    latestStatusReportFact: Maybe<StatusReportFactQl>;
    linkedFromMeasure: Maybe<MeasureQl>;
    linkedFromMeasureId: Maybe<Scalars['Guid']['output']>;
    linkedMeasures: Array<MeasureQl>;
    linkedTasks: Maybe<Array<Maybe<LinkedMeasureQl>>>;
    measureGroupId: Maybe<Scalars['Guid']['output']>;
    measureType: MeasureTypes;
    mission: Maybe<MissionQl>;
    missionId: Scalars['Guid']['output'];
    multiplier: Multipliers;
    name: Maybe<Scalars['String']['output']>;
    phaseType: PhaseType;
    previousFYMeasureId: Maybe<Scalars['Guid']['output']>;
    sequence: Scalars['Int']['output'];
    showForecast: Scalars['Boolean']['output'];
    showFutureLook: Scalars['Boolean']['output'];
    statusType: StatusTypes;
    sysEndTime: Scalars['DateTime']['output'];
    sysStartTime: Scalars['DateTime']['output'];
    tags: Array<TagQl>;
    targetType: TargetTypes;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    updatedByUserName: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    valueFormula: Maybe<Scalars['String']['output']>;
    valueFormulaData: Maybe<ValueFormulaQl>;
    valueFormulaPP: Maybe<Scalars['String']['output']>;
    valueHistory: Maybe<Array<MeasureAsOfQl>>;
    valueType: ValueTypes;
    version: Maybe<Scalars['Version']['output']>;
    yellowRange: Scalars['Decimal']['output'];
    yellowStart: Scalars['Decimal']['output'];
};

export type MeasureQlAsOfForDateArgs = {
    asOfDate: InputMaybe<Scalars['DateTime']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
};

export type MeasureQlLinkedFromMeasureArgs = {
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
};

export type MeasureQlValueHistoryArgs = {
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    hasActual: InputMaybe<Scalars['Boolean']['input']>;
    skip: InputMaybe<Scalars['Int']['input']>;
    take: InputMaybe<Scalars['Int']['input']>;
};

export enum MeasureTypes {
    Currency = 'CURRENCY',
    Date = 'DATE',
    Gyr = 'GYR',
    None = 'NONE',
    Numeric = 'NUMERIC',
    OnOff = 'ON_OFF',
    Percentage = 'PERCENTAGE',
    YesNo = 'YES_NO',
}

export type Mission = {
    behaviours: InputMaybe<Array<MissionPhrase>>;
    constraints: InputMaybe<Array<MissionPhrase>>;
    /** Overrides MissionStatement from Mission that is 1 up */
    customMissionStatement1Up: InputMaybe<Scalars['String']['input']>;
    /** Overrides MissionStatement from Mission that is 2 up */
    customMissionStatement2Up: InputMaybe<Scalars['String']['input']>;
    dottedLines: Array<DottedTeamMission>;
    freedoms: InputMaybe<Array<MissionPhrase>>;
    id: InputMaybe<Scalars['String']['input']>;
    leaderOfTeams: Array<Team>;
    missionDate: InputMaybe<Scalars['Date']['input']>;
    missionStatement: InputMaybe<Scalars['String']['input']>;
    owner: InputMaybe<Scalars['String']['input']>;
    teamId: InputMaybe<Scalars['String']['input']>;
    title: InputMaybe<Scalars['String']['input']>;
    userId: InputMaybe<Scalars['String']['input']>;
    utcInactive: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type MissionGroup = {
    id: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    sequence: Scalars['Int']['input'];
    teamId: Scalars['String']['input'];
    version: InputMaybe<Scalars['Version']['input']>;
};

export type MissionGroupDeleteResult = {
    __typename: 'MissionGroupDeleteResult';
    deletedObject: Maybe<MissionGroupQl>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type MissionGroupQl = {
    __typename: 'MissionGroupQL';
    id: Scalars['Guid']['output'];
    name: Scalars['String']['output'];
    sequence: Scalars['Int']['output'];
    teamId: Scalars['Guid']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type MissionPhrase = {
    id: InputMaybe<Scalars['String']['input']>;
    sequence: InputMaybe<Scalars['Int']['input']>;
    text: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type MissionPhraseQl = {
    __typename: 'MissionPhraseQL';
    id: Maybe<Scalars['Guid']['output']>;
    sequence: Scalars['Int']['output'];
    text: Maybe<Scalars['String']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type MissionQl = {
    __typename: 'MissionQL';
    attachmentCount: Scalars['Int']['output'];
    attachments: Maybe<Array<AttachmentQl>>;
    behaviours: Array<MissionPhraseQl>;
    commentCount: Scalars['Int']['output'];
    comments: Maybe<Array<CommentQl>>;
    constraints: Array<MissionPhraseQl>;
    /** Overrides MissionStatement from 1 Up Mission */
    customMissionStatement1Up: Maybe<Scalars['String']['output']>;
    /** Overrides MissionStatement from 2 Up Mission */
    customMissionStatement2Up: Maybe<Scalars['String']['output']>;
    dottedTeams: Array<DottedTeamMissionQl>;
    freedoms: Array<MissionPhraseQl>;
    id: Maybe<Scalars['Guid']['output']>;
    lastAttachment: Maybe<AttachmentQl>;
    lastComment: Maybe<CommentQl>;
    latestDraftStatusReport: Maybe<StatusReportQl>;
    latestPublishedStatusReport: Maybe<StatusReportQl>;
    leaderOfTeams: Array<TeamQl>;
    measures: Array<MeasureQl>;
    missionDate: Maybe<Scalars['Date']['output']>;
    missionGroupId: Maybe<Scalars['Guid']['output']>;
    missionStatement: Maybe<Scalars['String']['output']>;
    outstandingDependencyCount: Scalars['Int']['output'];
    owner: Maybe<Scalars['String']['output']>;
    publishedStatusReports: Array<StatusReportQl>;
    rights: RightQl;
    sequence: Scalars['Int']['output'];
    tasks: Array<TaskQl>;
    team: Maybe<TeamQl>;
    teamId: Maybe<Scalars['Guid']['output']>;
    title: Maybe<Scalars['String']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    userId: Maybe<Scalars['Guid']['output']>;
    userUsage: Maybe<UserUsageQl>;
    username: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcInactive: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type MissionQlOutstandingDependencyCountArgs = {
    financialYearCode: Scalars['String']['input'];
};

export type MissionQlUserUsageArgs = {
    usagePeriodEnd: Scalars['Date']['input'];
    usagePeriodStart: Scalars['Date']['input'];
};

export enum Multipliers {
    B = 'B',
    K = 'K',
    M = 'M',
    None = 'NONE',
}

export type Mutations = {
    __typename: 'Mutations';
    attachmentDelete: Maybe<AttachmentDeleteResult>;
    attachmentUpdate: Maybe<AttachmentQl>;
    cacheReset: Maybe<Scalars['String']['output']>;
    commentDelete: Maybe<CommentDeleteResult>;
    commentUpdate: Maybe<CommentQl>;
    contributorDelete: Maybe<ContributorDeleteResult>;
    contributorUpdate: Maybe<ContributorQl>;
    divisionUpdate: Maybe<DivisionQl>;
    dottedMissionSequenceUpdate: Maybe<DottedTeamMissionQl>;
    financialYearUpdate: Maybe<FinancialYearQl>;
    jsonUpload: Maybe<ActionResultQl>;
    likeToggle: Maybe<CommentQl>;
    measureAsOfUpdate: Maybe<MeasureAsOfQl>;
    measureDelete: Maybe<DeleteResultQl>;
    measureGroupDelete: Maybe<MeasureGroupDeleteResult>;
    measureGroupUpdate: Maybe<MeasureGroupQl>;
    measureImporterRollback: Maybe<RollbackQl>;
    measureImporterUpdate: Maybe<MeasureQl>;
    measureRecalculate: MeasureQl;
    measureRecalculateAll: Array<MeasureQl>;
    measureSequenceUpdate: Maybe<MeasureQl>;
    measureTransfer: Maybe<MeasureQl>;
    measureUnlink: Maybe<MeasureQl>;
    measureUpdate: Maybe<MeasureQl>;
    missionDelete: Maybe<DeleteResultQl>;
    missionGroupDelete: Maybe<MissionGroupDeleteResult>;
    missionGroupUpdate: Maybe<MissionGroupQl>;
    missionPhraseDelete: Maybe<DeleteResultQl>;
    missionSequenceUpdate: Maybe<MissionQl>;
    missionUpdate: Maybe<MissionQl>;
    notificationDismiss: Maybe<NotificationQl>;
    notificationsDismissAll: Array<NotificationQl>;
    resourceDelete: Maybe<DeleteResultQl>;
    resourceTaskUpdate: Maybe<TaskQl>;
    resourceTaskUserUpdate: Array<TaskQl>;
    resourceUpdate: Maybe<ResourceQl>;
    rightDelete: Maybe<DeleteResultQl>;
    rightUpdate: RightQl;
    statusReportDataRefresh: Maybe<StatusReportQl>;
    statusReportDelete: Maybe<StatusReportQl>;
    statusReportFactUpdate: StatusReportFactQl;
    statusReportUpdate: StatusReportQl;
    tagDelete: Maybe<DeleteResultQl>;
    tagEntityUpdate: Maybe<TagEntityQl>;
    tagLinkToggle: Maybe<ActionResultQl>;
    tagUpdate: Maybe<TagQl>;
    taskAccept: Maybe<TaskQl>;
    taskCategoryDelete: Maybe<DeleteResultQl>;
    taskCategorySwap: Maybe<ActionResultQl>;
    taskCategoryUpdate: Maybe<TaskCategoryQl>;
    taskDelete: Maybe<TaskDeleteResult>;
    taskRecalculateAll: Array<TaskQl>;
    taskSequenceUpdate: Maybe<TaskQl>;
    taskStatusUpdate: Maybe<TaskQl>;
    taskTagsUpdate: Maybe<TaskQl>;
    taskUpdate: Maybe<TaskQl>;
    teamDelete: Maybe<DeleteResultQl>;
    teamUpdate: Maybe<TeamQl>;
    templateReportCreate: TemplateReportQl;
    templateReportDelete: Maybe<TemplateReportQl>;
    templateReportElementDelete: Maybe<TemplateReportSectionElementDeleteResult>;
    templateReportElementUpdate: TemplateReportSectionElementQl;
    templateReportSectionUpdate: TemplateReportSectionQl;
    templateReportUpdate: TemplateReportQl;
    tenantDelete: Maybe<DeleteResultQl>;
    tenantUpdate: Maybe<TenantQl>;
    updateRole: Maybe<ActionResultQl>;
    userDelete: Maybe<DeleteResultQl>;
    userUpdate: Maybe<UserQl>;
    userUpdateTenantDefault: Array<UserTenantQl>;
};

export type MutationsAttachmentDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsAttachmentUpdateArgs = {
    attachment: Attachment;
    tenantId: Scalars['ID']['input'];
};

export type MutationsCommentDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsCommentUpdateArgs = {
    comment: Comment;
    tenantId: Scalars['ID']['input'];
};

export type MutationsContributorDeleteArgs = {
    contributorId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsContributorUpdateArgs = {
    contributor: Contributor;
    tenantId: Scalars['ID']['input'];
};

export type MutationsDivisionUpdateArgs = {
    division: Division;
    tenantId: Scalars['ID']['input'];
};

export type MutationsDottedMissionSequenceUpdateArgs = {
    missionGroupId: InputMaybe<Scalars['ID']['input']>;
    missionId: Scalars['ID']['input'];
    sequence: Scalars['Int']['input'];
    teamId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsFinancialYearUpdateArgs = {
    fy: FinancialYear;
    tenantId: Scalars['ID']['input'];
};

export type MutationsJsonUploadArgs = {
    divisionId: Scalars['ID']['input'];
    json: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsLikeToggleArgs = {
    isLiked: InputMaybe<Scalars['Boolean']['input']>;
    like: Like;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureAsOfUpdateArgs = {
    asOf: MeasureAsOf;
    batchId: InputMaybe<Scalars['ID']['input']>;
    isYtdValue?: InputMaybe<Scalars['Boolean']['input']>;
    skipCalculations?: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureGroupDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureGroupUpdateArgs = {
    measureGroup: MeasureGroup;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureImporterRollbackArgs = {
    batchId: Scalars['ID']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureImporterUpdateArgs = {
    batchId: InputMaybe<Scalars['ID']['input']>;
    comment: InputMaybe<Scalars['String']['input']>;
    description: InputMaybe<Scalars['String']['input']>;
    measureId: Scalars['ID']['input'];
    measureName: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
    updateName: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationsMeasureRecalculateArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureRecalculateAllArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureSequenceUpdateArgs = {
    measureGroupId: InputMaybe<Scalars['ID']['input']>;
    measureId: Scalars['ID']['input'];
    sequence: Scalars['Int']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureTransferArgs = {
    id: Scalars['ID']['input'];
    keepLinkedCopy: Scalars['Boolean']['input'];
    targetMissionId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureUnlinkArgs = {
    id: Scalars['ID']['input'];
    keepValues: Scalars['Boolean']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsMeasureUpdateArgs = {
    measure: Measure;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMissionDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMissionGroupDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMissionGroupUpdateArgs = {
    missionGroup: MissionGroup;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMissionPhraseDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsMissionSequenceUpdateArgs = {
    missionGroupId: InputMaybe<Scalars['ID']['input']>;
    missionId: Scalars['ID']['input'];
    sequence: Scalars['Int']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsMissionUpdateArgs = {
    mission: Mission;
    tenantId: Scalars['ID']['input'];
};

export type MutationsNotificationDismissArgs = {
    notificationId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsNotificationsDismissAllArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type MutationsResourceDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsResourceTaskUpdateArgs = {
    task: ResourceTask;
    tenantId: Scalars['ID']['input'];
};

export type MutationsResourceTaskUserUpdateArgs = {
    targetUserId: Scalars['ID']['input'];
    taskIds: Array<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsResourceUpdateArgs = {
    resource: Resource;
    tenantId: Scalars['ID']['input'];
};

export type MutationsRightDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsRightUpdateArgs = {
    right: Right;
    tenantId: Scalars['ID']['input'];
};

export type MutationsStatusReportDataRefreshArgs = {
    reportDate: InputMaybe<Scalars['DateTime']['input']>;
    statusReportId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsStatusReportDeleteArgs = {
    restore: InputMaybe<Scalars['Boolean']['input']>;
    statusReportId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsStatusReportFactUpdateArgs = {
    fact: StatusReportFact;
    statusReportId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsStatusReportUpdateArgs = {
    notifyUserIds: InputMaybe<Array<Scalars['ID']['input']>>;
    statusReport: StatusReport;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTagDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTagEntityUpdateArgs = {
    measureId: InputMaybe<Scalars['ID']['input']>;
    tags: Array<Tag>;
    taskId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTagLinkToggleArgs = {
    measureId: InputMaybe<Scalars['ID']['input']>;
    tagId: InputMaybe<Scalars['ID']['input']>;
    tagName: InputMaybe<Scalars['String']['input']>;
    taskId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTagUpdateArgs = {
    tag: Tag;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskAcceptArgs = {
    assignToTaskId: InputMaybe<Scalars['ID']['input']>;
    isAccepted: InputMaybe<Scalars['Boolean']['input']>;
    isDuplicate: InputMaybe<Scalars['Boolean']['input']>;
    isRejected: InputMaybe<Scalars['Boolean']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    parentTaskId: InputMaybe<Scalars['ID']['input']>;
    rejectReason: InputMaybe<Scalars['String']['input']>;
    taskId: Scalars['ID']['input'];
    taskName: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskCategoryDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskCategorySwapArgs = {
    id: Scalars['ID']['input'];
    swapId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskCategoryUpdateArgs = {
    taskCategory: TaskCategory;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskDeleteArgs = {
    id: Scalars['ID']['input'];
    isTaskResource: InputMaybe<Scalars['Boolean']['input']>;
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskRecalculateAllArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskSequenceUpdateArgs = {
    sequence: Scalars['Int']['input'];
    targetParentTaskId: InputMaybe<Scalars['ID']['input']>;
    taskId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskStatusUpdateArgs = {
    taskStatus: TaskStatusInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskTagsUpdateArgs = {
    tags: Array<Tag>;
    taskId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsTaskUpdateArgs = {
    task: Task;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTeamDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTeamUpdateArgs = {
    team: Team;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTemplateReportCreateArgs = {
    templateReport: TemplateReportCreateInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTemplateReportDeleteArgs = {
    restore: InputMaybe<Scalars['Boolean']['input']>;
    templateReportId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationsTemplateReportElementDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTemplateReportElementUpdateArgs = {
    templateReportElement: TemplateReportElementInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTemplateReportSectionUpdateArgs = {
    templateReportSection: TemplateReportSectionInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTemplateReportUpdateArgs = {
    notifyUserIds: InputMaybe<Array<Scalars['ID']['input']>>;
    templateReport: TemplateReportInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationsTenantDeleteArgs = {
    tenantId: Scalars['ID']['input'];
};

export type MutationsTenantUpdateArgs = {
    tenant: Tenant;
};

export type MutationsUpdateRoleArgs = {
    action: InputMaybe<Scalars['String']['input']>;
    role: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type MutationsUserDeleteArgs = {
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationsUserUpdateArgs = {
    user: User;
};

export type MutationsUserUpdateTenantDefaultArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type NotificationEventQl = {
    __typename: 'NotificationEventQL';
    comment: Maybe<CommentQl>;
    commentId: Maybe<Scalars['Guid']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    measure: Maybe<MeasureQl>;
    measureId: Maybe<Scalars['Guid']['output']>;
    mission: Maybe<MissionQl>;
    missionId: Maybe<Scalars['Guid']['output']>;
    notificationType: Maybe<NotificationTypes>;
    originatorUserId: Maybe<Scalars['Guid']['output']>;
    originatorUsername: Maybe<Scalars['String']['output']>;
    resource: Maybe<ResourceQl>;
    resourceId: Maybe<Scalars['Guid']['output']>;
    statusReport: Maybe<StatusReportQl>;
    statusReportId: Maybe<Scalars['Guid']['output']>;
    task: Maybe<TaskQl>;
    taskId: Maybe<Scalars['Guid']['output']>;
    templateReport: Maybe<TemplateReportQl>;
    templateReportId: Maybe<Scalars['Guid']['output']>;
    userId: Maybe<Scalars['Guid']['output']>;
    username: Maybe<Scalars['String']['output']>;
    utcCreated: Maybe<Scalars['DateTime']['output']>;
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationQl = {
    __typename: 'NotificationQL';
    id: Maybe<Scalars['Guid']['output']>;
    notificationEvent: Maybe<NotificationEventQl>;
    notificationEventId: Maybe<Scalars['Guid']['output']>;
    userId: Maybe<Scalars['Guid']['output']>;
    utcDismissed: Maybe<Scalars['DateTime']['output']>;
};

export enum NotificationTypes {
    AttachmentAdded = 'ATTACHMENT_ADDED',
    CommentAdded = 'COMMENT_ADDED',
    ContributorTaskAdded = 'CONTRIBUTOR_TASK_ADDED',
    ContributorTaskChanged = 'CONTRIBUTOR_TASK_CHANGED',
    MeasureOwnerChanged = 'MEASURE_OWNER_CHANGED',
    None = 'NONE',
    ResourceChanged = 'RESOURCE_CHANGED',
    ResourceDeactivated = 'RESOURCE_DEACTIVATED',
    StatusReportPublished = 'STATUS_REPORT_PUBLISHED',
    TemplateReportPublished = 'TEMPLATE_REPORT_PUBLISHED',
}

export type PeriodData = {
    __typename: 'PeriodData';
    measurePeriodData: Maybe<MeasurePeriodDataQl>;
};

export type PeriodDataMeasurePeriodDataArgs = {
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    measureId: Scalars['ID']['input'];
    reportPeriod: InputMaybe<Scalars['Int']['input']>;
    reportPeriodType: ReportPeriodTypes;
    tenantId: Scalars['ID']['input'];
};

export enum PhaseType {
    Cumulative = 'CUMULATIVE',
    PeriodEnd = 'PERIOD_END',
}

export type Queries = {
    __typename: 'Queries';
    attachments: Maybe<Array<AttachmentQl>>;
    breadcrumbs: Maybe<Array<TeamQl>>;
    comments: Maybe<Array<CommentQl>>;
    contributor: Maybe<ContributorQl>;
    contributors: Array<ContributorQl>;
    currencies: Array<CurrencyQl>;
    current_user: Maybe<UserQl>;
    default_contributor: Maybe<ContributorQl>;
    default_mission: Maybe<MissionQl>;
    dependencies: Maybe<Array<TaskQl>>;
    division: Maybe<DivisionQl>;
    divisions: Maybe<Array<DivisionQl>>;
    financialYear: Maybe<FinancialYearQl>;
    financialYears: Maybe<Array<FinancialYearQl>>;
    importerBatches: Maybe<Array<BatchQl>>;
    importerTimestamps: Maybe<Array<VersionTimestampQl>>;
    /** True, if this user has importer rights */
    isImporter: Scalars['Boolean']['output'];
    latestStatusReport: Maybe<StatusReportQl>;
    measure: Maybe<MeasureQl>;
    measureGroups: Array<MeasureGroupQl>;
    measureHistory: Array<MeasureQl>;
    measureValueFormula: Maybe<ValueFormulaQl>;
    measureValueFormulaEvaluation: Maybe<ValueFormulaQl>;
    measures: Array<MeasureQl>;
    measuresForImporter: Maybe<Array<MeasureQl>>;
    mission: Maybe<MissionQl>;
    missionGroups: Array<MissionGroupQl>;
    missionMentor: Maybe<Scalars['String']['output']>;
    mission_search: Array<MissionQl>;
    missions: Array<MissionQl>;
    notifications: Maybe<Array<NotificationQl>>;
    notificationsCount: Scalars['Int']['output'];
    periodData: Maybe<PeriodData>;
    reports: Maybe<Reports>;
    resource: Maybe<ResourceQl>;
    resource_search: Array<ResourceQl>;
    resources: Maybe<Array<ResourceQl>>;
    rights: Array<RightQl>;
    roleTypes: Maybe<Array<RoleQl>>;
    seriesValueHistory: Array<TemporalSeriesValueQl>;
    statusReport: Maybe<StatusReportQl>;
    statusReports: Maybe<Array<StatusReportQl>>;
    tags: Maybe<Array<TagQl>>;
    task: Maybe<TaskQl>;
    taskCategories: Maybe<Array<TaskCategoryQl>>;
    taskHistory: Maybe<Array<TaskQl>>;
    tasks: Array<TaskQl>;
    teams: Maybe<Array<TeamQl>>;
    tenant: Maybe<TenantQl>;
    tenants: Maybe<Array<TenantQl>>;
    usageStats: UsageReportQl;
    userSearch: Array<UserQl>;
};

export type QueriesAttachmentsArgs = {
    showDeleted: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesBreadcrumbsArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    teamCode: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesCommentsArgs = {
    measureId: InputMaybe<Scalars['ID']['input']>;
    taskId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesContributorArgs = {
    contributorId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
    userId: InputMaybe<Scalars['ID']['input']>;
};

export type QueriesContributorsArgs = {
    financialYearActiveDate: InputMaybe<Scalars['DateTime']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    teamCode: InputMaybe<Scalars['String']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesCurrenciesArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueriesDefault_ContributorArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesDefault_MissionArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesDependenciesArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type QueriesDivisionArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesDivisionsArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesFinancialYearArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesFinancialYearsArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueriesImporterBatchesArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueriesImporterTimestampsArgs = {
    batchId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesIsImporterArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueriesLatestStatusReportArgs = {
    missionId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesMeasureArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesMeasureGroupsArgs = {
    missionId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesMeasureHistoryArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesMeasureValueFormulaArgs = {
    measureId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesMeasureValueFormulaEvaluationArgs = {
    measureId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesMeasuresArgs = {
    filter_type: InputMaybe<Scalars['String']['input']>;
    filter_value: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    financialYearActiveDate: InputMaybe<Scalars['DateTime']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    taskCategoryIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesMeasuresForImporterArgs = {
    divisionCode: InputMaybe<Scalars['String']['input']>;
    filter_type: InputMaybe<Scalars['String']['input']>;
    filter_value: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesMissionArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesMissionGroupsArgs = {
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesMissionMentorArgs = {
    prompt: Scalars['String']['input'];
    promptName: Scalars['String']['input'];
};

export type QueriesMission_SearchArgs = {
    divisionId: InputMaybe<Scalars['ID']['input']>;
    financialYearActiveDate: InputMaybe<Scalars['DateTime']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
    includeInactive: InputMaybe<Scalars['Boolean']['input']>;
    isImportable: InputMaybe<Scalars['Boolean']['input']>;
    isWritable: InputMaybe<Scalars['Boolean']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
    userId: InputMaybe<Scalars['ID']['input']>;
};

export type QueriesMissionsArgs = {
    divisionId: InputMaybe<Scalars['ID']['input']>;
    financialYearActiveDate: InputMaybe<Scalars['DateTime']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
    includeInactive: InputMaybe<Scalars['Boolean']['input']>;
    isImportable: InputMaybe<Scalars['Boolean']['input']>;
    isWritable: InputMaybe<Scalars['Boolean']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
    userId: InputMaybe<Scalars['ID']['input']>;
};

export type QueriesNotificationsArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type QueriesNotificationsCountArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type QueriesResourceArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesResource_SearchArgs = {
    searchText: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesResourcesArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueriesRightsArgs = {
    divisionId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: InputMaybe<Scalars['ID']['input']>;
    userId: InputMaybe<Scalars['ID']['input']>;
};

export type QueriesSeriesValueHistoryArgs = {
    measureId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesStatusReportArgs = {
    statusReportId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesStatusReportsArgs = {
    missionId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesTagsArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueriesTaskArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesTaskCategoriesArgs = {
    divisionId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesTaskHistoryArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueriesTasksArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesTeamsArgs = {
    divisionId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    leaderUserId: InputMaybe<Scalars['ID']['input']>;
    teamCode: InputMaybe<Scalars['String']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueriesTenantArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueriesUsageStatsArgs = {
    includeLogins: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueriesUserSearchArgs = {
    search: InputMaybe<Scalars['String']['input']>;
    tenantId: InputMaybe<Scalars['ID']['input']>;
    useCache: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ReportPeriodTypes {
    Half = 'HALF',
    Month = 'MONTH',
    None = 'NONE',
    Quarter = 'QUARTER',
    Ytd = 'YTD',
}

export type Reports = {
    __typename: 'Reports';
    templateReport: Maybe<TemplateReportQl>;
    templateReportSection: Maybe<TemplateReportSectionQl>;
    templateReports: Array<TemplateReportQl>;
};

export type ReportsTemplateReportArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type ReportsTemplateReportSectionArgs = {
    id: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type ReportsTemplateReportsArgs = {
    isTemplate: InputMaybe<Scalars['Boolean']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type Resource = {
    id: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    userId: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type ResourceQl = {
    __typename: 'ResourceQL';
    displayName: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    name: Maybe<Scalars['String']['output']>;
    taskCount: Scalars['Int']['output'];
    tasks: Array<TaskQl>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    /** The FYs where the resource's user is a contributor. */
    userContributorFYs: Array<FinancialYearQl>;
    userId: Maybe<Scalars['Guid']['output']>;
    /** The FYs of the resource's user's missions. */
    userMissionFYs: Array<FinancialYearQl>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type ResourceQlTaskCountArgs = {
    financialYearCode: InputMaybe<Scalars['String']['input']>;
};

export type ResourceTask = {
    done: InputMaybe<Scalars['Date']['input']>;
    due: InputMaybe<Scalars['Date']['input']>;
    effortResourceWeight: Scalars['Int']['input'];
    id: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    rejectedReason: InputMaybe<Scalars['String']['input']>;
    resourceIsPrimary: Scalars['Boolean']['input'];
    start: InputMaybe<Scalars['Date']['input']>;
    utcAtRisk: InputMaybe<Scalars['DateTime']['input']>;
    utcCancelled: InputMaybe<Scalars['DateTime']['input']>;
    utcChangesPending: InputMaybe<Scalars['DateTime']['input']>;
    utcPostponed: InputMaybe<Scalars['DateTime']['input']>;
    utcRejected: InputMaybe<Scalars['DateTime']['input']>;
    utcResourceRemoved: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type Right = {
    divisionId: InputMaybe<Scalars['String']['input']>;
    export: InputMaybe<Scalars['Boolean']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    import: InputMaybe<Scalars['Boolean']['input']>;
    missionId: InputMaybe<Scalars['String']['input']>;
    read: InputMaybe<Scalars['Boolean']['input']>;
    teamId: InputMaybe<Scalars['String']['input']>;
    userId: InputMaybe<Scalars['String']['input']>;
    write: InputMaybe<Scalars['Boolean']['input']>;
};

export type RightQl = {
    __typename: 'RightQL';
    division: Maybe<DivisionQl>;
    divisionId: Maybe<Scalars['Guid']['output']>;
    export: Scalars['Boolean']['output'];
    id: Scalars['Guid']['output'];
    import: Scalars['Boolean']['output'];
    mission: Maybe<MissionQl>;
    missionId: Maybe<Scalars['Guid']['output']>;
    read: Scalars['Boolean']['output'];
    team: Maybe<TeamQl>;
    teamId: Maybe<Scalars['Guid']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    userId: Scalars['Guid']['output'];
    utcCreated: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    write: Scalars['Boolean']['output'];
};

export type RoleQl = {
    __typename: 'RoleQL';
    description: Scalars['String']['output'];
    id: Scalars['Guid']['output'];
    name: Scalars['String']['output'];
};

export type RollbackItemQl = {
    __typename: 'RollbackItemQL';
    batch: VersionTimestampQl;
    text: Scalars['String']['output'];
};

export type RollbackQl = {
    __typename: 'RollbackQL';
    completeText: Maybe<Scalars['String']['output']>;
    errorText: Maybe<Scalars['String']['output']>;
    isComplete: Maybe<Scalars['Boolean']['output']>;
    isError: Maybe<Scalars['Boolean']['output']>;
    items: Array<Maybe<RollbackItemQl>>;
};

export type SeriesType = {
    calcSymbol: InputMaybe<Scalars['String']['input']>;
    defaultFormat: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    sequence: InputMaybe<Scalars['Int']['input']>;
};

export type SeriesTypeQl = {
    __typename: 'SeriesTypeQL';
    calcSymbol: Maybe<Scalars['String']['output']>;
    defaultFormat: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    name: Maybe<Scalars['String']['output']>;
    sequence: Scalars['Int']['output'];
};

export type SeriesValue = {
    calcId: InputMaybe<Scalars['Int']['input']>;
    dateValue: InputMaybe<Scalars['Date']['input']>;
    decimalValue: InputMaybe<Scalars['Decimal']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    seriesType: InputMaybe<SeriesType>;
    stringValue: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type SeriesValueQl = {
    __typename: 'SeriesValueQL';
    calcId: Maybe<Scalars['Int']['output']>;
    dateValue: Maybe<Scalars['Date']['output']>;
    decimalValue: Maybe<Scalars['Decimal']['output']>;
    formatStr: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    seriesType: Maybe<SeriesTypeQl>;
    stringValue: Maybe<Scalars['String']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type StatusReport = {
    facts: Array<StatusReportFact>;
    id: InputMaybe<Scalars['Guid']['input']>;
    lastPeriodText: InputMaybe<Scalars['String']['input']>;
    missionId: Scalars['Guid']['input'];
    nextPeriodText: InputMaybe<Scalars['String']['input']>;
    reportDate: InputMaybe<Scalars['DateTime']['input']>;
    risksAndOpportunitiesText: InputMaybe<Scalars['String']['input']>;
    summaryText: InputMaybe<Scalars['String']['input']>;
    supportText: InputMaybe<Scalars['String']['input']>;
    title: InputMaybe<Scalars['String']['input']>;
    utcCompletedDate: InputMaybe<Scalars['DateTime']['input']>;
    utcDataDate: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type StatusReportFact = {
    actionText: InputMaybe<Scalars['String']['input']>;
    factText: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['Guid']['input']>;
    insightText: InputMaybe<Scalars['String']['input']>;
    isIncluded: Scalars['Boolean']['input'];
    measureId: InputMaybe<Scalars['Guid']['input']>;
    sequence: Scalars['Int']['input'];
    soWhatText: InputMaybe<Scalars['String']['input']>;
    taskId: InputMaybe<Scalars['Guid']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type StatusReportFactQl = {
    __typename: 'StatusReportFactQL';
    actionText: Maybe<Scalars['String']['output']>;
    factText: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    insightText: Maybe<Scalars['String']['output']>;
    isIncluded: Scalars['Boolean']['output'];
    measureId: Maybe<Scalars['Guid']['output']>;
    sequence: Scalars['Int']['output'];
    soWhatText: Maybe<Scalars['String']['output']>;
    statusReport: Maybe<StatusReportQl>;
    taskId: Maybe<Scalars['Guid']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type StatusReportQl = {
    __typename: 'StatusReportQL';
    facts: Array<StatusReportFactQl>;
    id: Maybe<Scalars['Guid']['output']>;
    lastPeriodText: Maybe<Scalars['String']['output']>;
    mission: Maybe<MissionQl>;
    missionId: Scalars['Guid']['output'];
    nextPeriodText: Maybe<Scalars['String']['output']>;
    reportDate: Maybe<Scalars['DateTime']['output']>;
    risksAndOpportunitiesText: Maybe<Scalars['String']['output']>;
    summaryText: Maybe<Scalars['String']['output']>;
    supportText: Maybe<Scalars['String']['output']>;
    title: Maybe<Scalars['String']['output']>;
    utcCompletedDate: Maybe<Scalars['DateTime']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDataDate: Maybe<Scalars['DateTime']['output']>;
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export enum StatusTypes {
    LessThanTarget = 'LESS_THAN_TARGET',
    MoreThanTarget = 'MORE_THAN_TARGET',
    None = 'NONE',
    TargetExact = 'TARGET_EXACT',
}

export type Subscriptions = {
    __typename: 'Subscriptions';
    chat: Maybe<Scalars['String']['output']>;
};

export type SubscriptionsChatArgs = {
    prompt: Scalars['String']['input'];
    promptName: Scalars['String']['input'];
};

export type Tag = {
    colourHex: InputMaybe<Scalars['String']['input']>;
    icon: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    isMeasureTag: Scalars['Boolean']['input'];
    isTaskTag: Scalars['Boolean']['input'];
    name: InputMaybe<Scalars['String']['input']>;
    tagType: TagTypes;
};

export type TagEntityQl = {
    __typename: 'TagEntityQL';
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
};

export type TagQl = {
    __typename: 'TagQL';
    colourHex: Maybe<Scalars['String']['output']>;
    icon: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    isMeasureTag: Scalars['Boolean']['output'];
    isTaskTag: Scalars['Boolean']['output'];
    name: Maybe<Scalars['String']['output']>;
    tagType: TagTypes;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
};

export enum TagTypes {
    AdminTag = 'ADMIN_TAG',
    UserTag = 'USER_TAG',
}

export enum TargetTypes {
    FullYear = 'FULL_YEAR',
    None = 'NONE',
    Reoccurring = 'REOCCURRING',
}

export type Task = {
    costWeight: Scalars['Int']['input'];
    description: InputMaybe<Scalars['String']['input']>;
    done: InputMaybe<Scalars['Date']['input']>;
    due: InputMaybe<Scalars['Date']['input']>;
    effortResourceWeight: Scalars['Int']['input'];
    effortWeight: Scalars['Int']['input'];
    id: InputMaybe<Scalars['String']['input']>;
    isDuplicate: Scalars['Boolean']['input'];
    isPercentageCompleteFromResources: Scalars['Boolean']['input'];
    isPercentageCompleteFromSubTasks: Scalars['Boolean']['input'];
    linkedMeasures: InputMaybe<Array<LinkedMeasure>>;
    missionId: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    parentTaskId: InputMaybe<Scalars['String']['input']>;
    percentComplete: Scalars['Decimal']['input'];
    rejectedReason: InputMaybe<Scalars['String']['input']>;
    resource: InputMaybe<Resource>;
    resourceId: InputMaybe<Scalars['String']['input']>;
    resourceIsPrimary: Scalars['Boolean']['input'];
    resourcedFromTaskId: InputMaybe<Scalars['String']['input']>;
    review: InputMaybe<Scalars['Date']['input']>;
    sequence: Scalars['Int']['input'];
    start: InputMaybe<Scalars['Date']['input']>;
    taskCategoryId: InputMaybe<Scalars['String']['input']>;
    utcAccepted: InputMaybe<Scalars['DateTime']['input']>;
    utcAtRisk: InputMaybe<Scalars['DateTime']['input']>;
    utcCancelled: InputMaybe<Scalars['DateTime']['input']>;
    utcChangesPending: InputMaybe<Scalars['DateTime']['input']>;
    utcPostponed: InputMaybe<Scalars['DateTime']['input']>;
    utcRejected: InputMaybe<Scalars['DateTime']['input']>;
    utcResourceRemoved: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TaskCategory = {
    colourHex: InputMaybe<Scalars['String']['input']>;
    divisionIds: InputMaybe<Array<Scalars['String']['input']>>;
    id: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TaskCategoryQl = {
    __typename: 'TaskCategoryQL';
    colourHex: Maybe<Scalars['String']['output']>;
    divisions: Maybe<Array<DivisionQl>>;
    hasIcon: Maybe<Scalars['Boolean']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    name: Maybe<Scalars['String']['output']>;
    taskCount: Scalars['Int']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type TaskDeleteResult = {
    __typename: 'TaskDeleteResult';
    deletedObject: Maybe<TaskQl>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type TaskQl = {
    __typename: 'TaskQL';
    attachmentCount: Scalars['Int']['output'];
    attachments: Array<AttachmentQl>;
    commentCount: Scalars['Int']['output'];
    comments: Array<CommentQl>;
    costWeight: Scalars['Int']['output'];
    description: Maybe<Scalars['String']['output']>;
    done: Maybe<Scalars['DateTime']['output']>;
    due: Maybe<Scalars['DateTime']['output']>;
    effortResourceWeight: Scalars['Int']['output'];
    effortWeight: Scalars['Int']['output'];
    id: Maybe<Scalars['Guid']['output']>;
    isDuplicate: Scalars['Boolean']['output'];
    isPercentageCompleteFromResources: Scalars['Boolean']['output'];
    isPercentageCompleteFromSubTasks: Scalars['Boolean']['output'];
    lastAttachment: Maybe<AttachmentQl>;
    lastComment: Maybe<CommentQl>;
    latestStatusReportFact: Maybe<StatusReportFactQl>;
    linkedMeasures: Array<LinkedMeasureQl>;
    mission: Maybe<MissionQl>;
    missionId: Maybe<Scalars['Guid']['output']>;
    name: Maybe<Scalars['String']['output']>;
    parentTask: Maybe<TaskQl>;
    parentTaskId: Maybe<Scalars['Guid']['output']>;
    percentComplete: Scalars['Decimal']['output'];
    rejectedReason: Maybe<Scalars['String']['output']>;
    resource: Maybe<ResourceQl>;
    resourceId: Maybe<Scalars['Guid']['output']>;
    resourceIsPrimary: Scalars['Boolean']['output'];
    resourcedFromTask: Maybe<TaskQl>;
    resourcedFromTaskId: Maybe<Scalars['Guid']['output']>;
    resourcedTasks: Array<TaskQl>;
    review: Maybe<Scalars['DateTime']['output']>;
    sequence: Scalars['Int']['output'];
    start: Maybe<Scalars['DateTime']['output']>;
    status: Maybe<Scalars['String']['output']>;
    subTasks: Array<TaskQl>;
    sysStartTime: Scalars['DateTime']['output'];
    tags: Array<TagQl>;
    taskCategory: Maybe<TaskCategoryQl>;
    taskCategoryId: Maybe<Scalars['Guid']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    updatedByUserName: Maybe<Scalars['String']['output']>;
    utcAccepted: Maybe<Scalars['DateTime']['output']>;
    utcAtRisk: Maybe<Scalars['DateTime']['output']>;
    utcCancelled: Maybe<Scalars['DateTime']['output']>;
    utcChangesPending: Maybe<Scalars['DateTime']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcPostponed: Maybe<Scalars['DateTime']['output']>;
    utcRejected: Maybe<Scalars['DateTime']['output']>;
    utcResourceRemoved: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type TaskStatusInput = {
    done: InputMaybe<Scalars['Date']['input']>;
    due: InputMaybe<Scalars['Date']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    percentComplete: Scalars['Decimal']['input'];
    start: InputMaybe<Scalars['Date']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type Team = {
    code: InputMaybe<Scalars['String']['input']>;
    divisionId: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    leaderMissionId: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TeamQl = {
    __typename: 'TeamQL';
    code: Maybe<Scalars['String']['output']>;
    contributors: Array<ContributorQl>;
    division: Maybe<DivisionQl>;
    dottedMissions: Array<DottedTeamMissionQl>;
    id: Maybe<Scalars['Guid']['output']>;
    leaderMission: Maybe<MissionQl>;
    missions: Array<MissionQl>;
    name: Maybe<Scalars['String']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type TemplateReportCreateInput = {
    id: InputMaybe<Scalars['Guid']['input']>;
    missionId: InputMaybe<Scalars['Guid']['input']>;
    reportDate: InputMaybe<Scalars['DateTime']['input']>;
    reportPeriod: InputMaybe<Scalars['Int']['input']>;
    reportPeriodType: InputMaybe<ReportPeriodTypes>;
    sections: Array<TemplateReportSectionCreateInput>;
    title: InputMaybe<Scalars['String']['input']>;
    utcCompletedDate: InputMaybe<Scalars['DateTime']['input']>;
    utcDataDate: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
    views: Array<TemplateReportViewCreateInput>;
};

export type TemplateReportCustomTableCellInput = {
    content: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['Guid']['input']>;
    rowIndex: Scalars['Int']['input'];
};

export type TemplateReportCustomTableCellQl = {
    __typename: 'TemplateReportCustomTableCellQL';
    content: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    rowIndex: Scalars['Int']['output'];
    version: Maybe<Scalars['Version']['output']>;
};

export type TemplateReportCustomTableColumnInput = {
    cells: Array<TemplateReportCustomTableCellInput>;
    columnName: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['Guid']['input']>;
    sequence: Scalars['Int']['input'];
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TemplateReportCustomTableColumnQl = {
    __typename: 'TemplateReportCustomTableColumnQL';
    cells: Array<TemplateReportCustomTableCellQl>;
    columnName: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    sequence: Scalars['Int']['output'];
    version: Maybe<Scalars['Version']['output']>;
};

export type TemplateReportElementGroupInput = {
    id: InputMaybe<Scalars['Guid']['input']>;
    layoutDirection: InputMaybe<Scalars['String']['input']>;
    layoutGrow: InputMaybe<Scalars['String']['input']>;
    sequence: Scalars['Int']['input'];
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TemplateReportElementGroupQl = {
    __typename: 'TemplateReportElementGroupQL';
    id: Maybe<Scalars['Guid']['output']>;
    layoutDirection: Maybe<Scalars['String']['output']>;
    layoutGrow: Maybe<Scalars['String']['output']>;
    sequence: Scalars['Int']['output'];
    version: Maybe<Scalars['Version']['output']>;
};

export type TemplateReportElementInput = {
    attachmentId: InputMaybe<Scalars['Guid']['input']>;
    columns: Array<TemplateReportCustomTableColumnInput>;
    groupId: InputMaybe<Scalars['Guid']['input']>;
    id: InputMaybe<Scalars['Guid']['input']>;
    layoutGrow: InputMaybe<Scalars['String']['input']>;
    measureLinks: Array<TemplateReportMeasureLinkInput>;
    sectionId: InputMaybe<Scalars['Guid']['input']>;
    sequence: Scalars['Int']['input'];
    sourceTemplateReportSectionElementId: InputMaybe<Scalars['Guid']['input']>;
    taskLinks: Array<TemplateReportTaskLinkInput>;
    textContent: InputMaybe<Scalars['String']['input']>;
    title: InputMaybe<Scalars['String']['input']>;
    type: InputMaybe<TemplateReportElementTypes>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export enum TemplateReportElementTypes {
    CustomTable = 'CUSTOM_TABLE',
    Image = 'IMAGE',
    Label = 'LABEL',
    Markdown = 'MARKDOWN',
    MeasureList = 'MEASURE_LIST',
    MeasureTable = 'MEASURE_TABLE',
    TaskBulletList = 'TASK_BULLET_LIST',
    TaskTable = 'TASK_TABLE',
    Unknown = 'UNKNOWN',
}

export type TemplateReportInput = {
    id: InputMaybe<Scalars['Guid']['input']>;
    missionId: InputMaybe<Scalars['Guid']['input']>;
    reportDate: InputMaybe<Scalars['DateTime']['input']>;
    reportPeriod: InputMaybe<Scalars['Int']['input']>;
    reportPeriodType: InputMaybe<ReportPeriodTypes>;
    title: InputMaybe<Scalars['String']['input']>;
    utcCompletedDate: InputMaybe<Scalars['DateTime']['input']>;
    utcDataDate: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TemplateReportMeasureLinkInput = {
    id: InputMaybe<Scalars['Guid']['input']>;
    measureId: Scalars['Guid']['input'];
    sequence: Scalars['Int']['input'];
};

export type TemplateReportMeasureLinkQl = {
    __typename: 'TemplateReportMeasureLinkQL';
    id: Maybe<Scalars['Guid']['output']>;
    measureId: Scalars['Guid']['output'];
    sequence: Scalars['Int']['output'];
};

export type TemplateReportQl = {
    __typename: 'TemplateReportQL';
    attachments: Maybe<Array<AttachmentQl>>;
    id: Maybe<Scalars['Guid']['output']>;
    isTemplate: Scalars['Boolean']['output'];
    mission: Maybe<MissionQl>;
    missionId: Maybe<Scalars['Guid']['output']>;
    reportDate: Maybe<Scalars['DateTime']['output']>;
    reportPeriod: Maybe<Scalars['Int']['output']>;
    reportPeriodType: Maybe<ReportPeriodTypes>;
    sections: Array<TemplateReportSectionQl>;
    title: Maybe<Scalars['String']['output']>;
    utcCompletedDate: Maybe<Scalars['DateTime']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDataDate: Maybe<Scalars['DateTime']['output']>;
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
    views: Array<TemplateReportViewQl>;
};

export type TemplateReportSectionCreateInput = {
    availableElementTypes: Array<TemplateReportElementTypes>;
    elements: Array<TemplateReportElementInput>;
    groups: Array<TemplateReportElementGroupInput>;
    id: InputMaybe<Scalars['Guid']['input']>;
    layoutDirection: InputMaybe<Scalars['String']['input']>;
    sequence: Scalars['Int']['input'];
    templateReportViewId: InputMaybe<Scalars['Guid']['input']>;
    title: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TemplateReportSectionElementDeleteResult = {
    __typename: 'TemplateReportSectionElementDeleteResult';
    deletedObject: Maybe<TemplateReportSectionElementQl>;
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type TemplateReportSectionElementQl = {
    __typename: 'TemplateReportSectionElementQL';
    attachment: Maybe<AttachmentQl>;
    attachmentId: Maybe<Scalars['Guid']['output']>;
    columns: Array<TemplateReportCustomTableColumnQl>;
    group: Maybe<TemplateReportElementGroupQl>;
    groupId: Maybe<Scalars['Guid']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    layoutGrow: Maybe<Scalars['String']['output']>;
    measureLinks: Array<TemplateReportMeasureLinkQl>;
    sectionId: Maybe<Scalars['Guid']['output']>;
    sequence: Scalars['Int']['output'];
    sourceTemplateReportSectionElementId: Maybe<Scalars['Guid']['output']>;
    taskLinks: Array<TemplateReportTaskLinkQl>;
    textContent: Maybe<Scalars['String']['output']>;
    title: Maybe<Scalars['String']['output']>;
    type: TemplateReportElementTypes;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type TemplateReportSectionInput = {
    availableElementTypes: Array<TemplateReportElementTypes>;
    groups: Array<TemplateReportElementGroupInput>;
    id: InputMaybe<Scalars['Guid']['input']>;
    layoutDirection: InputMaybe<Scalars['String']['input']>;
    sequence: Scalars['Int']['input'];
    title: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TemplateReportSectionQl = {
    __typename: 'TemplateReportSectionQL';
    availableElementTypes: Array<TemplateReportElementTypes>;
    elements: Array<TemplateReportSectionElementQl>;
    groups: Array<TemplateReportElementGroupQl>;
    id: Maybe<Scalars['Guid']['output']>;
    layoutDirection: Maybe<Scalars['String']['output']>;
    sequence: Scalars['Int']['output'];
    templateReportViewId: Maybe<Scalars['Guid']['output']>;
    title: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type TemplateReportTaskLinkInput = {
    id: InputMaybe<Scalars['Guid']['input']>;
    sequence: Scalars['Int']['input'];
    taskId: Scalars['Guid']['input'];
};

export type TemplateReportTaskLinkQl = {
    __typename: 'TemplateReportTaskLinkQL';
    id: Maybe<Scalars['Guid']['output']>;
    sequence: Scalars['Int']['output'];
    taskId: Scalars['Guid']['output'];
};

export type TemplateReportViewCreateInput = {
    id: InputMaybe<Scalars['Guid']['input']>;
    title: InputMaybe<Scalars['String']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type TemplateReportViewQl = {
    __typename: 'TemplateReportViewQL';
    id: Maybe<Scalars['Guid']['output']>;
    templateReportId: Scalars['Guid']['output'];
    title: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
};

export type TemporalSeriesValueQl = {
    __typename: 'TemporalSeriesValueQL';
    asOf: Maybe<MeasureAsOfQl>;
    calcId: Maybe<Scalars['Int']['output']>;
    dateValue: Maybe<Scalars['Date']['output']>;
    decimalValue: Maybe<Scalars['Decimal']['output']>;
    formatStr: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    seriesType: Maybe<SeriesTypeQl>;
    stringValue: Maybe<Scalars['String']['output']>;
    sysEndTime: Scalars['DateTime']['output'];
    sysStartTime: Scalars['DateTime']['output'];
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    updatedByUserName: Maybe<Scalars['String']['output']>;
    utcCreated: Scalars['DateTime']['output'];
    utcDeleted: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
};

export type Tenant = {
    code: InputMaybe<Scalars['String']['input']>;
    description: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    isAnalyticsEnabled: InputMaybe<Scalars['Boolean']['input']>;
    isEnabled: InputMaybe<Scalars['Boolean']['input']>;
};

export type TenantQl = {
    __typename: 'TenantQL';
    code: Maybe<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    isAnalyticsEnabled: Scalars['Boolean']['output'];
    isEnabled: Scalars['Boolean']['output'];
    users: Array<UserQl>;
};

export type UsageReportQl = {
    __typename: 'UsageReportQL';
    error: Maybe<Scalars['String']['output']>;
    tenants: Array<UsageReportRowQl>;
    usersByTenant: Array<UsageReportUsersByTenantQl>;
};

export type UsageReportRowQl = {
    __typename: 'UsageReportRowQL';
    contributors: Scalars['Int']['output'];
    divisionCount: Scalars['Int']['output'];
    fyCode: Maybe<Scalars['String']['output']>;
    fyStart: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['String']['output'];
    info: Scalars['String']['output'];
    login7: Scalars['Int']['output'];
    login30: Scalars['Int']['output'];
    login90: Scalars['Int']['output'];
    missionCount: Scalars['Int']['output'];
    missionOwners: Scalars['Int']['output'];
    teamCount: Scalars['Int']['output'];
    tenantCode: Scalars['String']['output'];
    tenantName: Maybe<Scalars['String']['output']>;
    viewers: Scalars['Int']['output'];
};

export type UsageReportUserRowQl = {
    __typename: 'UsageReportUserRowQL';
    divisionNames: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    email: Maybe<Scalars['String']['output']>;
    info: Maybe<Scalars['String']['output']>;
    isContributor: Scalars['Boolean']['output'];
    isMissionOwner: Scalars['Boolean']['output'];
    isViewer: Scalars['Boolean']['output'];
    lastLogin: Maybe<Scalars['DateTimeOffset']['output']>;
    leaderOfTeamNames: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    loginDays: Scalars['Float']['output'];
    missionCount: Scalars['Int']['output'];
    missionUtcCreated: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    teamNames: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    utcCreated: Scalars['DateTime']['output'];
};

export type UsageReportUsersByTenantQl = {
    __typename: 'UsageReportUsersByTenantQL';
    tenantCode: Scalars['String']['output'];
    tenantName: Maybe<Scalars['String']['output']>;
    users: Array<UsageReportUserRowQl>;
};

export type User = {
    accessEnabled: Scalars['Boolean']['input'];
    displayName: InputMaybe<Scalars['String']['input']>;
    emailAddress: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['String']['input']>;
    userRoles: InputMaybe<Array<InputMaybe<UserRole>>>;
    userTenants: InputMaybe<Array<InputMaybe<UserTenant>>>;
    utcSelfHelpEnabledUntil: InputMaybe<Scalars['DateTime']['input']>;
    version: InputMaybe<Scalars['Version']['input']>;
};

export type UserLoginAuditQl = {
    __typename: 'UserLoginAuditQL';
    dateTime: Maybe<Scalars['DateTimeOffset']['output']>;
    ipAddress: Scalars['String']['output'];
    userAgent: Scalars['String']['output'];
};

export type UserQl = {
    __typename: 'UserQL';
    accessEnabled: Scalars['Boolean']['output'];
    displayName: Maybe<Scalars['String']['output']>;
    emailAddress: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['Guid']['output']>;
    /** Has this user just signed up? */
    isNewUser: Scalars['Boolean']['output'];
    /** The last login for the current user */
    lastLogin: Maybe<UserLoginAuditQl>;
    /** The login history for the current user */
    loginHistory: Maybe<Array<UserLoginAuditQl>>;
    missionFYs: Array<FinancialYearQl>;
    rights: Array<RightQl>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    userRoles: Array<UserRoleQl>;
    userTenants: Array<UserTenantQl>;
    utcCreated: Maybe<Scalars['DateTime']['output']>;
    utcSelfHelpEnabledUntil: Maybe<Scalars['DateTime']['output']>;
    utcUpdated: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type UserRole = {
    id: InputMaybe<Scalars['String']['input']>;
    roleId: Scalars['String']['input'];
    tenantId: InputMaybe<Scalars['String']['input']>;
    userId: Scalars['String']['input'];
};

export type UserRoleQl = {
    __typename: 'UserRoleQL';
    id: Maybe<Scalars['Guid']['output']>;
    name: Scalars['String']['output'];
    role: RoleQl;
    roleId: Scalars['Guid']['output'];
    tenantId: Maybe<Scalars['Guid']['output']>;
    userId: Scalars['Guid']['output'];
};

export type UserTenant = {
    id: InputMaybe<Scalars['String']['input']>;
    isDefault: Scalars['Boolean']['input'];
    tenantId: InputMaybe<Scalars['String']['input']>;
    userId: InputMaybe<Scalars['String']['input']>;
};

export type UserTenantQl = {
    __typename: 'UserTenantQL';
    id: Maybe<Scalars['Guid']['output']>;
    isDefault: Scalars['Boolean']['output'];
    tenant: TenantQl;
    tenantId: Scalars['Guid']['output'];
    userId: Scalars['Guid']['output'];
};

export type UserUsageQl = {
    __typename: 'UserUsageQL';
    email: Maybe<Scalars['String']['output']>;
    lastSeen: Maybe<Scalars['DateTime']['output']>;
    totalDistinctDays: Maybe<Scalars['Int']['output']>;
    totalUsageInMinutes: Maybe<Scalars['Int']['output']>;
};

export type ValueArgumentQl = {
    __typename: 'ValueArgumentQL';
    argType: ArgumentTypes;
    decimalValue: Maybe<Scalars['Decimal']['output']>;
    function: Maybe<ValueFunctionQl>;
    functionName: Maybe<Scalars['String']['output']>;
    measureId: Maybe<Scalars['Guid']['output']>;
    variableName: Maybe<Scalars['String']['output']>;
};

export type ValueFormulaMeasureLookupQl = {
    __typename: 'ValueFormulaMeasureLookupQL';
    measureId: Scalars['ID']['output'];
    seriesType: Scalars['String']['output'];
};

export type ValueFormulaQl = {
    __typename: 'ValueFormulaQL';
    calculatedSeries: Array<Maybe<Scalars['String']['output']>>;
    formula: Maybe<Scalars['String']['output']>;
    formulaPP: Maybe<Scalars['String']['output']>;
    measureIdList: Array<Scalars['Guid']['output']>;
    measureLookups: Array<ValueFormulaMeasureLookupQl>;
    measures: Array<MeasureQl>;
    statements: Array<Maybe<ValueStatementQl>>;
    stepLogs: Array<ValueFormulaStepLogQl>;
    variables: Array<ValueVariableQl>;
};

export type ValueFormulaStepLogQl = {
    __typename: 'ValueFormulaStepLogQL';
    asOfDate: Scalars['Date']['output'];
    seriesName: Scalars['String']['output'];
    steps: Array<ValueFormulaStepQl>;
};

export type ValueFormulaStepQl = {
    __typename: 'ValueFormulaStepQL';
    decimalValue: Maybe<Scalars['Decimal']['output']>;
    formatNumber: Scalars['Boolean']['output'];
    indent: Maybe<Scalars['Int']['output']>;
    name: Maybe<Scalars['String']['output']>;
};

export type ValueFunctionQl = {
    __typename: 'ValueFunctionQL';
    arguments: Array<ValueArgumentQl>;
    functionName: Maybe<Scalars['String']['output']>;
};

export type ValueStatementQl = {
    __typename: 'ValueStatementQL';
    root: ValueArgumentQl;
    seriesName: Maybe<Scalars['String']['output']>;
};

export enum ValueTypes {
    Incremental = 'INCREMENTAL',
    Simple = 'SIMPLE',
}

export type ValueVariableQl = {
    __typename: 'ValueVariableQL';
    name: Maybe<Scalars['String']['output']>;
    value: ValueArgumentQl;
};

export type VersionTimestampQl = {
    __typename: 'VersionTimestampQL';
    action: ActionType;
    batchId: Scalars['Guid']['output'];
    id: Maybe<Scalars['Guid']['output']>;
    objectId: Maybe<Scalars['Guid']['output']>;
    sysStartTime: Maybe<Scalars['DateTime']['output']>;
    table: Maybe<Scalars['String']['output']>;
    updatedByUserId: Maybe<Scalars['Guid']['output']>;
    username: Maybe<Scalars['String']['output']>;
    utcBatchDate: Maybe<Scalars['DateTime']['output']>;
    utcRolledBack: Maybe<Scalars['DateTime']['output']>;
    version: Maybe<Scalars['Version']['output']>;
};

export type UpdateMissionMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Mission;
}>;

export type UpdateMissionMutation = {
    __typename: 'Mutations';
    missionUpdate: {
        __typename: 'MissionQL';
        id: string | null;
        userId: string | null;
        username: string | null;
        title: string | null;
        owner: string | null;
        missionStatement: string | null;
        missionDate: string | null;
        missionGroupId: string | null;
        customMissionStatement1Up: string | null;
        customMissionStatement2Up: string | null;
        sequence: number;
        utcInactive: string | null;
        utcDeleted: string | null;
        version: string | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
        } | null;
        dottedTeams: Array<{
            __typename: 'DottedTeamMissionQL';
            id: string | null;
            teamId: string | null;
            sequence: number;
            missionGroupId: string | null;
        }>;
        behaviours: Array<{
            __typename: 'MissionPhraseQL';
            id: string | null;
            text: string | null;
            sequence: number;
            version: string | null;
        }>;
        freedoms: Array<{
            __typename: 'MissionPhraseQL';
            id: string | null;
            text: string | null;
            sequence: number;
            version: string | null;
        }>;
        constraints: Array<{
            __typename: 'MissionPhraseQL';
            id: string | null;
            text: string | null;
            sequence: number;
            version: string | null;
        }>;
    } | null;
};

export type DeleteMissionMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteMissionMutation = {
    __typename: 'Mutations';
    missionDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateMeasureMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Measure;
}>;

export type UpdateMeasureMutation = {
    __typename: 'Mutations';
    measureUpdate: {
        __typename: 'MeasureQL';
        id: string | null;
        missionId: string;
        measureGroupId: string | null;
        name: string | null;
        description: string | null;
        measureType: MeasureTypes;
        phaseType: PhaseType;
        multiplier: Multipliers;
        decimalPlaces: number;
        statusType: StatusTypes;
        yellowStart: number;
        greenRange: number;
        yellowRange: number;
        isStatusLimited: boolean;
        frequencyNumber: number;
        frequencyPeriod: FrequencyPeriods;
        sequence: number;
        isLinked: boolean;
        utcUpdated: string | null;
        fullYearString: string | null;
        fullYearTarget: number | null;
        isFullYearTarget: boolean;
        chartDisplay: ChartDisplay;
        chartType: ChartType;
        showForecast: boolean;
        showFutureLook: boolean;
        isCustom: boolean;
        targetType: TargetTypes;
        valueType: ValueTypes;
        valueFormula: string | null;
        version: string | null;
        currency: {
            __typename: 'CurrencyQL';
            code: string;
            symbol: string | null;
            descr: string | null;
        } | null;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
        linkedFromMeasure: {
            __typename: 'MeasureQL';
            id: string | null;
            linkedMeasures: Array<{
                __typename: 'MeasureQL';
                id: string | null;
                mission: {
                    __typename: 'MissionQL';
                    id: string | null;
                    userId: string | null;
                    title: string | null;
                    owner: string | null;
                } | null;
            }>;
        } | null;
        tags: Array<{
            __typename: 'TagQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
            icon: string | null;
            tagType: TagTypes;
            isMeasureTag: boolean;
            isTaskTag: boolean;
        }>;
    } | null;
};

export type UpdateMeasureGroupMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: MeasureGroup;
}>;

export type UpdateMeasureGroupMutation = {
    __typename: 'Mutations';
    measureGroupUpdate: {
        __typename: 'MeasureGroupQL';
        id: string;
        missionId: string;
        name: string;
        sequence: number;
        version: string | null;
    } | null;
};

export type UpdateMeasureSequenceMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
    sequence: Scalars['Int']['input'];
    measureGroupId: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateMeasureSequenceMutation = {
    __typename: 'Mutations';
    measureSequenceUpdate: {
        __typename: 'MeasureQL';
        id: string | null;
        sequence: number;
        measureGroupId: string | null;
        version: string | null;
    } | null;
};

export type UnlinkMeasureMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
    keepValues: Scalars['Boolean']['input'];
}>;

export type UnlinkMeasureMutation = {
    __typename: 'Mutations';
    measureUnlink: {
        __typename: 'MeasureQL';
        id: string | null;
        name: string | null;
        isLinked: boolean;
        version: string | null;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            version: string | null;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
    } | null;
};

export type TransferMeasureMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
    targetMissionId: Scalars['ID']['input'];
    keepLinkedCopy: Scalars['Boolean']['input'];
}>;

export type TransferMeasureMutation = {
    __typename: 'Mutations';
    measureTransfer: {
        __typename: 'MeasureQL';
        id: string | null;
        missionId: string;
        isLinked: boolean;
        utcDeleted: string | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            userId: string | null;
            title: string | null;
            owner: string | null;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
        } | null;
        linkedMeasures: Array<{
            __typename: 'MeasureQL';
            id: string | null;
            isLinked: boolean;
            linkedFromMeasureId: string | null;
            linkedFromMeasure: {
                __typename: 'MeasureQL';
                id: string | null;
                name: string | null;
                mission: {
                    __typename: 'MissionQL';
                    id: string | null;
                    userId: string | null;
                    title: string | null;
                    owner: string | null;
                } | null;
            } | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                title: string | null;
                owner: string | null;
            } | null;
        }>;
    } | null;
};

export type RecalculateAllMeasuresMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type RecalculateAllMeasuresMutation = {
    __typename: 'Mutations';
    measureRecalculateAll: Array<{
        __typename: 'MeasureQL';
        id: string | null;
        version: string | null;
        utcUpdated: string | null;
    }>;
};

export type RecalculateAllTasksMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type RecalculateAllTasksMutation = {
    __typename: 'Mutations';
    taskRecalculateAll: Array<{
        __typename: 'TaskQL';
        id: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        percentComplete: number;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        utcUpdated: string | null;
        version: string | null;
    }>;
};

export type UpdateMissionSequenceMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
    sequence: Scalars['Int']['input'];
    missionGroupId: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateMissionSequenceMutation = {
    __typename: 'Mutations';
    missionSequenceUpdate: {
        __typename: 'MissionQL';
        id: string | null;
        sequence: number;
        missionGroupId: string | null;
        version: string | null;
    } | null;
};

export type UpdateDottedMissionSequenceMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    teamId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
    sequence: Scalars['Int']['input'];
    missionGroupId: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateDottedMissionSequenceMutation = {
    __typename: 'Mutations';
    dottedMissionSequenceUpdate: {
        __typename: 'DottedTeamMissionQL';
        id: string | null;
        sequence: number;
        missionGroupId: string | null;
    } | null;
};

export type UpdateTaskSequenceMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    taskId: Scalars['ID']['input'];
    sequence: Scalars['Int']['input'];
    targetParentTaskId: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateTaskSequenceMutation = {
    __typename: 'Mutations';
    taskSequenceUpdate: {
        __typename: 'TaskQL';
        id: string | null;
        sequence: number;
        missionId: string | null;
        parentTaskId: string | null;
        utcUpdated: string | null;
        version: string | null;
        parentTask: {
            __typename: 'TaskQL';
            id: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            version: string | null;
            resourcedFromTask: {
                __typename: 'TaskQL';
                id: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
            } | null;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
                resourcedFromTask: {
                    __typename: 'TaskQL';
                    id: string | null;
                    percentComplete: number;
                    utcUpdated: string | null;
                    version: string | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type DeleteMeasureMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteMeasureMutation = {
    __typename: 'Mutations';
    measureDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type DeleteMeasureGroupMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteMeasureGroupMutation = {
    __typename: 'Mutations';
    measureGroupDelete: {
        __typename: 'MeasureGroupDeleteResult';
        message: string;
        success: boolean;
        deletedObject: {
            __typename: 'MeasureGroupQL';
            id: string;
            utcDeleted: string | null;
        } | null;
    } | null;
};

export type UpdateMeasureAsOfMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: MeasureAsOf;
}>;

export type UpdateMeasureAsOfMutation = {
    __typename: 'Mutations';
    measureAsOfUpdate: {
        __typename: 'MeasureAsOfQL';
        id: string;
        measureId: string;
        asOfDate: string;
        statusValue: number;
        arrowColour: Arrows;
        arrowDirection: ArrowDirection;
        version: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        values: Array<{
            __typename: 'SeriesValueQL';
            id: string | null;
            calcId: number | null;
            decimalValue: number | null;
            stringValue: string | null;
            dateValue: string | null;
            formatStr: string | null;
            version: string | null;
            utcCreated: string | null;
            utcUpdated: string | null;
            seriesType: {
                __typename: 'SeriesTypeQL';
                name: string | null;
            } | null;
        }> | null;
    } | null;
};

export type FastUpdateMeasureAsOfMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: MeasureAsOf;
}>;

export type FastUpdateMeasureAsOfMutation = {
    __typename: 'Mutations';
    measureAsOfUpdate: {
        __typename: 'MeasureAsOfQL';
        id: string;
        measureId: string;
        asOfDate: string;
        statusValue: number;
        arrowColour: Arrows;
        arrowDirection: ArrowDirection;
        version: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        values: Array<{
            __typename: 'SeriesValueQL';
            id: string | null;
            calcId: number | null;
            decimalValue: number | null;
            stringValue: string | null;
            dateValue: string | null;
            formatStr: string | null;
            version: string | null;
            utcCreated: string | null;
            utcUpdated: string | null;
            seriesType: {
                __typename: 'SeriesTypeQL';
                name: string | null;
            } | null;
        }> | null;
    } | null;
};

export type RecalculateMeasureMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type RecalculateMeasureMutation = {
    __typename: 'Mutations';
    measureRecalculate: {
        __typename: 'MeasureQL';
        id: string | null;
        utcUpdated: string | null;
        version: string | null;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            measureId: string;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            version: string | null;
            utcCreated: string;
            utcUpdated: string | null;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                calcId: number | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                version: string | null;
                utcCreated: string | null;
                utcUpdated: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
    };
};

export type UpdateTaskMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Task;
}>;

export type UpdateTaskMutation = {
    __typename: 'Mutations';
    taskUpdate: {
        __typename: 'TaskQL';
        id: string | null;
        missionId: string | null;
        parentTaskId: string | null;
        name: string | null;
        description: string | null;
        percentComplete: number;
        start: string | null;
        due: string | null;
        done: string | null;
        review: string | null;
        resourcedFromTaskId: string | null;
        resourceId: string | null;
        utcAccepted: string | null;
        utcRejected: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        rejectedReason: string | null;
        utcResourceRemoved: string | null;
        utcChangesPending: string | null;
        isPercentageCompleteFromResources: boolean;
        isPercentageCompleteFromSubTasks: boolean;
        resourceIsPrimary: boolean;
        effortWeight: number;
        effortResourceWeight: number;
        costWeight: number;
        isDuplicate: boolean;
        sequence: number;
        taskCategoryId: string | null;
        utcUpdated: string | null;
        version: string | null;
        linkedMeasures: Array<{
            __typename: 'LinkedMeasureQL';
            id: string | null;
            measureId: string;
            taskId: string;
        }>;
        taskCategory: {
            __typename: 'TaskCategoryQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
        } | null;
        parentTask: {
            __typename: 'TaskQL';
            id: string | null;
            parentTaskId: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            version: string | null;
            resourcedFromTask: {
                __typename: 'TaskQL';
                id: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
                parentTask: {
                    __typename: 'TaskQL';
                    id: string | null;
                    percentComplete: number;
                    done: string | null;
                    utcUpdated: string | null;
                    version: string | null;
                } | null;
            } | null;
            subTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                start: string | null;
                due: string | null;
                done: string | null;
                percentComplete: number;
                utcCancelled: string | null;
                utcPostponed: string | null;
                utcAtRisk: string | null;
                utcUpdated: string | null;
                version: string | null;
            }>;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
                resourcedFromTask: {
                    __typename: 'TaskQL';
                    id: string | null;
                    percentComplete: number;
                    utcUpdated: string | null;
                    version: string | null;
                } | null;
            } | null;
        } | null;
        subTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            start: string | null;
            due: string | null;
            done: string | null;
            percentComplete: number;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            utcUpdated: string | null;
            version: string | null;
        }>;
        resource: {
            __typename: 'ResourceQL';
            id: string | null;
            userId: string | null;
            userMissionFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
            userContributorFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
        } | null;
        resourcedFromTask: {
            __typename: 'TaskQL';
            id: string | null;
            percentComplete: number;
            done: string | null;
            version: string | null;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                start: string | null;
                due: string | null;
                done: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
            } | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
            } | null;
            subTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                start: string | null;
                due: string | null;
                done: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
            }>;
            resourcedTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                resource: {
                    __typename: 'ResourceQL';
                    id: string | null;
                    userId: string | null;
                    displayName: string | null;
                    utcDeleted: string | null;
                } | null;
            }>;
        } | null;
    } | null;
};

export type UpdateTaskTagsMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    taskId: Scalars['ID']['input'];
    tags: Array<Tag> | Tag;
}>;

export type UpdateTaskTagsMutation = {
    __typename: 'Mutations';
    taskTagsUpdate: {
        __typename: 'TaskQL';
        id: string | null;
        utcUpdated: string | null;
        version: string | null;
        tags: Array<{
            __typename: 'TagQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
            icon: string | null;
            tagType: TagTypes;
            isMeasureTag: boolean;
            isTaskTag: boolean;
        }>;
    } | null;
};

export type UpdateTaskStatusMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: TaskStatusInput;
}>;

export type UpdateTaskStatusMutation = {
    __typename: 'Mutations';
    taskStatusUpdate: {
        __typename: 'TaskQL';
        id: string | null;
        percentComplete: number;
        start: string | null;
        due: string | null;
        done: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        utcUpdated: string | null;
        version: string | null;
        subTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            start: string | null;
            due: string | null;
            done: string | null;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            percentComplete: number;
            utcUpdated: string | null;
            version: string | null;
        }>;
        parentTask: {
            __typename: 'TaskQL';
            id: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            version: string | null;
            subTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                start: string | null;
                due: string | null;
                done: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
            }>;
        } | null;
        resourcedFromTask: {
            __typename: 'TaskQL';
            id: string | null;
            percentComplete: number;
            done: string | null;
            version: string | null;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                start: string | null;
                due: string | null;
                done: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
            } | null;
            subTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                start: string | null;
                due: string | null;
                done: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
            }>;
        } | null;
    } | null;
};

export type UpdateResourceTaskMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: ResourceTask;
}>;

export type UpdateResourceTaskMutation = {
    __typename: 'Mutations';
    resourceTaskUpdate: {
        __typename: 'TaskQL';
        id: string | null;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        percentComplete: number;
        utcRejected: string | null;
        rejectedReason: string | null;
        utcResourceRemoved: string | null;
        utcChangesPending: string | null;
        resourceIsPrimary: boolean;
        effortResourceWeight: number;
        utcUpdated: string | null;
        version: string | null;
    } | null;
};

export type DeleteTaskMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    isTaskResource: InputMaybe<Scalars['Boolean']['input']>;
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteTaskMutation = {
    __typename: 'Mutations';
    taskDelete: {
        __typename: 'TaskDeleteResult';
        message: string;
        success: boolean;
        deletedObject: {
            __typename: 'TaskQL';
            id: string | null;
            name: string | null;
            utcDeleted: string | null;
            utcAccepted: string | null;
            utcRejected: string | null;
            utcResourceRemoved: string | null;
            version: string | null;
            subTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                utcDeleted: string | null;
                utcAccepted: string | null;
                utcRejected: string | null;
                utcResourceRemoved: string | null;
                version: string | null;
            }>;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
            } | null;
            resourcedTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                utcResourceRemoved: string | null;
                utcAccepted: string | null;
                utcRejected: string | null;
                utcDeleted: string | null;
                version: string | null;
            }>;
            resourcedFromTask: {
                __typename: 'TaskQL';
                id: string | null;
                percentComplete: number;
                utcUpdated: string | null;
                version: string | null;
                parentTask: {
                    __typename: 'TaskQL';
                    id: string | null;
                    percentComplete: number;
                    utcUpdated: string | null;
                    version: string | null;
                } | null;
                resourcedTasks: Array<{
                    __typename: 'TaskQL';
                    id: string | null;
                    utcResourceRemoved: string | null;
                    utcAccepted: string | null;
                    utcRejected: string | null;
                    utcDeleted: string | null;
                    version: string | null;
                }>;
            } | null;
        } | null;
    } | null;
};

export type AttachmentUpdateMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Attachment;
}>;

export type AttachmentUpdateMutation = {
    __typename: 'Mutations';
    attachmentUpdate: {
        __typename: 'AttachmentQL';
        id: string | null;
        name: string | null;
        href: string | null;
        uploadUri: string | null;
        containerPath: string | null;
        isFile: boolean;
        isUrl: boolean;
        utcCreated: string;
        utcUpdated: string | null;
        version: string | null;
        userId: string | null;
        task: {
            __typename: 'TaskQL';
            id: string | null;
            attachmentCount: number;
            lastAttachment: {
                __typename: 'AttachmentQL';
                id: string | null;
                name: string | null;
            } | null;
            attachments: Array<{
                __typename: 'AttachmentQL';
                id: string | null;
                name: string | null;
                href: string | null;
                uploadUri: string | null;
                containerPath: string | null;
                isFile: boolean;
                isUrl: boolean;
                utcCreated: string;
                utcUpdated: string | null;
                userId: string | null;
                username: string | null;
                version: string | null;
            }>;
        } | null;
        measure: {
            __typename: 'MeasureQL';
            id: string | null;
            attachmentCount: number;
            lastAttachment: {
                __typename: 'AttachmentQL';
                id: string | null;
                name: string | null;
            } | null;
            attachments: Array<{
                __typename: 'AttachmentQL';
                id: string | null;
                name: string | null;
                href: string | null;
                uploadUri: string | null;
                containerPath: string | null;
                isFile: boolean;
                isUrl: boolean;
                utcCreated: string;
                utcUpdated: string | null;
                userId: string | null;
                username: string | null;
                version: string | null;
            }> | null;
        } | null;
        templateReport: {
            __typename: 'TemplateReportQL';
            id: string | null;
            attachments: Array<{
                __typename: 'AttachmentQL';
                id: string | null;
                name: string | null;
                href: string | null;
                uploadUri: string | null;
                containerPath: string | null;
                isFile: boolean;
                isUrl: boolean;
                utcCreated: string;
                utcUpdated: string | null;
                userId: string | null;
                username: string | null;
                version: string | null;
            }> | null;
        } | null;
    } | null;
};

export type AttachmentDeleteMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AttachmentDeleteMutation = {
    __typename: 'Mutations';
    attachmentDelete: {
        __typename: 'AttachmentDeleteResult';
        message: string;
        success: boolean;
        deletedObject: {
            __typename: 'AttachmentQL';
            id: string | null;
            utcDeleted: string | null;
            task: {
                __typename: 'TaskQL';
                id: string | null;
                attachmentCount: number;
                lastAttachment: {
                    __typename: 'AttachmentQL';
                    id: string | null;
                    name: string | null;
                } | null;
                attachments: Array<{
                    __typename: 'AttachmentQL';
                    id: string | null;
                    name: string | null;
                    href: string | null;
                    uploadUri: string | null;
                    containerPath: string | null;
                    isFile: boolean;
                    isUrl: boolean;
                    utcCreated: string;
                    utcUpdated: string | null;
                    userId: string | null;
                    username: string | null;
                    version: string | null;
                }>;
            } | null;
            measure: {
                __typename: 'MeasureQL';
                id: string | null;
                attachmentCount: number;
                lastAttachment: {
                    __typename: 'AttachmentQL';
                    id: string | null;
                    name: string | null;
                } | null;
                attachments: Array<{
                    __typename: 'AttachmentQL';
                    id: string | null;
                    name: string | null;
                    href: string | null;
                    uploadUri: string | null;
                    containerPath: string | null;
                    isFile: boolean;
                    isUrl: boolean;
                    utcCreated: string;
                    utcUpdated: string | null;
                    userId: string | null;
                    username: string | null;
                    version: string | null;
                }> | null;
            } | null;
            templateReport: {
                __typename: 'TemplateReportQL';
                id: string | null;
                attachments: Array<{
                    __typename: 'AttachmentQL';
                    id: string | null;
                    name: string | null;
                    href: string | null;
                    uploadUri: string | null;
                    containerPath: string | null;
                    isFile: boolean;
                    isUrl: boolean;
                    utcCreated: string;
                    utcUpdated: string | null;
                    userId: string | null;
                    username: string | null;
                    version: string | null;
                }> | null;
            } | null;
        } | null;
    } | null;
};

export type UpdateCommentMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Comment;
}>;

export type UpdateCommentMutation = {
    __typename: 'Mutations';
    commentUpdate: {
        __typename: 'CommentQL';
        id: string | null;
        text: string | null;
        measureId: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        version: string | null;
        task: {
            __typename: 'TaskQL';
            id: string | null;
            commentCount: number;
            lastComment: {
                __typename: 'CommentQL';
                id: string | null;
                text: string | null;
                username: string | null;
                userId: string | null;
                utcCreated: string;
            } | null;
        } | null;
        measure: {
            __typename: 'MeasureQL';
            id: string | null;
            commentCount: number;
            lastComment: {
                __typename: 'CommentQL';
                id: string | null;
                text: string | null;
                username: string | null;
                userId: string | null;
                utcCreated: string;
            } | null;
        } | null;
    } | null;
};

export type DeleteCommentMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteCommentMutation = {
    __typename: 'Mutations';
    commentDelete: {
        __typename: 'CommentDeleteResult';
        message: string;
        success: boolean;
        deletedObject: {
            __typename: 'CommentQL';
            id: string | null;
            utcDeleted: string | null;
            task: {
                __typename: 'TaskQL';
                id: string | null;
                commentCount: number;
                lastComment: {
                    __typename: 'CommentQL';
                    id: string | null;
                    text: string | null;
                    username: string | null;
                    userId: string | null;
                    utcCreated: string;
                } | null;
            } | null;
            measure: {
                __typename: 'MeasureQL';
                id: string | null;
                commentCount: number;
                lastComment: {
                    __typename: 'CommentQL';
                    id: string | null;
                    text: string | null;
                    username: string | null;
                    userId: string | null;
                    utcCreated: string;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type LikeCommentToggleMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Like;
    isLiked: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LikeCommentToggleMutation = {
    __typename: 'Mutations';
    likeToggle: {
        __typename: 'CommentQL';
        id: string | null;
        likes: Array<{
            __typename: 'LikeQL';
            id: string | null;
            userId: string | null;
            username: string | null;
            version: string | null;
        }>;
    } | null;
};

export type TaskAcceptMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    taskId: Scalars['ID']['input'];
    isAccepted: Scalars['Boolean']['input'];
    isRejected: Scalars['Boolean']['input'];
    rejectReason: InputMaybe<Scalars['String']['input']>;
    parentTaskId: InputMaybe<Scalars['ID']['input']>;
    assignToTaskId: InputMaybe<Scalars['ID']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    taskName: InputMaybe<Scalars['String']['input']>;
    isDuplicate: Scalars['Boolean']['input'];
}>;

export type TaskAcceptMutation = {
    __typename: 'Mutations';
    taskAccept: {
        __typename: 'TaskQL';
        id: string | null;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        percentComplete: number;
        parentTaskId: string | null;
        missionId: string | null;
        utcAccepted: string | null;
        utcRejected: string | null;
        rejectedReason: string | null;
        isDuplicate: boolean;
        utcUpdated: string | null;
        version: string | null;
        resourcedFromTask: {
            __typename: 'TaskQL';
            id: string | null;
            percentComplete: number;
            utcUpdated: string | null;
            version: string | null;
        } | null;
        parentTask: {
            __typename: 'TaskQL';
            id: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            utcUpdated: string | null;
            version: string | null;
        } | null;
        resource: {
            __typename: 'ResourceQL';
            id: string | null;
            userId: string | null;
        } | null;
        mission: { __typename: 'MissionQL'; id: string | null } | null;
    } | null;
};

export type UpdateDivisionMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Division;
}>;

export type UpdateDivisionMutation = {
    __typename: 'Mutations';
    divisionUpdate: {
        __typename: 'DivisionQL';
        id: string | null;
        code: string | null;
        name: string | null;
        purpose: string | null;
        vision: string | null;
        values: string | null;
        primaryColourHex: string | null;
        canUnpublishReport: boolean;
        financialYearId: string | null;
        utcInactive: string | null;
        version: string | null;
        financialYear: {
            __typename: 'FinancialYearQL';
            id: string | null;
            code: string | null;
        } | null;
    } | null;
};

export type UpdateResourceMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Resource;
}>;

export type UpdateResourceMutation = {
    __typename: 'Mutations';
    resourceUpdate: {
        __typename: 'ResourceQL';
        id: string | null;
        userId: string | null;
        name: string | null;
        displayName: string | null;
        version: string | null;
        tasks: Array<{ __typename: 'TaskQL'; id: string | null }>;
    } | null;
};

export type UpdateResourceTaskUserMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    taskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
    targetUserId: Scalars['ID']['input'];
}>;

export type UpdateResourceTaskUserMutation = {
    __typename: 'Mutations';
    resourceTaskUserUpdate: Array<{
        __typename: 'TaskQL';
        id: string | null;
        resourceId: string | null;
        resource: {
            __typename: 'ResourceQL';
            id: string | null;
            userId: string | null;
            name: string | null;
            displayName: string | null;
            version: string | null;
        } | null;
    }>;
};

export type DeleteResourceMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteResourceMutation = {
    __typename: 'Mutations';
    resourceDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateTagMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Tag;
}>;

export type UpdateTagMutation = {
    __typename: 'Mutations';
    tagUpdate: {
        __typename: 'TagQL';
        id: string | null;
        name: string | null;
        colourHex: string | null;
        icon: string | null;
        tagType: TagTypes;
        isMeasureTag: boolean;
        isTaskTag: boolean;
    } | null;
};

export type DeleteTagMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteTagMutation = {
    __typename: 'Mutations';
    tagDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateTaskCategoryMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: TaskCategory;
}>;

export type UpdateTaskCategoryMutation = {
    __typename: 'Mutations';
    taskCategoryUpdate: {
        __typename: 'TaskCategoryQL';
        id: string | null;
        name: string | null;
        colourHex: string | null;
        version: string | null;
    } | null;
};

export type DeleteTaskCategoryMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteTaskCategoryMutation = {
    __typename: 'Mutations';
    taskCategoryDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type SwapTaskCategoryMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    swapId: InputMaybe<Scalars['ID']['input']>;
}>;

export type SwapTaskCategoryMutation = {
    __typename: 'Mutations';
    taskCategorySwap: {
        __typename: 'ActionResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateUserMutationVariables = Exact<{
    input: User;
}>;

export type UpdateUserMutation = {
    __typename: 'Mutations';
    userUpdate: {
        __typename: 'UserQL';
        id: string | null;
        displayName: string | null;
        emailAddress: string | null;
        accessEnabled: boolean;
        utcSelfHelpEnabledUntil: string | null;
        utcUpdated: string | null;
        version: string | null;
        userTenants: Array<{
            __typename: 'UserTenantQL';
            tenantId: string;
            isDefault: boolean;
        }>;
        userRoles: Array<{
            __typename: 'UserRoleQL';
            roleId: string;
            tenantId: string | null;
            name: string;
        }>;
    } | null;
};

export type UpdateContributorMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Contributor;
}>;

export type UpdateContributorMutation = {
    __typename: 'Mutations';
    contributorUpdate: {
        __typename: 'ContributorQL';
        id: string | null;
        userId: string;
        userDisplayName: string | null;
        teamId: string | null;
        financialYearId: string;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
        } | null;
        financialYear: {
            __typename: 'FinancialYearQL';
            id: string | null;
            code: string | null;
        } | null;
    } | null;
};

export type DeleteContributorMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    contributorId: Scalars['ID']['input'];
}>;

export type DeleteContributorMutation = {
    __typename: 'Mutations';
    contributorDelete: {
        __typename: 'ContributorDeleteResult';
        message: string;
        success: boolean;
        deletedObject: {
            __typename: 'ContributorQL';
            id: string | null;
            utcDeleted: string | null;
        } | null;
    } | null;
};

export type UpdateRightMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    right: Right;
}>;

export type UpdateRightMutation = {
    __typename: 'Mutations';
    rightUpdate: {
        __typename: 'RightQL';
        id: string;
        read: boolean;
        write: boolean;
        export: boolean;
        import: boolean;
        divisionId: string | null;
        teamId: string | null;
        missionId: string | null;
        userId: string;
    };
};

export type RightDeleteMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RightDeleteMutation = {
    __typename: 'Mutations';
    rightDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateUserTenantDefaultMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
}>;

export type UpdateUserTenantDefaultMutation = {
    __typename: 'Mutations';
    userUpdateTenantDefault: Array<{
        __typename: 'UserTenantQL';
        id: string | null;
        userId: string;
        tenantId: string;
        isDefault: boolean;
        tenant: {
            __typename: 'TenantQL';
            id: string | null;
            code: string | null;
            description: string | null;
            isEnabled: boolean;
            isAnalyticsEnabled: boolean;
        };
    }>;
};

export type DeleteUserMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteUserMutation = {
    __typename: 'Mutations';
    userDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateFinancialYearMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: FinancialYear;
}>;

export type UpdateFinancialYearMutation = {
    __typename: 'Mutations';
    financialYearUpdate: {
        __typename: 'FinancialYearQL';
        id: string | null;
        code: string | null;
        name: string | null;
        startDate: string;
        endDate: string;
        utcInactive: string | null;
        version: string | null;
    } | null;
};

export type UpdateTeamMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: Team;
}>;

export type UpdateTeamMutation = {
    __typename: 'Mutations';
    teamUpdate: {
        __typename: 'TeamQL';
        id: string | null;
        code: string | null;
        name: string | null;
        utcUpdated: string | null;
        utcCreated: string;
        version: string | null;
        division: { __typename: 'DivisionQL'; id: string | null } | null;
        leaderMission: { __typename: 'MissionQL'; id: string | null } | null;
    } | null;
};

export type DeleteTeamMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteTeamMutation = {
    __typename: 'Mutations';
    teamDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateMissionGroupMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: MissionGroup;
}>;

export type UpdateMissionGroupMutation = {
    __typename: 'Mutations';
    missionGroupUpdate: {
        __typename: 'MissionGroupQL';
        id: string;
        teamId: string;
        name: string;
        sequence: number;
        version: string | null;
    } | null;
};

export type DeleteMissionGroupMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteMissionGroupMutation = {
    __typename: 'Mutations';
    missionGroupDelete: {
        __typename: 'MissionGroupDeleteResult';
        message: string;
        success: boolean;
        deletedObject: {
            __typename: 'MissionGroupQL';
            id: string;
            utcDeleted: string | null;
        } | null;
    } | null;
};

export type UpdateStatusReportMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: StatusReport;
    notifyUserIds: InputMaybe<
        Array<Scalars['ID']['input']> | Scalars['ID']['input']
    >;
}>;

export type UpdateStatusReportMutation = {
    __typename: 'Mutations';
    statusReportUpdate: {
        __typename: 'StatusReportQL';
        id: string | null;
        missionId: string;
        reportDate: string | null;
        utcCompletedDate: string | null;
        utcDataDate: string | null;
        title: string | null;
        summaryText: string | null;
        lastPeriodText: string | null;
        nextPeriodText: string | null;
        risksAndOpportunitiesText: string | null;
        supportText: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        utcDeleted: string | null;
        version: string | null;
        facts: Array<{
            __typename: 'StatusReportFactQL';
            id: string | null;
            measureId: string | null;
            taskId: string | null;
            factText: string | null;
            soWhatText: string | null;
            insightText: string | null;
            actionText: string | null;
            sequence: number;
            isIncluded: boolean;
            version: string | null;
        }>;
    };
};

export type UpdateStatusReportFactMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    statusReportId: Scalars['ID']['input'];
    fact: StatusReportFact;
}>;

export type UpdateStatusReportFactMutation = {
    __typename: 'Mutations';
    statusReportFactUpdate: {
        __typename: 'StatusReportFactQL';
        id: string | null;
        measureId: string | null;
        taskId: string | null;
        factText: string | null;
        soWhatText: string | null;
        insightText: string | null;
        actionText: string | null;
        sequence: number;
        isIncluded: boolean;
        version: string | null;
    };
};

export type StatusReportDataRefreshMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    statusReportId: Scalars['ID']['input'];
    reportDate: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type StatusReportDataRefreshMutation = {
    __typename: 'Mutations';
    statusReportDataRefresh: {
        __typename: 'StatusReportQL';
        id: string | null;
        utcCompletedDate: string | null;
        utcDataDate: string | null;
        utcUpdated: string | null;
        version: string | null;
    } | null;
};

export type DeleteStatusReportMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    statusReportId: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteStatusReportMutation = {
    __typename: 'Mutations';
    statusReportDelete: {
        __typename: 'StatusReportQL';
        id: string | null;
        utcDeleted: string | null;
        utcUpdated: string | null;
        version: string | null;
    } | null;
};

export type DeleteTemplateReportMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    templateReportId: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteTemplateReportMutation = {
    __typename: 'Mutations';
    templateReportDelete: {
        __typename: 'TemplateReportQL';
        id: string | null;
        utcDeleted: string | null;
        utcUpdated: string | null;
        version: string | null;
    } | null;
};

export type CreateTemplateReportMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: TemplateReportCreateInput;
}>;

export type CreateTemplateReportMutation = {
    __typename: 'Mutations';
    templateReportCreate: {
        __typename: 'TemplateReportQL';
        id: string | null;
        missionId: string | null;
        reportDate: string | null;
        reportPeriodType: ReportPeriodTypes | null;
        reportPeriod: number | null;
        utcCompletedDate: string | null;
        utcDataDate: string | null;
        title: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        utcDeleted: string | null;
        version: string | null;
    };
};

export type UpdateTemplateReportMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: TemplateReportInput;
    notifyUserIds: InputMaybe<
        Array<Scalars['ID']['input']> | Scalars['ID']['input']
    >;
}>;

export type UpdateTemplateReportMutation = {
    __typename: 'Mutations';
    templateReportUpdate: {
        __typename: 'TemplateReportQL';
        id: string | null;
        missionId: string | null;
        reportDate: string | null;
        reportPeriodType: ReportPeriodTypes | null;
        reportPeriod: number | null;
        utcCompletedDate: string | null;
        utcDataDate: string | null;
        title: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        utcDeleted: string | null;
        version: string | null;
    };
};

export type UpdateTemplateReportSectionMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: TemplateReportSectionInput;
}>;

export type UpdateTemplateReportSectionMutation = {
    __typename: 'Mutations';
    templateReportSectionUpdate: {
        __typename: 'TemplateReportSectionQL';
        id: string | null;
        title: string | null;
        layoutDirection: string | null;
        availableElementTypes: Array<TemplateReportElementTypes>;
        sequence: number;
        utcCreated: string;
        utcUpdated: string | null;
        utcDeleted: string | null;
        version: string | null;
        groups: Array<{
            __typename: 'TemplateReportElementGroupQL';
            id: string | null;
            sequence: number;
            layoutGrow: string | null;
            layoutDirection: string | null;
            version: string | null;
        }>;
    };
};

export type UpdateTemplateReportElementMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: TemplateReportElementInput;
}>;

export type UpdateTemplateReportElementMutation = {
    __typename: 'Mutations';
    templateReportElementUpdate: {
        __typename: 'TemplateReportSectionElementQL';
        id: string | null;
        title: string | null;
        textContent: string | null;
        sectionId: string | null;
        attachmentId: string | null;
        utcUpdated: string | null;
        version: string | null;
        measureLinks: Array<{
            __typename: 'TemplateReportMeasureLinkQL';
            id: string | null;
            sequence: number;
            measureId: string;
        }>;
        taskLinks: Array<{
            __typename: 'TemplateReportTaskLinkQL';
            id: string | null;
            sequence: number;
            taskId: string;
        }>;
        columns: Array<{
            __typename: 'TemplateReportCustomTableColumnQL';
            id: string | null;
            columnName: string | null;
            sequence: number;
            version: string | null;
            cells: Array<{
                __typename: 'TemplateReportCustomTableCellQL';
                id: string | null;
                rowIndex: number;
                content: string | null;
            }>;
        }>;
    };
};

export type DeleteTemplateReportElementMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    restore: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteTemplateReportElementMutation = {
    __typename: 'Mutations';
    templateReportElementDelete: {
        __typename: 'TemplateReportSectionElementDeleteResult';
        message: string;
        success: boolean;
        deletedObject: {
            __typename: 'TemplateReportSectionElementQL';
            id: string | null;
            utcDeleted: string | null;
        } | null;
    } | null;
};

export type DismissNotificationMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    notificationId: Scalars['ID']['input'];
}>;

export type DismissNotificationMutation = {
    __typename: 'Mutations';
    notificationDismiss: {
        __typename: 'NotificationQL';
        id: string | null;
        utcDismissed: string | null;
    } | null;
};

export type DismissAllNotificationsMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
}>;

export type DismissAllNotificationsMutation = {
    __typename: 'Mutations';
    notificationsDismissAll: Array<{
        __typename: 'NotificationQL';
        id: string | null;
        utcDismissed: string | null;
    }>;
};

export type UpdateTenantMutationVariables = Exact<{
    input: Tenant;
}>;

export type UpdateTenantMutation = {
    __typename: 'Mutations';
    tenantUpdate: {
        __typename: 'TenantQL';
        id: string | null;
        code: string | null;
        description: string | null;
        isEnabled: boolean;
        isAnalyticsEnabled: boolean;
    } | null;
};

export type DeleteTenantMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type DeleteTenantMutation = {
    __typename: 'Mutations';
    tenantDelete: {
        __typename: 'DeleteResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type CacheResetMutationVariables = Exact<{ [key: string]: never }>;

export type CacheResetMutation = {
    __typename: 'Mutations';
    cacheReset: string | null;
};

export type ToggleTagLinkMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: InputMaybe<Scalars['ID']['input']>;
    taskId: InputMaybe<Scalars['ID']['input']>;
    tagId: InputMaybe<Scalars['ID']['input']>;
    tagName: InputMaybe<Scalars['String']['input']>;
}>;

export type ToggleTagLinkMutation = {
    __typename: 'Mutations';
    tagLinkToggle: {
        __typename: 'ActionResultQL';
        id: string | null;
        success: boolean;
        message: string;
    } | null;
};

export type JsonUploadMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    divisionId: Scalars['ID']['input'];
    json: Scalars['String']['input'];
}>;

export type JsonUploadMutation = {
    __typename: 'Mutations';
    jsonUpload: {
        __typename: 'ActionResultQL';
        id: string | null;
        message: string;
        success: boolean;
    } | null;
};

export type RollbackMeasureImportMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    batchId: Scalars['ID']['input'];
    preview: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RollbackMeasureImportMutation = {
    __typename: 'Mutations';
    measureImporterRollback: {
        __typename: 'RollbackQL';
        isComplete: boolean | null;
        completeText: string | null;
        isError: boolean | null;
        errorText: string | null;
        items: Array<{
            __typename: 'RollbackItemQL';
            text: string;
            batch: {
                __typename: 'VersionTimestampQL';
                batchId: string;
                utcBatchDate: string | null;
                updatedByUserId: string | null;
                username: string | null;
            };
        } | null>;
    } | null;
};

export type GetDefaultMissionQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDefaultMissionQuery = {
    __typename: 'Queries';
    default_mission: {
        __typename: 'MissionQL';
        id: string | null;
        userId: string | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                vision: string | null;
                purpose: string | null;
                values: string | null;
                primaryColourHex: string | null;
                financialYear: {
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                    startDate: string;
                    endDate: string;
                } | null;
            } | null;
            leaderMission: {
                __typename: 'MissionQL';
                id: string | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
        } | null;
        leaderOfTeams: Array<{
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                vision: string | null;
                purpose: string | null;
                values: string | null;
                financialYear: {
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                    startDate: string;
                    endDate: string;
                } | null;
            } | null;
        }>;
    } | null;
    default_contributor: {
        __typename: 'ContributorQL';
        id: string | null;
        userId: string;
        financialYear: {
            __typename: 'FinancialYearQL';
            id: string | null;
            code: string | null;
            startDate: string;
            endDate: string;
        } | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
        } | null;
    } | null;
};

export type GetBreadcrumbsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    teamCode: Scalars['String']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBreadcrumbsQuery = {
    __typename: 'Queries';
    breadcrumbs: Array<{
        __typename: 'TeamQL';
        id: string | null;
        name: string | null;
        code: string | null;
        division: {
            __typename: 'DivisionQL';
            id: string | null;
            primaryColourHex: string | null;
        } | null;
        leaderMission: {
            __typename: 'MissionQL';
            id: string | null;
            title: string | null;
            owner: string | null;
            team: {
                __typename: 'TeamQL';
                id: string | null;
                code: string | null;
            } | null;
            leaderOfTeams: Array<{
                __typename: 'TeamQL';
                id: string | null;
                name: string | null;
                code: string | null;
                division: {
                    __typename: 'DivisionQL';
                    id: string | null;
                    primaryColourHex: string | null;
                } | null;
            }>;
        } | null;
        missions: Array<{
            __typename: 'MissionQL';
            id: string | null;
            title: string | null;
            owner: string | null;
            sequence: number;
            leaderOfTeams: Array<{
                __typename: 'TeamQL';
                id: string | null;
                name: string | null;
                code: string | null;
                division: {
                    __typename: 'DivisionQL';
                    id: string | null;
                    primaryColourHex: string | null;
                } | null;
                missions: Array<{
                    __typename: 'MissionQL';
                    id: string | null;
                    title: string | null;
                    owner: string | null;
                    sequence: number;
                }>;
                dottedMissions: Array<{
                    __typename: 'DottedTeamMissionQL';
                    id: string | null;
                    sequence: number;
                    mission: {
                        __typename: 'MissionQL';
                        id: string | null;
                        title: string | null;
                        owner: string | null;
                    } | null;
                }>;
                contributors: Array<{
                    __typename: 'ContributorQL';
                    id: string | null;
                    userDisplayName: string | null;
                    userActive: boolean;
                }>;
            }>;
        }>;
        dottedMissions: Array<{
            __typename: 'DottedTeamMissionQL';
            id: string | null;
            sequence: number;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                title: string | null;
                owner: string | null;
            } | null;
        }>;
        contributors: Array<{
            __typename: 'ContributorQL';
            id: string | null;
            userDisplayName: string | null;
            userActive: boolean;
        }>;
    }> | null;
};

export type GetMissionNavQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
}>;

export type GetMissionNavQuery = {
    __typename: 'Queries';
    mission: {
        __typename: 'MissionQL';
        id: string | null;
        owner: string | null;
        title: string | null;
        userId: string | null;
        rights: {
            __typename: 'RightQL';
            read: boolean;
            write: boolean;
            export: boolean;
            import: boolean;
        };
        leaderOfTeams: Array<{
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                primaryColourHex: string | null;
                financialYear: {
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                } | null;
            } | null;
        }>;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            leaderMission: {
                __typename: 'MissionQL';
                id: string | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                primaryColourHex: string | null;
                financialYear: {
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type GetMissionQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
}>;

export type GetMissionQuery = {
    __typename: 'Queries';
    mission: {
        __typename: 'MissionQL';
        id: string | null;
        userId: string | null;
        username: string | null;
        title: string | null;
        owner: string | null;
        missionStatement: string | null;
        missionDate: string | null;
        customMissionStatement1Up: string | null;
        customMissionStatement2Up: string | null;
        utcCreated: string;
        utcInactive: string | null;
        sequence: number;
        missionGroupId: string | null;
        version: string | null;
        rights: {
            __typename: 'RightQL';
            read: boolean;
            write: boolean;
            export: boolean;
            import: boolean;
        };
        leaderOfTeams: Array<{
            __typename: 'TeamQL';
            id: string | null;
            name: string | null;
            code: string | null;
            version: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                vision: string | null;
                purpose: string | null;
                values: string | null;
                primaryColourHex: string | null;
            } | null;
        }>;
        behaviours: Array<{
            __typename: 'MissionPhraseQL';
            id: string | null;
            text: string | null;
            sequence: number;
            version: string | null;
        }>;
        freedoms: Array<{
            __typename: 'MissionPhraseQL';
            id: string | null;
            text: string | null;
            sequence: number;
            version: string | null;
        }>;
        constraints: Array<{
            __typename: 'MissionPhraseQL';
            id: string | null;
            text: string | null;
            sequence: number;
            version: string | null;
        }>;
        dottedTeams: Array<{
            __typename: 'DottedTeamMissionQL';
            id: string | null;
            teamId: string | null;
            sequence: number;
            missionGroupId: string | null;
        }>;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            leaderMission: {
                __typename: 'MissionQL';
                id: string | null;
                title: string | null;
                userId: string | null;
                owner: string | null;
                missionStatement: string | null;
                team: {
                    __typename: 'TeamQL';
                    id: string | null;
                    code: string | null;
                    leaderMission: {
                        __typename: 'MissionQL';
                        id: string | null;
                        title: string | null;
                        owner: string | null;
                        missionStatement: string | null;
                    } | null;
                    division: {
                        __typename: 'DivisionQL';
                        id: string | null;
                    } | null;
                } | null;
            } | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                vision: string | null;
                purpose: string | null;
                values: string | null;
                primaryColourHex: string | null;
                financialYear: {
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                    startDate: string;
                    endDate: string;
                } | null;
            } | null;
        } | null;
    } | null;
    measureGroups: Array<{
        __typename: 'MeasureGroupQL';
        id: string;
        name: string;
        sequence: number;
        version: string | null;
    }>;
    measureSummary: Array<{
        __typename: 'MeasureQL';
        id: string | null;
        measureGroupId: string | null;
        isCustom: boolean;
        name: string | null;
        sequence: number;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
        } | null;
    }>;
    tasksSummary: Array<{
        __typename: 'TaskQL';
        id: string | null;
        parentTaskId: string | null;
        sequence: number;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        percentComplete: number;
        isDuplicate: boolean;
    }>;
};

export type GetTasksAndResourcesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
}>;

export type GetTasksAndResourcesQuery = {
    __typename: 'Queries';
    tasks: Array<{
        __typename: 'TaskQL';
        id: string | null;
        parentTaskId: string | null;
        sequence: number;
        name: string | null;
        isDuplicate: boolean;
        resourcedTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            resource: {
                __typename: 'ResourceQL';
                id: string | null;
                userId: string | null;
                displayName: string | null;
                utcDeleted: string | null;
            } | null;
        }>;
    }>;
};

export type GetMeasureGroupsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
}>;

export type GetMeasureGroupsQuery = {
    __typename: 'Queries';
    measureGroups: Array<{
        __typename: 'MeasureGroupQL';
        id: string;
        name: string;
        sequence: number;
        version: string | null;
    }>;
};

export type GetMeasureValueFormulaQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
}>;

export type GetMeasureValueFormulaQuery = {
    __typename: 'Queries';
    measureValueFormula: {
        __typename: 'ValueFormulaQL';
        formula: string | null;
        formulaPP: string | null;
        measures: Array<{
            __typename: 'MeasureQL';
            id: string | null;
            name: string | null;
            measureType: MeasureTypes;
            frequencyPeriod: FrequencyPeriods;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                title: string | null;
                owner: string | null;
                userId: string | null;
            } | null;
            valueHistory: Array<{
                __typename: 'MeasureAsOfQL';
                asOfDate: string;
                values: Array<{
                    __typename: 'SeriesValueQL';
                    decimalValue: number | null;
                    seriesType: {
                        __typename: 'SeriesTypeQL';
                        name: string | null;
                    } | null;
                }> | null;
            }> | null;
        }>;
        variables: Array<{
            __typename: 'ValueVariableQL';
            name: string | null;
            value: {
                __typename: 'ValueArgumentQL';
                argType: ArgumentTypes;
                decimalValue: number | null;
                measureId: string | null;
                variableName: string | null;
                functionName: string | null;
            };
        }>;
    } | null;
};

export type GetMeasureValueFormulaEvaluationQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
}>;

export type GetMeasureValueFormulaEvaluationQuery = {
    __typename: 'Queries';
    measureValueFormulaEvaluation: {
        __typename: 'ValueFormulaQL';
        formula: string | null;
        stepLogs: Array<{
            __typename: 'ValueFormulaStepLogQL';
            seriesName: string;
            asOfDate: string;
            steps: Array<{
                __typename: 'ValueFormulaStepQL';
                name: string | null;
                indent: number | null;
                decimalValue: number | null;
                formatNumber: boolean;
            }>;
        }>;
    } | null;
};

export type GetMissionSearchQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    divisionId: InputMaybe<Scalars['ID']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    isWritable: Scalars['Boolean']['input'];
    isImportable: Scalars['Boolean']['input'];
    includeInactive: InputMaybe<Scalars['Boolean']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMissionSearchQuery = {
    __typename: 'Queries';
    missions: Array<{
        __typename: 'MissionQL';
        id: string | null;
        title: string | null;
        owner: string | null;
        userId: string | null;
        username: string | null;
        utcInactive: string | null;
        leaderOfTeams: Array<{
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
        }>;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                code: string | null;
                financialYear: {
                    __typename: 'FinancialYearQL';
                    code: string | null;
                } | null;
            } | null;
        } | null;
    }>;
};

export type GetUserMissionSearchQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
    financialYearCode: Scalars['String']['input'];
}>;

export type GetUserMissionSearchQuery = {
    __typename: 'Queries';
    missions: Array<{ __typename: 'MissionQL'; id: string | null }>;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
    __typename: 'Queries';
    current_user: {
        __typename: 'UserQL';
        id: string | null;
        displayName: string | null;
        emailAddress: string | null;
        isNewUser: boolean;
        utcSelfHelpEnabledUntil: string | null;
        version: string | null;
        userTenants: Array<{
            __typename: 'UserTenantQL';
            tenantId: string;
            isDefault: boolean;
            tenant: {
                __typename: 'TenantQL';
                id: string | null;
                code: string | null;
                description: string | null;
                isEnabled: boolean;
            };
        }>;
        userRoles: Array<{
            __typename: 'UserRoleQL';
            roleId: string;
            tenantId: string | null;
            name: string;
        }>;
    } | null;
};

export type GetCurrentUserLastLoginQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetCurrentUserLastLoginQuery = {
    __typename: 'Queries';
    current_user: {
        __typename: 'UserQL';
        lastLogin: {
            __typename: 'UserLoginAuditQL';
            dateTime: string | null;
            ipAddress: string;
        } | null;
    } | null;
};

export type GetCurrentUserLoginHistoryQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetCurrentUserLoginHistoryQuery = {
    __typename: 'Queries';
    current_user: {
        __typename: 'UserQL';
        lastLogin: {
            __typename: 'UserLoginAuditQL';
            dateTime: string | null;
            ipAddress: string;
        } | null;
        loginHistory: Array<{
            __typename: 'UserLoginAuditQL';
            dateTime: string | null;
            ipAddress: string;
            userAgent: string;
        }> | null;
    } | null;
};

export type GetMissionGroupsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    teamId: Scalars['ID']['input'];
}>;

export type GetMissionGroupsQuery = {
    __typename: 'Queries';
    missionGroups: Array<{
        __typename: 'MissionGroupQL';
        id: string;
        name: string;
        sequence: number;
        version: string | null;
    }>;
};

export type GetMeasureValueHistoryQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
    historySkip: InputMaybe<Scalars['Int']['input']>;
    historyTake: InputMaybe<Scalars['Int']['input']>;
    historyHasActual: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetMeasureValueHistoryQuery = {
    __typename: 'Queries';
    measure: {
        __typename: 'MeasureQL';
        id: string | null;
        valueHistory: Array<{
            __typename: 'MeasureAsOfQL';
            id: string;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            version: string | null;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                version: string | null;
                calcId: number | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        }> | null;
    } | null;
};

export type GetMissionMeasuresQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetMissionMeasuresQuery = {
    __typename: 'Queries';
    measures: Array<{
        __typename: 'MeasureQL';
        id: string | null;
        measureGroupId: string | null;
        name: string | null;
        description: string | null;
        sequence: number;
        measureType: MeasureTypes;
        statusType: StatusTypes;
        valueType: ValueTypes;
        valueFormula: string | null;
        yellowStart: number;
        yellowRange: number;
        greenRange: number;
        chartDisplay: ChartDisplay;
        decimalPlaces: number;
        multiplier: Multipliers;
        frequencyPeriod: FrequencyPeriods;
        isStatusLimited: boolean;
        isCustom: boolean;
        showForecast: boolean;
        isFullYearTarget: boolean;
        fullYearTarget: number | null;
        linkedFromMeasureId: string | null;
        isLinked: boolean;
        commentCount: number;
        attachmentCount: number;
        linkedFromMeasure: {
            __typename: 'MeasureQL';
            id: string | null;
            name: string | null;
            utcDeleted: string | null;
            commentCount: number;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                title: string | null;
                owner: string | null;
                userId: string | null;
                utcInactive: string | null;
                utcDeleted: string | null;
            } | null;
            lastComment: {
                __typename: 'CommentQL';
                id: string | null;
                text: string | null;
                username: string | null;
                userId: string | null;
                utcCreated: string;
            } | null;
        } | null;
        currency: {
            __typename: 'CurrencyQL';
            code: string;
            symbol: string | null;
            descr: string | null;
        } | null;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
        lastComment: {
            __typename: 'CommentQL';
            id: string | null;
            text: string | null;
            username: string | null;
            userId: string | null;
            utcCreated: string;
        } | null;
        lastAttachment: {
            __typename: 'AttachmentQL';
            id: string | null;
        } | null;
    }>;
};

export type GetMeasureSearchQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: Scalars['String']['input'];
    missionId: InputMaybe<Scalars['ID']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMeasureSearchQuery = {
    __typename: 'Queries';
    measures: Array<{
        __typename: 'MeasureQL';
        id: string | null;
        name: string | null;
        description: string | null;
        isLinked: boolean;
        linkedFromMeasureId: string | null;
        sequence: number;
        group: {
            __typename: 'MeasureGroupQL';
            id: string;
            name: string;
            sequence: number;
        } | null;
        linkedFromMeasure: {
            __typename: 'MeasureQL';
            id: string | null;
            name: string | null;
            utcDeleted: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                utcDeleted: string | null;
                utcInactive: string | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
        } | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            owner: string | null;
            title: string | null;
            userId: string | null;
        } | null;
    }>;
};

export type GetMeasureQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type GetMeasureQuery = {
    __typename: 'Queries';
    measure: {
        __typename: 'MeasureQL';
        id: string | null;
        missionId: string;
        measureGroupId: string | null;
        name: string | null;
        description: string | null;
        measureType: MeasureTypes;
        phaseType: PhaseType;
        multiplier: Multipliers;
        decimalPlaces: number;
        statusType: StatusTypes;
        yellowStart: number;
        greenRange: number;
        yellowRange: number;
        isStatusLimited: boolean;
        sequence: number;
        version: string | null;
        frequencyNumber: number;
        frequencyPeriod: FrequencyPeriods;
        linkedFromMeasureId: string | null;
        isLinked: boolean;
        fullYearString: string | null;
        fullYearTarget: number | null;
        isFullYearTarget: boolean;
        chartDisplay: ChartDisplay;
        chartType: ChartType;
        showForecast: boolean;
        showFutureLook: boolean;
        isCustom: boolean;
        previousFYMeasureId: string | null;
        targetType: TargetTypes;
        valueType: ValueTypes;
        valueFormula: string | null;
        commentCount: number;
        attachmentCount: number;
        valueFormulaData: {
            __typename: 'ValueFormulaQL';
            calculatedSeries: Array<string | null>;
        } | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            userId: string | null;
            title: string | null;
            owner: string | null;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
        } | null;
        currency: {
            __typename: 'CurrencyQL';
            code: string;
            symbol: string | null;
            descr: string | null;
        } | null;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            version: string | null;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                calcId: number | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                version: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
        lastComment: {
            __typename: 'CommentQL';
            id: string | null;
            text: string | null;
            username: string | null;
            userId: string | null;
            utcCreated: string;
        } | null;
        lastAttachment: {
            __typename: 'AttachmentQL';
            id: string | null;
        } | null;
        valueHistory: Array<{
            __typename: 'MeasureAsOfQL';
            id: string;
            measureId: string;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            version: string | null;
            utcCreated: string;
            utcUpdated: string | null;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                calcId: number | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                version: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        }> | null;
        linkedFromMeasure: {
            __typename: 'MeasureQL';
            id: string | null;
            name: string | null;
            utcDeleted: string | null;
            commentCount: number;
            lastComment: {
                __typename: 'CommentQL';
                id: string | null;
                text: string | null;
                username: string | null;
                userId: string | null;
                utcCreated: string;
            } | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                title: string | null;
                owner: string | null;
                utcDeleted: string | null;
                utcInactive: string | null;
                team: {
                    __typename: 'TeamQL';
                    id: string | null;
                    code: string | null;
                } | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
            valueFormulaData: {
                __typename: 'ValueFormulaQL';
                formula: string | null;
            } | null;
        } | null;
        linkedMeasures: Array<{
            __typename: 'MeasureQL';
            id: string | null;
            isLinked: boolean;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                title: string | null;
                owner: string | null;
            } | null;
        }>;
        tags: Array<{
            __typename: 'TagQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
            icon: string | null;
            tagType: TagTypes;
            isMeasureTag: boolean;
            isTaskTag: boolean;
        }>;
    } | null;
};

export type GetMissionTasksQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetMissionTasksQuery = {
    __typename: 'Queries';
    tasks: Array<{
        __typename: 'TaskQL';
        id: string | null;
        missionId: string | null;
        parentTaskId: string | null;
        name: string | null;
        description: string | null;
        percentComplete: number;
        start: string | null;
        due: string | null;
        done: string | null;
        review: string | null;
        resourcedFromTaskId: string | null;
        resourceId: string | null;
        utcAccepted: string | null;
        utcRejected: string | null;
        rejectedReason: string | null;
        utcResourceRemoved: string | null;
        utcChangesPending: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        isPercentageCompleteFromResources: boolean;
        isPercentageCompleteFromSubTasks: boolean;
        sequence: number;
        resourceIsPrimary: boolean;
        effortWeight: number;
        effortResourceWeight: number;
        costWeight: number;
        isDuplicate: boolean;
        taskCategoryId: string | null;
        version: string | null;
        commentCount: number;
        attachmentCount: number;
        taskCategory: {
            __typename: 'TaskCategoryQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
        } | null;
        linkedMeasures: Array<{
            __typename: 'LinkedMeasureQL';
            id: string | null;
            measureId: string;
            taskId: string;
        }>;
        resourcedTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            parentTaskId: string | null;
            resourcedFromTaskId: string | null;
            missionId: string | null;
            name: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            resourceIsPrimary: boolean;
            effortWeight: number;
            effortResourceWeight: number;
            costWeight: number;
            utcAccepted: string | null;
            utcRejected: string | null;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            isPercentageCompleteFromResources: boolean;
            isPercentageCompleteFromSubTasks: boolean;
            isDuplicate: boolean;
            rejectedReason: string | null;
            utcResourceRemoved: string | null;
            commentCount: number;
            version: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                team: {
                    __typename: 'TeamQL';
                    id: string | null;
                    code: string | null;
                } | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
            resource: {
                __typename: 'ResourceQL';
                id: string | null;
                userId: string | null;
                displayName: string | null;
                userMissionFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
                userContributorFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
            } | null;
            tags: Array<{
                __typename: 'TagQL';
                id: string | null;
                name: string | null;
            }>;
        }>;
        resource: {
            __typename: 'ResourceQL';
            id: string | null;
            userId: string | null;
            displayName: string | null;
            version: string | null;
            userMissionFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
            userContributorFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
        } | null;
        resourcedFromTask: {
            __typename: 'TaskQL';
            id: string | null;
            name: string | null;
            start: string | null;
            due: string | null;
            done: string | null;
            percentComplete: number;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                title: string | null;
                owner: string | null;
                utcInactive: string | null;
                utcDeleted: string | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
        } | null;
        lastAttachment: {
            __typename: 'AttachmentQL';
            id: string | null;
            name: string | null;
        } | null;
        lastComment: {
            __typename: 'CommentQL';
            id: string | null;
            userId: string | null;
            text: string | null;
            username: string | null;
            utcCreated: string;
        } | null;
        tags: Array<{
            __typename: 'TagQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
            icon: string | null;
            tagType: TagTypes;
            isMeasureTag: boolean;
            isTaskTag: boolean;
        }>;
    }>;
};

export type GetAllMeasuresQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    teamId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    taskCategoryIds: InputMaybe<
        Array<Scalars['ID']['input']> | Scalars['ID']['input']
    >;
    financialYearActiveDate: InputMaybe<Scalars['DateTime']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type GetAllMeasuresQuery = {
    __typename: 'Queries';
    measures: Array<{
        __typename: 'MeasureQL';
        id: string | null;
        name: string | null;
        isStatusLimited: boolean;
        sequence: number;
        utcDeleted: string | null;
        isLinked: boolean;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            asOfDate: string;
            utcCreated: string;
            utcUpdated: string | null;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                decimalValue: number | null;
                stringValue: string | null;
                dateValue: string | null;
                formatStr: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
        group: {
            __typename: 'MeasureGroupQL';
            id: string;
            name: string;
            sequence: number;
        } | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            owner: string | null;
            title: string | null;
            userId: string | null;
            sequence: number;
            team: {
                __typename: 'TeamQL';
                id: string | null;
                code: string | null;
                name: string | null;
                division: {
                    __typename: 'DivisionQL';
                    id: string | null;
                    name: string | null;
                } | null;
            } | null;
            leaderOfTeams: Array<{
                __typename: 'TeamQL';
                id: string | null;
                name: string | null;
                code: string | null;
            }>;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
        } | null;
        lastComment: {
            __typename: 'CommentQL';
            id: string | null;
            userId: string | null;
            text: string | null;
            username: string | null;
            utcCreated: string;
        } | null;
        linkedFromMeasure: {
            __typename: 'MeasureQL';
            id: string | null;
            name: string | null;
            utcDeleted: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                username: string | null;
                owner: string | null;
                title: string | null;
                utcDeleted: string | null;
                utcInactive: string | null;
            } | null;
        } | null;
    }>;
};

export type GetAllTasksQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    teamId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type GetAllTasksQuery = {
    __typename: 'Queries';
    tasks: Array<{
        __typename: 'TaskQL';
        id: string | null;
        missionId: string | null;
        parentTaskId: string | null;
        name: string | null;
        description: string | null;
        percentComplete: number;
        start: string | null;
        due: string | null;
        done: string | null;
        review: string | null;
        resourcedFromTaskId: string | null;
        resourceId: string | null;
        utcAccepted: string | null;
        utcRejected: string | null;
        rejectedReason: string | null;
        utcResourceRemoved: string | null;
        utcChangesPending: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        isPercentageCompleteFromResources: boolean;
        isPercentageCompleteFromSubTasks: boolean;
        sequence: number;
        resourceIsPrimary: boolean;
        effortWeight: number;
        effortResourceWeight: number;
        costWeight: number;
        isDuplicate: boolean;
        taskCategoryId: string | null;
        version: string | null;
        taskCategory: {
            __typename: 'TaskCategoryQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
        } | null;
        linkedMeasures: Array<{
            __typename: 'LinkedMeasureQL';
            id: string | null;
            measureId: string;
            taskId: string;
        }>;
        resourcedTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            parentTaskId: string | null;
            resourcedFromTaskId: string | null;
            missionId: string | null;
            name: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            resourceIsPrimary: boolean;
            utcAccepted: string | null;
            utcRejected: string | null;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            isPercentageCompleteFromResources: boolean;
            isPercentageCompleteFromSubTasks: boolean;
            isDuplicate: boolean;
            rejectedReason: string | null;
            utcResourceRemoved: string | null;
            version: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                team: {
                    __typename: 'TeamQL';
                    id: string | null;
                    code: string | null;
                    name: string | null;
                } | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
            resource: {
                __typename: 'ResourceQL';
                id: string | null;
                userId: string | null;
                displayName: string | null;
                userMissionFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
                userContributorFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
            } | null;
        }>;
        resource: {
            __typename: 'ResourceQL';
            id: string | null;
            userId: string | null;
            displayName: string | null;
            version: string | null;
            userMissionFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
            userContributorFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
        } | null;
        resourcedFromTask: {
            __typename: 'TaskQL';
            id: string | null;
            name: string | null;
            start: string | null;
            due: string | null;
            done: string | null;
            percentComplete: number;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                title: string | null;
                owner: string | null;
                utcInactive: string | null;
                utcDeleted: string | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
        } | null;
        lastComment: {
            __typename: 'CommentQL';
            id: string | null;
            userId: string | null;
            text: string | null;
            username: string | null;
            utcCreated: string;
        } | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            owner: string | null;
            title: string | null;
            userId: string | null;
            sequence: number;
            team: {
                __typename: 'TeamQL';
                id: string | null;
                code: string | null;
                name: string | null;
            } | null;
            leaderOfTeams: Array<{
                __typename: 'TeamQL';
                id: string | null;
                name: string | null;
                code: string | null;
            }>;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
        } | null;
        tags: Array<{
            __typename: 'TagQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
            icon: string | null;
            tagType: TagTypes;
            isMeasureTag: boolean;
            isTaskTag: boolean;
        }>;
    }>;
};

export type GetNotificationsCountQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
}>;

export type GetNotificationsCountQuery = {
    __typename: 'Queries';
    notificationsCount: number;
};

export type GetNotificationsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
}>;

export type GetNotificationsQuery = {
    __typename: 'Queries';
    notifications: Array<{
        __typename: 'NotificationQL';
        id: string | null;
        notificationEventId: string | null;
        userId: string | null;
        utcDismissed: string | null;
        notificationEvent: {
            __typename: 'NotificationEventQL';
            id: string | null;
            utcCreated: string | null;
            notificationType: NotificationTypes | null;
            userId: string | null;
            username: string | null;
            originatorUserId: string | null;
            originatorUsername: string | null;
            missionId: string | null;
            statusReportId: string | null;
            templateReportId: string | null;
            measureId: string | null;
            taskId: string | null;
            resourceId: string | null;
            comment: {
                __typename: 'CommentQL';
                id: string | null;
                text: string | null;
                utcDeleted: string | null;
            } | null;
            task: {
                __typename: 'TaskQL';
                id: string | null;
                name: string | null;
                utcDeleted: string | null;
                resource: {
                    __typename: 'ResourceQL';
                    id: string | null;
                    userId: string | null;
                    displayName: string | null;
                    userContributorFYs: Array<{
                        __typename: 'FinancialYearQL';
                        id: string | null;
                        code: string | null;
                    }>;
                } | null;
            } | null;
            measure: {
                __typename: 'MeasureQL';
                id: string | null;
                name: string | null;
                utcDeleted: string | null;
                linkedMeasures: Array<{
                    __typename: 'MeasureQL';
                    id: string | null;
                    isLinked: boolean;
                    mission: {
                        __typename: 'MissionQL';
                        id: string | null;
                        userId: string | null;
                        title: string | null;
                        owner: string | null;
                        team: {
                            __typename: 'TeamQL';
                            id: string | null;
                            code: string | null;
                        } | null;
                    } | null;
                }>;
            } | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
            } | null;
            resource: {
                __typename: 'ResourceQL';
                id: string | null;
                displayName: string | null;
            } | null;
        } | null;
    }> | null;
};

export type GetDependenciesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDependenciesQuery = {
    __typename: 'Queries';
    dependencies: Array<{
        __typename: 'TaskQL';
        id: string | null;
        missionId: string | null;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        utcCreated: string;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            userId: string | null;
            username: string | null;
            title: string | null;
            owner: string | null;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
        } | null;
        parentTask: {
            __typename: 'TaskQL';
            id: string | null;
            name: string | null;
            taskCategory: {
                __typename: 'TaskCategoryQL';
                id: string | null;
                name: string | null;
                colourHex: string | null;
            } | null;
        } | null;
        resourcedTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            parentTaskId: string | null;
            missionId: string | null;
            name: string | null;
            description: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            utcAccepted: string | null;
            utcRejected: string | null;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            effortWeight: number;
            costWeight: number;
            rejectedReason: string | null;
            utcCreated: string;
            utcResourceRemoved: string | null;
            resourceId: string | null;
            commentCount: number;
            tags: Array<{
                __typename: 'TagQL';
                id: string | null;
                name: string | null;
            }>;
            resource: {
                __typename: 'ResourceQL';
                id: string | null;
                userId: string | null;
                displayName: string | null;
                userMissionFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
                userContributorFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
            } | null;
            resourcedTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                resource: {
                    __typename: 'ResourceQL';
                    id: string | null;
                    userId: string | null;
                    displayName: string | null;
                } | null;
            }>;
            lastComment: {
                __typename: 'CommentQL';
                id: string | null;
                text: string | null;
                username: string | null;
                userId: string | null;
                utcCreated: string;
            } | null;
        }>;
    }> | null;
};

export type GetTaskQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type GetTaskQuery = {
    __typename: 'Queries';
    task: {
        __typename: 'TaskQL';
        id: string | null;
        missionId: string | null;
        parentTaskId: string | null;
        name: string | null;
        description: string | null;
        percentComplete: number;
        start: string | null;
        due: string | null;
        done: string | null;
        review: string | null;
        resourcedFromTaskId: string | null;
        resourceId: string | null;
        utcAccepted: string | null;
        utcRejected: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        rejectedReason: string | null;
        utcResourceRemoved: string | null;
        utcChangesPending: string | null;
        isPercentageCompleteFromResources: boolean;
        isPercentageCompleteFromSubTasks: boolean;
        resourceIsPrimary: boolean;
        effortWeight: number;
        effortResourceWeight: number;
        costWeight: number;
        isDuplicate: boolean;
        sequence: number;
        taskCategoryId: string | null;
        version: string | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            userId: string | null;
            title: string | null;
            owner: string | null;
            utcInactive: string | null;
            utcDeleted: string | null;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
            team: {
                __typename: 'TeamQL';
                id: string | null;
                code: string | null;
                division: {
                    __typename: 'DivisionQL';
                    id: string | null;
                    financialYear: {
                        __typename: 'FinancialYearQL';
                        id: string | null;
                        code: string | null;
                        startDate: string;
                        endDate: string;
                    } | null;
                } | null;
            } | null;
        } | null;
        parentTask: {
            __typename: 'TaskQL';
            id: string | null;
            parentTaskId: string | null;
            name: string | null;
            taskCategory: {
                __typename: 'TaskCategoryQL';
                id: string | null;
                name: string | null;
                colourHex: string | null;
            } | null;
        } | null;
        linkedMeasures: Array<{
            __typename: 'LinkedMeasureQL';
            id: string | null;
            measureId: string;
            taskId: string;
        }>;
        taskCategory: {
            __typename: 'TaskCategoryQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
        } | null;
        resource: {
            __typename: 'ResourceQL';
            id: string | null;
            userId: string | null;
            displayName: string | null;
            version: string | null;
            userMissionFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
            userContributorFYs: Array<{
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            }>;
        } | null;
        subTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            name: string | null;
            due: string | null;
            done: string | null;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            isDuplicate: boolean;
            utcResourceRemoved: string | null;
            utcChangesPending: string | null;
            sequence: number;
            resourcedFromTask: {
                __typename: 'TaskQL';
                id: string | null;
                mission: {
                    __typename: 'MissionQL';
                    id: string | null;
                    userId: string | null;
                    title: string | null;
                    owner: string | null;
                    utcInactive: string | null;
                    utcDeleted: string | null;
                } | null;
            } | null;
        }>;
        resourcedTasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            parentTaskId: string | null;
            resourcedFromTaskId: string | null;
            missionId: string | null;
            name: string | null;
            percentComplete: number;
            start: string | null;
            due: string | null;
            done: string | null;
            utcAccepted: string | null;
            utcRejected: string | null;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            isPercentageCompleteFromResources: boolean;
            isPercentageCompleteFromSubTasks: boolean;
            rejectedReason: string | null;
            resourceIsPrimary: boolean;
            effortResourceWeight: number;
            isDuplicate: boolean;
            utcResourceRemoved: string | null;
            utcChangesPending: string | null;
            taskCategoryId: string | null;
            version: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                team: {
                    __typename: 'TeamQL';
                    id: string | null;
                    code: string | null;
                } | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                parentTask: { __typename: 'TaskQL'; id: string | null } | null;
            } | null;
            resource: {
                __typename: 'ResourceQL';
                id: string | null;
                userId: string | null;
                displayName: string | null;
                version: string | null;
                userMissionFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
                userContributorFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
            } | null;
        }>;
        resourcedFromTask: {
            __typename: 'TaskQL';
            id: string | null;
            name: string | null;
            start: string | null;
            due: string | null;
            done: string | null;
            percentComplete: number;
            utcCancelled: string | null;
            utcPostponed: string | null;
            utcAtRisk: string | null;
            utcDeleted: string | null;
            resourcedTasks: Array<{
                __typename: 'TaskQL';
                id: string | null;
                resource: {
                    __typename: 'ResourceQL';
                    id: string | null;
                    userId: string | null;
                    displayName: string | null;
                    userMissionFYs: Array<{
                        __typename: 'FinancialYearQL';
                        id: string | null;
                        code: string | null;
                    }>;
                    userContributorFYs: Array<{
                        __typename: 'FinancialYearQL';
                        id: string | null;
                        code: string | null;
                    }>;
                } | null;
            }>;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                name: string | null;
                taskCategory: {
                    __typename: 'TaskCategoryQL';
                    id: string | null;
                    name: string | null;
                    colourHex: string | null;
                } | null;
            } | null;
            resourcedFromTask: {
                __typename: 'TaskQL';
                id: string | null;
                mission: {
                    __typename: 'MissionQL';
                    id: string | null;
                    userId: string | null;
                } | null;
            } | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                title: string | null;
                owner: string | null;
                utcInactive: string | null;
                utcDeleted: string | null;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
            resource: {
                __typename: 'ResourceQL';
                userId: string | null;
                userMissionFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
                userContributorFYs: Array<{
                    __typename: 'FinancialYearQL';
                    id: string | null;
                    code: string | null;
                }>;
            } | null;
        } | null;
        tags: Array<{
            __typename: 'TagQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
            icon: string | null;
            tagType: TagTypes;
            isMeasureTag: boolean;
            isTaskTag: boolean;
        }>;
    } | null;
};

export type GetTaskCategoriesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    divisionId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTaskCategoriesQuery = {
    __typename: 'Queries';
    taskCategories: Array<{
        __typename: 'TaskCategoryQL';
        id: string | null;
        name: string | null;
        colourHex: string | null;
        taskCount: number;
        divisions: Array<{
            __typename: 'DivisionQL';
            id: string | null;
            name: string | null;
        }> | null;
    }> | null;
};

export type GetTaskCategoryOptionsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    divisionId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetTaskCategoryOptionsQuery = {
    __typename: 'Queries';
    taskCategories: Array<{
        __typename: 'TaskCategoryQL';
        id: string | null;
        name: string | null;
        colourHex: string | null;
    }> | null;
};

export type GetTagsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type GetTagsQuery = {
    __typename: 'Queries';
    tags: Array<{
        __typename: 'TagQL';
        id: string | null;
        name: string | null;
        colourHex: string | null;
        icon: string | null;
        tagType: TagTypes;
        isMeasureTag: boolean;
        isTaskTag: boolean;
    }> | null;
};

export type GetMeasureHistoryQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type GetMeasureHistoryQuery = {
    __typename: 'Queries';
    measureHistory: Array<{
        __typename: 'MeasureQL';
        name: string | null;
        isLinked: boolean;
        linkedFromMeasureId: string | null;
        isCustom: boolean;
        frequencyPeriod: FrequencyPeriods;
        measureType: MeasureTypes;
        decimalPlaces: number;
        multiplier: Multipliers;
        currencyCode: string | null;
        utcUpdated: string | null;
        updatedByUserId: string | null;
        updatedByUserName: string | null;
        sysStartTime: string;
        sysEndTime: string;
    }>;
};

export type GetSeriesValueHistoryQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
}>;

export type GetSeriesValueHistoryQuery = {
    __typename: 'Queries';
    seriesValueHistory: Array<{
        __typename: 'TemporalSeriesValueQL';
        stringValue: string | null;
        decimalValue: number | null;
        dateValue: string | null;
        utcUpdated: string | null;
        utcDeleted: string | null;
        sysStartTime: string;
        sysEndTime: string;
        updatedByUserId: string | null;
        updatedByUserName: string | null;
        seriesType: { __typename: 'SeriesTypeQL'; name: string | null } | null;
        asOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            asOfDate: string;
            arrowDirection: ArrowDirection;
            arrowColour: Arrows;
            utcDeleted: string | null;
            sysStartTime: string;
            sysEndTime: string;
        } | null;
    }>;
};

export type GetTaskHistoryQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type GetTaskHistoryQuery = {
    __typename: 'Queries';
    taskHistory: Array<{
        __typename: 'TaskQL';
        missionId: string | null;
        parentTaskId: string | null;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        isDuplicate: boolean;
        resourceIsPrimary: boolean;
        percentComplete: number;
        utcAccepted: string | null;
        utcRejected: string | null;
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        rejectedReason: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        updatedByUserId: string | null;
        updatedByUserName: string | null;
        sysStartTime: string;
        taskCategory: {
            __typename: 'TaskCategoryQL';
            name: string | null;
        } | null;
    }> | null;
};

export type GetTeamsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    divisionId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTeamsQuery = {
    __typename: 'Queries';
    teams: Array<{
        __typename: 'TeamQL';
        id: string | null;
        code: string | null;
        name: string | null;
        version: string | null;
        division: {
            __typename: 'DivisionQL';
            id: string | null;
            name: string | null;
            financialYear: {
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
            } | null;
        } | null;
        leaderMission: {
            __typename: 'MissionQL';
            id: string | null;
            owner: string | null;
            title: string | null;
            userId: string | null;
            username: string | null;
        } | null;
    }> | null;
};

export type GetTeamMissionsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    teamCode: Scalars['String']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTeamMissionsQuery = {
    __typename: 'Queries';
    teams: Array<{
        __typename: 'TeamQL';
        id: string | null;
        code: string | null;
        name: string | null;
        version: string | null;
        leaderMission: {
            __typename: 'MissionQL';
            id: string | null;
            title: string | null;
            owner: string | null;
            userId: string | null;
            missionStatement: string | null;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
        } | null;
        missions: Array<{
            __typename: 'MissionQL';
            id: string | null;
            title: string | null;
            owner: string | null;
            userId: string | null;
            missionStatement: string | null;
            sequence: number;
            missionGroupId: string | null;
            leaderOfTeams: Array<{
                __typename: 'TeamQL';
                id: string | null;
                name: string | null;
                code: string | null;
            }>;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
        }>;
        dottedMissions: Array<{
            __typename: 'DottedTeamMissionQL';
            id: string | null;
            sequence: number;
            missionGroupId: string | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                title: string | null;
                owner: string | null;
                userId: string | null;
                missionStatement: string | null;
                leaderOfTeams: Array<{
                    __typename: 'TeamQL';
                    id: string | null;
                    name: string | null;
                    code: string | null;
                }>;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
            } | null;
        }>;
        division: {
            __typename: 'DivisionQL';
            id: string | null;
            financialYear: {
                __typename: 'FinancialYearQL';
                id: string | null;
                code: string | null;
                startDate: string;
            } | null;
        } | null;
    }> | null;
};

export type GetTeamUsersQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    teamCode: Scalars['String']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTeamUsersQuery = {
    __typename: 'Queries';
    teams: Array<{
        __typename: 'TeamQL';
        leaderMission: {
            __typename: 'MissionQL';
            id: string | null;
            userId: string | null;
            username: string | null;
        } | null;
        missions: Array<{
            __typename: 'MissionQL';
            id: string | null;
            userId: string | null;
            username: string | null;
        }>;
    }> | null;
};

export type GetUsersSearchQueryVariables = Exact<{
    tenantId: InputMaybe<Scalars['ID']['input']>;
    searchText: Scalars['String']['input'];
    useCache: Scalars['Boolean']['input'];
}>;

export type GetUsersSearchQuery = {
    __typename: 'Queries';
    userSearch: Array<{
        __typename: 'UserQL';
        id: string | null;
        displayName: string | null;
        missionFYs: Array<{
            __typename: 'FinancialYearQL';
            id: string | null;
            code: string | null;
        }>;
    }>;
};

export type GetUsersQueryVariables = Exact<{
    tenantId: InputMaybe<Scalars['ID']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    useCache: Scalars['Boolean']['input'];
}>;

export type GetUsersQuery = {
    __typename: 'Queries';
    userSearch: Array<{
        __typename: 'UserQL';
        id: string | null;
        displayName: string | null;
        emailAddress: string | null;
        accessEnabled: boolean;
        utcSelfHelpEnabledUntil: string | null;
        utcUpdated: string | null;
        version: string | null;
        userTenants: Array<{
            __typename: 'UserTenantQL';
            id: string | null;
            userId: string;
            tenantId: string;
            isDefault: boolean;
        }>;
        userRoles: Array<{
            __typename: 'UserRoleQL';
            id: string | null;
            userId: string;
            roleId: string;
            tenantId: string | null;
            name: string;
        }>;
    }>;
};

export type GetRightsQueryVariables = Exact<{
    tenantId: InputMaybe<Scalars['ID']['input']>;
    userId: InputMaybe<Scalars['ID']['input']>;
    divisionId: InputMaybe<Scalars['ID']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    missionId: InputMaybe<Scalars['ID']['input']>;
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRightsQuery = {
    __typename: 'Queries';
    rights: Array<{
        __typename: 'RightQL';
        id: string;
        read: boolean;
        write: boolean;
        export: boolean;
        import: boolean;
        divisionId: string | null;
        teamId: string | null;
        missionId: string | null;
        userId: string;
    }>;
};

export type GetCommentsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: InputMaybe<Scalars['ID']['input']>;
    taskId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetCommentsQuery = {
    __typename: 'Queries';
    comments: Array<{
        __typename: 'CommentQL';
        id: string | null;
        userId: string | null;
        text: string | null;
        inReplyToId: string | null;
        username: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        version: string | null;
        likes: Array<{
            __typename: 'LikeQL';
            id: string | null;
            userId: string | null;
            username: string | null;
            version: string | null;
        }>;
    }> | null;
};

export type GetTaskAttachmentsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    taskId: Scalars['ID']['input'];
}>;

export type GetTaskAttachmentsQuery = {
    __typename: 'Queries';
    task: {
        __typename: 'TaskQL';
        id: string | null;
        name: string | null;
        version: string | null;
        attachments: Array<{
            __typename: 'AttachmentQL';
            id: string | null;
            name: string | null;
            href: string | null;
            uploadUri: string | null;
            containerPath: string | null;
            isFile: boolean;
            isUrl: boolean;
            utcCreated: string;
            utcUpdated: string | null;
            userId: string | null;
            username: string | null;
            version: string | null;
        }>;
    } | null;
};

export type GetTemplateReportAttachmentsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    templateReportId: Scalars['ID']['input'];
}>;

export type GetTemplateReportAttachmentsQuery = {
    __typename: 'Queries';
    reports: {
        __typename: 'Reports';
        templateReport: {
            __typename: 'TemplateReportQL';
            id: string | null;
            version: string | null;
            attachments: Array<{
                __typename: 'AttachmentQL';
                id: string | null;
                name: string | null;
                href: string | null;
                uploadUri: string | null;
                containerPath: string | null;
                isFile: boolean;
                isUrl: boolean;
                utcCreated: string;
                utcUpdated: string | null;
                userId: string | null;
                username: string | null;
                version: string | null;
            }> | null;
        } | null;
    } | null;
};

export type GetMeasureAttachmentsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
}>;

export type GetMeasureAttachmentsQuery = {
    __typename: 'Queries';
    measure: {
        __typename: 'MeasureQL';
        id: string | null;
        name: string | null;
        version: string | null;
        attachments: Array<{
            __typename: 'AttachmentQL';
            id: string | null;
            name: string | null;
            href: string | null;
            uploadUri: string | null;
            containerPath: string | null;
            isFile: boolean;
            isUrl: boolean;
            utcCreated: string;
            utcUpdated: string | null;
            userId: string | null;
            username: string | null;
            version: string | null;
        }> | null;
    } | null;
};

export type GetResourcesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetResourcesQuery = {
    __typename: 'Queries';
    resources: Array<{
        __typename: 'ResourceQL';
        id: string | null;
        name: string | null;
        displayName: string | null;
        userId: string | null;
        taskCount: number;
        version: string | null;
    }> | null;
};

export type GetResourceQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type GetResourceQuery = {
    __typename: 'Queries';
    resource: {
        __typename: 'ResourceQL';
        id: string | null;
        name: string | null;
        displayName: string | null;
        userId: string | null;
        version: string | null;
        tasks: Array<{
            __typename: 'TaskQL';
            id: string | null;
            isDuplicate: boolean;
            name: string | null;
            utcAccepted: string | null;
            utcRejected: string | null;
            parentTask: {
                __typename: 'TaskQL';
                id: string | null;
                parentTask: { __typename: 'TaskQL'; id: string | null } | null;
            } | null;
            resourcedFromTask: {
                __typename: 'TaskQL';
                id: string | null;
                parentTask: { __typename: 'TaskQL'; id: string | null } | null;
                mission: {
                    __typename: 'MissionQL';
                    id: string | null;
                    team: {
                        __typename: 'TeamQL';
                        id: string | null;
                        code: string | null;
                        division: {
                            __typename: 'DivisionQL';
                            id: string | null;
                            financialYear: {
                                __typename: 'FinancialYearQL';
                                id: string | null;
                                code: string | null;
                                startDate: string;
                            } | null;
                        } | null;
                    } | null;
                } | null;
            } | null;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                owner: string | null;
                title: string | null;
                team: {
                    __typename: 'TeamQL';
                    id: string | null;
                    code: string | null;
                    division: {
                        __typename: 'DivisionQL';
                        id: string | null;
                        financialYear: {
                            __typename: 'FinancialYearQL';
                            id: string | null;
                            code: string | null;
                            startDate: string;
                        } | null;
                    } | null;
                } | null;
            } | null;
        }>;
    } | null;
};

export type GetResourcesSearchQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    searchText: Scalars['String']['input'];
}>;

export type GetResourcesSearchQuery = {
    __typename: 'Queries';
    resource_search: Array<{
        __typename: 'ResourceQL';
        id: string | null;
        name: string | null;
        displayName: string | null;
        userMissionFYs: Array<{
            __typename: 'FinancialYearQL';
            id: string | null;
            code: string | null;
        }>;
    }>;
};

export type GetStatusReportsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
}>;

export type GetStatusReportsQuery = {
    __typename: 'Queries';
    statusReports: Array<{
        __typename: 'StatusReportQL';
        id: string | null;
        title: string | null;
        reportDate: string | null;
        summaryText: string | null;
        lastPeriodText: string | null;
        utcCompletedDate: string | null;
        utcDataDate: string | null;
        utcCreated: string;
        utcDeleted: string | null;
        facts: Array<{
            __typename: 'StatusReportFactQL';
            id: string | null;
            measureId: string | null;
            taskId: string | null;
            isIncluded: boolean;
        }>;
    }> | null;
};

export type GetStatusReportsDataLastUpdatedQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetStatusReportsDataLastUpdatedQuery = {
    __typename: 'Queries';
    measures: Array<{
        __typename: 'MeasureQL';
        id: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        utcDeleted: string | null;
        valueHistory: Array<{
            __typename: 'MeasureAsOfQL';
            utcCreated: string;
            utcUpdated: string | null;
            utcDeleted: string | null;
        }> | null;
    }>;
    tasks: Array<{
        __typename: 'TaskQL';
        id: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        utcDeleted: string | null;
    }>;
};

export type GetStatusReportQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    statusReportId: Scalars['ID']['input'];
}>;

export type GetStatusReportQuery = {
    __typename: 'Queries';
    statusReport: {
        __typename: 'StatusReportQL';
        id: string | null;
        missionId: string;
        reportDate: string | null;
        utcCompletedDate: string | null;
        utcDataDate: string | null;
        title: string | null;
        summaryText: string | null;
        lastPeriodText: string | null;
        nextPeriodText: string | null;
        risksAndOpportunitiesText: string | null;
        supportText: string | null;
        utcCreated: string;
        utcUpdated: string | null;
        version: string | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            userId: string | null;
            missionStatement: string | null;
            title: string | null;
            owner: string | null;
            utcCreated: string;
            rights: {
                __typename: 'RightQL';
                read: boolean;
                write: boolean;
                export: boolean;
                import: boolean;
            };
            team: {
                __typename: 'TeamQL';
                id: string | null;
                code: string | null;
                leaderMission: {
                    __typename: 'MissionQL';
                    id: string | null;
                    userId: string | null;
                    username: string | null;
                } | null;
                division: {
                    __typename: 'DivisionQL';
                    id: string | null;
                    canUnpublishReport: boolean;
                    financialYear: {
                        __typename: 'FinancialYearQL';
                        id: string | null;
                        startDate: string;
                        endDate: string;
                    } | null;
                } | null;
            } | null;
        } | null;
        facts: Array<{
            __typename: 'StatusReportFactQL';
            id: string | null;
            measureId: string | null;
            taskId: string | null;
            factText: string | null;
            soWhatText: string | null;
            insightText: string | null;
            actionText: string | null;
            sequence: number;
            isIncluded: boolean;
            version: string | null;
        }>;
    } | null;
};

export type GetLatestStatusReportQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: Scalars['ID']['input'];
}>;

export type GetLatestStatusReportQuery = {
    __typename: 'Queries';
    latestStatusReport: {
        __typename: 'StatusReportQL';
        id: string | null;
        title: string | null;
        reportDate: string | null;
        summaryText: string | null;
        lastPeriodText: string | null;
        nextPeriodText: string | null;
        risksAndOpportunitiesText: string | null;
        supportText: string | null;
    } | null;
};

export type GetTeamAlignmentQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: Scalars['String']['input'];
    teamId: InputMaybe<Scalars['ID']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    isWritable: Scalars['Boolean']['input'];
    includeInactive: InputMaybe<Scalars['Boolean']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetTeamAlignmentQuery = {
    __typename: 'Queries';
    missions: Array<{
        __typename: 'MissionQL';
        id: string | null;
        title: string | null;
        owner: string | null;
        missionStatement: string | null;
        sequence: number;
        userId: string | null;
        username: string | null;
        outstandingDependencyCount: number;
        latestPublishedStatusReport: {
            __typename: 'StatusReportQL';
            id: string | null;
            title: string | null;
            reportDate: string | null;
            utcCompletedDate: string | null;
        } | null;
        latestDraftStatusReport: {
            __typename: 'StatusReportQL';
            id: string | null;
            title: string | null;
            reportDate: string | null;
            utcUpdated: string | null;
            utcCompletedDate: string | null;
        } | null;
        leaderOfTeams: Array<{
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
        }>;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                name: string | null;
            } | null;
        } | null;
        dottedTeams: Array<{
            __typename: 'DottedTeamMissionQL';
            id: string | null;
            teamId: string | null;
            sequence: number;
            missionGroupId: string | null;
        }>;
        measures: Array<{ __typename: 'MeasureQL'; id: string | null }>;
        behaviours: Array<{ __typename: 'MissionPhraseQL'; id: string | null }>;
        freedoms: Array<{ __typename: 'MissionPhraseQL'; id: string | null }>;
        constraints: Array<{
            __typename: 'MissionPhraseQL';
            id: string | null;
        }>;
    }>;
    contributors: Array<{
        __typename: 'ContributorQL';
        id: string | null;
        userId: string;
        userDisplayName: string | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                name: string | null;
            } | null;
        } | null;
    }>;
};

export type GetTeamUsageQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    financialYearActiveDate: InputMaybe<Scalars['DateTime']['input']>;
    teamId: InputMaybe<Scalars['ID']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
    isWritable: Scalars['Boolean']['input'];
    includeInactive: InputMaybe<Scalars['Boolean']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
    usagePeriodStart: Scalars['Date']['input'];
    usagePeriodEnd: Scalars['Date']['input'];
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type GetTeamUsageQuery = {
    __typename: 'Queries';
    missions: Array<{
        __typename: 'MissionQL';
        id: string | null;
        userId: string | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            division: {
                __typename: 'DivisionQL';
                id: string | null;
                name: string | null;
            } | null;
        } | null;
        userUsage: {
            __typename: 'UserUsageQL';
            lastSeen: string | null;
            totalUsageInMinutes: number | null;
            totalDistinctDays: number | null;
        } | null;
    }>;
    contributors: Array<{
        __typename: 'ContributorQL';
        id: string | null;
        userId: string;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
        } | null;
        userUsage: {
            __typename: 'UserUsageQL';
            lastSeen: string | null;
            totalUsageInMinutes: number | null;
            totalDistinctDays: number | null;
        } | null;
    }>;
};

export type GetMeasuresForStatusReportQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: InputMaybe<Scalars['ID']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetMeasuresForStatusReportQuery = {
    __typename: 'Queries';
    measures: Array<{
        __typename: 'MeasureQL';
        id: string | null;
        name: string | null;
        isStatusLimited: boolean;
        frequencyPeriod: FrequencyPeriods;
        valueType: ValueTypes;
        sequence: number;
        utcDeleted: string | null;
        isLinked: boolean;
        linkedFromMeasureId: string | null;
        lastAsOf: {
            __typename: 'MeasureAsOfQL';
            id: string;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                formatStr: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
        asOfForDate: {
            __typename: 'MeasureAsOfQL';
            id: string;
            asOfDate: string;
            statusValue: number;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
            values: Array<{
                __typename: 'SeriesValueQL';
                id: string | null;
                formatStr: string | null;
                seriesType: {
                    __typename: 'SeriesTypeQL';
                    name: string | null;
                } | null;
            }> | null;
        } | null;
        latestStatusReportFact: {
            __typename: 'StatusReportFactQL';
            id: string | null;
            soWhatText: string | null;
            insightText: string | null;
            actionText: string | null;
            statusReport: {
                __typename: 'StatusReportQL';
                id: string | null;
                reportDate: string | null;
                title: string | null;
            } | null;
        } | null;
        group: {
            __typename: 'MeasureGroupQL';
            id: string;
            name: string;
            sequence: number;
        } | null;
    }>;
};

export type GetTasksForStatusReportQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: InputMaybe<Scalars['ID']['input']>;
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetTasksForStatusReportQuery = {
    __typename: 'Queries';
    tasks: Array<{
        __typename: 'TaskQL';
        id: string | null;
        parentTaskId: string | null;
        sequence: number;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        isDuplicate: boolean;
        percentComplete: number;
        utcDeleted: string | null;
        taskCategory: {
            __typename: 'TaskCategoryQL';
            id: string | null;
            name: string | null;
            colourHex: string | null;
        } | null;
        linkedMeasures: Array<{
            __typename: 'LinkedMeasureQL';
            id: string | null;
            measureId: string;
            taskId: string;
        }>;
        latestStatusReportFact: {
            __typename: 'StatusReportFactQL';
            id: string | null;
            soWhatText: string | null;
            insightText: string | null;
            actionText: string | null;
            statusReport: {
                __typename: 'StatusReportQL';
                id: string | null;
                reportDate: string | null;
                title: string | null;
            } | null;
        } | null;
    }>;
};

export type GetRoleTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoleTypesQuery = {
    __typename: 'Queries';
    roleTypes: Array<{
        __typename: 'RoleQL';
        id: string;
        name: string;
        description: string;
    }> | null;
};

export type GetFinancialYearsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type GetFinancialYearsQuery = {
    __typename: 'Queries';
    financialYears: Array<{
        __typename: 'FinancialYearQL';
        id: string | null;
        code: string | null;
        name: string | null;
        startDate: string;
        endDate: string;
        utcInactive: string | null;
        version: string | null;
    }> | null;
};

export type GetFinancialYearQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFinancialYearQuery = {
    __typename: 'Queries';
    financialYear: {
        __typename: 'FinancialYearQL';
        id: string | null;
        code: string | null;
        name: string | null;
        startDate: string;
        endDate: string;
        utcInactive: string | null;
        version: string | null;
    } | null;
};

export type GetContributorQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    userId: InputMaybe<Scalars['ID']['input']>;
    contributorId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetContributorQuery = {
    __typename: 'Queries';
    contributor: {
        __typename: 'ContributorQL';
        id: string | null;
        userId: string;
        userDisplayName: string | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
        } | null;
    } | null;
};

export type GetContributorsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    teamCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetContributorsQuery = {
    __typename: 'Queries';
    contributors: Array<{
        __typename: 'ContributorQL';
        id: string | null;
        userId: string;
        userDisplayName: string | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
        } | null;
    }>;
};

export type GetContributorsSearchQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
    searchText: InputMaybe<Scalars['String']['input']>;
}>;

export type GetContributorsSearchQuery = {
    __typename: 'Queries';
    contributors: Array<{
        __typename: 'ContributorQL';
        id: string | null;
        userId: string;
        userDisplayName: string | null;
        team: {
            __typename: 'TeamQL';
            id: string | null;
            code: string | null;
            name: string | null;
        } | null;
    }>;
};

export type GetDivisionsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    financialYearCode: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDivisionsQuery = {
    __typename: 'Queries';
    divisions: Array<{
        __typename: 'DivisionQL';
        id: string | null;
        code: string | null;
        name: string | null;
        purpose: string | null;
        vision: string | null;
        values: string | null;
        primaryColourHex: string | null;
        canUnpublishReport: boolean;
        utcInactive: string | null;
        version: string | null;
        financialYear: {
            __typename: 'FinancialYearQL';
            id: string | null;
            code: string | null;
        } | null;
    }> | null;
};

export type GetTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantsQuery = {
    __typename: 'Queries';
    tenants: Array<{
        __typename: 'TenantQL';
        id: string | null;
        code: string | null;
        description: string | null;
        isEnabled: boolean;
        isAnalyticsEnabled: boolean;
    }> | null;
};

export type GetUsageStatsQueryVariables = Exact<{
    includeLogins: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetUsageStatsQuery = {
    __typename: 'Queries';
    usageStats: {
        __typename: 'UsageReportQL';
        error: string | null;
        tenants: Array<{
            __typename: 'UsageReportRowQL';
            id: string;
            tenantCode: string;
            tenantName: string | null;
            fyCode: string | null;
            fyStart: string | null;
            divisionCount: number;
            missionCount: number;
            teamCount: number;
            missionOwners: number;
            contributors: number;
            viewers: number;
            login7: number;
            login30: number;
            login90: number;
            info: string;
        }>;
        usersByTenant: Array<{
            __typename: 'UsageReportUsersByTenantQL';
            tenantCode: string;
            tenantName: string | null;
            users: Array<{
                __typename: 'UsageReportUserRowQL';
                name: string;
                email: string | null;
                lastLogin: string | null;
                isMissionOwner: boolean;
                isContributor: boolean;
                isViewer: boolean;
                loginDays: number;
                utcCreated: string;
                missionUtcCreated: string | null;
                missionCount: number;
                info: string | null;
                divisionNames: Array<string | null> | null;
                leaderOfTeamNames: Array<string | null> | null;
                teamNames: Array<string | null> | null;
            }>;
        }>;
    };
};

export type GetCurrenciesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type GetCurrenciesQuery = {
    __typename: 'Queries';
    currencies: Array<{
        __typename: 'CurrencyQL';
        code: string;
        symbol: string | null;
        descr: string | null;
    }>;
};

export type ImporterBatchesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type ImporterBatchesQuery = {
    __typename: 'Queries';
    importerBatches: Array<{
        __typename: 'BatchQL';
        batchId: string;
        utcBatchDate: string | null;
        updatedByUserId: string | null;
        username: string | null;
    }> | null;
};

export type GetIsImporterQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type GetIsImporterQuery = { __typename: 'Queries'; isImporter: boolean };

export type GetImporterTimestampsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    batchId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetImporterTimestampsQuery = {
    __typename: 'Queries';
    importerTimestamps: Array<{
        __typename: 'VersionTimestampQL';
        id: string | null;
        batchId: string;
        action: ActionType;
        utcBatchDate: string | null;
        utcRolledBack: string | null;
        table: string | null;
        objectId: string | null;
        sysStartTime: string | null;
        version: string | null;
        updatedByUserId: string | null;
        username: string | null;
    }> | null;
};

export type GetReportTemplatesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type GetReportTemplatesQuery = {
    __typename: 'Queries';
    reports: {
        __typename: 'Reports';
        templateReports: Array<{
            __typename: 'TemplateReportQL';
            id: string | null;
            title: string | null;
            reportPeriodType: ReportPeriodTypes | null;
            reportPeriod: number | null;
        }>;
    } | null;
};

export type GetMissionTemplatesReportsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    missionId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetMissionTemplatesReportsQuery = {
    __typename: 'Queries';
    reports: {
        __typename: 'Reports';
        templateReports: Array<{
            __typename: 'TemplateReportQL';
            id: string | null;
            title: string | null;
            reportDate: string | null;
            reportPeriodType: ReportPeriodTypes | null;
            reportPeriod: number | null;
            utcCompletedDate: string | null;
            utcDataDate: string | null;
            utcCreated: string;
            utcDeleted: string | null;
            views: Array<{
                __typename: 'TemplateReportViewQL';
                id: string | null;
                title: string | null;
            }>;
            sections: Array<{
                __typename: 'TemplateReportSectionQL';
                id: string | null;
                sequence: number;
                groups: Array<{
                    __typename: 'TemplateReportElementGroupQL';
                    id: string | null;
                    sequence: number;
                }>;
                elements: Array<{
                    __typename: 'TemplateReportSectionElementQL';
                    id: string | null;
                    groupId: string | null;
                    sequence: number;
                    textContent: string | null;
                    title: string | null;
                    measureLinks: Array<{
                        __typename: 'TemplateReportMeasureLinkQL';
                        id: string | null;
                        sequence: number;
                        measureId: string;
                    }>;
                    taskLinks: Array<{
                        __typename: 'TemplateReportTaskLinkQL';
                        id: string | null;
                        sequence: number;
                        taskId: string;
                    }>;
                }>;
            }>;
        }>;
    } | null;
};

export type GetTemplateReportQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type GetTemplateReportQuery = {
    __typename: 'Queries';
    reports: {
        __typename: 'Reports';
        templateReport: {
            __typename: 'TemplateReportQL';
            id: string | null;
            title: string | null;
            reportDate: string | null;
            reportPeriodType: ReportPeriodTypes | null;
            reportPeriod: number | null;
            utcCompletedDate: string | null;
            utcDataDate: string | null;
            utcUpdated: string | null;
            version: string | null;
            views: Array<{
                __typename: 'TemplateReportViewQL';
                id: string | null;
                title: string | null;
            }>;
            sections: Array<{
                __typename: 'TemplateReportSectionQL';
                id: string | null;
                templateReportViewId: string | null;
                title: string | null;
                sequence: number;
                availableElementTypes: Array<TemplateReportElementTypes>;
                layoutDirection: string | null;
                version: string | null;
                utcUpdated: string | null;
                groups: Array<{
                    __typename: 'TemplateReportElementGroupQL';
                    id: string | null;
                    sequence: number;
                    layoutGrow: string | null;
                    layoutDirection: string | null;
                    version: string | null;
                }>;
                elements: Array<{
                    __typename: 'TemplateReportSectionElementQL';
                    id: string | null;
                    groupId: string | null;
                    sectionId: string | null;
                    sourceTemplateReportSectionElementId: string | null;
                    attachmentId: string | null;
                    title: string | null;
                    sequence: number;
                    type: TemplateReportElementTypes;
                    layoutGrow: string | null;
                    textContent: string | null;
                    utcUpdated: string | null;
                    version: string | null;
                    measureLinks: Array<{
                        __typename: 'TemplateReportMeasureLinkQL';
                        id: string | null;
                        sequence: number;
                        measureId: string;
                    }>;
                    taskLinks: Array<{
                        __typename: 'TemplateReportTaskLinkQL';
                        id: string | null;
                        sequence: number;
                        taskId: string;
                    }>;
                    columns: Array<{
                        __typename: 'TemplateReportCustomTableColumnQL';
                        id: string | null;
                        columnName: string | null;
                        sequence: number;
                        version: string | null;
                        cells: Array<{
                            __typename: 'TemplateReportCustomTableCellQL';
                            id: string | null;
                            rowIndex: number;
                            content: string | null;
                        }>;
                    }>;
                }>;
            }>;
            mission: {
                __typename: 'MissionQL';
                id: string | null;
                userId: string | null;
                missionStatement: string | null;
                title: string | null;
                owner: string | null;
                utcCreated: string;
                rights: {
                    __typename: 'RightQL';
                    read: boolean;
                    write: boolean;
                    export: boolean;
                    import: boolean;
                };
                team: {
                    __typename: 'TeamQL';
                    id: string | null;
                    code: string | null;
                    leaderMission: {
                        __typename: 'MissionQL';
                        id: string | null;
                        userId: string | null;
                        username: string | null;
                    } | null;
                    division: {
                        __typename: 'DivisionQL';
                        id: string | null;
                        canUnpublishReport: boolean;
                        financialYear: {
                            __typename: 'FinancialYearQL';
                            id: string | null;
                            startDate: string;
                            endDate: string;
                        } | null;
                    } | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type GetTemplateReportSectionQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    id: Scalars['ID']['input'];
}>;

export type GetTemplateReportSectionQuery = {
    __typename: 'Queries';
    reports: {
        __typename: 'Reports';
        templateReportSection: {
            __typename: 'TemplateReportSectionQL';
            id: string | null;
            title: string | null;
            sequence: number;
            availableElementTypes: Array<TemplateReportElementTypes>;
            layoutDirection: string | null;
            version: string | null;
            utcUpdated: string | null;
            groups: Array<{
                __typename: 'TemplateReportElementGroupQL';
                id: string | null;
                sequence: number;
                layoutGrow: string | null;
                layoutDirection: string | null;
                version: string | null;
            }>;
            elements: Array<{
                __typename: 'TemplateReportSectionElementQL';
                id: string | null;
                groupId: string | null;
                sectionId: string | null;
                attachmentId: string | null;
                title: string | null;
                sequence: number;
                type: TemplateReportElementTypes;
                layoutGrow: string | null;
                textContent: string | null;
                utcUpdated: string | null;
                version: string | null;
                measureLinks: Array<{
                    __typename: 'TemplateReportMeasureLinkQL';
                    id: string | null;
                    sequence: number;
                    measureId: string;
                }>;
                taskLinks: Array<{
                    __typename: 'TemplateReportTaskLinkQL';
                    id: string | null;
                    sequence: number;
                    taskId: string;
                }>;
                columns: Array<{
                    __typename: 'TemplateReportCustomTableColumnQL';
                    id: string | null;
                    columnName: string | null;
                    sequence: number;
                    version: string | null;
                    cells: Array<{
                        __typename: 'TemplateReportCustomTableCellQL';
                        id: string | null;
                        rowIndex: number;
                        content: string | null;
                    }>;
                }>;
            }>;
        } | null;
    } | null;
};

export type GetMissionMentorQueryVariables = Exact<{
    promptName: Scalars['String']['input'];
    prompt: Scalars['String']['input'];
}>;

export type GetMissionMentorQuery = {
    __typename: 'Queries';
    missionMentor: string | null;
};

export type MaiSubscriptionVariables = Exact<{
    promptName: Scalars['String']['input'];
    prompt: Scalars['String']['input'];
}>;

export type MaiSubscription = {
    __typename: 'Subscriptions';
    chat: string | null;
};

export type GetMeasurePeriodDataQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    measureId: Scalars['ID']['input'];
    forDateTime: InputMaybe<Scalars['DateTime']['input']>;
    reportPeriodType: ReportPeriodTypes;
    reportPeriod: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMeasurePeriodDataQuery = {
    __typename: 'Queries';
    periodData: {
        __typename: 'PeriodData';
        measurePeriodData: {
            __typename: 'MeasurePeriodDataQL';
            measureId: string | null;
            targetFormatted: string | null;
            actualFormatted: string | null;
            nextTargetFormatted: string | null;
            statusValue: number;
            arrowDirection: ArrowDirection;
            arrowColour: Arrows;
            period: string | null;
            nextPeriod: string | null;
            previousPeriod: string | null;
            noActualsAsOfDates: Array<string>;
        } | null;
    } | null;
};

export const UpdateMissionDocument = gql`
    mutation UpdateMission($tenantId: ID!, $input: Mission!) {
        missionUpdate(tenantId: $tenantId, mission: $input) {
            id
            userId
            username
            title
            owner
            missionStatement
            missionDate
            missionGroupId
            customMissionStatement1Up
            customMissionStatement2Up
            sequence
            utcInactive
            utcDeleted
            version
            team {
                id
                code
                name
            }
            dottedTeams {
                id
                teamId
                sequence
                missionGroupId
            }
            behaviours {
                id
                text
                sequence
                version
            }
            freedoms {
                id
                text
                sequence
                version
            }
            constraints {
                id
                text
                sequence
                version
            }
        }
    }
`;

/**
 * __useUpdateMissionMutation__
 *
 * To run a mutation, you first call `useUpdateMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMissionMutation, { data, loading, error }] = useUpdateMissionMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMissionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateMissionMutation,
        UpdateMissionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateMissionMutation,
        UpdateMissionMutationVariables
    >(UpdateMissionDocument, options);
}
export type UpdateMissionMutationHookResult = ReturnType<
    typeof useUpdateMissionMutation
>;
export type UpdateMissionMutationResult =
    ApolloReactCommon.MutationResult<UpdateMissionMutation>;
export type UpdateMissionMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateMissionMutation,
        UpdateMissionMutationVariables
    >;
export const DeleteMissionDocument = gql`
    mutation DeleteMission($tenantId: ID!, $id: ID!, $restore: Boolean) {
        missionDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteMissionMutation__
 *
 * To run a mutation, you first call `useDeleteMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMissionMutation, { data, loading, error }] = useDeleteMissionMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteMissionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteMissionMutation,
        DeleteMissionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteMissionMutation,
        DeleteMissionMutationVariables
    >(DeleteMissionDocument, options);
}
export type DeleteMissionMutationHookResult = ReturnType<
    typeof useDeleteMissionMutation
>;
export type DeleteMissionMutationResult =
    ApolloReactCommon.MutationResult<DeleteMissionMutation>;
export type DeleteMissionMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteMissionMutation,
        DeleteMissionMutationVariables
    >;
export const UpdateMeasureDocument = gql`
    mutation UpdateMeasure($tenantId: ID!, $input: Measure!) {
        measureUpdate(tenantId: $tenantId, measure: $input) {
            id
            missionId
            measureGroupId
            name
            description
            measureType
            phaseType
            currency {
                code
                symbol
                descr
            }
            lastAsOf {
                id
                arrowColour
                arrowDirection
                values {
                    id
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    seriesType {
                        name
                    }
                }
            }
            multiplier
            decimalPlaces
            statusType
            yellowStart
            greenRange
            yellowRange
            isStatusLimited
            frequencyNumber
            frequencyPeriod
            sequence
            isLinked
            utcUpdated
            fullYearString
            fullYearTarget
            isFullYearTarget
            chartDisplay
            chartType
            showForecast
            showFutureLook
            isCustom
            targetType
            valueType
            valueFormula
            linkedFromMeasure {
                id
                linkedMeasures {
                    id
                    mission {
                        id
                        userId
                        title
                        owner
                    }
                }
            }
            tags {
                id
                name
                colourHex
                icon
                tagType
                isMeasureTag
                isTaskTag
            }
            version
        }
    }
`;

/**
 * __useUpdateMeasureMutation__
 *
 * To run a mutation, you first call `useUpdateMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeasureMutation, { data, loading, error }] = useUpdateMeasureMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeasureMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateMeasureMutation,
        UpdateMeasureMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateMeasureMutation,
        UpdateMeasureMutationVariables
    >(UpdateMeasureDocument, options);
}
export type UpdateMeasureMutationHookResult = ReturnType<
    typeof useUpdateMeasureMutation
>;
export type UpdateMeasureMutationResult =
    ApolloReactCommon.MutationResult<UpdateMeasureMutation>;
export type UpdateMeasureMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateMeasureMutation,
        UpdateMeasureMutationVariables
    >;
export const UpdateMeasureGroupDocument = gql`
    mutation UpdateMeasureGroup($tenantId: ID!, $input: MeasureGroup!) {
        measureGroupUpdate(tenantId: $tenantId, measureGroup: $input) {
            id
            missionId
            name
            sequence
            version
        }
    }
`;

/**
 * __useUpdateMeasureGroupMutation__
 *
 * To run a mutation, you first call `useUpdateMeasureGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeasureGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeasureGroupMutation, { data, loading, error }] = useUpdateMeasureGroupMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeasureGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateMeasureGroupMutation,
        UpdateMeasureGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateMeasureGroupMutation,
        UpdateMeasureGroupMutationVariables
    >(UpdateMeasureGroupDocument, options);
}
export type UpdateMeasureGroupMutationHookResult = ReturnType<
    typeof useUpdateMeasureGroupMutation
>;
export type UpdateMeasureGroupMutationResult =
    ApolloReactCommon.MutationResult<UpdateMeasureGroupMutation>;
export type UpdateMeasureGroupMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateMeasureGroupMutation,
        UpdateMeasureGroupMutationVariables
    >;
export const UpdateMeasureSequenceDocument = gql`
    mutation UpdateMeasureSequence(
        $tenantId: ID!
        $measureId: ID!
        $sequence: Int!
        $measureGroupId: ID
    ) {
        measureSequenceUpdate(
            tenantId: $tenantId
            measureId: $measureId
            sequence: $sequence
            measureGroupId: $measureGroupId
        ) {
            id
            sequence
            measureGroupId
            version
        }
    }
`;

/**
 * __useUpdateMeasureSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateMeasureSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeasureSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeasureSequenceMutation, { data, loading, error }] = useUpdateMeasureSequenceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *      sequence: // value for 'sequence'
 *      measureGroupId: // value for 'measureGroupId'
 *   },
 * });
 */
export function useUpdateMeasureSequenceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateMeasureSequenceMutation,
        UpdateMeasureSequenceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateMeasureSequenceMutation,
        UpdateMeasureSequenceMutationVariables
    >(UpdateMeasureSequenceDocument, options);
}
export type UpdateMeasureSequenceMutationHookResult = ReturnType<
    typeof useUpdateMeasureSequenceMutation
>;
export type UpdateMeasureSequenceMutationResult =
    ApolloReactCommon.MutationResult<UpdateMeasureSequenceMutation>;
export type UpdateMeasureSequenceMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateMeasureSequenceMutation,
        UpdateMeasureSequenceMutationVariables
    >;
export const UnlinkMeasureDocument = gql`
    mutation UnlinkMeasure(
        $tenantId: ID!
        $measureId: ID!
        $keepValues: Boolean!
    ) {
        measureUnlink(
            tenantId: $tenantId
            id: $measureId
            keepValues: $keepValues
        ) {
            id
            name
            isLinked
            version
            lastAsOf {
                id
                version
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                values {
                    id
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    seriesType {
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __useUnlinkMeasureMutation__
 *
 * To run a mutation, you first call `useUnlinkMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkMeasureMutation, { data, loading, error }] = useUnlinkMeasureMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *      keepValues: // value for 'keepValues'
 *   },
 * });
 */
export function useUnlinkMeasureMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UnlinkMeasureMutation,
        UnlinkMeasureMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UnlinkMeasureMutation,
        UnlinkMeasureMutationVariables
    >(UnlinkMeasureDocument, options);
}
export type UnlinkMeasureMutationHookResult = ReturnType<
    typeof useUnlinkMeasureMutation
>;
export type UnlinkMeasureMutationResult =
    ApolloReactCommon.MutationResult<UnlinkMeasureMutation>;
export type UnlinkMeasureMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UnlinkMeasureMutation,
        UnlinkMeasureMutationVariables
    >;
export const TransferMeasureDocument = gql`
    mutation TransferMeasure(
        $tenantId: ID!
        $measureId: ID!
        $targetMissionId: ID!
        $keepLinkedCopy: Boolean!
    ) {
        measureTransfer(
            tenantId: $tenantId
            id: $measureId
            targetMissionId: $targetMissionId
            keepLinkedCopy: $keepLinkedCopy
        ) {
            id
            missionId
            isLinked
            utcDeleted
            mission {
                id
                userId
                title
                owner
                rights {
                    read
                    write
                    export
                    import
                }
            }
            linkedMeasures {
                id
                isLinked
                linkedFromMeasureId
                linkedFromMeasure {
                    id
                    name
                    mission {
                        id
                        userId
                        title
                        owner
                    }
                }
                mission {
                    id
                    userId
                    title
                    owner
                }
            }
        }
    }
`;

/**
 * __useTransferMeasureMutation__
 *
 * To run a mutation, you first call `useTransferMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferMeasureMutation, { data, loading, error }] = useTransferMeasureMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *      targetMissionId: // value for 'targetMissionId'
 *      keepLinkedCopy: // value for 'keepLinkedCopy'
 *   },
 * });
 */
export function useTransferMeasureMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TransferMeasureMutation,
        TransferMeasureMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TransferMeasureMutation,
        TransferMeasureMutationVariables
    >(TransferMeasureDocument, options);
}
export type TransferMeasureMutationHookResult = ReturnType<
    typeof useTransferMeasureMutation
>;
export type TransferMeasureMutationResult =
    ApolloReactCommon.MutationResult<TransferMeasureMutation>;
export type TransferMeasureMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        TransferMeasureMutation,
        TransferMeasureMutationVariables
    >;
export const RecalculateAllMeasuresDocument = gql`
    mutation RecalculateAllMeasures(
        $tenantId: ID!
        $financialYearCode: String
    ) {
        measureRecalculateAll(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
        ) {
            id
            version
            utcUpdated
        }
    }
`;

/**
 * __useRecalculateAllMeasuresMutation__
 *
 * To run a mutation, you first call `useRecalculateAllMeasuresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculateAllMeasuresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculateAllMeasuresMutation, { data, loading, error }] = useRecalculateAllMeasuresMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useRecalculateAllMeasuresMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RecalculateAllMeasuresMutation,
        RecalculateAllMeasuresMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RecalculateAllMeasuresMutation,
        RecalculateAllMeasuresMutationVariables
    >(RecalculateAllMeasuresDocument, options);
}
export type RecalculateAllMeasuresMutationHookResult = ReturnType<
    typeof useRecalculateAllMeasuresMutation
>;
export type RecalculateAllMeasuresMutationResult =
    ApolloReactCommon.MutationResult<RecalculateAllMeasuresMutation>;
export type RecalculateAllMeasuresMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        RecalculateAllMeasuresMutation,
        RecalculateAllMeasuresMutationVariables
    >;
export const RecalculateAllTasksDocument = gql`
    mutation RecalculateAllTasks($tenantId: ID!, $financialYearCode: String) {
        taskRecalculateAll(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
        ) {
            id
            start
            due
            done
            percentComplete
            utcCancelled
            utcPostponed
            utcAtRisk
            utcUpdated
            version
        }
    }
`;

/**
 * __useRecalculateAllTasksMutation__
 *
 * To run a mutation, you first call `useRecalculateAllTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculateAllTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculateAllTasksMutation, { data, loading, error }] = useRecalculateAllTasksMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useRecalculateAllTasksMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RecalculateAllTasksMutation,
        RecalculateAllTasksMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RecalculateAllTasksMutation,
        RecalculateAllTasksMutationVariables
    >(RecalculateAllTasksDocument, options);
}
export type RecalculateAllTasksMutationHookResult = ReturnType<
    typeof useRecalculateAllTasksMutation
>;
export type RecalculateAllTasksMutationResult =
    ApolloReactCommon.MutationResult<RecalculateAllTasksMutation>;
export type RecalculateAllTasksMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        RecalculateAllTasksMutation,
        RecalculateAllTasksMutationVariables
    >;
export const UpdateMissionSequenceDocument = gql`
    mutation UpdateMissionSequence(
        $tenantId: ID!
        $missionId: ID!
        $sequence: Int!
        $missionGroupId: ID
    ) {
        missionSequenceUpdate(
            tenantId: $tenantId
            missionId: $missionId
            sequence: $sequence
            missionGroupId: $missionGroupId
        ) {
            id
            sequence
            missionGroupId
            version
        }
    }
`;

/**
 * __useUpdateMissionSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateMissionSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMissionSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMissionSequenceMutation, { data, loading, error }] = useUpdateMissionSequenceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *      sequence: // value for 'sequence'
 *      missionGroupId: // value for 'missionGroupId'
 *   },
 * });
 */
export function useUpdateMissionSequenceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateMissionSequenceMutation,
        UpdateMissionSequenceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateMissionSequenceMutation,
        UpdateMissionSequenceMutationVariables
    >(UpdateMissionSequenceDocument, options);
}
export type UpdateMissionSequenceMutationHookResult = ReturnType<
    typeof useUpdateMissionSequenceMutation
>;
export type UpdateMissionSequenceMutationResult =
    ApolloReactCommon.MutationResult<UpdateMissionSequenceMutation>;
export type UpdateMissionSequenceMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateMissionSequenceMutation,
        UpdateMissionSequenceMutationVariables
    >;
export const UpdateDottedMissionSequenceDocument = gql`
    mutation UpdateDottedMissionSequence(
        $tenantId: ID!
        $teamId: ID!
        $missionId: ID!
        $sequence: Int!
        $missionGroupId: ID
    ) {
        dottedMissionSequenceUpdate(
            tenantId: $tenantId
            teamId: $teamId
            missionId: $missionId
            sequence: $sequence
            missionGroupId: $missionGroupId
        ) {
            id
            sequence
            missionGroupId
        }
    }
`;

/**
 * __useUpdateDottedMissionSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateDottedMissionSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDottedMissionSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDottedMissionSequenceMutation, { data, loading, error }] = useUpdateDottedMissionSequenceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      teamId: // value for 'teamId'
 *      missionId: // value for 'missionId'
 *      sequence: // value for 'sequence'
 *      missionGroupId: // value for 'missionGroupId'
 *   },
 * });
 */
export function useUpdateDottedMissionSequenceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateDottedMissionSequenceMutation,
        UpdateDottedMissionSequenceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateDottedMissionSequenceMutation,
        UpdateDottedMissionSequenceMutationVariables
    >(UpdateDottedMissionSequenceDocument, options);
}
export type UpdateDottedMissionSequenceMutationHookResult = ReturnType<
    typeof useUpdateDottedMissionSequenceMutation
>;
export type UpdateDottedMissionSequenceMutationResult =
    ApolloReactCommon.MutationResult<UpdateDottedMissionSequenceMutation>;
export type UpdateDottedMissionSequenceMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateDottedMissionSequenceMutation,
        UpdateDottedMissionSequenceMutationVariables
    >;
export const UpdateTaskSequenceDocument = gql`
    mutation UpdateTaskSequence(
        $tenantId: ID!
        $taskId: ID!
        $sequence: Int!
        $targetParentTaskId: ID
    ) {
        taskSequenceUpdate(
            tenantId: $tenantId
            taskId: $taskId
            sequence: $sequence
            targetParentTaskId: $targetParentTaskId
        ) {
            id
            sequence
            missionId
            parentTaskId
            parentTask {
                id
                percentComplete
                start
                due
                done
                version
                resourcedFromTask {
                    id
                    percentComplete
                    utcUpdated
                    version
                }
                parentTask {
                    id
                    percentComplete
                    utcUpdated
                    version
                    resourcedFromTask {
                        id
                        percentComplete
                        utcUpdated
                        version
                    }
                }
            }
            utcUpdated
            version
        }
    }
`;

/**
 * __useUpdateTaskSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateTaskSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskSequenceMutation, { data, loading, error }] = useUpdateTaskSequenceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      taskId: // value for 'taskId'
 *      sequence: // value for 'sequence'
 *      targetParentTaskId: // value for 'targetParentTaskId'
 *   },
 * });
 */
export function useUpdateTaskSequenceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTaskSequenceMutation,
        UpdateTaskSequenceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTaskSequenceMutation,
        UpdateTaskSequenceMutationVariables
    >(UpdateTaskSequenceDocument, options);
}
export type UpdateTaskSequenceMutationHookResult = ReturnType<
    typeof useUpdateTaskSequenceMutation
>;
export type UpdateTaskSequenceMutationResult =
    ApolloReactCommon.MutationResult<UpdateTaskSequenceMutation>;
export type UpdateTaskSequenceMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateTaskSequenceMutation,
        UpdateTaskSequenceMutationVariables
    >;
export const DeleteMeasureDocument = gql`
    mutation DeleteMeasure($tenantId: ID!, $id: ID!, $restore: Boolean) {
        measureDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteMeasureMutation__
 *
 * To run a mutation, you first call `useDeleteMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeasureMutation, { data, loading, error }] = useDeleteMeasureMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteMeasureMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteMeasureMutation,
        DeleteMeasureMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteMeasureMutation,
        DeleteMeasureMutationVariables
    >(DeleteMeasureDocument, options);
}
export type DeleteMeasureMutationHookResult = ReturnType<
    typeof useDeleteMeasureMutation
>;
export type DeleteMeasureMutationResult =
    ApolloReactCommon.MutationResult<DeleteMeasureMutation>;
export type DeleteMeasureMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteMeasureMutation,
        DeleteMeasureMutationVariables
    >;
export const DeleteMeasureGroupDocument = gql`
    mutation DeleteMeasureGroup($tenantId: ID!, $id: ID!, $restore: Boolean) {
        measureGroupDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            message
            success
            deletedObject {
                id
                utcDeleted
            }
        }
    }
`;

/**
 * __useDeleteMeasureGroupMutation__
 *
 * To run a mutation, you first call `useDeleteMeasureGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeasureGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeasureGroupMutation, { data, loading, error }] = useDeleteMeasureGroupMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteMeasureGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteMeasureGroupMutation,
        DeleteMeasureGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteMeasureGroupMutation,
        DeleteMeasureGroupMutationVariables
    >(DeleteMeasureGroupDocument, options);
}
export type DeleteMeasureGroupMutationHookResult = ReturnType<
    typeof useDeleteMeasureGroupMutation
>;
export type DeleteMeasureGroupMutationResult =
    ApolloReactCommon.MutationResult<DeleteMeasureGroupMutation>;
export type DeleteMeasureGroupMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteMeasureGroupMutation,
        DeleteMeasureGroupMutationVariables
    >;
export const UpdateMeasureAsOfDocument = gql`
    mutation UpdateMeasureAsOf($tenantId: ID!, $input: MeasureAsOf!) {
        measureAsOfUpdate(tenantId: $tenantId, asOf: $input) {
            id
            measureId
            asOfDate
            statusValue
            arrowColour
            arrowDirection
            version
            utcCreated
            utcUpdated
            values {
                id
                calcId
                decimalValue
                stringValue
                dateValue
                formatStr
                version
                seriesType {
                    name
                }
                utcCreated
                utcUpdated
            }
        }
    }
`;

/**
 * __useUpdateMeasureAsOfMutation__
 *
 * To run a mutation, you first call `useUpdateMeasureAsOfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeasureAsOfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeasureAsOfMutation, { data, loading, error }] = useUpdateMeasureAsOfMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeasureAsOfMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateMeasureAsOfMutation,
        UpdateMeasureAsOfMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateMeasureAsOfMutation,
        UpdateMeasureAsOfMutationVariables
    >(UpdateMeasureAsOfDocument, options);
}
export type UpdateMeasureAsOfMutationHookResult = ReturnType<
    typeof useUpdateMeasureAsOfMutation
>;
export type UpdateMeasureAsOfMutationResult =
    ApolloReactCommon.MutationResult<UpdateMeasureAsOfMutation>;
export type UpdateMeasureAsOfMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateMeasureAsOfMutation,
        UpdateMeasureAsOfMutationVariables
    >;
export const FastUpdateMeasureAsOfDocument = gql`
    mutation FastUpdateMeasureAsOf($tenantId: ID!, $input: MeasureAsOf!) {
        measureAsOfUpdate(
            tenantId: $tenantId
            asOf: $input
            skipCalculations: true
        ) {
            id
            measureId
            asOfDate
            statusValue
            arrowColour
            arrowDirection
            version
            utcCreated
            utcUpdated
            values {
                id
                calcId
                decimalValue
                stringValue
                dateValue
                formatStr
                version
                seriesType {
                    name
                }
                utcCreated
                utcUpdated
            }
        }
    }
`;

/**
 * __useFastUpdateMeasureAsOfMutation__
 *
 * To run a mutation, you first call `useFastUpdateMeasureAsOfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastUpdateMeasureAsOfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastUpdateMeasureAsOfMutation, { data, loading, error }] = useFastUpdateMeasureAsOfMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFastUpdateMeasureAsOfMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        FastUpdateMeasureAsOfMutation,
        FastUpdateMeasureAsOfMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        FastUpdateMeasureAsOfMutation,
        FastUpdateMeasureAsOfMutationVariables
    >(FastUpdateMeasureAsOfDocument, options);
}
export type FastUpdateMeasureAsOfMutationHookResult = ReturnType<
    typeof useFastUpdateMeasureAsOfMutation
>;
export type FastUpdateMeasureAsOfMutationResult =
    ApolloReactCommon.MutationResult<FastUpdateMeasureAsOfMutation>;
export type FastUpdateMeasureAsOfMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        FastUpdateMeasureAsOfMutation,
        FastUpdateMeasureAsOfMutationVariables
    >;
export const RecalculateMeasureDocument = gql`
    mutation RecalculateMeasure($tenantId: ID!, $id: ID!) {
        measureRecalculate(tenantId: $tenantId, id: $id) {
            id
            utcUpdated
            version
            lastAsOf {
                id
                measureId
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                version
                utcCreated
                utcUpdated
                values {
                    id
                    calcId
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    version
                    seriesType {
                        name
                    }
                    utcCreated
                    utcUpdated
                }
            }
        }
    }
`;

/**
 * __useRecalculateMeasureMutation__
 *
 * To run a mutation, you first call `useRecalculateMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculateMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculateMeasureMutation, { data, loading, error }] = useRecalculateMeasureMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecalculateMeasureMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RecalculateMeasureMutation,
        RecalculateMeasureMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RecalculateMeasureMutation,
        RecalculateMeasureMutationVariables
    >(RecalculateMeasureDocument, options);
}
export type RecalculateMeasureMutationHookResult = ReturnType<
    typeof useRecalculateMeasureMutation
>;
export type RecalculateMeasureMutationResult =
    ApolloReactCommon.MutationResult<RecalculateMeasureMutation>;
export type RecalculateMeasureMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        RecalculateMeasureMutation,
        RecalculateMeasureMutationVariables
    >;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($tenantId: ID!, $input: Task!) {
        taskUpdate(tenantId: $tenantId, task: $input) {
            id
            missionId
            parentTaskId
            name
            description
            percentComplete
            start
            due
            done
            review
            resourcedFromTaskId
            resourceId
            utcAccepted
            utcRejected
            utcCancelled
            utcPostponed
            utcAtRisk
            rejectedReason
            utcResourceRemoved
            utcChangesPending
            isPercentageCompleteFromResources
            isPercentageCompleteFromSubTasks
            resourceIsPrimary
            effortWeight
            effortResourceWeight
            costWeight
            isDuplicate
            linkedMeasures {
                id
                measureId
                taskId
            }
            sequence
            taskCategoryId
            taskCategory {
                id
                name
                colourHex
            }
            utcUpdated
            version
            parentTask {
                id
                parentTaskId
                percentComplete
                start
                due
                done
                version
                resourcedFromTask {
                    id
                    percentComplete
                    utcUpdated
                    version
                    parentTask {
                        id
                        percentComplete
                        done
                        utcUpdated
                        version
                    }
                }
                subTasks {
                    id
                    start
                    due
                    done
                    percentComplete
                    utcCancelled
                    utcPostponed
                    utcAtRisk
                    utcUpdated
                    version
                }
                parentTask {
                    id
                    percentComplete
                    utcUpdated
                    version
                    resourcedFromTask {
                        id
                        percentComplete
                        utcUpdated
                        version
                    }
                }
            }
            subTasks {
                id
                start
                due
                done
                percentComplete
                utcCancelled
                utcPostponed
                utcAtRisk
                utcUpdated
                version
            }
            resource {
                id
                userId
                userMissionFYs {
                    id
                    code
                }
                userContributorFYs {
                    id
                    code
                }
            }
            resourcedFromTask {
                id
                percentComplete
                done
                version
                parentTask {
                    id
                    start
                    due
                    done
                    percentComplete
                    utcUpdated
                    version
                }
                mission {
                    id
                    userId
                }
                subTasks {
                    id
                    start
                    due
                    done
                    percentComplete
                    utcUpdated
                    version
                }
                resourcedTasks {
                    id
                    resource {
                        id
                        userId
                        displayName
                        utcDeleted
                    }
                }
            }
        }
    }
`;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTaskMutation,
        UpdateTaskMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTaskMutation,
        UpdateTaskMutationVariables
    >(UpdateTaskDocument, options);
}
export type UpdateTaskMutationHookResult = ReturnType<
    typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
    ApolloReactCommon.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
>;
export const UpdateTaskTagsDocument = gql`
    mutation UpdateTaskTags($tenantId: ID!, $taskId: ID!, $tags: [Tag!]!) {
        taskTagsUpdate(tenantId: $tenantId, taskId: $taskId, tags: $tags) {
            id
            utcUpdated
            version
            tags {
                id
                name
                colourHex
                icon
                tagType
                isMeasureTag
                isTaskTag
            }
        }
    }
`;

/**
 * __useUpdateTaskTagsMutation__
 *
 * To run a mutation, you first call `useUpdateTaskTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskTagsMutation, { data, loading, error }] = useUpdateTaskTagsMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      taskId: // value for 'taskId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateTaskTagsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTaskTagsMutation,
        UpdateTaskTagsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTaskTagsMutation,
        UpdateTaskTagsMutationVariables
    >(UpdateTaskTagsDocument, options);
}
export type UpdateTaskTagsMutationHookResult = ReturnType<
    typeof useUpdateTaskTagsMutation
>;
export type UpdateTaskTagsMutationResult =
    ApolloReactCommon.MutationResult<UpdateTaskTagsMutation>;
export type UpdateTaskTagsMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateTaskTagsMutation,
        UpdateTaskTagsMutationVariables
    >;
export const UpdateTaskStatusDocument = gql`
    mutation UpdateTaskStatus($tenantId: ID!, $input: TaskStatusInput!) {
        taskStatusUpdate(tenantId: $tenantId, taskStatus: $input) {
            id
            percentComplete
            start
            due
            done
            utcCancelled
            utcPostponed
            utcAtRisk
            utcUpdated
            version
            subTasks {
                id
                start
                due
                done
                utcCancelled
                utcPostponed
                utcAtRisk
                percentComplete
                utcUpdated
                version
            }
            parentTask {
                id
                percentComplete
                start
                due
                done
                version
                subTasks {
                    id
                    start
                    due
                    done
                    percentComplete
                    utcUpdated
                    version
                }
            }
            resourcedFromTask {
                id
                percentComplete
                done
                version
                parentTask {
                    id
                    start
                    due
                    done
                    percentComplete
                    utcUpdated
                    version
                }
                subTasks {
                    id
                    start
                    due
                    done
                    percentComplete
                    utcUpdated
                    version
                }
            }
        }
    }
`;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTaskStatusMutation,
        UpdateTaskStatusMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTaskStatusMutation,
        UpdateTaskStatusMutationVariables
    >(UpdateTaskStatusDocument, options);
}
export type UpdateTaskStatusMutationHookResult = ReturnType<
    typeof useUpdateTaskStatusMutation
>;
export type UpdateTaskStatusMutationResult =
    ApolloReactCommon.MutationResult<UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateTaskStatusMutation,
        UpdateTaskStatusMutationVariables
    >;
export const UpdateResourceTaskDocument = gql`
    mutation UpdateResourceTask($tenantId: ID!, $input: ResourceTask!) {
        resourceTaskUpdate(tenantId: $tenantId, task: $input) {
            id
            name
            start
            due
            done
            percentComplete
            utcRejected
            rejectedReason
            utcResourceRemoved
            utcChangesPending
            resourceIsPrimary
            effortResourceWeight
            utcUpdated
            version
        }
    }
`;

/**
 * __useUpdateResourceTaskMutation__
 *
 * To run a mutation, you first call `useUpdateResourceTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceTaskMutation, { data, loading, error }] = useUpdateResourceTaskMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceTaskMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateResourceTaskMutation,
        UpdateResourceTaskMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateResourceTaskMutation,
        UpdateResourceTaskMutationVariables
    >(UpdateResourceTaskDocument, options);
}
export type UpdateResourceTaskMutationHookResult = ReturnType<
    typeof useUpdateResourceTaskMutation
>;
export type UpdateResourceTaskMutationResult =
    ApolloReactCommon.MutationResult<UpdateResourceTaskMutation>;
export type UpdateResourceTaskMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateResourceTaskMutation,
        UpdateResourceTaskMutationVariables
    >;
export const DeleteTaskDocument = gql`
    mutation DeleteTask(
        $tenantId: ID!
        $id: ID!
        $isTaskResource: Boolean
        $restore: Boolean
    ) {
        taskDelete(
            tenantId: $tenantId
            id: $id
            isTaskResource: $isTaskResource
            restore: $restore
        ) {
            message
            success
            deletedObject {
                id
                name
                utcDeleted
                utcAccepted
                utcRejected
                utcResourceRemoved
                version
                subTasks {
                    id
                    percentComplete
                    utcUpdated
                    utcDeleted
                    utcAccepted
                    utcRejected
                    utcResourceRemoved
                    version
                }
                parentTask {
                    id
                    percentComplete
                    utcUpdated
                    version
                }
                resourcedTasks {
                    id
                    utcResourceRemoved
                    utcAccepted
                    utcRejected
                    utcDeleted
                    version
                }
                resourcedFromTask {
                    id
                    percentComplete
                    utcUpdated
                    version
                    parentTask {
                        id
                        percentComplete
                        utcUpdated
                        version
                    }
                    resourcedTasks {
                        id
                        utcResourceRemoved
                        utcAccepted
                        utcRejected
                        utcDeleted
                        version
                    }
                }
            }
        }
    }
`;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      isTaskResource: // value for 'isTaskResource'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteTaskMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteTaskMutation,
        DeleteTaskMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteTaskMutation,
        DeleteTaskMutationVariables
    >(DeleteTaskDocument, options);
}
export type DeleteTaskMutationHookResult = ReturnType<
    typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
    ApolloReactCommon.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
>;
export const AttachmentUpdateDocument = gql`
    mutation attachmentUpdate($tenantId: ID!, $input: Attachment!) {
        attachmentUpdate(attachment: $input, tenantId: $tenantId) {
            id
            name
            href
            uploadUri
            containerPath
            isFile
            isUrl
            utcCreated
            utcUpdated
            version
            userId
            task {
                id
                attachmentCount
                lastAttachment {
                    id
                    name
                }
                attachments {
                    id
                    name
                    href
                    uploadUri
                    containerPath
                    isFile
                    isUrl
                    utcCreated
                    utcUpdated
                    userId
                    username
                    version
                }
            }
            measure {
                id
                attachmentCount
                lastAttachment {
                    id
                    name
                }
                attachments {
                    id
                    name
                    href
                    uploadUri
                    containerPath
                    isFile
                    isUrl
                    utcCreated
                    utcUpdated
                    userId
                    username
                    version
                }
            }
            templateReport {
                id
                attachments {
                    id
                    name
                    href
                    uploadUri
                    containerPath
                    isFile
                    isUrl
                    utcCreated
                    utcUpdated
                    userId
                    username
                    version
                }
            }
        }
    }
`;

/**
 * __useAttachmentUpdateMutation__
 *
 * To run a mutation, you first call `useAttachmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachmentUpdateMutation, { data, loading, error }] = useAttachmentUpdateMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachmentUpdateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AttachmentUpdateMutation,
        AttachmentUpdateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        AttachmentUpdateMutation,
        AttachmentUpdateMutationVariables
    >(AttachmentUpdateDocument, options);
}
export type AttachmentUpdateMutationHookResult = ReturnType<
    typeof useAttachmentUpdateMutation
>;
export type AttachmentUpdateMutationResult =
    ApolloReactCommon.MutationResult<AttachmentUpdateMutation>;
export type AttachmentUpdateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        AttachmentUpdateMutation,
        AttachmentUpdateMutationVariables
    >;
export const AttachmentDeleteDocument = gql`
    mutation attachmentDelete($tenantId: ID!, $id: ID!, $restore: Boolean) {
        attachmentDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            message
            success
            deletedObject {
                id
                utcDeleted
                task {
                    id
                    attachmentCount
                    lastAttachment {
                        id
                        name
                    }
                    attachments {
                        id
                        name
                        href
                        uploadUri
                        containerPath
                        isFile
                        isUrl
                        utcCreated
                        utcUpdated
                        userId
                        username
                        version
                    }
                }
                measure {
                    id
                    attachmentCount
                    lastAttachment {
                        id
                        name
                    }
                    attachments {
                        id
                        name
                        href
                        uploadUri
                        containerPath
                        isFile
                        isUrl
                        utcCreated
                        utcUpdated
                        userId
                        username
                        version
                    }
                }
                templateReport {
                    id
                    attachments {
                        id
                        name
                        href
                        uploadUri
                        containerPath
                        isFile
                        isUrl
                        utcCreated
                        utcUpdated
                        userId
                        username
                        version
                    }
                }
            }
        }
    }
`;

/**
 * __useAttachmentDeleteMutation__
 *
 * To run a mutation, you first call `useAttachmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachmentDeleteMutation, { data, loading, error }] = useAttachmentDeleteMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useAttachmentDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AttachmentDeleteMutation,
        AttachmentDeleteMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        AttachmentDeleteMutation,
        AttachmentDeleteMutationVariables
    >(AttachmentDeleteDocument, options);
}
export type AttachmentDeleteMutationHookResult = ReturnType<
    typeof useAttachmentDeleteMutation
>;
export type AttachmentDeleteMutationResult =
    ApolloReactCommon.MutationResult<AttachmentDeleteMutation>;
export type AttachmentDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        AttachmentDeleteMutation,
        AttachmentDeleteMutationVariables
    >;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($tenantId: ID!, $input: Comment!) {
        commentUpdate(tenantId: $tenantId, comment: $input) {
            id
            text
            measureId
            utcCreated
            utcUpdated
            version
            task {
                id
                commentCount
                lastComment {
                    id
                    text
                    username
                    userId
                    utcCreated
                }
            }
            measure {
                id
                commentCount
                lastComment {
                    id
                    text
                    username
                    userId
                    utcCreated
                }
            }
        }
    }
`;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCommentMutation,
        UpdateCommentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCommentMutation,
        UpdateCommentMutationVariables
    >(UpdateCommentDocument, options);
}
export type UpdateCommentMutationHookResult = ReturnType<
    typeof useUpdateCommentMutation
>;
export type UpdateCommentMutationResult =
    ApolloReactCommon.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateCommentMutation,
        UpdateCommentMutationVariables
    >;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($tenantId: ID!, $id: ID!, $restore: Boolean) {
        commentDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            message
            success
            deletedObject {
                id
                utcDeleted
                task {
                    id
                    commentCount
                    lastComment {
                        id
                        text
                        username
                        userId
                        utcCreated
                    }
                }
                measure {
                    id
                    commentCount
                    lastComment {
                        id
                        text
                        username
                        userId
                        utcCreated
                    }
                }
            }
        }
    }
`;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteCommentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteCommentMutation,
        DeleteCommentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteCommentMutation,
        DeleteCommentMutationVariables
    >(DeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<
    typeof useDeleteCommentMutation
>;
export type DeleteCommentMutationResult =
    ApolloReactCommon.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteCommentMutation,
        DeleteCommentMutationVariables
    >;
export const LikeCommentToggleDocument = gql`
    mutation LikeCommentToggle(
        $tenantId: ID!
        $input: Like!
        $isLiked: Boolean
    ) {
        likeToggle(tenantId: $tenantId, like: $input, isLiked: $isLiked) {
            id
            likes {
                id
                userId
                username
                version
            }
        }
    }
`;

/**
 * __useLikeCommentToggleMutation__
 *
 * To run a mutation, you first call `useLikeCommentToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeCommentToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeCommentToggleMutation, { data, loading, error }] = useLikeCommentToggleMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *      isLiked: // value for 'isLiked'
 *   },
 * });
 */
export function useLikeCommentToggleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        LikeCommentToggleMutation,
        LikeCommentToggleMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        LikeCommentToggleMutation,
        LikeCommentToggleMutationVariables
    >(LikeCommentToggleDocument, options);
}
export type LikeCommentToggleMutationHookResult = ReturnType<
    typeof useLikeCommentToggleMutation
>;
export type LikeCommentToggleMutationResult =
    ApolloReactCommon.MutationResult<LikeCommentToggleMutation>;
export type LikeCommentToggleMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        LikeCommentToggleMutation,
        LikeCommentToggleMutationVariables
    >;
export const TaskAcceptDocument = gql`
    mutation TaskAccept(
        $tenantId: ID!
        $taskId: ID!
        $isAccepted: Boolean!
        $isRejected: Boolean!
        $rejectReason: String
        $parentTaskId: ID
        $assignToTaskId: ID
        $missionId: ID
        $taskName: String
        $isDuplicate: Boolean!
    ) {
        taskAccept(
            tenantId: $tenantId
            taskId: $taskId
            isAccepted: $isAccepted
            isRejected: $isRejected
            rejectReason: $rejectReason
            parentTaskId: $parentTaskId
            assignToTaskId: $assignToTaskId
            missionId: $missionId
            taskName: $taskName
            isDuplicate: $isDuplicate
        ) {
            id
            name
            start
            due
            done
            percentComplete
            parentTaskId
            missionId
            resourcedFromTask {
                id
                percentComplete
                utcUpdated
                version
            }
            parentTask {
                id
                percentComplete
                start
                due
                done
                utcUpdated
                version
            }
            resource {
                id
                userId
            }
            mission {
                id
            }
            utcAccepted
            utcRejected
            rejectedReason
            isDuplicate
            utcUpdated
            version
        }
    }
`;

/**
 * __useTaskAcceptMutation__
 *
 * To run a mutation, you first call `useTaskAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskAcceptMutation, { data, loading, error }] = useTaskAcceptMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      taskId: // value for 'taskId'
 *      isAccepted: // value for 'isAccepted'
 *      isRejected: // value for 'isRejected'
 *      rejectReason: // value for 'rejectReason'
 *      parentTaskId: // value for 'parentTaskId'
 *      assignToTaskId: // value for 'assignToTaskId'
 *      missionId: // value for 'missionId'
 *      taskName: // value for 'taskName'
 *      isDuplicate: // value for 'isDuplicate'
 *   },
 * });
 */
export function useTaskAcceptMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TaskAcceptMutation,
        TaskAcceptMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TaskAcceptMutation,
        TaskAcceptMutationVariables
    >(TaskAcceptDocument, options);
}
export type TaskAcceptMutationHookResult = ReturnType<
    typeof useTaskAcceptMutation
>;
export type TaskAcceptMutationResult =
    ApolloReactCommon.MutationResult<TaskAcceptMutation>;
export type TaskAcceptMutationOptions = ApolloReactCommon.BaseMutationOptions<
    TaskAcceptMutation,
    TaskAcceptMutationVariables
>;
export const UpdateDivisionDocument = gql`
    mutation UpdateDivision($tenantId: ID!, $input: Division!) {
        divisionUpdate(tenantId: $tenantId, division: $input) {
            id
            code
            name
            purpose
            vision
            values
            primaryColourHex
            canUnpublishReport
            financialYearId
            financialYear {
                id
                code
            }
            utcInactive
            version
        }
    }
`;

/**
 * __useUpdateDivisionMutation__
 *
 * To run a mutation, you first call `useUpdateDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDivisionMutation, { data, loading, error }] = useUpdateDivisionMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDivisionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateDivisionMutation,
        UpdateDivisionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateDivisionMutation,
        UpdateDivisionMutationVariables
    >(UpdateDivisionDocument, options);
}
export type UpdateDivisionMutationHookResult = ReturnType<
    typeof useUpdateDivisionMutation
>;
export type UpdateDivisionMutationResult =
    ApolloReactCommon.MutationResult<UpdateDivisionMutation>;
export type UpdateDivisionMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateDivisionMutation,
        UpdateDivisionMutationVariables
    >;
export const UpdateResourceDocument = gql`
    mutation UpdateResource($tenantId: ID!, $input: Resource!) {
        resourceUpdate(tenantId: $tenantId, resource: $input) {
            id
            userId
            name
            displayName
            version
            tasks {
                id
            }
        }
    }
`;

/**
 * __useUpdateResourceMutation__
 *
 * To run a mutation, you first call `useUpdateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceMutation, { data, loading, error }] = useUpdateResourceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateResourceMutation,
        UpdateResourceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateResourceMutation,
        UpdateResourceMutationVariables
    >(UpdateResourceDocument, options);
}
export type UpdateResourceMutationHookResult = ReturnType<
    typeof useUpdateResourceMutation
>;
export type UpdateResourceMutationResult =
    ApolloReactCommon.MutationResult<UpdateResourceMutation>;
export type UpdateResourceMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateResourceMutation,
        UpdateResourceMutationVariables
    >;
export const UpdateResourceTaskUserDocument = gql`
    mutation UpdateResourceTaskUser(
        $tenantId: ID!
        $taskIds: [ID!]!
        $targetUserId: ID!
    ) {
        resourceTaskUserUpdate(
            tenantId: $tenantId
            taskIds: $taskIds
            targetUserId: $targetUserId
        ) {
            id
            resourceId
            resource {
                id
                userId
                name
                displayName
                version
            }
        }
    }
`;

/**
 * __useUpdateResourceTaskUserMutation__
 *
 * To run a mutation, you first call `useUpdateResourceTaskUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceTaskUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceTaskUserMutation, { data, loading, error }] = useUpdateResourceTaskUserMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      taskIds: // value for 'taskIds'
 *      targetUserId: // value for 'targetUserId'
 *   },
 * });
 */
export function useUpdateResourceTaskUserMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateResourceTaskUserMutation,
        UpdateResourceTaskUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateResourceTaskUserMutation,
        UpdateResourceTaskUserMutationVariables
    >(UpdateResourceTaskUserDocument, options);
}
export type UpdateResourceTaskUserMutationHookResult = ReturnType<
    typeof useUpdateResourceTaskUserMutation
>;
export type UpdateResourceTaskUserMutationResult =
    ApolloReactCommon.MutationResult<UpdateResourceTaskUserMutation>;
export type UpdateResourceTaskUserMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateResourceTaskUserMutation,
        UpdateResourceTaskUserMutationVariables
    >;
export const DeleteResourceDocument = gql`
    mutation DeleteResource($tenantId: ID!, $id: ID!, $restore: Boolean) {
        resourceDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteResourceMutation__
 *
 * To run a mutation, you first call `useDeleteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceMutation, { data, loading, error }] = useDeleteResourceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteResourceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteResourceMutation,
        DeleteResourceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteResourceMutation,
        DeleteResourceMutationVariables
    >(DeleteResourceDocument, options);
}
export type DeleteResourceMutationHookResult = ReturnType<
    typeof useDeleteResourceMutation
>;
export type DeleteResourceMutationResult =
    ApolloReactCommon.MutationResult<DeleteResourceMutation>;
export type DeleteResourceMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteResourceMutation,
        DeleteResourceMutationVariables
    >;
export const UpdateTagDocument = gql`
    mutation UpdateTag($tenantId: ID!, $input: Tag!) {
        tagUpdate(tenantId: $tenantId, tag: $input) {
            id
            name
            colourHex
            icon
            tagType
            isMeasureTag
            isTaskTag
        }
    }
`;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTagMutation,
        UpdateTagMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTagMutation,
        UpdateTagMutationVariables
    >(UpdateTagDocument, options);
}
export type UpdateTagMutationHookResult = ReturnType<
    typeof useUpdateTagMutation
>;
export type UpdateTagMutationResult =
    ApolloReactCommon.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateTagMutation,
    UpdateTagMutationVariables
>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($tenantId: ID!, $id: ID!, $restore: Boolean) {
        tagDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteTagMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteTagMutation,
        DeleteTagMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteTagMutation,
        DeleteTagMutationVariables
    >(DeleteTagDocument, options);
}
export type DeleteTagMutationHookResult = ReturnType<
    typeof useDeleteTagMutation
>;
export type DeleteTagMutationResult =
    ApolloReactCommon.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteTagMutation,
    DeleteTagMutationVariables
>;
export const UpdateTaskCategoryDocument = gql`
    mutation UpdateTaskCategory($tenantId: ID!, $input: TaskCategory!) {
        taskCategoryUpdate(tenantId: $tenantId, taskCategory: $input) {
            id
            name
            colourHex
            version
        }
    }
`;

/**
 * __useUpdateTaskCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateTaskCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskCategoryMutation, { data, loading, error }] = useUpdateTaskCategoryMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskCategoryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTaskCategoryMutation,
        UpdateTaskCategoryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTaskCategoryMutation,
        UpdateTaskCategoryMutationVariables
    >(UpdateTaskCategoryDocument, options);
}
export type UpdateTaskCategoryMutationHookResult = ReturnType<
    typeof useUpdateTaskCategoryMutation
>;
export type UpdateTaskCategoryMutationResult =
    ApolloReactCommon.MutationResult<UpdateTaskCategoryMutation>;
export type UpdateTaskCategoryMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateTaskCategoryMutation,
        UpdateTaskCategoryMutationVariables
    >;
export const DeleteTaskCategoryDocument = gql`
    mutation DeleteTaskCategory($tenantId: ID!, $id: ID!, $restore: Boolean) {
        taskCategoryDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteTaskCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteTaskCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskCategoryMutation, { data, loading, error }] = useDeleteTaskCategoryMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteTaskCategoryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteTaskCategoryMutation,
        DeleteTaskCategoryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteTaskCategoryMutation,
        DeleteTaskCategoryMutationVariables
    >(DeleteTaskCategoryDocument, options);
}
export type DeleteTaskCategoryMutationHookResult = ReturnType<
    typeof useDeleteTaskCategoryMutation
>;
export type DeleteTaskCategoryMutationResult =
    ApolloReactCommon.MutationResult<DeleteTaskCategoryMutation>;
export type DeleteTaskCategoryMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteTaskCategoryMutation,
        DeleteTaskCategoryMutationVariables
    >;
export const SwapTaskCategoryDocument = gql`
    mutation SwapTaskCategory($tenantId: ID!, $id: ID!, $swapId: ID) {
        taskCategorySwap(tenantId: $tenantId, id: $id, swapId: $swapId) {
            id
            message
            success
        }
    }
`;

/**
 * __useSwapTaskCategoryMutation__
 *
 * To run a mutation, you first call `useSwapTaskCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapTaskCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapTaskCategoryMutation, { data, loading, error }] = useSwapTaskCategoryMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      swapId: // value for 'swapId'
 *   },
 * });
 */
export function useSwapTaskCategoryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SwapTaskCategoryMutation,
        SwapTaskCategoryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SwapTaskCategoryMutation,
        SwapTaskCategoryMutationVariables
    >(SwapTaskCategoryDocument, options);
}
export type SwapTaskCategoryMutationHookResult = ReturnType<
    typeof useSwapTaskCategoryMutation
>;
export type SwapTaskCategoryMutationResult =
    ApolloReactCommon.MutationResult<SwapTaskCategoryMutation>;
export type SwapTaskCategoryMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        SwapTaskCategoryMutation,
        SwapTaskCategoryMutationVariables
    >;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: User!) {
        userUpdate(user: $input) {
            id
            displayName
            emailAddress
            accessEnabled
            utcSelfHelpEnabledUntil
            userTenants {
                tenantId
                isDefault
            }
            userRoles {
                roleId
                tenantId
                name
            }
            utcUpdated
            version
        }
    }
`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<
    typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
    ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;
export const UpdateContributorDocument = gql`
    mutation UpdateContributor($tenantId: ID!, $input: Contributor!) {
        contributorUpdate(tenantId: $tenantId, contributor: $input) {
            id
            userId
            userDisplayName
            teamId
            financialYearId
            team {
                id
                code
            }
            financialYear {
                id
                code
            }
        }
    }
`;

/**
 * __useUpdateContributorMutation__
 *
 * To run a mutation, you first call `useUpdateContributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContributorMutation, { data, loading, error }] = useUpdateContributorMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContributorMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateContributorMutation,
        UpdateContributorMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateContributorMutation,
        UpdateContributorMutationVariables
    >(UpdateContributorDocument, options);
}
export type UpdateContributorMutationHookResult = ReturnType<
    typeof useUpdateContributorMutation
>;
export type UpdateContributorMutationResult =
    ApolloReactCommon.MutationResult<UpdateContributorMutation>;
export type UpdateContributorMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateContributorMutation,
        UpdateContributorMutationVariables
    >;
export const DeleteContributorDocument = gql`
    mutation DeleteContributor($tenantId: ID!, $contributorId: ID!) {
        contributorDelete(tenantId: $tenantId, contributorId: $contributorId) {
            message
            success
            deletedObject {
                id
                utcDeleted
            }
        }
    }
`;

/**
 * __useDeleteContributorMutation__
 *
 * To run a mutation, you first call `useDeleteContributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContributorMutation, { data, loading, error }] = useDeleteContributorMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      contributorId: // value for 'contributorId'
 *   },
 * });
 */
export function useDeleteContributorMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteContributorMutation,
        DeleteContributorMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteContributorMutation,
        DeleteContributorMutationVariables
    >(DeleteContributorDocument, options);
}
export type DeleteContributorMutationHookResult = ReturnType<
    typeof useDeleteContributorMutation
>;
export type DeleteContributorMutationResult =
    ApolloReactCommon.MutationResult<DeleteContributorMutation>;
export type DeleteContributorMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteContributorMutation,
        DeleteContributorMutationVariables
    >;
export const UpdateRightDocument = gql`
    mutation updateRight($tenantId: ID!, $right: Right!) {
        rightUpdate(tenantId: $tenantId, right: $right) {
            id
            read
            write
            export
            import
            divisionId
            teamId
            missionId
            userId
        }
    }
`;

/**
 * __useUpdateRightMutation__
 *
 * To run a mutation, you first call `useUpdateRightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRightMutation, { data, loading, error }] = useUpdateRightMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      right: // value for 'right'
 *   },
 * });
 */
export function useUpdateRightMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateRightMutation,
        UpdateRightMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateRightMutation,
        UpdateRightMutationVariables
    >(UpdateRightDocument, options);
}
export type UpdateRightMutationHookResult = ReturnType<
    typeof useUpdateRightMutation
>;
export type UpdateRightMutationResult =
    ApolloReactCommon.MutationResult<UpdateRightMutation>;
export type UpdateRightMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateRightMutation,
    UpdateRightMutationVariables
>;
export const RightDeleteDocument = gql`
    mutation rightDelete($tenantId: ID!, $id: ID!, $restore: Boolean) {
        rightDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useRightDeleteMutation__
 *
 * To run a mutation, you first call `useRightDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRightDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rightDeleteMutation, { data, loading, error }] = useRightDeleteMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useRightDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RightDeleteMutation,
        RightDeleteMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RightDeleteMutation,
        RightDeleteMutationVariables
    >(RightDeleteDocument, options);
}
export type RightDeleteMutationHookResult = ReturnType<
    typeof useRightDeleteMutation
>;
export type RightDeleteMutationResult =
    ApolloReactCommon.MutationResult<RightDeleteMutation>;
export type RightDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    RightDeleteMutation,
    RightDeleteMutationVariables
>;
export const UpdateUserTenantDefaultDocument = gql`
    mutation UpdateUserTenantDefault($tenantId: ID!, $userId: ID!) {
        userUpdateTenantDefault(tenantId: $tenantId, userId: $userId) {
            id
            userId
            tenantId
            isDefault
            tenant {
                id
                code
                description
                isEnabled
                isAnalyticsEnabled
            }
        }
    }
`;

/**
 * __useUpdateUserTenantDefaultMutation__
 *
 * To run a mutation, you first call `useUpdateUserTenantDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTenantDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTenantDefaultMutation, { data, loading, error }] = useUpdateUserTenantDefaultMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserTenantDefaultMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateUserTenantDefaultMutation,
        UpdateUserTenantDefaultMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateUserTenantDefaultMutation,
        UpdateUserTenantDefaultMutationVariables
    >(UpdateUserTenantDefaultDocument, options);
}
export type UpdateUserTenantDefaultMutationHookResult = ReturnType<
    typeof useUpdateUserTenantDefaultMutation
>;
export type UpdateUserTenantDefaultMutationResult =
    ApolloReactCommon.MutationResult<UpdateUserTenantDefaultMutation>;
export type UpdateUserTenantDefaultMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateUserTenantDefaultMutation,
        UpdateUserTenantDefaultMutationVariables
    >;
export const DeleteUserDocument = gql`
    mutation DeleteUser($tenantId: ID!, $id: ID!, $restore: Boolean) {
        userDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteUserMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteUserMutation,
        DeleteUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteUserMutation,
        DeleteUserMutationVariables
    >(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<
    typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
    ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
>;
export const UpdateFinancialYearDocument = gql`
    mutation UpdateFinancialYear($tenantId: ID!, $input: FinancialYear!) {
        financialYearUpdate(tenantId: $tenantId, fy: $input) {
            id
            code
            name
            startDate
            endDate
            utcInactive
            version
        }
    }
`;

/**
 * __useUpdateFinancialYearMutation__
 *
 * To run a mutation, you first call `useUpdateFinancialYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancialYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancialYearMutation, { data, loading, error }] = useUpdateFinancialYearMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFinancialYearMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateFinancialYearMutation,
        UpdateFinancialYearMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateFinancialYearMutation,
        UpdateFinancialYearMutationVariables
    >(UpdateFinancialYearDocument, options);
}
export type UpdateFinancialYearMutationHookResult = ReturnType<
    typeof useUpdateFinancialYearMutation
>;
export type UpdateFinancialYearMutationResult =
    ApolloReactCommon.MutationResult<UpdateFinancialYearMutation>;
export type UpdateFinancialYearMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateFinancialYearMutation,
        UpdateFinancialYearMutationVariables
    >;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($tenantId: ID!, $input: Team!) {
        teamUpdate(tenantId: $tenantId, team: $input) {
            id
            code
            name
            division {
                id
            }
            leaderMission {
                id
            }
            utcUpdated
            utcCreated
            version
        }
    }
`;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTeamMutation,
        UpdateTeamMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTeamMutation,
        UpdateTeamMutationVariables
    >(UpdateTeamDocument, options);
}
export type UpdateTeamMutationHookResult = ReturnType<
    typeof useUpdateTeamMutation
>;
export type UpdateTeamMutationResult =
    ApolloReactCommon.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($tenantId: ID!, $id: ID!, $restore: Boolean) {
        teamDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteTeamMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteTeamMutation,
        DeleteTeamMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteTeamMutation,
        DeleteTeamMutationVariables
    >(DeleteTeamDocument, options);
}
export type DeleteTeamMutationHookResult = ReturnType<
    typeof useDeleteTeamMutation
>;
export type DeleteTeamMutationResult =
    ApolloReactCommon.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteTeamMutation,
    DeleteTeamMutationVariables
>;
export const UpdateMissionGroupDocument = gql`
    mutation UpdateMissionGroup($tenantId: ID!, $input: MissionGroup!) {
        missionGroupUpdate(tenantId: $tenantId, missionGroup: $input) {
            id
            teamId
            name
            sequence
            version
        }
    }
`;

/**
 * __useUpdateMissionGroupMutation__
 *
 * To run a mutation, you first call `useUpdateMissionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMissionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMissionGroupMutation, { data, loading, error }] = useUpdateMissionGroupMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMissionGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateMissionGroupMutation,
        UpdateMissionGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateMissionGroupMutation,
        UpdateMissionGroupMutationVariables
    >(UpdateMissionGroupDocument, options);
}
export type UpdateMissionGroupMutationHookResult = ReturnType<
    typeof useUpdateMissionGroupMutation
>;
export type UpdateMissionGroupMutationResult =
    ApolloReactCommon.MutationResult<UpdateMissionGroupMutation>;
export type UpdateMissionGroupMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateMissionGroupMutation,
        UpdateMissionGroupMutationVariables
    >;
export const DeleteMissionGroupDocument = gql`
    mutation DeleteMissionGroup($tenantId: ID!, $id: ID!, $restore: Boolean) {
        missionGroupDelete(tenantId: $tenantId, id: $id, restore: $restore) {
            message
            success
            deletedObject {
                id
                utcDeleted
            }
        }
    }
`;

/**
 * __useDeleteMissionGroupMutation__
 *
 * To run a mutation, you first call `useDeleteMissionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMissionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMissionGroupMutation, { data, loading, error }] = useDeleteMissionGroupMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteMissionGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteMissionGroupMutation,
        DeleteMissionGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteMissionGroupMutation,
        DeleteMissionGroupMutationVariables
    >(DeleteMissionGroupDocument, options);
}
export type DeleteMissionGroupMutationHookResult = ReturnType<
    typeof useDeleteMissionGroupMutation
>;
export type DeleteMissionGroupMutationResult =
    ApolloReactCommon.MutationResult<DeleteMissionGroupMutation>;
export type DeleteMissionGroupMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteMissionGroupMutation,
        DeleteMissionGroupMutationVariables
    >;
export const UpdateStatusReportDocument = gql`
    mutation UpdateStatusReport(
        $tenantId: ID!
        $input: StatusReport!
        $notifyUserIds: [ID!]
    ) {
        statusReportUpdate(
            tenantId: $tenantId
            statusReport: $input
            notifyUserIds: $notifyUserIds
        ) {
            id
            missionId
            reportDate
            utcCompletedDate
            utcDataDate
            title
            summaryText
            lastPeriodText
            nextPeriodText
            risksAndOpportunitiesText
            supportText
            facts {
                id
                measureId
                taskId
                factText
                soWhatText
                insightText
                actionText
                sequence
                isIncluded
                version
            }
            utcCreated
            utcUpdated
            utcDeleted
            version
        }
    }
`;

/**
 * __useUpdateStatusReportMutation__
 *
 * To run a mutation, you first call `useUpdateStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusReportMutation, { data, loading, error }] = useUpdateStatusReportMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *      notifyUserIds: // value for 'notifyUserIds'
 *   },
 * });
 */
export function useUpdateStatusReportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateStatusReportMutation,
        UpdateStatusReportMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateStatusReportMutation,
        UpdateStatusReportMutationVariables
    >(UpdateStatusReportDocument, options);
}
export type UpdateStatusReportMutationHookResult = ReturnType<
    typeof useUpdateStatusReportMutation
>;
export type UpdateStatusReportMutationResult =
    ApolloReactCommon.MutationResult<UpdateStatusReportMutation>;
export type UpdateStatusReportMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateStatusReportMutation,
        UpdateStatusReportMutationVariables
    >;
export const UpdateStatusReportFactDocument = gql`
    mutation UpdateStatusReportFact(
        $tenantId: ID!
        $statusReportId: ID!
        $fact: StatusReportFact!
    ) {
        statusReportFactUpdate(
            tenantId: $tenantId
            statusReportId: $statusReportId
            fact: $fact
        ) {
            id
            measureId
            taskId
            factText
            soWhatText
            insightText
            actionText
            sequence
            isIncluded
            version
        }
    }
`;

/**
 * __useUpdateStatusReportFactMutation__
 *
 * To run a mutation, you first call `useUpdateStatusReportFactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusReportFactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusReportFactMutation, { data, loading, error }] = useUpdateStatusReportFactMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      statusReportId: // value for 'statusReportId'
 *      fact: // value for 'fact'
 *   },
 * });
 */
export function useUpdateStatusReportFactMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateStatusReportFactMutation,
        UpdateStatusReportFactMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateStatusReportFactMutation,
        UpdateStatusReportFactMutationVariables
    >(UpdateStatusReportFactDocument, options);
}
export type UpdateStatusReportFactMutationHookResult = ReturnType<
    typeof useUpdateStatusReportFactMutation
>;
export type UpdateStatusReportFactMutationResult =
    ApolloReactCommon.MutationResult<UpdateStatusReportFactMutation>;
export type UpdateStatusReportFactMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateStatusReportFactMutation,
        UpdateStatusReportFactMutationVariables
    >;
export const StatusReportDataRefreshDocument = gql`
    mutation StatusReportDataRefresh(
        $tenantId: ID!
        $statusReportId: ID!
        $reportDate: DateTime
    ) {
        statusReportDataRefresh(
            tenantId: $tenantId
            statusReportId: $statusReportId
            reportDate: $reportDate
        ) {
            id
            utcCompletedDate
            utcDataDate
            utcUpdated
            version
        }
    }
`;

/**
 * __useStatusReportDataRefreshMutation__
 *
 * To run a mutation, you first call `useStatusReportDataRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStatusReportDataRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [statusReportDataRefreshMutation, { data, loading, error }] = useStatusReportDataRefreshMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      statusReportId: // value for 'statusReportId'
 *      reportDate: // value for 'reportDate'
 *   },
 * });
 */
export function useStatusReportDataRefreshMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        StatusReportDataRefreshMutation,
        StatusReportDataRefreshMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        StatusReportDataRefreshMutation,
        StatusReportDataRefreshMutationVariables
    >(StatusReportDataRefreshDocument, options);
}
export type StatusReportDataRefreshMutationHookResult = ReturnType<
    typeof useStatusReportDataRefreshMutation
>;
export type StatusReportDataRefreshMutationResult =
    ApolloReactCommon.MutationResult<StatusReportDataRefreshMutation>;
export type StatusReportDataRefreshMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        StatusReportDataRefreshMutation,
        StatusReportDataRefreshMutationVariables
    >;
export const DeleteStatusReportDocument = gql`
    mutation DeleteStatusReport(
        $tenantId: ID!
        $statusReportId: ID!
        $restore: Boolean
    ) {
        statusReportDelete(
            tenantId: $tenantId
            statusReportId: $statusReportId
            restore: $restore
        ) {
            id
            utcDeleted
            utcUpdated
            version
        }
    }
`;

/**
 * __useDeleteStatusReportMutation__
 *
 * To run a mutation, you first call `useDeleteStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatusReportMutation, { data, loading, error }] = useDeleteStatusReportMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      statusReportId: // value for 'statusReportId'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteStatusReportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteStatusReportMutation,
        DeleteStatusReportMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteStatusReportMutation,
        DeleteStatusReportMutationVariables
    >(DeleteStatusReportDocument, options);
}
export type DeleteStatusReportMutationHookResult = ReturnType<
    typeof useDeleteStatusReportMutation
>;
export type DeleteStatusReportMutationResult =
    ApolloReactCommon.MutationResult<DeleteStatusReportMutation>;
export type DeleteStatusReportMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteStatusReportMutation,
        DeleteStatusReportMutationVariables
    >;
export const DeleteTemplateReportDocument = gql`
    mutation DeleteTemplateReport(
        $tenantId: ID!
        $templateReportId: ID!
        $restore: Boolean
    ) {
        templateReportDelete(
            tenantId: $tenantId
            templateReportId: $templateReportId
            restore: $restore
        ) {
            id
            utcDeleted
            utcUpdated
            version
        }
    }
`;

/**
 * __useDeleteTemplateReportMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateReportMutation, { data, loading, error }] = useDeleteTemplateReportMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      templateReportId: // value for 'templateReportId'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteTemplateReportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteTemplateReportMutation,
        DeleteTemplateReportMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteTemplateReportMutation,
        DeleteTemplateReportMutationVariables
    >(DeleteTemplateReportDocument, options);
}
export type DeleteTemplateReportMutationHookResult = ReturnType<
    typeof useDeleteTemplateReportMutation
>;
export type DeleteTemplateReportMutationResult =
    ApolloReactCommon.MutationResult<DeleteTemplateReportMutation>;
export type DeleteTemplateReportMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteTemplateReportMutation,
        DeleteTemplateReportMutationVariables
    >;
export const CreateTemplateReportDocument = gql`
    mutation CreateTemplateReport(
        $tenantId: ID!
        $input: TemplateReportCreateInput!
    ) {
        templateReportCreate(tenantId: $tenantId, templateReport: $input) {
            id
            missionId
            reportDate
            reportPeriodType
            reportPeriod
            utcCompletedDate
            utcDataDate
            title
            utcCreated
            utcUpdated
            utcDeleted
            version
        }
    }
`;

/**
 * __useCreateTemplateReportMutation__
 *
 * To run a mutation, you first call `useCreateTemplateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateReportMutation, { data, loading, error }] = useCreateTemplateReportMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateReportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateTemplateReportMutation,
        CreateTemplateReportMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateTemplateReportMutation,
        CreateTemplateReportMutationVariables
    >(CreateTemplateReportDocument, options);
}
export type CreateTemplateReportMutationHookResult = ReturnType<
    typeof useCreateTemplateReportMutation
>;
export type CreateTemplateReportMutationResult =
    ApolloReactCommon.MutationResult<CreateTemplateReportMutation>;
export type CreateTemplateReportMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        CreateTemplateReportMutation,
        CreateTemplateReportMutationVariables
    >;
export const UpdateTemplateReportDocument = gql`
    mutation UpdateTemplateReport(
        $tenantId: ID!
        $input: TemplateReportInput!
        $notifyUserIds: [ID!]
    ) {
        templateReportUpdate(
            tenantId: $tenantId
            templateReport: $input
            notifyUserIds: $notifyUserIds
        ) {
            id
            missionId
            reportDate
            reportPeriodType
            reportPeriod
            utcCompletedDate
            utcDataDate
            title
            utcCreated
            utcUpdated
            utcDeleted
            version
        }
    }
`;

/**
 * __useUpdateTemplateReportMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateReportMutation, { data, loading, error }] = useUpdateTemplateReportMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *      notifyUserIds: // value for 'notifyUserIds'
 *   },
 * });
 */
export function useUpdateTemplateReportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTemplateReportMutation,
        UpdateTemplateReportMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTemplateReportMutation,
        UpdateTemplateReportMutationVariables
    >(UpdateTemplateReportDocument, options);
}
export type UpdateTemplateReportMutationHookResult = ReturnType<
    typeof useUpdateTemplateReportMutation
>;
export type UpdateTemplateReportMutationResult =
    ApolloReactCommon.MutationResult<UpdateTemplateReportMutation>;
export type UpdateTemplateReportMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateTemplateReportMutation,
        UpdateTemplateReportMutationVariables
    >;
export const UpdateTemplateReportSectionDocument = gql`
    mutation UpdateTemplateReportSection(
        $tenantId: ID!
        $input: TemplateReportSectionInput!
    ) {
        templateReportSectionUpdate(
            tenantId: $tenantId
            templateReportSection: $input
        ) {
            id
            title
            layoutDirection
            availableElementTypes
            sequence
            groups {
                id
                sequence
                layoutGrow
                layoutDirection
                version
            }
            utcCreated
            utcUpdated
            utcDeleted
            version
        }
    }
`;

/**
 * __useUpdateTemplateReportSectionMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateReportSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateReportSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateReportSectionMutation, { data, loading, error }] = useUpdateTemplateReportSectionMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateReportSectionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTemplateReportSectionMutation,
        UpdateTemplateReportSectionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTemplateReportSectionMutation,
        UpdateTemplateReportSectionMutationVariables
    >(UpdateTemplateReportSectionDocument, options);
}
export type UpdateTemplateReportSectionMutationHookResult = ReturnType<
    typeof useUpdateTemplateReportSectionMutation
>;
export type UpdateTemplateReportSectionMutationResult =
    ApolloReactCommon.MutationResult<UpdateTemplateReportSectionMutation>;
export type UpdateTemplateReportSectionMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateTemplateReportSectionMutation,
        UpdateTemplateReportSectionMutationVariables
    >;
export const UpdateTemplateReportElementDocument = gql`
    mutation UpdateTemplateReportElement(
        $tenantId: ID!
        $input: TemplateReportElementInput!
    ) {
        templateReportElementUpdate(
            tenantId: $tenantId
            templateReportElement: $input
        ) {
            id
            title
            textContent
            sectionId
            attachmentId
            utcUpdated
            measureLinks {
                id
                sequence
                measureId
            }
            taskLinks {
                id
                sequence
                taskId
            }
            columns {
                id
                columnName
                sequence
                cells {
                    id
                    rowIndex
                    content
                }
                version
            }
            version
        }
    }
`;

/**
 * __useUpdateTemplateReportElementMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateReportElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateReportElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateReportElementMutation, { data, loading, error }] = useUpdateTemplateReportElementMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateReportElementMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTemplateReportElementMutation,
        UpdateTemplateReportElementMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTemplateReportElementMutation,
        UpdateTemplateReportElementMutationVariables
    >(UpdateTemplateReportElementDocument, options);
}
export type UpdateTemplateReportElementMutationHookResult = ReturnType<
    typeof useUpdateTemplateReportElementMutation
>;
export type UpdateTemplateReportElementMutationResult =
    ApolloReactCommon.MutationResult<UpdateTemplateReportElementMutation>;
export type UpdateTemplateReportElementMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateTemplateReportElementMutation,
        UpdateTemplateReportElementMutationVariables
    >;
export const DeleteTemplateReportElementDocument = gql`
    mutation DeleteTemplateReportElement(
        $tenantId: ID!
        $id: ID!
        $restore: Boolean
    ) {
        templateReportElementDelete(
            tenantId: $tenantId
            id: $id
            restore: $restore
        ) {
            message
            success
            deletedObject {
                id
                utcDeleted
            }
        }
    }
`;

/**
 * __useDeleteTemplateReportElementMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateReportElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateReportElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateReportElementMutation, { data, loading, error }] = useDeleteTemplateReportElementMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      restore: // value for 'restore'
 *   },
 * });
 */
export function useDeleteTemplateReportElementMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteTemplateReportElementMutation,
        DeleteTemplateReportElementMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteTemplateReportElementMutation,
        DeleteTemplateReportElementMutationVariables
    >(DeleteTemplateReportElementDocument, options);
}
export type DeleteTemplateReportElementMutationHookResult = ReturnType<
    typeof useDeleteTemplateReportElementMutation
>;
export type DeleteTemplateReportElementMutationResult =
    ApolloReactCommon.MutationResult<DeleteTemplateReportElementMutation>;
export type DeleteTemplateReportElementMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteTemplateReportElementMutation,
        DeleteTemplateReportElementMutationVariables
    >;
export const DismissNotificationDocument = gql`
    mutation DismissNotification($tenantId: ID!, $notificationId: ID!) {
        notificationDismiss(
            tenantId: $tenantId
            notificationId: $notificationId
        ) {
            id
            utcDismissed
        }
    }
`;

/**
 * __useDismissNotificationMutation__
 *
 * To run a mutation, you first call `useDismissNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissNotificationMutation, { data, loading, error }] = useDismissNotificationMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useDismissNotificationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DismissNotificationMutation,
        DismissNotificationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DismissNotificationMutation,
        DismissNotificationMutationVariables
    >(DismissNotificationDocument, options);
}
export type DismissNotificationMutationHookResult = ReturnType<
    typeof useDismissNotificationMutation
>;
export type DismissNotificationMutationResult =
    ApolloReactCommon.MutationResult<DismissNotificationMutation>;
export type DismissNotificationMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DismissNotificationMutation,
        DismissNotificationMutationVariables
    >;
export const DismissAllNotificationsDocument = gql`
    mutation DismissAllNotifications($tenantId: ID!, $userId: ID!) {
        notificationsDismissAll(tenantId: $tenantId, userId: $userId) {
            id
            utcDismissed
        }
    }
`;

/**
 * __useDismissAllNotificationsMutation__
 *
 * To run a mutation, you first call `useDismissAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissAllNotificationsMutation, { data, loading, error }] = useDismissAllNotificationsMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDismissAllNotificationsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DismissAllNotificationsMutation,
        DismissAllNotificationsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DismissAllNotificationsMutation,
        DismissAllNotificationsMutationVariables
    >(DismissAllNotificationsDocument, options);
}
export type DismissAllNotificationsMutationHookResult = ReturnType<
    typeof useDismissAllNotificationsMutation
>;
export type DismissAllNotificationsMutationResult =
    ApolloReactCommon.MutationResult<DismissAllNotificationsMutation>;
export type DismissAllNotificationsMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DismissAllNotificationsMutation,
        DismissAllNotificationsMutationVariables
    >;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($input: Tenant!) {
        tenantUpdate(tenant: $input) {
            id
            code
            description
            isEnabled
            isAnalyticsEnabled
        }
    }
`;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateTenantMutation,
        UpdateTenantMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateTenantMutation,
        UpdateTenantMutationVariables
    >(UpdateTenantDocument, options);
}
export type UpdateTenantMutationHookResult = ReturnType<
    typeof useUpdateTenantMutation
>;
export type UpdateTenantMutationResult =
    ApolloReactCommon.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
>;
export const DeleteTenantDocument = gql`
    mutation DeleteTenant($tenantId: ID!) {
        tenantDelete(tenantId: $tenantId) {
            id
            message
            success
        }
    }
`;

/**
 * __useDeleteTenantMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMutation, { data, loading, error }] = useDeleteTenantMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useDeleteTenantMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteTenantMutation,
        DeleteTenantMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteTenantMutation,
        DeleteTenantMutationVariables
    >(DeleteTenantDocument, options);
}
export type DeleteTenantMutationHookResult = ReturnType<
    typeof useDeleteTenantMutation
>;
export type DeleteTenantMutationResult =
    ApolloReactCommon.MutationResult<DeleteTenantMutation>;
export type DeleteTenantMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteTenantMutation,
    DeleteTenantMutationVariables
>;
export const CacheResetDocument = gql`
    mutation cacheReset {
        cacheReset
    }
`;

/**
 * __useCacheResetMutation__
 *
 * To run a mutation, you first call `useCacheResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCacheResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cacheResetMutation, { data, loading, error }] = useCacheResetMutation({
 *   variables: {
 *   },
 * });
 */
export function useCacheResetMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CacheResetMutation,
        CacheResetMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CacheResetMutation,
        CacheResetMutationVariables
    >(CacheResetDocument, options);
}
export type CacheResetMutationHookResult = ReturnType<
    typeof useCacheResetMutation
>;
export type CacheResetMutationResult =
    ApolloReactCommon.MutationResult<CacheResetMutation>;
export type CacheResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CacheResetMutation,
    CacheResetMutationVariables
>;
export const ToggleTagLinkDocument = gql`
    mutation ToggleTagLink(
        $tenantId: ID!
        $measureId: ID
        $taskId: ID
        $tagId: ID
        $tagName: String
    ) {
        tagLinkToggle(
            tenantId: $tenantId
            measureId: $measureId
            taskId: $taskId
            tagId: $tagId
            tagName: $tagName
        ) {
            id
            success
            message
        }
    }
`;

/**
 * __useToggleTagLinkMutation__
 *
 * To run a mutation, you first call `useToggleTagLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTagLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTagLinkMutation, { data, loading, error }] = useToggleTagLinkMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *      taskId: // value for 'taskId'
 *      tagId: // value for 'tagId'
 *      tagName: // value for 'tagName'
 *   },
 * });
 */
export function useToggleTagLinkMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ToggleTagLinkMutation,
        ToggleTagLinkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ToggleTagLinkMutation,
        ToggleTagLinkMutationVariables
    >(ToggleTagLinkDocument, options);
}
export type ToggleTagLinkMutationHookResult = ReturnType<
    typeof useToggleTagLinkMutation
>;
export type ToggleTagLinkMutationResult =
    ApolloReactCommon.MutationResult<ToggleTagLinkMutation>;
export type ToggleTagLinkMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        ToggleTagLinkMutation,
        ToggleTagLinkMutationVariables
    >;
export const JsonUploadDocument = gql`
    mutation JsonUpload($tenantId: ID!, $divisionId: ID!, $json: String!) {
        jsonUpload(tenantId: $tenantId, divisionId: $divisionId, json: $json) {
            id
            message
            success
        }
    }
`;

/**
 * __useJsonUploadMutation__
 *
 * To run a mutation, you first call `useJsonUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJsonUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jsonUploadMutation, { data, loading, error }] = useJsonUploadMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      divisionId: // value for 'divisionId'
 *      json: // value for 'json'
 *   },
 * });
 */
export function useJsonUploadMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        JsonUploadMutation,
        JsonUploadMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        JsonUploadMutation,
        JsonUploadMutationVariables
    >(JsonUploadDocument, options);
}
export type JsonUploadMutationHookResult = ReturnType<
    typeof useJsonUploadMutation
>;
export type JsonUploadMutationResult =
    ApolloReactCommon.MutationResult<JsonUploadMutation>;
export type JsonUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
    JsonUploadMutation,
    JsonUploadMutationVariables
>;
export const RollbackMeasureImportDocument = gql`
    mutation RollbackMeasureImport(
        $tenantId: ID!
        $batchId: ID!
        $preview: Boolean
    ) {
        measureImporterRollback(
            tenantId: $tenantId
            batchId: $batchId
            preview: $preview
        ) {
            items {
                batch {
                    batchId
                    utcBatchDate
                    updatedByUserId
                    username
                }
                text
            }
            isComplete
            completeText
            isError
            errorText
        }
    }
`;

/**
 * __useRollbackMeasureImportMutation__
 *
 * To run a mutation, you first call `useRollbackMeasureImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRollbackMeasureImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rollbackMeasureImportMutation, { data, loading, error }] = useRollbackMeasureImportMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      batchId: // value for 'batchId'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useRollbackMeasureImportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RollbackMeasureImportMutation,
        RollbackMeasureImportMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RollbackMeasureImportMutation,
        RollbackMeasureImportMutationVariables
    >(RollbackMeasureImportDocument, options);
}
export type RollbackMeasureImportMutationHookResult = ReturnType<
    typeof useRollbackMeasureImportMutation
>;
export type RollbackMeasureImportMutationResult =
    ApolloReactCommon.MutationResult<RollbackMeasureImportMutation>;
export type RollbackMeasureImportMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        RollbackMeasureImportMutation,
        RollbackMeasureImportMutationVariables
    >;
export const GetDefaultMissionDocument = gql`
    query GetDefaultMission($tenantId: ID!, $financialYearCode: String) {
        default_mission(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
        ) {
            id
            userId
            team {
                id
                code
                division {
                    id
                    vision
                    purpose
                    values
                    primaryColourHex
                    financialYear {
                        id
                        code
                        startDate
                        endDate
                    }
                }
                leaderMission {
                    id
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
            }
            leaderOfTeams {
                id
                code
                division {
                    id
                    vision
                    purpose
                    values
                    financialYear {
                        id
                        code
                        startDate
                        endDate
                    }
                }
            }
        }
        default_contributor(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
        ) {
            id
            userId
            financialYear {
                id
                code
                startDate
                endDate
            }
            team {
                id
                code
            }
        }
    }
`;

/**
 * __useGetDefaultMissionQuery__
 *
 * To run a query within a React component, call `useGetDefaultMissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultMissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultMissionQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetDefaultMissionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetDefaultMissionQuery,
        GetDefaultMissionQueryVariables
    > &
        (
            | { variables: GetDefaultMissionQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetDefaultMissionQuery,
        GetDefaultMissionQueryVariables
    >(GetDefaultMissionDocument, options);
}
export function useGetDefaultMissionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetDefaultMissionQuery,
        GetDefaultMissionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetDefaultMissionQuery,
        GetDefaultMissionQueryVariables
    >(GetDefaultMissionDocument, options);
}
export function useGetDefaultMissionSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetDefaultMissionQuery,
              GetDefaultMissionQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetDefaultMissionQuery,
        GetDefaultMissionQueryVariables
    >(GetDefaultMissionDocument, options);
}
export type GetDefaultMissionQueryHookResult = ReturnType<
    typeof useGetDefaultMissionQuery
>;
export type GetDefaultMissionLazyQueryHookResult = ReturnType<
    typeof useGetDefaultMissionLazyQuery
>;
export type GetDefaultMissionSuspenseQueryHookResult = ReturnType<
    typeof useGetDefaultMissionSuspenseQuery
>;
export type GetDefaultMissionQueryResult = ApolloReactCommon.QueryResult<
    GetDefaultMissionQuery,
    GetDefaultMissionQueryVariables
>;
export function refetchGetDefaultMissionQuery(
    variables: GetDefaultMissionQueryVariables
) {
    return { query: GetDefaultMissionDocument, variables: variables };
}
export const GetBreadcrumbsDocument = gql`
    query GetBreadcrumbs(
        $tenantId: ID!
        $teamCode: String!
        $financialYearCode: String
    ) {
        breadcrumbs(
            tenantId: $tenantId
            teamCode: $teamCode
            financialYearCode: $financialYearCode
        ) {
            id
            name
            code
            division {
                id
                primaryColourHex
            }
            leaderMission {
                id
                title
                owner
                team {
                    id
                    code
                }
                leaderOfTeams {
                    id
                    name
                    code
                    division {
                        id
                        primaryColourHex
                    }
                }
            }
            missions {
                id
                title
                owner
                sequence
                leaderOfTeams {
                    id
                    name
                    code
                    division {
                        id
                        primaryColourHex
                    }
                    missions {
                        id
                        title
                        owner
                        sequence
                    }
                    dottedMissions {
                        id
                        sequence
                        mission {
                            id
                            title
                            owner
                        }
                    }
                    contributors {
                        id
                        userDisplayName
                        userActive
                    }
                }
            }
            dottedMissions {
                id
                sequence
                mission {
                    id
                    title
                    owner
                }
            }
            contributors {
                id
                userDisplayName
                userActive
            }
        }
    }
`;

/**
 * __useGetBreadcrumbsQuery__
 *
 * To run a query within a React component, call `useGetBreadcrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreadcrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreadcrumbsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      teamCode: // value for 'teamCode'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetBreadcrumbsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetBreadcrumbsQuery,
        GetBreadcrumbsQueryVariables
    > &
        (
            | { variables: GetBreadcrumbsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetBreadcrumbsQuery,
        GetBreadcrumbsQueryVariables
    >(GetBreadcrumbsDocument, options);
}
export function useGetBreadcrumbsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetBreadcrumbsQuery,
        GetBreadcrumbsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetBreadcrumbsQuery,
        GetBreadcrumbsQueryVariables
    >(GetBreadcrumbsDocument, options);
}
export function useGetBreadcrumbsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetBreadcrumbsQuery,
              GetBreadcrumbsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetBreadcrumbsQuery,
        GetBreadcrumbsQueryVariables
    >(GetBreadcrumbsDocument, options);
}
export type GetBreadcrumbsQueryHookResult = ReturnType<
    typeof useGetBreadcrumbsQuery
>;
export type GetBreadcrumbsLazyQueryHookResult = ReturnType<
    typeof useGetBreadcrumbsLazyQuery
>;
export type GetBreadcrumbsSuspenseQueryHookResult = ReturnType<
    typeof useGetBreadcrumbsSuspenseQuery
>;
export type GetBreadcrumbsQueryResult = ApolloReactCommon.QueryResult<
    GetBreadcrumbsQuery,
    GetBreadcrumbsQueryVariables
>;
export function refetchGetBreadcrumbsQuery(
    variables: GetBreadcrumbsQueryVariables
) {
    return { query: GetBreadcrumbsDocument, variables: variables };
}
export const GetMissionNavDocument = gql`
    query GetMissionNav($tenantId: ID!, $missionId: ID!) {
        mission: mission(tenantId: $tenantId, id: $missionId) {
            id
            owner
            title
            userId
            rights {
                read
                write
                export
                import
            }
            leaderOfTeams {
                id
                code
                division {
                    id
                    primaryColourHex
                    financialYear {
                        id
                        code
                    }
                }
            }
            team {
                id
                code
                leaderMission {
                    id
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
                division {
                    id
                    primaryColourHex
                    financialYear {
                        id
                        code
                    }
                }
            }
        }
    }
`;

/**
 * __useGetMissionNavQuery__
 *
 * To run a query within a React component, call `useGetMissionNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionNavQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetMissionNavQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionNavQuery,
        GetMissionNavQueryVariables
    > &
        (
            | { variables: GetMissionNavQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMissionNavQuery,
        GetMissionNavQueryVariables
    >(GetMissionNavDocument, options);
}
export function useGetMissionNavLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionNavQuery,
        GetMissionNavQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionNavQuery,
        GetMissionNavQueryVariables
    >(GetMissionNavDocument, options);
}
export function useGetMissionNavSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionNavQuery,
              GetMissionNavQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionNavQuery,
        GetMissionNavQueryVariables
    >(GetMissionNavDocument, options);
}
export type GetMissionNavQueryHookResult = ReturnType<
    typeof useGetMissionNavQuery
>;
export type GetMissionNavLazyQueryHookResult = ReturnType<
    typeof useGetMissionNavLazyQuery
>;
export type GetMissionNavSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionNavSuspenseQuery
>;
export type GetMissionNavQueryResult = ApolloReactCommon.QueryResult<
    GetMissionNavQuery,
    GetMissionNavQueryVariables
>;
export function refetchGetMissionNavQuery(
    variables: GetMissionNavQueryVariables
) {
    return { query: GetMissionNavDocument, variables: variables };
}
export const GetMissionDocument = gql`
    query GetMission($tenantId: ID!, $missionId: ID!) {
        mission: mission(tenantId: $tenantId, id: $missionId) {
            id
            userId
            username
            title
            owner
            missionStatement
            missionDate
            customMissionStatement1Up
            customMissionStatement2Up
            utcCreated
            utcInactive
            sequence
            missionGroupId
            version
            rights {
                read
                write
                export
                import
            }
            leaderOfTeams {
                id
                name
                code
                division {
                    id
                    vision
                    purpose
                    values
                    primaryColourHex
                }
                version
            }
            behaviours {
                id
                text
                sequence
                version
            }
            freedoms {
                id
                text
                sequence
                version
            }
            constraints {
                id
                text
                sequence
                version
            }
            dottedTeams {
                id
                teamId
                sequence
                missionGroupId
            }
            team {
                id
                code
                leaderMission {
                    id
                    title
                    userId
                    owner
                    missionStatement
                    team {
                        id
                        code
                        leaderMission {
                            id
                            title
                            owner
                            missionStatement
                        }
                        division {
                            id
                        }
                    }
                }
                division {
                    id
                    vision
                    purpose
                    values
                    primaryColourHex
                    financialYear {
                        id
                        code
                        startDate
                        endDate
                    }
                }
            }
        }
        measureGroups: measureGroups(
            tenantId: $tenantId
            missionId: $missionId
        ) {
            id
            name
            sequence
            version
        }
        measureSummary: measures(tenantId: $tenantId, missionId: $missionId) {
            id
            measureGroupId
            isCustom
            name
            sequence
            lastAsOf {
                id
                arrowColour
                arrowDirection
            }
        }
        tasksSummary: tasks(tenantId: $tenantId, missionId: $missionId) {
            id
            parentTaskId
            sequence
            name
            start
            due
            done
            utcCancelled
            utcPostponed
            utcAtRisk
            percentComplete
            isDuplicate
        }
    }
`;

/**
 * __useGetMissionQuery__
 *
 * To run a query within a React component, call `useGetMissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetMissionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionQuery,
        GetMissionQueryVariables
    > &
        (
            | { variables: GetMissionQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetMissionQuery, GetMissionQueryVariables>(
        GetMissionDocument,
        options
    );
}
export function useGetMissionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionQuery,
        GetMissionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionQuery,
        GetMissionQueryVariables
    >(GetMissionDocument, options);
}
export function useGetMissionSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionQuery,
              GetMissionQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionQuery,
        GetMissionQueryVariables
    >(GetMissionDocument, options);
}
export type GetMissionQueryHookResult = ReturnType<typeof useGetMissionQuery>;
export type GetMissionLazyQueryHookResult = ReturnType<
    typeof useGetMissionLazyQuery
>;
export type GetMissionSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionSuspenseQuery
>;
export type GetMissionQueryResult = ApolloReactCommon.QueryResult<
    GetMissionQuery,
    GetMissionQueryVariables
>;
export function refetchGetMissionQuery(variables: GetMissionQueryVariables) {
    return { query: GetMissionDocument, variables: variables };
}
export const GetTasksAndResourcesDocument = gql`
    query GetTasksAndResources($tenantId: ID!, $missionId: ID!) {
        tasks(tenantId: $tenantId, missionId: $missionId) {
            id
            parentTaskId
            sequence
            name
            isDuplicate
            resourcedTasks {
                id
                resource {
                    id
                    userId
                    displayName
                    utcDeleted
                }
            }
        }
    }
`;

/**
 * __useGetTasksAndResourcesQuery__
 *
 * To run a query within a React component, call `useGetTasksAndResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksAndResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksAndResourcesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetTasksAndResourcesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTasksAndResourcesQuery,
        GetTasksAndResourcesQueryVariables
    > &
        (
            | { variables: GetTasksAndResourcesQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTasksAndResourcesQuery,
        GetTasksAndResourcesQueryVariables
    >(GetTasksAndResourcesDocument, options);
}
export function useGetTasksAndResourcesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTasksAndResourcesQuery,
        GetTasksAndResourcesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTasksAndResourcesQuery,
        GetTasksAndResourcesQueryVariables
    >(GetTasksAndResourcesDocument, options);
}
export function useGetTasksAndResourcesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTasksAndResourcesQuery,
              GetTasksAndResourcesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTasksAndResourcesQuery,
        GetTasksAndResourcesQueryVariables
    >(GetTasksAndResourcesDocument, options);
}
export type GetTasksAndResourcesQueryHookResult = ReturnType<
    typeof useGetTasksAndResourcesQuery
>;
export type GetTasksAndResourcesLazyQueryHookResult = ReturnType<
    typeof useGetTasksAndResourcesLazyQuery
>;
export type GetTasksAndResourcesSuspenseQueryHookResult = ReturnType<
    typeof useGetTasksAndResourcesSuspenseQuery
>;
export type GetTasksAndResourcesQueryResult = ApolloReactCommon.QueryResult<
    GetTasksAndResourcesQuery,
    GetTasksAndResourcesQueryVariables
>;
export function refetchGetTasksAndResourcesQuery(
    variables: GetTasksAndResourcesQueryVariables
) {
    return { query: GetTasksAndResourcesDocument, variables: variables };
}
export const GetMeasureGroupsDocument = gql`
    query GetMeasureGroups($tenantId: ID!, $missionId: ID!) {
        measureGroups: measureGroups(
            tenantId: $tenantId
            missionId: $missionId
        ) {
            id
            name
            sequence
            version
        }
    }
`;

/**
 * __useGetMeasureGroupsQuery__
 *
 * To run a query within a React component, call `useGetMeasureGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureGroupsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetMeasureGroupsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureGroupsQuery,
        GetMeasureGroupsQueryVariables
    > &
        (
            | { variables: GetMeasureGroupsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasureGroupsQuery,
        GetMeasureGroupsQueryVariables
    >(GetMeasureGroupsDocument, options);
}
export function useGetMeasureGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureGroupsQuery,
        GetMeasureGroupsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureGroupsQuery,
        GetMeasureGroupsQueryVariables
    >(GetMeasureGroupsDocument, options);
}
export function useGetMeasureGroupsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureGroupsQuery,
              GetMeasureGroupsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureGroupsQuery,
        GetMeasureGroupsQueryVariables
    >(GetMeasureGroupsDocument, options);
}
export type GetMeasureGroupsQueryHookResult = ReturnType<
    typeof useGetMeasureGroupsQuery
>;
export type GetMeasureGroupsLazyQueryHookResult = ReturnType<
    typeof useGetMeasureGroupsLazyQuery
>;
export type GetMeasureGroupsSuspenseQueryHookResult = ReturnType<
    typeof useGetMeasureGroupsSuspenseQuery
>;
export type GetMeasureGroupsQueryResult = ApolloReactCommon.QueryResult<
    GetMeasureGroupsQuery,
    GetMeasureGroupsQueryVariables
>;
export function refetchGetMeasureGroupsQuery(
    variables: GetMeasureGroupsQueryVariables
) {
    return { query: GetMeasureGroupsDocument, variables: variables };
}
export const GetMeasureValueFormulaDocument = gql`
    query GetMeasureValueFormula($tenantId: ID!, $measureId: ID!) {
        measureValueFormula: measureValueFormula(
            tenantId: $tenantId
            measureId: $measureId
        ) {
            formula
            formulaPP
            measures {
                id
                name
                mission {
                    id
                    title
                    owner
                    userId
                }
                measureType
                frequencyPeriod
                valueHistory {
                    asOfDate
                    values {
                        decimalValue
                        seriesType {
                            name
                        }
                    }
                }
            }
            variables {
                name
                value {
                    argType
                    decimalValue
                    measureId
                    variableName
                    functionName
                }
            }
        }
    }
`;

/**
 * __useGetMeasureValueFormulaQuery__
 *
 * To run a query within a React component, call `useGetMeasureValueFormulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureValueFormulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureValueFormulaQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *   },
 * });
 */
export function useGetMeasureValueFormulaQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureValueFormulaQuery,
        GetMeasureValueFormulaQueryVariables
    > &
        (
            | {
                  variables: GetMeasureValueFormulaQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasureValueFormulaQuery,
        GetMeasureValueFormulaQueryVariables
    >(GetMeasureValueFormulaDocument, options);
}
export function useGetMeasureValueFormulaLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureValueFormulaQuery,
        GetMeasureValueFormulaQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureValueFormulaQuery,
        GetMeasureValueFormulaQueryVariables
    >(GetMeasureValueFormulaDocument, options);
}
export function useGetMeasureValueFormulaSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureValueFormulaQuery,
              GetMeasureValueFormulaQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureValueFormulaQuery,
        GetMeasureValueFormulaQueryVariables
    >(GetMeasureValueFormulaDocument, options);
}
export type GetMeasureValueFormulaQueryHookResult = ReturnType<
    typeof useGetMeasureValueFormulaQuery
>;
export type GetMeasureValueFormulaLazyQueryHookResult = ReturnType<
    typeof useGetMeasureValueFormulaLazyQuery
>;
export type GetMeasureValueFormulaSuspenseQueryHookResult = ReturnType<
    typeof useGetMeasureValueFormulaSuspenseQuery
>;
export type GetMeasureValueFormulaQueryResult = ApolloReactCommon.QueryResult<
    GetMeasureValueFormulaQuery,
    GetMeasureValueFormulaQueryVariables
>;
export function refetchGetMeasureValueFormulaQuery(
    variables: GetMeasureValueFormulaQueryVariables
) {
    return { query: GetMeasureValueFormulaDocument, variables: variables };
}
export const GetMeasureValueFormulaEvaluationDocument = gql`
    query GetMeasureValueFormulaEvaluation($tenantId: ID!, $measureId: ID!) {
        measureValueFormulaEvaluation(
            tenantId: $tenantId
            measureId: $measureId
        ) {
            formula
            stepLogs {
                seriesName
                asOfDate
                steps {
                    name
                    indent
                    decimalValue
                    formatNumber
                }
            }
        }
    }
`;

/**
 * __useGetMeasureValueFormulaEvaluationQuery__
 *
 * To run a query within a React component, call `useGetMeasureValueFormulaEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureValueFormulaEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureValueFormulaEvaluationQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *   },
 * });
 */
export function useGetMeasureValueFormulaEvaluationQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureValueFormulaEvaluationQuery,
        GetMeasureValueFormulaEvaluationQueryVariables
    > &
        (
            | {
                  variables: GetMeasureValueFormulaEvaluationQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasureValueFormulaEvaluationQuery,
        GetMeasureValueFormulaEvaluationQueryVariables
    >(GetMeasureValueFormulaEvaluationDocument, options);
}
export function useGetMeasureValueFormulaEvaluationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureValueFormulaEvaluationQuery,
        GetMeasureValueFormulaEvaluationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureValueFormulaEvaluationQuery,
        GetMeasureValueFormulaEvaluationQueryVariables
    >(GetMeasureValueFormulaEvaluationDocument, options);
}
export function useGetMeasureValueFormulaEvaluationSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureValueFormulaEvaluationQuery,
              GetMeasureValueFormulaEvaluationQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureValueFormulaEvaluationQuery,
        GetMeasureValueFormulaEvaluationQueryVariables
    >(GetMeasureValueFormulaEvaluationDocument, options);
}
export type GetMeasureValueFormulaEvaluationQueryHookResult = ReturnType<
    typeof useGetMeasureValueFormulaEvaluationQuery
>;
export type GetMeasureValueFormulaEvaluationLazyQueryHookResult = ReturnType<
    typeof useGetMeasureValueFormulaEvaluationLazyQuery
>;
export type GetMeasureValueFormulaEvaluationSuspenseQueryHookResult =
    ReturnType<typeof useGetMeasureValueFormulaEvaluationSuspenseQuery>;
export type GetMeasureValueFormulaEvaluationQueryResult =
    ApolloReactCommon.QueryResult<
        GetMeasureValueFormulaEvaluationQuery,
        GetMeasureValueFormulaEvaluationQueryVariables
    >;
export function refetchGetMeasureValueFormulaEvaluationQuery(
    variables: GetMeasureValueFormulaEvaluationQueryVariables
) {
    return {
        query: GetMeasureValueFormulaEvaluationDocument,
        variables: variables,
    };
}
export const GetMissionSearchDocument = gql`
    query GetMissionSearch(
        $tenantId: ID!
        $divisionId: ID
        $searchText: String
        $isWritable: Boolean!
        $isImportable: Boolean!
        $includeInactive: Boolean
        $includeDeleted: Boolean
        $financialYearCode: String
    ) {
        missions: mission_search(
            tenantId: $tenantId
            divisionId: $divisionId
            searchText: $searchText
            isWritable: $isWritable
            isImportable: $isImportable
            includeInactive: $includeInactive
            includeDeleted: $includeDeleted
            financialYearCode: $financialYearCode
        ) {
            id
            title
            owner
            userId
            username
            utcInactive
            leaderOfTeams {
                id
                code
                name
            }
            team {
                id
                code
                name
                division {
                    id
                    code
                    financialYear {
                        code
                    }
                }
            }
        }
    }
`;

/**
 * __useGetMissionSearchQuery__
 *
 * To run a query within a React component, call `useGetMissionSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionSearchQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      divisionId: // value for 'divisionId'
 *      searchText: // value for 'searchText'
 *      isWritable: // value for 'isWritable'
 *      isImportable: // value for 'isImportable'
 *      includeInactive: // value for 'includeInactive'
 *      includeDeleted: // value for 'includeDeleted'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetMissionSearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionSearchQuery,
        GetMissionSearchQueryVariables
    > &
        (
            | { variables: GetMissionSearchQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMissionSearchQuery,
        GetMissionSearchQueryVariables
    >(GetMissionSearchDocument, options);
}
export function useGetMissionSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionSearchQuery,
        GetMissionSearchQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionSearchQuery,
        GetMissionSearchQueryVariables
    >(GetMissionSearchDocument, options);
}
export function useGetMissionSearchSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionSearchQuery,
              GetMissionSearchQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionSearchQuery,
        GetMissionSearchQueryVariables
    >(GetMissionSearchDocument, options);
}
export type GetMissionSearchQueryHookResult = ReturnType<
    typeof useGetMissionSearchQuery
>;
export type GetMissionSearchLazyQueryHookResult = ReturnType<
    typeof useGetMissionSearchLazyQuery
>;
export type GetMissionSearchSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionSearchSuspenseQuery
>;
export type GetMissionSearchQueryResult = ApolloReactCommon.QueryResult<
    GetMissionSearchQuery,
    GetMissionSearchQueryVariables
>;
export function refetchGetMissionSearchQuery(
    variables: GetMissionSearchQueryVariables
) {
    return { query: GetMissionSearchDocument, variables: variables };
}
export const GetUserMissionSearchDocument = gql`
    query GetUserMissionSearch(
        $tenantId: ID!
        $userId: ID!
        $financialYearCode: String!
    ) {
        missions: mission_search(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            userId: $userId
        ) {
            id
        }
    }
`;

/**
 * __useGetUserMissionSearchQuery__
 *
 * To run a query within a React component, call `useGetUserMissionSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMissionSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMissionSearchQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetUserMissionSearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetUserMissionSearchQuery,
        GetUserMissionSearchQueryVariables
    > &
        (
            | { variables: GetUserMissionSearchQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetUserMissionSearchQuery,
        GetUserMissionSearchQueryVariables
    >(GetUserMissionSearchDocument, options);
}
export function useGetUserMissionSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetUserMissionSearchQuery,
        GetUserMissionSearchQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetUserMissionSearchQuery,
        GetUserMissionSearchQueryVariables
    >(GetUserMissionSearchDocument, options);
}
export function useGetUserMissionSearchSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetUserMissionSearchQuery,
              GetUserMissionSearchQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetUserMissionSearchQuery,
        GetUserMissionSearchQueryVariables
    >(GetUserMissionSearchDocument, options);
}
export type GetUserMissionSearchQueryHookResult = ReturnType<
    typeof useGetUserMissionSearchQuery
>;
export type GetUserMissionSearchLazyQueryHookResult = ReturnType<
    typeof useGetUserMissionSearchLazyQuery
>;
export type GetUserMissionSearchSuspenseQueryHookResult = ReturnType<
    typeof useGetUserMissionSearchSuspenseQuery
>;
export type GetUserMissionSearchQueryResult = ApolloReactCommon.QueryResult<
    GetUserMissionSearchQuery,
    GetUserMissionSearchQueryVariables
>;
export function refetchGetUserMissionSearchQuery(
    variables: GetUserMissionSearchQueryVariables
) {
    return { query: GetUserMissionSearchDocument, variables: variables };
}
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
        current_user {
            id
            displayName
            emailAddress
            userTenants {
                tenantId
                isDefault
                tenant {
                    id
                    code
                    description
                    isEnabled
                }
            }
            userRoles {
                roleId
                tenantId
                name
            }
            isNewUser
            utcSelfHelpEnabledUntil
            version
        }
    }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >(GetCurrentUserDocument, options);
}
export function useGetCurrentUserSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetCurrentUserQuery,
              GetCurrentUserQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
    typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
    typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
>;
export function refetchGetCurrentUserQuery(
    variables?: GetCurrentUserQueryVariables
) {
    return { query: GetCurrentUserDocument, variables: variables };
}
export const GetCurrentUserLastLoginDocument = gql`
    query GetCurrentUserLastLogin {
        current_user {
            lastLogin {
                dateTime
                ipAddress
            }
        }
    }
`;

/**
 * __useGetCurrentUserLastLoginQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserLastLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserLastLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserLastLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserLastLoginQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetCurrentUserLastLoginQuery,
        GetCurrentUserLastLoginQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetCurrentUserLastLoginQuery,
        GetCurrentUserLastLoginQueryVariables
    >(GetCurrentUserLastLoginDocument, options);
}
export function useGetCurrentUserLastLoginLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCurrentUserLastLoginQuery,
        GetCurrentUserLastLoginQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetCurrentUserLastLoginQuery,
        GetCurrentUserLastLoginQueryVariables
    >(GetCurrentUserLastLoginDocument, options);
}
export function useGetCurrentUserLastLoginSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetCurrentUserLastLoginQuery,
              GetCurrentUserLastLoginQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetCurrentUserLastLoginQuery,
        GetCurrentUserLastLoginQueryVariables
    >(GetCurrentUserLastLoginDocument, options);
}
export type GetCurrentUserLastLoginQueryHookResult = ReturnType<
    typeof useGetCurrentUserLastLoginQuery
>;
export type GetCurrentUserLastLoginLazyQueryHookResult = ReturnType<
    typeof useGetCurrentUserLastLoginLazyQuery
>;
export type GetCurrentUserLastLoginSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserLastLoginSuspenseQuery
>;
export type GetCurrentUserLastLoginQueryResult = ApolloReactCommon.QueryResult<
    GetCurrentUserLastLoginQuery,
    GetCurrentUserLastLoginQueryVariables
>;
export function refetchGetCurrentUserLastLoginQuery(
    variables?: GetCurrentUserLastLoginQueryVariables
) {
    return { query: GetCurrentUserLastLoginDocument, variables: variables };
}
export const GetCurrentUserLoginHistoryDocument = gql`
    query GetCurrentUserLoginHistory {
        current_user {
            lastLogin {
                dateTime
                ipAddress
            }
            loginHistory {
                dateTime
                ipAddress
                userAgent
            }
        }
    }
`;

/**
 * __useGetCurrentUserLoginHistoryQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserLoginHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserLoginHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserLoginHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserLoginHistoryQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetCurrentUserLoginHistoryQuery,
        GetCurrentUserLoginHistoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetCurrentUserLoginHistoryQuery,
        GetCurrentUserLoginHistoryQueryVariables
    >(GetCurrentUserLoginHistoryDocument, options);
}
export function useGetCurrentUserLoginHistoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCurrentUserLoginHistoryQuery,
        GetCurrentUserLoginHistoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetCurrentUserLoginHistoryQuery,
        GetCurrentUserLoginHistoryQueryVariables
    >(GetCurrentUserLoginHistoryDocument, options);
}
export function useGetCurrentUserLoginHistorySuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetCurrentUserLoginHistoryQuery,
              GetCurrentUserLoginHistoryQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetCurrentUserLoginHistoryQuery,
        GetCurrentUserLoginHistoryQueryVariables
    >(GetCurrentUserLoginHistoryDocument, options);
}
export type GetCurrentUserLoginHistoryQueryHookResult = ReturnType<
    typeof useGetCurrentUserLoginHistoryQuery
>;
export type GetCurrentUserLoginHistoryLazyQueryHookResult = ReturnType<
    typeof useGetCurrentUserLoginHistoryLazyQuery
>;
export type GetCurrentUserLoginHistorySuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserLoginHistorySuspenseQuery
>;
export type GetCurrentUserLoginHistoryQueryResult =
    ApolloReactCommon.QueryResult<
        GetCurrentUserLoginHistoryQuery,
        GetCurrentUserLoginHistoryQueryVariables
    >;
export function refetchGetCurrentUserLoginHistoryQuery(
    variables?: GetCurrentUserLoginHistoryQueryVariables
) {
    return { query: GetCurrentUserLoginHistoryDocument, variables: variables };
}
export const GetMissionGroupsDocument = gql`
    query GetMissionGroups($tenantId: ID!, $teamId: ID!) {
        missionGroups: missionGroups(tenantId: $tenantId, teamId: $teamId) {
            id
            name
            sequence
            version
        }
    }
`;

/**
 * __useGetMissionGroupsQuery__
 *
 * To run a query within a React component, call `useGetMissionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionGroupsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetMissionGroupsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionGroupsQuery,
        GetMissionGroupsQueryVariables
    > &
        (
            | { variables: GetMissionGroupsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMissionGroupsQuery,
        GetMissionGroupsQueryVariables
    >(GetMissionGroupsDocument, options);
}
export function useGetMissionGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionGroupsQuery,
        GetMissionGroupsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionGroupsQuery,
        GetMissionGroupsQueryVariables
    >(GetMissionGroupsDocument, options);
}
export function useGetMissionGroupsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionGroupsQuery,
              GetMissionGroupsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionGroupsQuery,
        GetMissionGroupsQueryVariables
    >(GetMissionGroupsDocument, options);
}
export type GetMissionGroupsQueryHookResult = ReturnType<
    typeof useGetMissionGroupsQuery
>;
export type GetMissionGroupsLazyQueryHookResult = ReturnType<
    typeof useGetMissionGroupsLazyQuery
>;
export type GetMissionGroupsSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionGroupsSuspenseQuery
>;
export type GetMissionGroupsQueryResult = ApolloReactCommon.QueryResult<
    GetMissionGroupsQuery,
    GetMissionGroupsQueryVariables
>;
export function refetchGetMissionGroupsQuery(
    variables: GetMissionGroupsQueryVariables
) {
    return { query: GetMissionGroupsDocument, variables: variables };
}
export const GetMeasureValueHistoryDocument = gql`
    query GetMeasureValueHistory(
        $tenantId: ID!
        $id: ID!
        $historySkip: Int
        $historyTake: Int
        $historyHasActual: Boolean
    ) {
        measure(tenantId: $tenantId, id: $id) {
            id
            valueHistory(
                skip: $historySkip
                take: $historyTake
                hasActual: $historyHasActual
            ) {
                id
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                version
                values {
                    id
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    version
                    calcId
                    seriesType {
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __useGetMeasureValueHistoryQuery__
 *
 * To run a query within a React component, call `useGetMeasureValueHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureValueHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureValueHistoryQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *      historySkip: // value for 'historySkip'
 *      historyTake: // value for 'historyTake'
 *      historyHasActual: // value for 'historyHasActual'
 *   },
 * });
 */
export function useGetMeasureValueHistoryQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureValueHistoryQuery,
        GetMeasureValueHistoryQueryVariables
    > &
        (
            | {
                  variables: GetMeasureValueHistoryQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasureValueHistoryQuery,
        GetMeasureValueHistoryQueryVariables
    >(GetMeasureValueHistoryDocument, options);
}
export function useGetMeasureValueHistoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureValueHistoryQuery,
        GetMeasureValueHistoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureValueHistoryQuery,
        GetMeasureValueHistoryQueryVariables
    >(GetMeasureValueHistoryDocument, options);
}
export function useGetMeasureValueHistorySuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureValueHistoryQuery,
              GetMeasureValueHistoryQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureValueHistoryQuery,
        GetMeasureValueHistoryQueryVariables
    >(GetMeasureValueHistoryDocument, options);
}
export type GetMeasureValueHistoryQueryHookResult = ReturnType<
    typeof useGetMeasureValueHistoryQuery
>;
export type GetMeasureValueHistoryLazyQueryHookResult = ReturnType<
    typeof useGetMeasureValueHistoryLazyQuery
>;
export type GetMeasureValueHistorySuspenseQueryHookResult = ReturnType<
    typeof useGetMeasureValueHistorySuspenseQuery
>;
export type GetMeasureValueHistoryQueryResult = ApolloReactCommon.QueryResult<
    GetMeasureValueHistoryQuery,
    GetMeasureValueHistoryQueryVariables
>;
export function refetchGetMeasureValueHistoryQuery(
    variables: GetMeasureValueHistoryQueryVariables
) {
    return { query: GetMeasureValueHistoryDocument, variables: variables };
}
export const GetMissionMeasuresDocument = gql`
    query GetMissionMeasures($tenantId: ID!, $missionId: ID) {
        measures(tenantId: $tenantId, missionId: $missionId) {
            id
            measureGroupId
            name
            description
            sequence
            measureType
            statusType
            valueType
            valueFormula
            yellowStart
            yellowRange
            greenRange
            chartDisplay
            decimalPlaces
            multiplier
            frequencyPeriod
            isStatusLimited
            isCustom
            showForecast
            isFullYearTarget
            fullYearTarget
            linkedFromMeasureId
            linkedFromMeasure {
                id
                name
                utcDeleted
                mission {
                    id
                    title
                    owner
                    userId
                    utcInactive
                    utcDeleted
                }
                commentCount
                lastComment {
                    id
                    text
                    username
                    userId
                    utcCreated
                }
            }
            isLinked
            currency {
                code
                symbol
                descr
            }
            lastAsOf {
                id
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                values {
                    id
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    seriesType {
                        name
                    }
                }
            }
            commentCount
            lastComment {
                id
                text
                username
                userId
                utcCreated
            }
            attachmentCount
            lastAttachment {
                id
            }
        }
    }
`;

/**
 * __useGetMissionMeasuresQuery__
 *
 * To run a query within a React component, call `useGetMissionMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionMeasuresQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetMissionMeasuresQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionMeasuresQuery,
        GetMissionMeasuresQueryVariables
    > &
        (
            | { variables: GetMissionMeasuresQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMissionMeasuresQuery,
        GetMissionMeasuresQueryVariables
    >(GetMissionMeasuresDocument, options);
}
export function useGetMissionMeasuresLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionMeasuresQuery,
        GetMissionMeasuresQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionMeasuresQuery,
        GetMissionMeasuresQueryVariables
    >(GetMissionMeasuresDocument, options);
}
export function useGetMissionMeasuresSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionMeasuresQuery,
              GetMissionMeasuresQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionMeasuresQuery,
        GetMissionMeasuresQueryVariables
    >(GetMissionMeasuresDocument, options);
}
export type GetMissionMeasuresQueryHookResult = ReturnType<
    typeof useGetMissionMeasuresQuery
>;
export type GetMissionMeasuresLazyQueryHookResult = ReturnType<
    typeof useGetMissionMeasuresLazyQuery
>;
export type GetMissionMeasuresSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionMeasuresSuspenseQuery
>;
export type GetMissionMeasuresQueryResult = ApolloReactCommon.QueryResult<
    GetMissionMeasuresQuery,
    GetMissionMeasuresQueryVariables
>;
export function refetchGetMissionMeasuresQuery(
    variables: GetMissionMeasuresQueryVariables
) {
    return { query: GetMissionMeasuresDocument, variables: variables };
}
export const GetMeasureSearchDocument = gql`
    query GetMeasureSearch(
        $tenantId: ID!
        $financialYearCode: String!
        $missionId: ID
        $searchText: String
    ) {
        measures(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            missionId: $missionId
            searchText: $searchText
        ) {
            id
            name
            description
            isLinked
            linkedFromMeasureId
            sequence
            group {
                id
                name
                sequence
            }
            linkedFromMeasure {
                id
                name
                utcDeleted
                mission {
                    id
                    utcDeleted
                    utcInactive
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
            }
            mission {
                id
                owner
                title
                userId
            }
        }
    }
`;

/**
 * __useGetMeasureSearchQuery__
 *
 * To run a query within a React component, call `useGetMeasureSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureSearchQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *      missionId: // value for 'missionId'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetMeasureSearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureSearchQuery,
        GetMeasureSearchQueryVariables
    > &
        (
            | { variables: GetMeasureSearchQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasureSearchQuery,
        GetMeasureSearchQueryVariables
    >(GetMeasureSearchDocument, options);
}
export function useGetMeasureSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureSearchQuery,
        GetMeasureSearchQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureSearchQuery,
        GetMeasureSearchQueryVariables
    >(GetMeasureSearchDocument, options);
}
export function useGetMeasureSearchSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureSearchQuery,
              GetMeasureSearchQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureSearchQuery,
        GetMeasureSearchQueryVariables
    >(GetMeasureSearchDocument, options);
}
export type GetMeasureSearchQueryHookResult = ReturnType<
    typeof useGetMeasureSearchQuery
>;
export type GetMeasureSearchLazyQueryHookResult = ReturnType<
    typeof useGetMeasureSearchLazyQuery
>;
export type GetMeasureSearchSuspenseQueryHookResult = ReturnType<
    typeof useGetMeasureSearchSuspenseQuery
>;
export type GetMeasureSearchQueryResult = ApolloReactCommon.QueryResult<
    GetMeasureSearchQuery,
    GetMeasureSearchQueryVariables
>;
export function refetchGetMeasureSearchQuery(
    variables: GetMeasureSearchQueryVariables
) {
    return { query: GetMeasureSearchDocument, variables: variables };
}
export const GetMeasureDocument = gql`
    query GetMeasure($tenantId: ID!, $id: ID!) {
        measure(tenantId: $tenantId, id: $id) {
            id
            missionId
            measureGroupId
            name
            description
            measureType
            phaseType
            multiplier
            decimalPlaces
            statusType
            yellowStart
            greenRange
            yellowRange
            isStatusLimited
            sequence
            version
            frequencyNumber
            frequencyPeriod
            linkedFromMeasureId
            isLinked
            fullYearString
            fullYearTarget
            isFullYearTarget
            chartDisplay
            chartType
            showForecast
            showFutureLook
            isCustom
            previousFYMeasureId
            targetType
            valueType
            valueFormula
            valueFormulaData {
                calculatedSeries
            }
            mission {
                id
                userId
                title
                owner
                rights {
                    read
                    write
                    export
                    import
                }
            }
            currency {
                code
                symbol
                descr
            }
            lastAsOf {
                id
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                version
                values {
                    id
                    calcId
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    version
                    seriesType {
                        name
                    }
                }
            }
            commentCount
            lastComment {
                id
                text
                username
                userId
                utcCreated
            }
            attachmentCount
            lastAttachment {
                id
            }
            valueHistory {
                id
                measureId
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                version
                utcCreated
                utcUpdated
                values {
                    id
                    calcId
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    version
                    seriesType {
                        name
                    }
                }
            }
            linkedFromMeasure {
                id
                name
                utcDeleted
                commentCount
                lastComment {
                    id
                    text
                    username
                    userId
                    utcCreated
                }
                mission {
                    id
                    userId
                    title
                    owner
                    utcDeleted
                    utcInactive
                    team {
                        id
                        code
                    }
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
                valueFormulaData {
                    formula
                }
            }
            linkedMeasures {
                id
                isLinked
                mission {
                    id
                    userId
                    title
                    owner
                }
            }
            tags {
                id
                name
                colourHex
                icon
                tagType
                isMeasureTag
                isTaskTag
            }
        }
    }
`;

/**
 * __useGetMeasureQuery__
 *
 * To run a query within a React component, call `useGetMeasureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMeasureQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureQuery,
        GetMeasureQueryVariables
    > &
        (
            | { variables: GetMeasureQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetMeasureQuery, GetMeasureQueryVariables>(
        GetMeasureDocument,
        options
    );
}
export function useGetMeasureLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureQuery,
        GetMeasureQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureQuery,
        GetMeasureQueryVariables
    >(GetMeasureDocument, options);
}
export function useGetMeasureSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureQuery,
              GetMeasureQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureQuery,
        GetMeasureQueryVariables
    >(GetMeasureDocument, options);
}
export type GetMeasureQueryHookResult = ReturnType<typeof useGetMeasureQuery>;
export type GetMeasureLazyQueryHookResult = ReturnType<
    typeof useGetMeasureLazyQuery
>;
export type GetMeasureSuspenseQueryHookResult = ReturnType<
    typeof useGetMeasureSuspenseQuery
>;
export type GetMeasureQueryResult = ApolloReactCommon.QueryResult<
    GetMeasureQuery,
    GetMeasureQueryVariables
>;
export function refetchGetMeasureQuery(variables: GetMeasureQueryVariables) {
    return { query: GetMeasureDocument, variables: variables };
}
export const GetMissionTasksDocument = gql`
    query GetMissionTasks($tenantId: ID!, $missionId: ID) {
        tasks(tenantId: $tenantId, missionId: $missionId) {
            id
            missionId
            parentTaskId
            name
            description
            percentComplete
            start
            due
            done
            review
            resourcedFromTaskId
            resourceId
            utcAccepted
            utcRejected
            rejectedReason
            utcResourceRemoved
            utcChangesPending
            utcCancelled
            utcPostponed
            utcAtRisk
            isPercentageCompleteFromResources
            isPercentageCompleteFromSubTasks
            sequence
            resourceIsPrimary
            effortWeight
            effortResourceWeight
            costWeight
            isDuplicate
            taskCategoryId
            taskCategory {
                id
                name
                colourHex
            }
            linkedMeasures {
                id
                measureId
                taskId
            }
            version
            resourcedTasks {
                id
                parentTaskId
                resourcedFromTaskId
                missionId
                name
                percentComplete
                start
                due
                done
                resourceIsPrimary
                effortWeight
                effortResourceWeight
                costWeight
                utcAccepted
                utcRejected
                utcCancelled
                utcPostponed
                utcAtRisk
                isPercentageCompleteFromResources
                isPercentageCompleteFromSubTasks
                isDuplicate
                rejectedReason
                utcResourceRemoved
                commentCount
                version
                mission {
                    id
                    userId
                    team {
                        id
                        code
                    }
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
                resource {
                    id
                    userId
                    userMissionFYs {
                        id
                        code
                    }
                    userContributorFYs {
                        id
                        code
                    }
                    displayName
                }
                tags {
                    id
                    name
                }
            }
            resource {
                id
                userId
                userMissionFYs {
                    id
                    code
                }
                userContributorFYs {
                    id
                    code
                }
                displayName
                version
            }
            resourcedFromTask {
                id
                name
                start
                due
                done
                percentComplete
                utcCancelled
                utcPostponed
                utcAtRisk
                mission {
                    id
                    userId
                    title
                    owner
                    utcInactive
                    utcDeleted
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
            }
            lastAttachment {
                id
                name
            }
            lastComment {
                id
                userId
                text
                username
                utcCreated
            }
            commentCount
            attachmentCount
            tags {
                id
                name
                colourHex
                icon
                tagType
                isMeasureTag
                isTaskTag
            }
        }
    }
`;

/**
 * __useGetMissionTasksQuery__
 *
 * To run a query within a React component, call `useGetMissionTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionTasksQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetMissionTasksQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionTasksQuery,
        GetMissionTasksQueryVariables
    > &
        (
            | { variables: GetMissionTasksQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMissionTasksQuery,
        GetMissionTasksQueryVariables
    >(GetMissionTasksDocument, options);
}
export function useGetMissionTasksLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionTasksQuery,
        GetMissionTasksQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionTasksQuery,
        GetMissionTasksQueryVariables
    >(GetMissionTasksDocument, options);
}
export function useGetMissionTasksSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionTasksQuery,
              GetMissionTasksQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionTasksQuery,
        GetMissionTasksQueryVariables
    >(GetMissionTasksDocument, options);
}
export type GetMissionTasksQueryHookResult = ReturnType<
    typeof useGetMissionTasksQuery
>;
export type GetMissionTasksLazyQueryHookResult = ReturnType<
    typeof useGetMissionTasksLazyQuery
>;
export type GetMissionTasksSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionTasksSuspenseQuery
>;
export type GetMissionTasksQueryResult = ApolloReactCommon.QueryResult<
    GetMissionTasksQuery,
    GetMissionTasksQueryVariables
>;
export function refetchGetMissionTasksQuery(
    variables: GetMissionTasksQueryVariables
) {
    return { query: GetMissionTasksDocument, variables: variables };
}
export const GetAllMeasuresDocument = gql`
    query GetAllMeasures(
        $tenantId: ID!
        $teamId: ID
        $financialYearCode: String
        $taskCategoryIds: [ID!]
        $financialYearActiveDate: DateTime
        $forDateTime: DateTime
    ) {
        measures(
            tenantId: $tenantId
            teamId: $teamId
            financialYearCode: $financialYearCode
            taskCategoryIds: $taskCategoryIds
            financialYearActiveDate: $financialYearActiveDate
            forDateTime: $forDateTime
        ) {
            id
            name
            isStatusLimited
            sequence
            utcDeleted
            lastAsOf {
                id
                asOfDate
                utcCreated
                utcUpdated
                statusValue
                arrowColour
                arrowDirection
                values {
                    id
                    decimalValue
                    stringValue
                    dateValue
                    formatStr
                    seriesType {
                        name
                    }
                }
            }
            isLinked
            group {
                id
                name
                sequence
            }
            mission {
                id
                owner
                title
                userId
                sequence
                team {
                    id
                    code
                    name
                    division {
                        id
                        name
                    }
                }
                leaderOfTeams {
                    id
                    name
                    code
                }
                rights {
                    read
                    write
                    export
                    import
                }
            }
            lastComment {
                id
                userId
                text
                username
                utcCreated
            }
            linkedFromMeasure {
                id
                name
                utcDeleted
                mission {
                    id
                    userId
                    username
                    owner
                    title
                    utcDeleted
                    utcInactive
                }
            }
        }
    }
`;

/**
 * __useGetAllMeasuresQuery__
 *
 * To run a query within a React component, call `useGetAllMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMeasuresQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      teamId: // value for 'teamId'
 *      financialYearCode: // value for 'financialYearCode'
 *      taskCategoryIds: // value for 'taskCategoryIds'
 *      financialYearActiveDate: // value for 'financialYearActiveDate'
 *      forDateTime: // value for 'forDateTime'
 *   },
 * });
 */
export function useGetAllMeasuresQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetAllMeasuresQuery,
        GetAllMeasuresQueryVariables
    > &
        (
            | { variables: GetAllMeasuresQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetAllMeasuresQuery,
        GetAllMeasuresQueryVariables
    >(GetAllMeasuresDocument, options);
}
export function useGetAllMeasuresLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetAllMeasuresQuery,
        GetAllMeasuresQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetAllMeasuresQuery,
        GetAllMeasuresQueryVariables
    >(GetAllMeasuresDocument, options);
}
export function useGetAllMeasuresSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetAllMeasuresQuery,
              GetAllMeasuresQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetAllMeasuresQuery,
        GetAllMeasuresQueryVariables
    >(GetAllMeasuresDocument, options);
}
export type GetAllMeasuresQueryHookResult = ReturnType<
    typeof useGetAllMeasuresQuery
>;
export type GetAllMeasuresLazyQueryHookResult = ReturnType<
    typeof useGetAllMeasuresLazyQuery
>;
export type GetAllMeasuresSuspenseQueryHookResult = ReturnType<
    typeof useGetAllMeasuresSuspenseQuery
>;
export type GetAllMeasuresQueryResult = ApolloReactCommon.QueryResult<
    GetAllMeasuresQuery,
    GetAllMeasuresQueryVariables
>;
export function refetchGetAllMeasuresQuery(
    variables: GetAllMeasuresQueryVariables
) {
    return { query: GetAllMeasuresDocument, variables: variables };
}
export const GetAllTasksDocument = gql`
    query GetAllTasks(
        $tenantId: ID!
        $teamId: ID
        $financialYearCode: String
        $forDateTime: DateTime
    ) {
        tasks(
            tenantId: $tenantId
            teamId: $teamId
            financialYearCode: $financialYearCode
            forDateTime: $forDateTime
        ) {
            id
            missionId
            parentTaskId
            name
            description
            percentComplete
            start
            due
            done
            review
            resourcedFromTaskId
            resourceId
            utcAccepted
            utcRejected
            rejectedReason
            utcResourceRemoved
            utcChangesPending
            utcCancelled
            utcPostponed
            utcAtRisk
            isPercentageCompleteFromResources
            isPercentageCompleteFromSubTasks
            sequence
            resourceIsPrimary
            effortWeight
            effortResourceWeight
            costWeight
            isDuplicate
            taskCategoryId
            taskCategory {
                id
                name
                colourHex
            }
            linkedMeasures {
                id
                measureId
                taskId
            }
            version
            resourcedTasks {
                id
                parentTaskId
                resourcedFromTaskId
                missionId
                name
                percentComplete
                start
                due
                done
                resourceIsPrimary
                utcAccepted
                utcRejected
                utcCancelled
                utcPostponed
                utcAtRisk
                isPercentageCompleteFromResources
                isPercentageCompleteFromSubTasks
                isDuplicate
                rejectedReason
                utcResourceRemoved
                version
                mission {
                    id
                    userId
                    team {
                        id
                        code
                        name
                    }
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
                resource {
                    id
                    userId
                    userMissionFYs {
                        id
                        code
                    }
                    userContributorFYs {
                        id
                        code
                    }
                    displayName
                }
            }
            resource {
                id
                userId
                userMissionFYs {
                    id
                    code
                }
                userContributorFYs {
                    id
                    code
                }
                displayName
                version
            }
            resourcedFromTask {
                id
                name
                start
                due
                done
                percentComplete
                utcCancelled
                utcPostponed
                utcAtRisk
                mission {
                    id
                    userId
                    title
                    owner
                    utcInactive
                    utcDeleted
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
            }
            lastComment {
                id
                userId
                text
                username
                utcCreated
            }
            mission {
                id
                owner
                title
                userId
                sequence
                team {
                    id
                    code
                    name
                }
                leaderOfTeams {
                    id
                    name
                    code
                }
                rights {
                    read
                    write
                    export
                    import
                }
            }
            tags {
                id
                name
                colourHex
                icon
                tagType
                isMeasureTag
                isTaskTag
            }
        }
    }
`;

/**
 * __useGetAllTasksQuery__
 *
 * To run a query within a React component, call `useGetAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTasksQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      teamId: // value for 'teamId'
 *      financialYearCode: // value for 'financialYearCode'
 *      forDateTime: // value for 'forDateTime'
 *   },
 * });
 */
export function useGetAllTasksQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetAllTasksQuery,
        GetAllTasksQueryVariables
    > &
        (
            | { variables: GetAllTasksQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetAllTasksQuery,
        GetAllTasksQueryVariables
    >(GetAllTasksDocument, options);
}
export function useGetAllTasksLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetAllTasksQuery,
        GetAllTasksQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetAllTasksQuery,
        GetAllTasksQueryVariables
    >(GetAllTasksDocument, options);
}
export function useGetAllTasksSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetAllTasksQuery,
              GetAllTasksQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetAllTasksQuery,
        GetAllTasksQueryVariables
    >(GetAllTasksDocument, options);
}
export type GetAllTasksQueryHookResult = ReturnType<typeof useGetAllTasksQuery>;
export type GetAllTasksLazyQueryHookResult = ReturnType<
    typeof useGetAllTasksLazyQuery
>;
export type GetAllTasksSuspenseQueryHookResult = ReturnType<
    typeof useGetAllTasksSuspenseQuery
>;
export type GetAllTasksQueryResult = ApolloReactCommon.QueryResult<
    GetAllTasksQuery,
    GetAllTasksQueryVariables
>;
export function refetchGetAllTasksQuery(variables: GetAllTasksQueryVariables) {
    return { query: GetAllTasksDocument, variables: variables };
}
export const GetNotificationsCountDocument = gql`
    query GetNotificationsCount($tenantId: ID!, $userId: ID!) {
        notificationsCount(tenantId: $tenantId, userId: $userId)
    }
`;

/**
 * __useGetNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsCountQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNotificationsCountQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetNotificationsCountQuery,
        GetNotificationsCountQueryVariables
    > &
        (
            | { variables: GetNotificationsCountQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetNotificationsCountQuery,
        GetNotificationsCountQueryVariables
    >(GetNotificationsCountDocument, options);
}
export function useGetNotificationsCountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetNotificationsCountQuery,
        GetNotificationsCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetNotificationsCountQuery,
        GetNotificationsCountQueryVariables
    >(GetNotificationsCountDocument, options);
}
export function useGetNotificationsCountSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetNotificationsCountQuery,
              GetNotificationsCountQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetNotificationsCountQuery,
        GetNotificationsCountQueryVariables
    >(GetNotificationsCountDocument, options);
}
export type GetNotificationsCountQueryHookResult = ReturnType<
    typeof useGetNotificationsCountQuery
>;
export type GetNotificationsCountLazyQueryHookResult = ReturnType<
    typeof useGetNotificationsCountLazyQuery
>;
export type GetNotificationsCountSuspenseQueryHookResult = ReturnType<
    typeof useGetNotificationsCountSuspenseQuery
>;
export type GetNotificationsCountQueryResult = ApolloReactCommon.QueryResult<
    GetNotificationsCountQuery,
    GetNotificationsCountQueryVariables
>;
export function refetchGetNotificationsCountQuery(
    variables: GetNotificationsCountQueryVariables
) {
    return { query: GetNotificationsCountDocument, variables: variables };
}
export const GetNotificationsDocument = gql`
    query GetNotifications($tenantId: ID!, $userId: ID!) {
        notifications(tenantId: $tenantId, userId: $userId) {
            id
            notificationEventId
            userId
            utcDismissed
            notificationEvent {
                id
                utcCreated
                notificationType
                userId
                username
                originatorUserId
                originatorUsername
                missionId
                statusReportId
                templateReportId
                measureId
                taskId
                resourceId
                comment {
                    id
                    text
                    utcDeleted
                }
                task {
                    id
                    name
                    utcDeleted
                    resource {
                        id
                        userId
                        userContributorFYs {
                            id
                            code
                        }
                        displayName
                    }
                }
                measure {
                    id
                    name
                    utcDeleted
                    linkedMeasures {
                        id
                        isLinked
                        mission {
                            id
                            userId
                            title
                            owner
                            team {
                                id
                                code
                            }
                        }
                    }
                }
                mission {
                    id
                    userId
                }
                resource {
                    id
                    displayName
                }
            }
        }
    }
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNotificationsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetNotificationsQuery,
        GetNotificationsQueryVariables
    > &
        (
            | { variables: GetNotificationsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetNotificationsQuery,
        GetNotificationsQueryVariables
    >(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetNotificationsQuery,
        GetNotificationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetNotificationsQuery,
        GetNotificationsQueryVariables
    >(GetNotificationsDocument, options);
}
export function useGetNotificationsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetNotificationsQuery,
              GetNotificationsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetNotificationsQuery,
        GetNotificationsQueryVariables
    >(GetNotificationsDocument, options);
}
export type GetNotificationsQueryHookResult = ReturnType<
    typeof useGetNotificationsQuery
>;
export type GetNotificationsLazyQueryHookResult = ReturnType<
    typeof useGetNotificationsLazyQuery
>;
export type GetNotificationsSuspenseQueryHookResult = ReturnType<
    typeof useGetNotificationsSuspenseQuery
>;
export type GetNotificationsQueryResult = ApolloReactCommon.QueryResult<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
>;
export function refetchGetNotificationsQuery(
    variables: GetNotificationsQueryVariables
) {
    return { query: GetNotificationsDocument, variables: variables };
}
export const GetDependenciesDocument = gql`
    query GetDependencies(
        $tenantId: ID!
        $userId: ID!
        $financialYearCode: String
    ) {
        dependencies(
            tenantId: $tenantId
            userId: $userId
            financialYearCode: $financialYearCode
        ) {
            id
            missionId
            name
            start
            due
            done
            utcCancelled
            utcPostponed
            utcAtRisk
            utcCreated
            mission {
                id
                userId
                username
                title
                owner
                rights {
                    read
                    write
                    export
                    import
                }
            }
            parentTask {
                id
                name
                taskCategory {
                    id
                    name
                    colourHex
                }
            }
            resourcedTasks {
                id
                parentTaskId
                missionId
                name
                description
                percentComplete
                start
                due
                done
                utcAccepted
                utcRejected
                utcCancelled
                utcPostponed
                utcAtRisk
                effortWeight
                costWeight
                rejectedReason
                utcCreated
                utcResourceRemoved
                tags {
                    id
                    name
                }
                resourceId
                resource {
                    id
                    userId
                    userMissionFYs {
                        id
                        code
                    }
                    userContributorFYs {
                        id
                        code
                    }
                    displayName
                }
                resourcedTasks {
                    id
                    resource {
                        id
                        userId
                        displayName
                    }
                }
                commentCount
                lastComment {
                    id
                    text
                    username
                    userId
                    utcCreated
                }
            }
        }
    }
`;

/**
 * __useGetDependenciesQuery__
 *
 * To run a query within a React component, call `useGetDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependenciesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetDependenciesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetDependenciesQuery,
        GetDependenciesQueryVariables
    > &
        (
            | { variables: GetDependenciesQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetDependenciesQuery,
        GetDependenciesQueryVariables
    >(GetDependenciesDocument, options);
}
export function useGetDependenciesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetDependenciesQuery,
        GetDependenciesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetDependenciesQuery,
        GetDependenciesQueryVariables
    >(GetDependenciesDocument, options);
}
export function useGetDependenciesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetDependenciesQuery,
              GetDependenciesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetDependenciesQuery,
        GetDependenciesQueryVariables
    >(GetDependenciesDocument, options);
}
export type GetDependenciesQueryHookResult = ReturnType<
    typeof useGetDependenciesQuery
>;
export type GetDependenciesLazyQueryHookResult = ReturnType<
    typeof useGetDependenciesLazyQuery
>;
export type GetDependenciesSuspenseQueryHookResult = ReturnType<
    typeof useGetDependenciesSuspenseQuery
>;
export type GetDependenciesQueryResult = ApolloReactCommon.QueryResult<
    GetDependenciesQuery,
    GetDependenciesQueryVariables
>;
export function refetchGetDependenciesQuery(
    variables: GetDependenciesQueryVariables
) {
    return { query: GetDependenciesDocument, variables: variables };
}
export const GetTaskDocument = gql`
    query GetTask($tenantId: ID!, $id: ID!) {
        task(tenantId: $tenantId, id: $id) {
            id
            missionId
            parentTaskId
            name
            description
            percentComplete
            start
            due
            done
            review
            resourcedFromTaskId
            resourceId
            utcAccepted
            utcRejected
            utcCancelled
            utcPostponed
            utcAtRisk
            rejectedReason
            utcResourceRemoved
            utcChangesPending
            isPercentageCompleteFromResources
            isPercentageCompleteFromSubTasks
            resourceIsPrimary
            effortWeight
            effortResourceWeight
            costWeight
            isDuplicate
            mission {
                id
                userId
                title
                owner
                utcInactive
                utcDeleted
                rights {
                    read
                    write
                    export
                    import
                }
                team {
                    id
                    code
                    division {
                        id
                        financialYear {
                            id
                            code
                            startDate
                            endDate
                        }
                    }
                }
            }
            parentTask {
                id
                parentTaskId
                name
                taskCategory {
                    id
                    name
                    colourHex
                }
            }
            linkedMeasures {
                id
                measureId
                taskId
            }
            sequence
            taskCategoryId
            taskCategory {
                id
                name
                colourHex
            }
            version
            resource {
                id
                userId
                userMissionFYs {
                    id
                    code
                }
                userContributorFYs {
                    id
                    code
                }
                displayName
                version
            }
            subTasks {
                id
                name
                due
                done
                utcCancelled
                utcPostponed
                utcAtRisk
                isDuplicate
                utcResourceRemoved
                utcChangesPending
                sequence
                resourcedFromTask {
                    id
                    mission {
                        id
                        userId
                        title
                        owner
                        utcInactive
                        utcDeleted
                    }
                }
            }
            resourcedTasks {
                id
                parentTaskId
                resourcedFromTaskId
                missionId
                name
                percentComplete
                start
                due
                done
                utcAccepted
                utcRejected
                utcCancelled
                utcPostponed
                utcAtRisk
                isPercentageCompleteFromResources
                isPercentageCompleteFromSubTasks
                rejectedReason
                resourceIsPrimary
                effortResourceWeight
                isDuplicate
                utcResourceRemoved
                utcChangesPending
                taskCategoryId
                mission {
                    id
                    userId
                    team {
                        id
                        code
                    }
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
                parentTask {
                    id
                    parentTask {
                        id
                    }
                }
                resource {
                    id
                    userId
                    userMissionFYs {
                        id
                        code
                    }
                    userContributorFYs {
                        id
                        code
                    }
                    displayName
                    version
                }
                version
            }
            resourcedFromTask {
                id
                name
                start
                due
                done
                percentComplete
                utcCancelled
                utcPostponed
                utcAtRisk
                percentComplete
                utcDeleted
                resourcedTasks {
                    id
                    resource {
                        id
                        userId
                        userMissionFYs {
                            id
                            code
                        }
                        userContributorFYs {
                            id
                            code
                        }
                        displayName
                    }
                }
                parentTask {
                    id
                    name
                    taskCategory {
                        id
                        name
                        colourHex
                    }
                }
                resourcedFromTask {
                    id
                    mission {
                        id
                        userId
                    }
                }
                mission {
                    id
                    userId
                    title
                    owner
                    utcInactive
                    utcDeleted
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
                resource {
                    userId
                    userMissionFYs {
                        id
                        code
                    }
                    userContributorFYs {
                        id
                        code
                    }
                }
            }
            tags {
                id
                name
                colourHex
                icon
                tagType
                isMeasureTag
                isTaskTag
            }
        }
    }
`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTaskQuery,
        GetTaskQueryVariables
    > &
        (
            | { variables: GetTaskQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetTaskQuery, GetTaskQueryVariables>(
        GetTaskDocument,
        options
    );
}
export function useGetTaskLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTaskQuery,
        GetTaskQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(
        GetTaskDocument,
        options
    );
}
export function useGetTaskSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTaskQuery,
              GetTaskQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTaskQuery,
        GetTaskQueryVariables
    >(GetTaskDocument, options);
}
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskSuspenseQueryHookResult = ReturnType<
    typeof useGetTaskSuspenseQuery
>;
export type GetTaskQueryResult = ApolloReactCommon.QueryResult<
    GetTaskQuery,
    GetTaskQueryVariables
>;
export function refetchGetTaskQuery(variables: GetTaskQueryVariables) {
    return { query: GetTaskDocument, variables: variables };
}
export const GetTaskCategoriesDocument = gql`
    query GetTaskCategories(
        $tenantId: ID!
        $divisionId: ID
        $financialYearCode: String
    ) {
        taskCategories(
            tenantId: $tenantId
            divisionId: $divisionId
            financialYearCode: $financialYearCode
        ) {
            id
            name
            colourHex
            taskCount
            divisions {
                id
                name
            }
        }
    }
`;

/**
 * __useGetTaskCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTaskCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCategoriesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      divisionId: // value for 'divisionId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetTaskCategoriesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTaskCategoriesQuery,
        GetTaskCategoriesQueryVariables
    > &
        (
            | { variables: GetTaskCategoriesQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTaskCategoriesQuery,
        GetTaskCategoriesQueryVariables
    >(GetTaskCategoriesDocument, options);
}
export function useGetTaskCategoriesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTaskCategoriesQuery,
        GetTaskCategoriesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTaskCategoriesQuery,
        GetTaskCategoriesQueryVariables
    >(GetTaskCategoriesDocument, options);
}
export function useGetTaskCategoriesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTaskCategoriesQuery,
              GetTaskCategoriesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTaskCategoriesQuery,
        GetTaskCategoriesQueryVariables
    >(GetTaskCategoriesDocument, options);
}
export type GetTaskCategoriesQueryHookResult = ReturnType<
    typeof useGetTaskCategoriesQuery
>;
export type GetTaskCategoriesLazyQueryHookResult = ReturnType<
    typeof useGetTaskCategoriesLazyQuery
>;
export type GetTaskCategoriesSuspenseQueryHookResult = ReturnType<
    typeof useGetTaskCategoriesSuspenseQuery
>;
export type GetTaskCategoriesQueryResult = ApolloReactCommon.QueryResult<
    GetTaskCategoriesQuery,
    GetTaskCategoriesQueryVariables
>;
export function refetchGetTaskCategoriesQuery(
    variables: GetTaskCategoriesQueryVariables
) {
    return { query: GetTaskCategoriesDocument, variables: variables };
}
export const GetTaskCategoryOptionsDocument = gql`
    query GetTaskCategoryOptions($tenantId: ID!, $divisionId: ID) {
        taskCategories(tenantId: $tenantId, divisionId: $divisionId) {
            id
            name
            colourHex
        }
    }
`;

/**
 * __useGetTaskCategoryOptionsQuery__
 *
 * To run a query within a React component, call `useGetTaskCategoryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCategoryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCategoryOptionsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      divisionId: // value for 'divisionId'
 *   },
 * });
 */
export function useGetTaskCategoryOptionsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTaskCategoryOptionsQuery,
        GetTaskCategoryOptionsQueryVariables
    > &
        (
            | {
                  variables: GetTaskCategoryOptionsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTaskCategoryOptionsQuery,
        GetTaskCategoryOptionsQueryVariables
    >(GetTaskCategoryOptionsDocument, options);
}
export function useGetTaskCategoryOptionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTaskCategoryOptionsQuery,
        GetTaskCategoryOptionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTaskCategoryOptionsQuery,
        GetTaskCategoryOptionsQueryVariables
    >(GetTaskCategoryOptionsDocument, options);
}
export function useGetTaskCategoryOptionsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTaskCategoryOptionsQuery,
              GetTaskCategoryOptionsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTaskCategoryOptionsQuery,
        GetTaskCategoryOptionsQueryVariables
    >(GetTaskCategoryOptionsDocument, options);
}
export type GetTaskCategoryOptionsQueryHookResult = ReturnType<
    typeof useGetTaskCategoryOptionsQuery
>;
export type GetTaskCategoryOptionsLazyQueryHookResult = ReturnType<
    typeof useGetTaskCategoryOptionsLazyQuery
>;
export type GetTaskCategoryOptionsSuspenseQueryHookResult = ReturnType<
    typeof useGetTaskCategoryOptionsSuspenseQuery
>;
export type GetTaskCategoryOptionsQueryResult = ApolloReactCommon.QueryResult<
    GetTaskCategoryOptionsQuery,
    GetTaskCategoryOptionsQueryVariables
>;
export function refetchGetTaskCategoryOptionsQuery(
    variables: GetTaskCategoryOptionsQueryVariables
) {
    return { query: GetTaskCategoryOptionsDocument, variables: variables };
}
export const GetTagsDocument = gql`
    query GetTags($tenantId: ID!) {
        tags(tenantId: $tenantId) {
            id
            name
            colourHex
            icon
            tagType
            isMeasureTag
            isTaskTag
        }
    }
`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetTagsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTagsQuery,
        GetTagsQueryVariables
    > &
        (
            | { variables: GetTagsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetTagsQuery, GetTagsQueryVariables>(
        GetTagsDocument,
        options
    );
}
export function useGetTagsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTagsQuery,
        GetTagsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(
        GetTagsDocument,
        options
    );
}
export function useGetTagsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTagsQuery,
              GetTagsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTagsQuery,
        GetTagsQueryVariables
    >(GetTagsDocument, options);
}
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsSuspenseQueryHookResult = ReturnType<
    typeof useGetTagsSuspenseQuery
>;
export type GetTagsQueryResult = ApolloReactCommon.QueryResult<
    GetTagsQuery,
    GetTagsQueryVariables
>;
export function refetchGetTagsQuery(variables: GetTagsQueryVariables) {
    return { query: GetTagsDocument, variables: variables };
}
export const GetMeasureHistoryDocument = gql`
    query GetMeasureHistory($tenantId: ID!, $id: ID!) {
        measureHistory(tenantId: $tenantId, id: $id) {
            name
            isLinked
            linkedFromMeasureId
            isCustom
            frequencyPeriod
            measureType
            decimalPlaces
            multiplier
            currencyCode
            utcUpdated
            updatedByUserId
            updatedByUserName
            sysStartTime
            sysEndTime
        }
    }
`;

/**
 * __useGetMeasureHistoryQuery__
 *
 * To run a query within a React component, call `useGetMeasureHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureHistoryQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMeasureHistoryQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureHistoryQuery,
        GetMeasureHistoryQueryVariables
    > &
        (
            | { variables: GetMeasureHistoryQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasureHistoryQuery,
        GetMeasureHistoryQueryVariables
    >(GetMeasureHistoryDocument, options);
}
export function useGetMeasureHistoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureHistoryQuery,
        GetMeasureHistoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureHistoryQuery,
        GetMeasureHistoryQueryVariables
    >(GetMeasureHistoryDocument, options);
}
export function useGetMeasureHistorySuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureHistoryQuery,
              GetMeasureHistoryQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureHistoryQuery,
        GetMeasureHistoryQueryVariables
    >(GetMeasureHistoryDocument, options);
}
export type GetMeasureHistoryQueryHookResult = ReturnType<
    typeof useGetMeasureHistoryQuery
>;
export type GetMeasureHistoryLazyQueryHookResult = ReturnType<
    typeof useGetMeasureHistoryLazyQuery
>;
export type GetMeasureHistorySuspenseQueryHookResult = ReturnType<
    typeof useGetMeasureHistorySuspenseQuery
>;
export type GetMeasureHistoryQueryResult = ApolloReactCommon.QueryResult<
    GetMeasureHistoryQuery,
    GetMeasureHistoryQueryVariables
>;
export function refetchGetMeasureHistoryQuery(
    variables: GetMeasureHistoryQueryVariables
) {
    return { query: GetMeasureHistoryDocument, variables: variables };
}
export const GetSeriesValueHistoryDocument = gql`
    query GetSeriesValueHistory($tenantId: ID!, $measureId: ID!) {
        seriesValueHistory(tenantId: $tenantId, measureId: $measureId) {
            stringValue
            decimalValue
            dateValue
            seriesType {
                name
            }
            utcUpdated
            utcDeleted
            sysStartTime
            sysEndTime
            updatedByUserId
            updatedByUserName
            asOf {
                id
                asOfDate
                arrowDirection
                arrowColour
                utcDeleted
                sysStartTime
                sysEndTime
            }
        }
    }
`;

/**
 * __useGetSeriesValueHistoryQuery__
 *
 * To run a query within a React component, call `useGetSeriesValueHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesValueHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesValueHistoryQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *   },
 * });
 */
export function useGetSeriesValueHistoryQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetSeriesValueHistoryQuery,
        GetSeriesValueHistoryQueryVariables
    > &
        (
            | { variables: GetSeriesValueHistoryQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetSeriesValueHistoryQuery,
        GetSeriesValueHistoryQueryVariables
    >(GetSeriesValueHistoryDocument, options);
}
export function useGetSeriesValueHistoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetSeriesValueHistoryQuery,
        GetSeriesValueHistoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetSeriesValueHistoryQuery,
        GetSeriesValueHistoryQueryVariables
    >(GetSeriesValueHistoryDocument, options);
}
export function useGetSeriesValueHistorySuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetSeriesValueHistoryQuery,
              GetSeriesValueHistoryQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetSeriesValueHistoryQuery,
        GetSeriesValueHistoryQueryVariables
    >(GetSeriesValueHistoryDocument, options);
}
export type GetSeriesValueHistoryQueryHookResult = ReturnType<
    typeof useGetSeriesValueHistoryQuery
>;
export type GetSeriesValueHistoryLazyQueryHookResult = ReturnType<
    typeof useGetSeriesValueHistoryLazyQuery
>;
export type GetSeriesValueHistorySuspenseQueryHookResult = ReturnType<
    typeof useGetSeriesValueHistorySuspenseQuery
>;
export type GetSeriesValueHistoryQueryResult = ApolloReactCommon.QueryResult<
    GetSeriesValueHistoryQuery,
    GetSeriesValueHistoryQueryVariables
>;
export function refetchGetSeriesValueHistoryQuery(
    variables: GetSeriesValueHistoryQueryVariables
) {
    return { query: GetSeriesValueHistoryDocument, variables: variables };
}
export const GetTaskHistoryDocument = gql`
    query GetTaskHistory($tenantId: ID!, $id: ID!) {
        taskHistory(tenantId: $tenantId, id: $id) {
            missionId
            parentTaskId
            name
            start
            due
            done
            isDuplicate
            resourceIsPrimary
            percentComplete
            utcAccepted
            utcRejected
            utcPostponed
            utcCancelled
            utcAtRisk
            rejectedReason
            utcCreated
            utcUpdated
            updatedByUserId
            updatedByUserName
            sysStartTime
            taskCategory {
                name
            }
        }
    }
`;

/**
 * __useGetTaskHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskHistoryQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskHistoryQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTaskHistoryQuery,
        GetTaskHistoryQueryVariables
    > &
        (
            | { variables: GetTaskHistoryQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTaskHistoryQuery,
        GetTaskHistoryQueryVariables
    >(GetTaskHistoryDocument, options);
}
export function useGetTaskHistoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTaskHistoryQuery,
        GetTaskHistoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTaskHistoryQuery,
        GetTaskHistoryQueryVariables
    >(GetTaskHistoryDocument, options);
}
export function useGetTaskHistorySuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTaskHistoryQuery,
              GetTaskHistoryQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTaskHistoryQuery,
        GetTaskHistoryQueryVariables
    >(GetTaskHistoryDocument, options);
}
export type GetTaskHistoryQueryHookResult = ReturnType<
    typeof useGetTaskHistoryQuery
>;
export type GetTaskHistoryLazyQueryHookResult = ReturnType<
    typeof useGetTaskHistoryLazyQuery
>;
export type GetTaskHistorySuspenseQueryHookResult = ReturnType<
    typeof useGetTaskHistorySuspenseQuery
>;
export type GetTaskHistoryQueryResult = ApolloReactCommon.QueryResult<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
>;
export function refetchGetTaskHistoryQuery(
    variables: GetTaskHistoryQueryVariables
) {
    return { query: GetTaskHistoryDocument, variables: variables };
}
export const GetTeamsDocument = gql`
    query GetTeams(
        $tenantId: ID!
        $divisionId: ID
        $financialYearCode: String
    ) {
        teams(
            tenantId: $tenantId
            divisionId: $divisionId
            financialYearCode: $financialYearCode
        ) {
            id
            code
            name
            version
            division {
                id
                name
                financialYear {
                    id
                    code
                }
            }
            leaderMission {
                id
                owner
                title
                userId
                username
            }
        }
    }
`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      divisionId: // value for 'divisionId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetTeamsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTeamsQuery,
        GetTeamsQueryVariables
    > &
        (
            | { variables: GetTeamsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(
        GetTeamsDocument,
        options
    );
}
export function useGetTeamsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTeamsQuery,
        GetTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(
        GetTeamsDocument,
        options
    );
}
export function useGetTeamsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTeamsQuery,
              GetTeamsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTeamsQuery,
        GetTeamsQueryVariables
    >(GetTeamsDocument, options);
}
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<
    typeof useGetTeamsLazyQuery
>;
export type GetTeamsSuspenseQueryHookResult = ReturnType<
    typeof useGetTeamsSuspenseQuery
>;
export type GetTeamsQueryResult = ApolloReactCommon.QueryResult<
    GetTeamsQuery,
    GetTeamsQueryVariables
>;
export function refetchGetTeamsQuery(variables: GetTeamsQueryVariables) {
    return { query: GetTeamsDocument, variables: variables };
}
export const GetTeamMissionsDocument = gql`
    query GetTeamMissions(
        $tenantId: ID!
        $teamCode: String!
        $financialYearCode: String
    ) {
        teams(
            tenantId: $tenantId
            teamCode: $teamCode
            financialYearCode: $financialYearCode
        ) {
            id
            code
            name
            version
            leaderMission {
                id
                title
                owner
                userId
                missionStatement
                rights {
                    read
                    write
                    export
                    import
                }
            }
            missions {
                id
                title
                owner
                userId
                missionStatement
                sequence
                leaderOfTeams {
                    id
                    name
                    code
                }
                missionGroupId
                rights {
                    read
                    write
                    export
                    import
                }
            }
            dottedMissions {
                id
                sequence
                missionGroupId
                mission {
                    id
                    title
                    owner
                    userId
                    missionStatement
                    leaderOfTeams {
                        id
                        name
                        code
                    }
                    rights {
                        read
                        write
                        export
                        import
                    }
                }
            }
            division {
                id
                financialYear {
                    id
                    code
                    startDate
                }
            }
        }
    }
`;

/**
 * __useGetTeamMissionsQuery__
 *
 * To run a query within a React component, call `useGetTeamMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMissionsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      teamCode: // value for 'teamCode'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetTeamMissionsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTeamMissionsQuery,
        GetTeamMissionsQueryVariables
    > &
        (
            | { variables: GetTeamMissionsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTeamMissionsQuery,
        GetTeamMissionsQueryVariables
    >(GetTeamMissionsDocument, options);
}
export function useGetTeamMissionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTeamMissionsQuery,
        GetTeamMissionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTeamMissionsQuery,
        GetTeamMissionsQueryVariables
    >(GetTeamMissionsDocument, options);
}
export function useGetTeamMissionsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTeamMissionsQuery,
              GetTeamMissionsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTeamMissionsQuery,
        GetTeamMissionsQueryVariables
    >(GetTeamMissionsDocument, options);
}
export type GetTeamMissionsQueryHookResult = ReturnType<
    typeof useGetTeamMissionsQuery
>;
export type GetTeamMissionsLazyQueryHookResult = ReturnType<
    typeof useGetTeamMissionsLazyQuery
>;
export type GetTeamMissionsSuspenseQueryHookResult = ReturnType<
    typeof useGetTeamMissionsSuspenseQuery
>;
export type GetTeamMissionsQueryResult = ApolloReactCommon.QueryResult<
    GetTeamMissionsQuery,
    GetTeamMissionsQueryVariables
>;
export function refetchGetTeamMissionsQuery(
    variables: GetTeamMissionsQueryVariables
) {
    return { query: GetTeamMissionsDocument, variables: variables };
}
export const GetTeamUsersDocument = gql`
    query GetTeamUsers(
        $tenantId: ID!
        $teamCode: String!
        $financialYearCode: String
    ) {
        teams(
            tenantId: $tenantId
            teamCode: $teamCode
            financialYearCode: $financialYearCode
        ) {
            leaderMission {
                id
                userId
                username
            }
            missions {
                id
                userId
                username
            }
        }
    }
`;

/**
 * __useGetTeamUsersQuery__
 *
 * To run a query within a React component, call `useGetTeamUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamUsersQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      teamCode: // value for 'teamCode'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetTeamUsersQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTeamUsersQuery,
        GetTeamUsersQueryVariables
    > &
        (
            | { variables: GetTeamUsersQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTeamUsersQuery,
        GetTeamUsersQueryVariables
    >(GetTeamUsersDocument, options);
}
export function useGetTeamUsersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTeamUsersQuery,
        GetTeamUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTeamUsersQuery,
        GetTeamUsersQueryVariables
    >(GetTeamUsersDocument, options);
}
export function useGetTeamUsersSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTeamUsersQuery,
              GetTeamUsersQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTeamUsersQuery,
        GetTeamUsersQueryVariables
    >(GetTeamUsersDocument, options);
}
export type GetTeamUsersQueryHookResult = ReturnType<
    typeof useGetTeamUsersQuery
>;
export type GetTeamUsersLazyQueryHookResult = ReturnType<
    typeof useGetTeamUsersLazyQuery
>;
export type GetTeamUsersSuspenseQueryHookResult = ReturnType<
    typeof useGetTeamUsersSuspenseQuery
>;
export type GetTeamUsersQueryResult = ApolloReactCommon.QueryResult<
    GetTeamUsersQuery,
    GetTeamUsersQueryVariables
>;
export function refetchGetTeamUsersQuery(
    variables: GetTeamUsersQueryVariables
) {
    return { query: GetTeamUsersDocument, variables: variables };
}
export const GetUsersSearchDocument = gql`
    query GetUsersSearch(
        $tenantId: ID
        $searchText: String!
        $useCache: Boolean!
    ) {
        userSearch(
            tenantId: $tenantId
            search: $searchText
            useCache: $useCache
        ) {
            id
            displayName
            missionFYs {
                id
                code
            }
        }
    }
`;

/**
 * __useGetUsersSearchQuery__
 *
 * To run a query within a React component, call `useGetUsersSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersSearchQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      searchText: // value for 'searchText'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useGetUsersSearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetUsersSearchQuery,
        GetUsersSearchQueryVariables
    > &
        (
            | { variables: GetUsersSearchQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetUsersSearchQuery,
        GetUsersSearchQueryVariables
    >(GetUsersSearchDocument, options);
}
export function useGetUsersSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetUsersSearchQuery,
        GetUsersSearchQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetUsersSearchQuery,
        GetUsersSearchQueryVariables
    >(GetUsersSearchDocument, options);
}
export function useGetUsersSearchSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetUsersSearchQuery,
              GetUsersSearchQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetUsersSearchQuery,
        GetUsersSearchQueryVariables
    >(GetUsersSearchDocument, options);
}
export type GetUsersSearchQueryHookResult = ReturnType<
    typeof useGetUsersSearchQuery
>;
export type GetUsersSearchLazyQueryHookResult = ReturnType<
    typeof useGetUsersSearchLazyQuery
>;
export type GetUsersSearchSuspenseQueryHookResult = ReturnType<
    typeof useGetUsersSearchSuspenseQuery
>;
export type GetUsersSearchQueryResult = ApolloReactCommon.QueryResult<
    GetUsersSearchQuery,
    GetUsersSearchQueryVariables
>;
export function refetchGetUsersSearchQuery(
    variables: GetUsersSearchQueryVariables
) {
    return { query: GetUsersSearchDocument, variables: variables };
}
export const GetUsersDocument = gql`
    query GetUsers($tenantId: ID, $searchText: String, $useCache: Boolean!) {
        userSearch(
            tenantId: $tenantId
            search: $searchText
            useCache: $useCache
        ) {
            id
            displayName
            emailAddress
            accessEnabled
            utcSelfHelpEnabledUntil
            userTenants {
                id
                userId
                tenantId
                isDefault
            }
            userRoles {
                id
                userId
                roleId
                tenantId
                name
            }
            utcUpdated
            version
        }
    }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      searchText: // value for 'searchText'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useGetUsersQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetUsersQuery,
        GetUsersQueryVariables
    > &
        (
            | { variables: GetUsersQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(
        GetUsersDocument,
        options
    );
}
export function useGetUsersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetUsersQuery,
        GetUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
        GetUsersDocument,
        options
    );
}
export function useGetUsersSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetUsersQuery,
              GetUsersQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetUsersQuery,
        GetUsersQueryVariables
    >(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
    typeof useGetUsersLazyQuery
>;
export type GetUsersSuspenseQueryHookResult = ReturnType<
    typeof useGetUsersSuspenseQuery
>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<
    GetUsersQuery,
    GetUsersQueryVariables
>;
export function refetchGetUsersQuery(variables: GetUsersQueryVariables) {
    return { query: GetUsersDocument, variables: variables };
}
export const GetRightsDocument = gql`
    query GetRights(
        $tenantId: ID
        $userId: ID
        $divisionId: ID
        $teamId: ID
        $missionId: ID
        $financialYearCode: String
    ) {
        rights(
            tenantId: $tenantId
            userId: $userId
            divisionId: $divisionId
            teamId: $teamId
            missionId: $missionId
            financialYearCode: $financialYearCode
        ) {
            id
            read
            write
            export
            import
            divisionId
            teamId
            missionId
            userId
        }
    }
`;

/**
 * __useGetRightsQuery__
 *
 * To run a query within a React component, call `useGetRightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRightsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *      divisionId: // value for 'divisionId'
 *      teamId: // value for 'teamId'
 *      missionId: // value for 'missionId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetRightsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetRightsQuery,
        GetRightsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetRightsQuery, GetRightsQueryVariables>(
        GetRightsDocument,
        options
    );
}
export function useGetRightsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetRightsQuery,
        GetRightsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetRightsQuery,
        GetRightsQueryVariables
    >(GetRightsDocument, options);
}
export function useGetRightsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetRightsQuery,
              GetRightsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetRightsQuery,
        GetRightsQueryVariables
    >(GetRightsDocument, options);
}
export type GetRightsQueryHookResult = ReturnType<typeof useGetRightsQuery>;
export type GetRightsLazyQueryHookResult = ReturnType<
    typeof useGetRightsLazyQuery
>;
export type GetRightsSuspenseQueryHookResult = ReturnType<
    typeof useGetRightsSuspenseQuery
>;
export type GetRightsQueryResult = ApolloReactCommon.QueryResult<
    GetRightsQuery,
    GetRightsQueryVariables
>;
export function refetchGetRightsQuery(variables?: GetRightsQueryVariables) {
    return { query: GetRightsDocument, variables: variables };
}
export const GetCommentsDocument = gql`
    query GetComments($tenantId: ID!, $measureId: ID, $taskId: ID) {
        comments(tenantId: $tenantId, measureId: $measureId, taskId: $taskId) {
            id
            userId
            text
            inReplyToId
            likes {
                id
                userId
                username
                version
            }
            username
            utcCreated
            utcUpdated
            version
        }
    }
`;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetCommentsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetCommentsQuery,
        GetCommentsQueryVariables
    > &
        (
            | { variables: GetCommentsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetCommentsQuery,
        GetCommentsQueryVariables
    >(GetCommentsDocument, options);
}
export function useGetCommentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCommentsQuery,
        GetCommentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetCommentsQuery,
        GetCommentsQueryVariables
    >(GetCommentsDocument, options);
}
export function useGetCommentsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetCommentsQuery,
              GetCommentsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetCommentsQuery,
        GetCommentsQueryVariables
    >(GetCommentsDocument, options);
}
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<
    typeof useGetCommentsLazyQuery
>;
export type GetCommentsSuspenseQueryHookResult = ReturnType<
    typeof useGetCommentsSuspenseQuery
>;
export type GetCommentsQueryResult = ApolloReactCommon.QueryResult<
    GetCommentsQuery,
    GetCommentsQueryVariables
>;
export function refetchGetCommentsQuery(variables: GetCommentsQueryVariables) {
    return { query: GetCommentsDocument, variables: variables };
}
export const GetTaskAttachmentsDocument = gql`
    query GetTaskAttachments($tenantId: ID!, $taskId: ID!) {
        task(tenantId: $tenantId, id: $taskId) {
            id
            name
            version
            attachments {
                id
                name
                href
                uploadUri
                containerPath
                isFile
                isUrl
                utcCreated
                utcUpdated
                userId
                username
                version
            }
        }
    }
`;

/**
 * __useGetTaskAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetTaskAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskAttachmentsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskAttachmentsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTaskAttachmentsQuery,
        GetTaskAttachmentsQueryVariables
    > &
        (
            | { variables: GetTaskAttachmentsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTaskAttachmentsQuery,
        GetTaskAttachmentsQueryVariables
    >(GetTaskAttachmentsDocument, options);
}
export function useGetTaskAttachmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTaskAttachmentsQuery,
        GetTaskAttachmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTaskAttachmentsQuery,
        GetTaskAttachmentsQueryVariables
    >(GetTaskAttachmentsDocument, options);
}
export function useGetTaskAttachmentsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTaskAttachmentsQuery,
              GetTaskAttachmentsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTaskAttachmentsQuery,
        GetTaskAttachmentsQueryVariables
    >(GetTaskAttachmentsDocument, options);
}
export type GetTaskAttachmentsQueryHookResult = ReturnType<
    typeof useGetTaskAttachmentsQuery
>;
export type GetTaskAttachmentsLazyQueryHookResult = ReturnType<
    typeof useGetTaskAttachmentsLazyQuery
>;
export type GetTaskAttachmentsSuspenseQueryHookResult = ReturnType<
    typeof useGetTaskAttachmentsSuspenseQuery
>;
export type GetTaskAttachmentsQueryResult = ApolloReactCommon.QueryResult<
    GetTaskAttachmentsQuery,
    GetTaskAttachmentsQueryVariables
>;
export function refetchGetTaskAttachmentsQuery(
    variables: GetTaskAttachmentsQueryVariables
) {
    return { query: GetTaskAttachmentsDocument, variables: variables };
}
export const GetTemplateReportAttachmentsDocument = gql`
    query GetTemplateReportAttachments($tenantId: ID!, $templateReportId: ID!) {
        reports {
            templateReport(tenantId: $tenantId, id: $templateReportId) {
                id
                version
                attachments {
                    id
                    name
                    href
                    uploadUri
                    containerPath
                    isFile
                    isUrl
                    utcCreated
                    utcUpdated
                    userId
                    username
                    version
                }
            }
        }
    }
`;

/**
 * __useGetTemplateReportAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetTemplateReportAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateReportAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateReportAttachmentsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      templateReportId: // value for 'templateReportId'
 *   },
 * });
 */
export function useGetTemplateReportAttachmentsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTemplateReportAttachmentsQuery,
        GetTemplateReportAttachmentsQueryVariables
    > &
        (
            | {
                  variables: GetTemplateReportAttachmentsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTemplateReportAttachmentsQuery,
        GetTemplateReportAttachmentsQueryVariables
    >(GetTemplateReportAttachmentsDocument, options);
}
export function useGetTemplateReportAttachmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTemplateReportAttachmentsQuery,
        GetTemplateReportAttachmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTemplateReportAttachmentsQuery,
        GetTemplateReportAttachmentsQueryVariables
    >(GetTemplateReportAttachmentsDocument, options);
}
export function useGetTemplateReportAttachmentsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTemplateReportAttachmentsQuery,
              GetTemplateReportAttachmentsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTemplateReportAttachmentsQuery,
        GetTemplateReportAttachmentsQueryVariables
    >(GetTemplateReportAttachmentsDocument, options);
}
export type GetTemplateReportAttachmentsQueryHookResult = ReturnType<
    typeof useGetTemplateReportAttachmentsQuery
>;
export type GetTemplateReportAttachmentsLazyQueryHookResult = ReturnType<
    typeof useGetTemplateReportAttachmentsLazyQuery
>;
export type GetTemplateReportAttachmentsSuspenseQueryHookResult = ReturnType<
    typeof useGetTemplateReportAttachmentsSuspenseQuery
>;
export type GetTemplateReportAttachmentsQueryResult =
    ApolloReactCommon.QueryResult<
        GetTemplateReportAttachmentsQuery,
        GetTemplateReportAttachmentsQueryVariables
    >;
export function refetchGetTemplateReportAttachmentsQuery(
    variables: GetTemplateReportAttachmentsQueryVariables
) {
    return {
        query: GetTemplateReportAttachmentsDocument,
        variables: variables,
    };
}
export const GetMeasureAttachmentsDocument = gql`
    query GetMeasureAttachments($tenantId: ID!, $measureId: ID!) {
        measure(tenantId: $tenantId, id: $measureId) {
            id
            name
            version
            attachments {
                id
                name
                href
                uploadUri
                containerPath
                isFile
                isUrl
                utcCreated
                utcUpdated
                userId
                username
                version
            }
        }
    }
`;

/**
 * __useGetMeasureAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetMeasureAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureAttachmentsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *   },
 * });
 */
export function useGetMeasureAttachmentsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasureAttachmentsQuery,
        GetMeasureAttachmentsQueryVariables
    > &
        (
            | { variables: GetMeasureAttachmentsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasureAttachmentsQuery,
        GetMeasureAttachmentsQueryVariables
    >(GetMeasureAttachmentsDocument, options);
}
export function useGetMeasureAttachmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasureAttachmentsQuery,
        GetMeasureAttachmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasureAttachmentsQuery,
        GetMeasureAttachmentsQueryVariables
    >(GetMeasureAttachmentsDocument, options);
}
export function useGetMeasureAttachmentsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasureAttachmentsQuery,
              GetMeasureAttachmentsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasureAttachmentsQuery,
        GetMeasureAttachmentsQueryVariables
    >(GetMeasureAttachmentsDocument, options);
}
export type GetMeasureAttachmentsQueryHookResult = ReturnType<
    typeof useGetMeasureAttachmentsQuery
>;
export type GetMeasureAttachmentsLazyQueryHookResult = ReturnType<
    typeof useGetMeasureAttachmentsLazyQuery
>;
export type GetMeasureAttachmentsSuspenseQueryHookResult = ReturnType<
    typeof useGetMeasureAttachmentsSuspenseQuery
>;
export type GetMeasureAttachmentsQueryResult = ApolloReactCommon.QueryResult<
    GetMeasureAttachmentsQuery,
    GetMeasureAttachmentsQueryVariables
>;
export function refetchGetMeasureAttachmentsQuery(
    variables: GetMeasureAttachmentsQueryVariables
) {
    return { query: GetMeasureAttachmentsDocument, variables: variables };
}
export const GetResourcesDocument = gql`
    query GetResources($tenantId: ID!, $financialYearCode: String) {
        resources(tenantId: $tenantId) {
            id
            name
            displayName
            userId
            taskCount(financialYearCode: $financialYearCode)
            version
        }
    }
`;

/**
 * __useGetResourcesQuery__
 *
 * To run a query within a React component, call `useGetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetResourcesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetResourcesQuery,
        GetResourcesQueryVariables
    > &
        (
            | { variables: GetResourcesQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetResourcesQuery,
        GetResourcesQueryVariables
    >(GetResourcesDocument, options);
}
export function useGetResourcesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetResourcesQuery,
        GetResourcesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetResourcesQuery,
        GetResourcesQueryVariables
    >(GetResourcesDocument, options);
}
export function useGetResourcesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetResourcesQuery,
              GetResourcesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetResourcesQuery,
        GetResourcesQueryVariables
    >(GetResourcesDocument, options);
}
export type GetResourcesQueryHookResult = ReturnType<
    typeof useGetResourcesQuery
>;
export type GetResourcesLazyQueryHookResult = ReturnType<
    typeof useGetResourcesLazyQuery
>;
export type GetResourcesSuspenseQueryHookResult = ReturnType<
    typeof useGetResourcesSuspenseQuery
>;
export type GetResourcesQueryResult = ApolloReactCommon.QueryResult<
    GetResourcesQuery,
    GetResourcesQueryVariables
>;
export function refetchGetResourcesQuery(
    variables: GetResourcesQueryVariables
) {
    return { query: GetResourcesDocument, variables: variables };
}
export const GetResourceDocument = gql`
    query GetResource($tenantId: ID!, $id: ID!) {
        resource(tenantId: $tenantId, id: $id) {
            id
            name
            displayName
            userId
            tasks {
                id
                parentTask {
                    id
                    parentTask {
                        id
                    }
                }
                isDuplicate
                name
                utcAccepted
                utcRejected
                resourcedFromTask {
                    id
                    parentTask {
                        id
                    }
                    mission {
                        id
                        team {
                            id
                            code
                            division {
                                id
                                financialYear {
                                    id
                                    code
                                    startDate
                                }
                            }
                        }
                    }
                }
                mission {
                    id
                    owner
                    title
                    team {
                        id
                        code
                        division {
                            id
                            financialYear {
                                id
                                code
                                startDate
                            }
                        }
                    }
                }
            }
            version
        }
    }
`;

/**
 * __useGetResourceQuery__
 *
 * To run a query within a React component, call `useGetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResourceQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetResourceQuery,
        GetResourceQueryVariables
    > &
        (
            | { variables: GetResourceQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetResourceQuery,
        GetResourceQueryVariables
    >(GetResourceDocument, options);
}
export function useGetResourceLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetResourceQuery,
        GetResourceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetResourceQuery,
        GetResourceQueryVariables
    >(GetResourceDocument, options);
}
export function useGetResourceSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetResourceQuery,
              GetResourceQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetResourceQuery,
        GetResourceQueryVariables
    >(GetResourceDocument, options);
}
export type GetResourceQueryHookResult = ReturnType<typeof useGetResourceQuery>;
export type GetResourceLazyQueryHookResult = ReturnType<
    typeof useGetResourceLazyQuery
>;
export type GetResourceSuspenseQueryHookResult = ReturnType<
    typeof useGetResourceSuspenseQuery
>;
export type GetResourceQueryResult = ApolloReactCommon.QueryResult<
    GetResourceQuery,
    GetResourceQueryVariables
>;
export function refetchGetResourceQuery(variables: GetResourceQueryVariables) {
    return { query: GetResourceDocument, variables: variables };
}
export const GetResourcesSearchDocument = gql`
    query GetResourcesSearch($tenantId: ID!, $searchText: String!) {
        resource_search(tenantId: $tenantId, searchText: $searchText) {
            id
            name
            displayName
            userMissionFYs {
                id
                code
            }
        }
    }
`;

/**
 * __useGetResourcesSearchQuery__
 *
 * To run a query within a React component, call `useGetResourcesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesSearchQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetResourcesSearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetResourcesSearchQuery,
        GetResourcesSearchQueryVariables
    > &
        (
            | { variables: GetResourcesSearchQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetResourcesSearchQuery,
        GetResourcesSearchQueryVariables
    >(GetResourcesSearchDocument, options);
}
export function useGetResourcesSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetResourcesSearchQuery,
        GetResourcesSearchQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetResourcesSearchQuery,
        GetResourcesSearchQueryVariables
    >(GetResourcesSearchDocument, options);
}
export function useGetResourcesSearchSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetResourcesSearchQuery,
              GetResourcesSearchQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetResourcesSearchQuery,
        GetResourcesSearchQueryVariables
    >(GetResourcesSearchDocument, options);
}
export type GetResourcesSearchQueryHookResult = ReturnType<
    typeof useGetResourcesSearchQuery
>;
export type GetResourcesSearchLazyQueryHookResult = ReturnType<
    typeof useGetResourcesSearchLazyQuery
>;
export type GetResourcesSearchSuspenseQueryHookResult = ReturnType<
    typeof useGetResourcesSearchSuspenseQuery
>;
export type GetResourcesSearchQueryResult = ApolloReactCommon.QueryResult<
    GetResourcesSearchQuery,
    GetResourcesSearchQueryVariables
>;
export function refetchGetResourcesSearchQuery(
    variables: GetResourcesSearchQueryVariables
) {
    return { query: GetResourcesSearchDocument, variables: variables };
}
export const GetStatusReportsDocument = gql`
    query GetStatusReports($tenantId: ID!, $missionId: ID!) {
        statusReports(tenantId: $tenantId, missionId: $missionId) {
            id
            title
            reportDate
            summaryText
            lastPeriodText
            utcCompletedDate
            utcDataDate
            utcCreated
            utcDeleted
            facts {
                id
                measureId
                taskId
                isIncluded
            }
        }
    }
`;

/**
 * __useGetStatusReportsQuery__
 *
 * To run a query within a React component, call `useGetStatusReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusReportsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetStatusReportsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetStatusReportsQuery,
        GetStatusReportsQueryVariables
    > &
        (
            | { variables: GetStatusReportsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetStatusReportsQuery,
        GetStatusReportsQueryVariables
    >(GetStatusReportsDocument, options);
}
export function useGetStatusReportsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetStatusReportsQuery,
        GetStatusReportsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetStatusReportsQuery,
        GetStatusReportsQueryVariables
    >(GetStatusReportsDocument, options);
}
export function useGetStatusReportsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetStatusReportsQuery,
              GetStatusReportsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetStatusReportsQuery,
        GetStatusReportsQueryVariables
    >(GetStatusReportsDocument, options);
}
export type GetStatusReportsQueryHookResult = ReturnType<
    typeof useGetStatusReportsQuery
>;
export type GetStatusReportsLazyQueryHookResult = ReturnType<
    typeof useGetStatusReportsLazyQuery
>;
export type GetStatusReportsSuspenseQueryHookResult = ReturnType<
    typeof useGetStatusReportsSuspenseQuery
>;
export type GetStatusReportsQueryResult = ApolloReactCommon.QueryResult<
    GetStatusReportsQuery,
    GetStatusReportsQueryVariables
>;
export function refetchGetStatusReportsQuery(
    variables: GetStatusReportsQueryVariables
) {
    return { query: GetStatusReportsDocument, variables: variables };
}
export const GetStatusReportsDataLastUpdatedDocument = gql`
    query GetStatusReportsDataLastUpdated($tenantId: ID!, $missionId: ID) {
        measures(
            tenantId: $tenantId
            missionId: $missionId
            includeDeleted: true
        ) {
            id
            utcCreated
            utcUpdated
            utcDeleted
            valueHistory {
                utcCreated
                utcUpdated
                utcDeleted
            }
        }
        tasks(
            tenantId: $tenantId
            missionId: $missionId
            includeDeleted: true
        ) {
            id
            utcCreated
            utcUpdated
            utcDeleted
        }
    }
`;

/**
 * __useGetStatusReportsDataLastUpdatedQuery__
 *
 * To run a query within a React component, call `useGetStatusReportsDataLastUpdatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusReportsDataLastUpdatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusReportsDataLastUpdatedQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetStatusReportsDataLastUpdatedQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetStatusReportsDataLastUpdatedQuery,
        GetStatusReportsDataLastUpdatedQueryVariables
    > &
        (
            | {
                  variables: GetStatusReportsDataLastUpdatedQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetStatusReportsDataLastUpdatedQuery,
        GetStatusReportsDataLastUpdatedQueryVariables
    >(GetStatusReportsDataLastUpdatedDocument, options);
}
export function useGetStatusReportsDataLastUpdatedLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetStatusReportsDataLastUpdatedQuery,
        GetStatusReportsDataLastUpdatedQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetStatusReportsDataLastUpdatedQuery,
        GetStatusReportsDataLastUpdatedQueryVariables
    >(GetStatusReportsDataLastUpdatedDocument, options);
}
export function useGetStatusReportsDataLastUpdatedSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetStatusReportsDataLastUpdatedQuery,
              GetStatusReportsDataLastUpdatedQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetStatusReportsDataLastUpdatedQuery,
        GetStatusReportsDataLastUpdatedQueryVariables
    >(GetStatusReportsDataLastUpdatedDocument, options);
}
export type GetStatusReportsDataLastUpdatedQueryHookResult = ReturnType<
    typeof useGetStatusReportsDataLastUpdatedQuery
>;
export type GetStatusReportsDataLastUpdatedLazyQueryHookResult = ReturnType<
    typeof useGetStatusReportsDataLastUpdatedLazyQuery
>;
export type GetStatusReportsDataLastUpdatedSuspenseQueryHookResult = ReturnType<
    typeof useGetStatusReportsDataLastUpdatedSuspenseQuery
>;
export type GetStatusReportsDataLastUpdatedQueryResult =
    ApolloReactCommon.QueryResult<
        GetStatusReportsDataLastUpdatedQuery,
        GetStatusReportsDataLastUpdatedQueryVariables
    >;
export function refetchGetStatusReportsDataLastUpdatedQuery(
    variables: GetStatusReportsDataLastUpdatedQueryVariables
) {
    return {
        query: GetStatusReportsDataLastUpdatedDocument,
        variables: variables,
    };
}
export const GetStatusReportDocument = gql`
    query GetStatusReport($tenantId: ID!, $statusReportId: ID!) {
        statusReport(tenantId: $tenantId, statusReportId: $statusReportId) {
            id
            missionId
            reportDate
            utcCompletedDate
            utcDataDate
            title
            summaryText
            lastPeriodText
            nextPeriodText
            risksAndOpportunitiesText
            supportText
            mission {
                id
                userId
                missionStatement
                title
                owner
                utcCreated
                rights {
                    read
                    write
                    export
                    import
                }
                team {
                    id
                    code
                    leaderMission {
                        id
                        userId
                        username
                    }
                    division {
                        id
                        canUnpublishReport
                        financialYear {
                            id
                            startDate
                            endDate
                        }
                    }
                }
            }
            facts {
                id
                measureId
                taskId
                factText
                soWhatText
                insightText
                actionText
                sequence
                isIncluded
                version
            }
            utcCreated
            utcUpdated
            version
        }
    }
`;

/**
 * __useGetStatusReportQuery__
 *
 * To run a query within a React component, call `useGetStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusReportQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      statusReportId: // value for 'statusReportId'
 *   },
 * });
 */
export function useGetStatusReportQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetStatusReportQuery,
        GetStatusReportQueryVariables
    > &
        (
            | { variables: GetStatusReportQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetStatusReportQuery,
        GetStatusReportQueryVariables
    >(GetStatusReportDocument, options);
}
export function useGetStatusReportLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetStatusReportQuery,
        GetStatusReportQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetStatusReportQuery,
        GetStatusReportQueryVariables
    >(GetStatusReportDocument, options);
}
export function useGetStatusReportSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetStatusReportQuery,
              GetStatusReportQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetStatusReportQuery,
        GetStatusReportQueryVariables
    >(GetStatusReportDocument, options);
}
export type GetStatusReportQueryHookResult = ReturnType<
    typeof useGetStatusReportQuery
>;
export type GetStatusReportLazyQueryHookResult = ReturnType<
    typeof useGetStatusReportLazyQuery
>;
export type GetStatusReportSuspenseQueryHookResult = ReturnType<
    typeof useGetStatusReportSuspenseQuery
>;
export type GetStatusReportQueryResult = ApolloReactCommon.QueryResult<
    GetStatusReportQuery,
    GetStatusReportQueryVariables
>;
export function refetchGetStatusReportQuery(
    variables: GetStatusReportQueryVariables
) {
    return { query: GetStatusReportDocument, variables: variables };
}
export const GetLatestStatusReportDocument = gql`
    query GetLatestStatusReport($tenantId: ID!, $missionId: ID!) {
        latestStatusReport(tenantId: $tenantId, missionId: $missionId) {
            id
            title
            reportDate
            summaryText
            lastPeriodText
            nextPeriodText
            risksAndOpportunitiesText
            supportText
        }
    }
`;

/**
 * __useGetLatestStatusReportQuery__
 *
 * To run a query within a React component, call `useGetLatestStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestStatusReportQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetLatestStatusReportQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetLatestStatusReportQuery,
        GetLatestStatusReportQueryVariables
    > &
        (
            | { variables: GetLatestStatusReportQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetLatestStatusReportQuery,
        GetLatestStatusReportQueryVariables
    >(GetLatestStatusReportDocument, options);
}
export function useGetLatestStatusReportLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetLatestStatusReportQuery,
        GetLatestStatusReportQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetLatestStatusReportQuery,
        GetLatestStatusReportQueryVariables
    >(GetLatestStatusReportDocument, options);
}
export function useGetLatestStatusReportSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetLatestStatusReportQuery,
              GetLatestStatusReportQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetLatestStatusReportQuery,
        GetLatestStatusReportQueryVariables
    >(GetLatestStatusReportDocument, options);
}
export type GetLatestStatusReportQueryHookResult = ReturnType<
    typeof useGetLatestStatusReportQuery
>;
export type GetLatestStatusReportLazyQueryHookResult = ReturnType<
    typeof useGetLatestStatusReportLazyQuery
>;
export type GetLatestStatusReportSuspenseQueryHookResult = ReturnType<
    typeof useGetLatestStatusReportSuspenseQuery
>;
export type GetLatestStatusReportQueryResult = ApolloReactCommon.QueryResult<
    GetLatestStatusReportQuery,
    GetLatestStatusReportQueryVariables
>;
export function refetchGetLatestStatusReportQuery(
    variables: GetLatestStatusReportQueryVariables
) {
    return { query: GetLatestStatusReportDocument, variables: variables };
}
export const GetTeamAlignmentDocument = gql`
    query GetTeamAlignment(
        $tenantId: ID!
        $financialYearCode: String!
        $teamId: ID
        $searchText: String
        $isWritable: Boolean!
        $includeInactive: Boolean
        $includeDeleted: Boolean
    ) {
        missions: mission_search(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            teamId: $teamId
            searchText: $searchText
            isWritable: $isWritable
            includeInactive: $includeInactive
            includeDeleted: $includeDeleted
        ) {
            id
            title
            owner
            missionStatement
            sequence
            userId
            username
            outstandingDependencyCount(financialYearCode: $financialYearCode)
            latestPublishedStatusReport {
                id
                title
                reportDate
                utcCompletedDate
            }
            latestDraftStatusReport {
                id
                title
                reportDate
                utcUpdated
                utcCompletedDate
            }
            leaderOfTeams {
                id
                code
            }
            team {
                id
                code
                name
                division {
                    id
                    name
                }
            }
            dottedTeams {
                id
                teamId
                sequence
                missionGroupId
            }
            measures {
                id
            }
            behaviours {
                id
            }
            freedoms {
                id
            }
            constraints {
                id
            }
        }
        contributors: contributors(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            teamId: $teamId
        ) {
            id
            team {
                id
                code
                name
                division {
                    id
                    name
                }
            }
            userId
            userDisplayName
        }
    }
`;

/**
 * __useGetTeamAlignmentQuery__
 *
 * To run a query within a React component, call `useGetTeamAlignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamAlignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamAlignmentQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *      teamId: // value for 'teamId'
 *      searchText: // value for 'searchText'
 *      isWritable: // value for 'isWritable'
 *      includeInactive: // value for 'includeInactive'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useGetTeamAlignmentQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTeamAlignmentQuery,
        GetTeamAlignmentQueryVariables
    > &
        (
            | { variables: GetTeamAlignmentQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTeamAlignmentQuery,
        GetTeamAlignmentQueryVariables
    >(GetTeamAlignmentDocument, options);
}
export function useGetTeamAlignmentLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTeamAlignmentQuery,
        GetTeamAlignmentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTeamAlignmentQuery,
        GetTeamAlignmentQueryVariables
    >(GetTeamAlignmentDocument, options);
}
export function useGetTeamAlignmentSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTeamAlignmentQuery,
              GetTeamAlignmentQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTeamAlignmentQuery,
        GetTeamAlignmentQueryVariables
    >(GetTeamAlignmentDocument, options);
}
export type GetTeamAlignmentQueryHookResult = ReturnType<
    typeof useGetTeamAlignmentQuery
>;
export type GetTeamAlignmentLazyQueryHookResult = ReturnType<
    typeof useGetTeamAlignmentLazyQuery
>;
export type GetTeamAlignmentSuspenseQueryHookResult = ReturnType<
    typeof useGetTeamAlignmentSuspenseQuery
>;
export type GetTeamAlignmentQueryResult = ApolloReactCommon.QueryResult<
    GetTeamAlignmentQuery,
    GetTeamAlignmentQueryVariables
>;
export function refetchGetTeamAlignmentQuery(
    variables: GetTeamAlignmentQueryVariables
) {
    return { query: GetTeamAlignmentDocument, variables: variables };
}
export const GetTeamUsageDocument = gql`
    query GetTeamUsage(
        $tenantId: ID!
        $financialYearCode: String
        $financialYearActiveDate: DateTime
        $teamId: ID
        $searchText: String
        $isWritable: Boolean!
        $includeInactive: Boolean
        $includeDeleted: Boolean
        $usagePeriodStart: Date!
        $usagePeriodEnd: Date!
        $forDateTime: DateTime
    ) {
        missions: mission_search(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            financialYearActiveDate: $financialYearActiveDate
            teamId: $teamId
            searchText: $searchText
            isWritable: $isWritable
            includeInactive: $includeInactive
            includeDeleted: $includeDeleted
            forDateTime: $forDateTime
        ) {
            id
            userId
            team {
                id
                division {
                    id
                    name
                }
            }
            userUsage(
                usagePeriodStart: $usagePeriodStart
                usagePeriodEnd: $usagePeriodEnd
            ) {
                lastSeen
                totalUsageInMinutes
                totalDistinctDays
            }
        }
        contributors: contributors(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            financialYearActiveDate: $financialYearActiveDate
            teamId: $teamId
        ) {
            id
            team {
                id
                code
                name
            }
            userId
            userUsage(
                usagePeriodStart: $usagePeriodStart
                usagePeriodEnd: $usagePeriodEnd
            ) {
                lastSeen
                totalUsageInMinutes
                totalDistinctDays
            }
        }
    }
`;

/**
 * __useGetTeamUsageQuery__
 *
 * To run a query within a React component, call `useGetTeamUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamUsageQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *      financialYearActiveDate: // value for 'financialYearActiveDate'
 *      teamId: // value for 'teamId'
 *      searchText: // value for 'searchText'
 *      isWritable: // value for 'isWritable'
 *      includeInactive: // value for 'includeInactive'
 *      includeDeleted: // value for 'includeDeleted'
 *      usagePeriodStart: // value for 'usagePeriodStart'
 *      usagePeriodEnd: // value for 'usagePeriodEnd'
 *      forDateTime: // value for 'forDateTime'
 *   },
 * });
 */
export function useGetTeamUsageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTeamUsageQuery,
        GetTeamUsageQueryVariables
    > &
        (
            | { variables: GetTeamUsageQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTeamUsageQuery,
        GetTeamUsageQueryVariables
    >(GetTeamUsageDocument, options);
}
export function useGetTeamUsageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTeamUsageQuery,
        GetTeamUsageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTeamUsageQuery,
        GetTeamUsageQueryVariables
    >(GetTeamUsageDocument, options);
}
export function useGetTeamUsageSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTeamUsageQuery,
              GetTeamUsageQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTeamUsageQuery,
        GetTeamUsageQueryVariables
    >(GetTeamUsageDocument, options);
}
export type GetTeamUsageQueryHookResult = ReturnType<
    typeof useGetTeamUsageQuery
>;
export type GetTeamUsageLazyQueryHookResult = ReturnType<
    typeof useGetTeamUsageLazyQuery
>;
export type GetTeamUsageSuspenseQueryHookResult = ReturnType<
    typeof useGetTeamUsageSuspenseQuery
>;
export type GetTeamUsageQueryResult = ApolloReactCommon.QueryResult<
    GetTeamUsageQuery,
    GetTeamUsageQueryVariables
>;
export function refetchGetTeamUsageQuery(
    variables: GetTeamUsageQueryVariables
) {
    return { query: GetTeamUsageDocument, variables: variables };
}
export const GetMeasuresForStatusReportDocument = gql`
    query GetMeasuresForStatusReport(
        $tenantId: ID!
        $missionId: ID
        $forDateTime: DateTime
        $includeDeleted: Boolean
    ) {
        measures(
            tenantId: $tenantId
            missionId: $missionId
            forDateTime: $forDateTime
            includeDeleted: $includeDeleted
        ) {
            id
            name
            isStatusLimited
            frequencyPeriod
            valueType
            sequence
            utcDeleted
            lastAsOf {
                id
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                values {
                    id
                    formatStr
                    seriesType {
                        name
                    }
                }
            }
            asOfForDate: asOfForDate(forDateTime: $forDateTime) {
                id
                asOfDate
                statusValue
                arrowColour
                arrowDirection
                values {
                    id
                    formatStr
                    seriesType {
                        name
                    }
                }
            }
            isLinked
            linkedFromMeasureId
            latestStatusReportFact {
                id
                soWhatText
                insightText
                actionText
                statusReport {
                    id
                    reportDate
                    title
                }
            }
            group {
                id
                name
                sequence
            }
        }
    }
`;

/**
 * __useGetMeasuresForStatusReportQuery__
 *
 * To run a query within a React component, call `useGetMeasuresForStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasuresForStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasuresForStatusReportQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *      forDateTime: // value for 'forDateTime'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useGetMeasuresForStatusReportQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasuresForStatusReportQuery,
        GetMeasuresForStatusReportQueryVariables
    > &
        (
            | {
                  variables: GetMeasuresForStatusReportQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasuresForStatusReportQuery,
        GetMeasuresForStatusReportQueryVariables
    >(GetMeasuresForStatusReportDocument, options);
}
export function useGetMeasuresForStatusReportLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasuresForStatusReportQuery,
        GetMeasuresForStatusReportQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasuresForStatusReportQuery,
        GetMeasuresForStatusReportQueryVariables
    >(GetMeasuresForStatusReportDocument, options);
}
export function useGetMeasuresForStatusReportSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasuresForStatusReportQuery,
              GetMeasuresForStatusReportQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasuresForStatusReportQuery,
        GetMeasuresForStatusReportQueryVariables
    >(GetMeasuresForStatusReportDocument, options);
}
export type GetMeasuresForStatusReportQueryHookResult = ReturnType<
    typeof useGetMeasuresForStatusReportQuery
>;
export type GetMeasuresForStatusReportLazyQueryHookResult = ReturnType<
    typeof useGetMeasuresForStatusReportLazyQuery
>;
export type GetMeasuresForStatusReportSuspenseQueryHookResult = ReturnType<
    typeof useGetMeasuresForStatusReportSuspenseQuery
>;
export type GetMeasuresForStatusReportQueryResult =
    ApolloReactCommon.QueryResult<
        GetMeasuresForStatusReportQuery,
        GetMeasuresForStatusReportQueryVariables
    >;
export function refetchGetMeasuresForStatusReportQuery(
    variables: GetMeasuresForStatusReportQueryVariables
) {
    return { query: GetMeasuresForStatusReportDocument, variables: variables };
}
export const GetTasksForStatusReportDocument = gql`
    query GetTasksForStatusReport(
        $tenantId: ID!
        $missionId: ID
        $forDateTime: DateTime
        $includeDeleted: Boolean
    ) {
        tasks(
            tenantId: $tenantId
            missionId: $missionId
            forDateTime: $forDateTime
            includeDeleted: $includeDeleted
        ) {
            id
            parentTaskId
            sequence
            name
            start
            due
            done
            utcCancelled
            utcPostponed
            utcAtRisk
            isDuplicate
            percentComplete
            utcDeleted
            taskCategory {
                id
                name
                colourHex
            }
            linkedMeasures {
                id
                measureId
                taskId
            }
            latestStatusReportFact {
                id
                soWhatText
                insightText
                actionText
                statusReport {
                    id
                    reportDate
                    title
                }
            }
        }
    }
`;

/**
 * __useGetTasksForStatusReportQuery__
 *
 * To run a query within a React component, call `useGetTasksForStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksForStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksForStatusReportQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *      forDateTime: // value for 'forDateTime'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useGetTasksForStatusReportQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTasksForStatusReportQuery,
        GetTasksForStatusReportQueryVariables
    > &
        (
            | {
                  variables: GetTasksForStatusReportQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTasksForStatusReportQuery,
        GetTasksForStatusReportQueryVariables
    >(GetTasksForStatusReportDocument, options);
}
export function useGetTasksForStatusReportLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTasksForStatusReportQuery,
        GetTasksForStatusReportQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTasksForStatusReportQuery,
        GetTasksForStatusReportQueryVariables
    >(GetTasksForStatusReportDocument, options);
}
export function useGetTasksForStatusReportSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTasksForStatusReportQuery,
              GetTasksForStatusReportQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTasksForStatusReportQuery,
        GetTasksForStatusReportQueryVariables
    >(GetTasksForStatusReportDocument, options);
}
export type GetTasksForStatusReportQueryHookResult = ReturnType<
    typeof useGetTasksForStatusReportQuery
>;
export type GetTasksForStatusReportLazyQueryHookResult = ReturnType<
    typeof useGetTasksForStatusReportLazyQuery
>;
export type GetTasksForStatusReportSuspenseQueryHookResult = ReturnType<
    typeof useGetTasksForStatusReportSuspenseQuery
>;
export type GetTasksForStatusReportQueryResult = ApolloReactCommon.QueryResult<
    GetTasksForStatusReportQuery,
    GetTasksForStatusReportQueryVariables
>;
export function refetchGetTasksForStatusReportQuery(
    variables: GetTasksForStatusReportQueryVariables
) {
    return { query: GetTasksForStatusReportDocument, variables: variables };
}
export const GetRoleTypesDocument = gql`
    query GetRoleTypes {
        roleTypes {
            id
            name
            description
        }
    }
`;

/**
 * __useGetRoleTypesQuery__
 *
 * To run a query within a React component, call `useGetRoleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetRoleTypesQuery,
        GetRoleTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetRoleTypesQuery,
        GetRoleTypesQueryVariables
    >(GetRoleTypesDocument, options);
}
export function useGetRoleTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetRoleTypesQuery,
        GetRoleTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetRoleTypesQuery,
        GetRoleTypesQueryVariables
    >(GetRoleTypesDocument, options);
}
export function useGetRoleTypesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetRoleTypesQuery,
              GetRoleTypesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetRoleTypesQuery,
        GetRoleTypesQueryVariables
    >(GetRoleTypesDocument, options);
}
export type GetRoleTypesQueryHookResult = ReturnType<
    typeof useGetRoleTypesQuery
>;
export type GetRoleTypesLazyQueryHookResult = ReturnType<
    typeof useGetRoleTypesLazyQuery
>;
export type GetRoleTypesSuspenseQueryHookResult = ReturnType<
    typeof useGetRoleTypesSuspenseQuery
>;
export type GetRoleTypesQueryResult = ApolloReactCommon.QueryResult<
    GetRoleTypesQuery,
    GetRoleTypesQueryVariables
>;
export function refetchGetRoleTypesQuery(
    variables?: GetRoleTypesQueryVariables
) {
    return { query: GetRoleTypesDocument, variables: variables };
}
export const GetFinancialYearsDocument = gql`
    query GetFinancialYears($tenantId: ID!) {
        financialYears(tenantId: $tenantId) {
            id
            code
            name
            startDate
            endDate
            utcInactive
            version
        }
    }
`;

/**
 * __useGetFinancialYearsQuery__
 *
 * To run a query within a React component, call `useGetFinancialYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialYearsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetFinancialYearsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetFinancialYearsQuery,
        GetFinancialYearsQueryVariables
    > &
        (
            | { variables: GetFinancialYearsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetFinancialYearsQuery,
        GetFinancialYearsQueryVariables
    >(GetFinancialYearsDocument, options);
}
export function useGetFinancialYearsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetFinancialYearsQuery,
        GetFinancialYearsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetFinancialYearsQuery,
        GetFinancialYearsQueryVariables
    >(GetFinancialYearsDocument, options);
}
export function useGetFinancialYearsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetFinancialYearsQuery,
              GetFinancialYearsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetFinancialYearsQuery,
        GetFinancialYearsQueryVariables
    >(GetFinancialYearsDocument, options);
}
export type GetFinancialYearsQueryHookResult = ReturnType<
    typeof useGetFinancialYearsQuery
>;
export type GetFinancialYearsLazyQueryHookResult = ReturnType<
    typeof useGetFinancialYearsLazyQuery
>;
export type GetFinancialYearsSuspenseQueryHookResult = ReturnType<
    typeof useGetFinancialYearsSuspenseQuery
>;
export type GetFinancialYearsQueryResult = ApolloReactCommon.QueryResult<
    GetFinancialYearsQuery,
    GetFinancialYearsQueryVariables
>;
export function refetchGetFinancialYearsQuery(
    variables: GetFinancialYearsQueryVariables
) {
    return { query: GetFinancialYearsDocument, variables: variables };
}
export const GetFinancialYearDocument = gql`
    query GetFinancialYear($tenantId: ID!, $financialYearCode: String) {
        financialYear(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
        ) {
            id
            code
            name
            startDate
            endDate
            utcInactive
            version
        }
    }
`;

/**
 * __useGetFinancialYearQuery__
 *
 * To run a query within a React component, call `useGetFinancialYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialYearQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetFinancialYearQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetFinancialYearQuery,
        GetFinancialYearQueryVariables
    > &
        (
            | { variables: GetFinancialYearQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetFinancialYearQuery,
        GetFinancialYearQueryVariables
    >(GetFinancialYearDocument, options);
}
export function useGetFinancialYearLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetFinancialYearQuery,
        GetFinancialYearQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetFinancialYearQuery,
        GetFinancialYearQueryVariables
    >(GetFinancialYearDocument, options);
}
export function useGetFinancialYearSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetFinancialYearQuery,
              GetFinancialYearQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetFinancialYearQuery,
        GetFinancialYearQueryVariables
    >(GetFinancialYearDocument, options);
}
export type GetFinancialYearQueryHookResult = ReturnType<
    typeof useGetFinancialYearQuery
>;
export type GetFinancialYearLazyQueryHookResult = ReturnType<
    typeof useGetFinancialYearLazyQuery
>;
export type GetFinancialYearSuspenseQueryHookResult = ReturnType<
    typeof useGetFinancialYearSuspenseQuery
>;
export type GetFinancialYearQueryResult = ApolloReactCommon.QueryResult<
    GetFinancialYearQuery,
    GetFinancialYearQueryVariables
>;
export function refetchGetFinancialYearQuery(
    variables: GetFinancialYearQueryVariables
) {
    return { query: GetFinancialYearDocument, variables: variables };
}
export const GetContributorDocument = gql`
    query GetContributor(
        $tenantId: ID!
        $financialYearCode: String
        $userId: ID
        $contributorId: ID
    ) {
        contributor(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            userId: $userId
            contributorId: $contributorId
        ) {
            id
            team {
                id
                code
                name
            }
            userId
            userDisplayName
        }
    }
`;

/**
 * __useGetContributorQuery__
 *
 * To run a query within a React component, call `useGetContributorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContributorQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *      userId: // value for 'userId'
 *      contributorId: // value for 'contributorId'
 *   },
 * });
 */
export function useGetContributorQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetContributorQuery,
        GetContributorQueryVariables
    > &
        (
            | { variables: GetContributorQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetContributorQuery,
        GetContributorQueryVariables
    >(GetContributorDocument, options);
}
export function useGetContributorLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetContributorQuery,
        GetContributorQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetContributorQuery,
        GetContributorQueryVariables
    >(GetContributorDocument, options);
}
export function useGetContributorSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetContributorQuery,
              GetContributorQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetContributorQuery,
        GetContributorQueryVariables
    >(GetContributorDocument, options);
}
export type GetContributorQueryHookResult = ReturnType<
    typeof useGetContributorQuery
>;
export type GetContributorLazyQueryHookResult = ReturnType<
    typeof useGetContributorLazyQuery
>;
export type GetContributorSuspenseQueryHookResult = ReturnType<
    typeof useGetContributorSuspenseQuery
>;
export type GetContributorQueryResult = ApolloReactCommon.QueryResult<
    GetContributorQuery,
    GetContributorQueryVariables
>;
export function refetchGetContributorQuery(
    variables: GetContributorQueryVariables
) {
    return { query: GetContributorDocument, variables: variables };
}
export const GetContributorsDocument = gql`
    query GetContributors(
        $tenantId: ID!
        $financialYearCode: String
        $teamCode: String
    ) {
        contributors(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            teamCode: $teamCode
        ) {
            id
            team {
                id
                code
                name
            }
            userId
            userDisplayName
        }
    }
`;

/**
 * __useGetContributorsQuery__
 *
 * To run a query within a React component, call `useGetContributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContributorsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *      teamCode: // value for 'teamCode'
 *   },
 * });
 */
export function useGetContributorsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetContributorsQuery,
        GetContributorsQueryVariables
    > &
        (
            | { variables: GetContributorsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetContributorsQuery,
        GetContributorsQueryVariables
    >(GetContributorsDocument, options);
}
export function useGetContributorsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetContributorsQuery,
        GetContributorsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetContributorsQuery,
        GetContributorsQueryVariables
    >(GetContributorsDocument, options);
}
export function useGetContributorsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetContributorsQuery,
              GetContributorsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetContributorsQuery,
        GetContributorsQueryVariables
    >(GetContributorsDocument, options);
}
export type GetContributorsQueryHookResult = ReturnType<
    typeof useGetContributorsQuery
>;
export type GetContributorsLazyQueryHookResult = ReturnType<
    typeof useGetContributorsLazyQuery
>;
export type GetContributorsSuspenseQueryHookResult = ReturnType<
    typeof useGetContributorsSuspenseQuery
>;
export type GetContributorsQueryResult = ApolloReactCommon.QueryResult<
    GetContributorsQuery,
    GetContributorsQueryVariables
>;
export function refetchGetContributorsQuery(
    variables: GetContributorsQueryVariables
) {
    return { query: GetContributorsDocument, variables: variables };
}
export const GetContributorsSearchDocument = gql`
    query GetContributorsSearch(
        $tenantId: ID!
        $financialYearCode: String
        $searchText: String
    ) {
        contributors(
            tenantId: $tenantId
            financialYearCode: $financialYearCode
            searchText: $searchText
        ) {
            id
            team {
                id
                code
                name
            }
            userId
            userDisplayName
        }
    }
`;

/**
 * __useGetContributorsSearchQuery__
 *
 * To run a query within a React component, call `useGetContributorsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContributorsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContributorsSearchQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetContributorsSearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetContributorsSearchQuery,
        GetContributorsSearchQueryVariables
    > &
        (
            | { variables: GetContributorsSearchQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetContributorsSearchQuery,
        GetContributorsSearchQueryVariables
    >(GetContributorsSearchDocument, options);
}
export function useGetContributorsSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetContributorsSearchQuery,
        GetContributorsSearchQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetContributorsSearchQuery,
        GetContributorsSearchQueryVariables
    >(GetContributorsSearchDocument, options);
}
export function useGetContributorsSearchSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetContributorsSearchQuery,
              GetContributorsSearchQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetContributorsSearchQuery,
        GetContributorsSearchQueryVariables
    >(GetContributorsSearchDocument, options);
}
export type GetContributorsSearchQueryHookResult = ReturnType<
    typeof useGetContributorsSearchQuery
>;
export type GetContributorsSearchLazyQueryHookResult = ReturnType<
    typeof useGetContributorsSearchLazyQuery
>;
export type GetContributorsSearchSuspenseQueryHookResult = ReturnType<
    typeof useGetContributorsSearchSuspenseQuery
>;
export type GetContributorsSearchQueryResult = ApolloReactCommon.QueryResult<
    GetContributorsSearchQuery,
    GetContributorsSearchQueryVariables
>;
export function refetchGetContributorsSearchQuery(
    variables: GetContributorsSearchQueryVariables
) {
    return { query: GetContributorsSearchDocument, variables: variables };
}
export const GetDivisionsDocument = gql`
    query GetDivisions($tenantId: ID!, $financialYearCode: String) {
        divisions(tenantId: $tenantId, financialYearCode: $financialYearCode) {
            id
            code
            name
            purpose
            vision
            values
            primaryColourHex
            canUnpublishReport
            utcInactive
            version
            financialYear {
                id
                code
            }
        }
    }
`;

/**
 * __useGetDivisionsQuery__
 *
 * To run a query within a React component, call `useGetDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDivisionsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      financialYearCode: // value for 'financialYearCode'
 *   },
 * });
 */
export function useGetDivisionsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetDivisionsQuery,
        GetDivisionsQueryVariables
    > &
        (
            | { variables: GetDivisionsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetDivisionsQuery,
        GetDivisionsQueryVariables
    >(GetDivisionsDocument, options);
}
export function useGetDivisionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetDivisionsQuery,
        GetDivisionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetDivisionsQuery,
        GetDivisionsQueryVariables
    >(GetDivisionsDocument, options);
}
export function useGetDivisionsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetDivisionsQuery,
              GetDivisionsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetDivisionsQuery,
        GetDivisionsQueryVariables
    >(GetDivisionsDocument, options);
}
export type GetDivisionsQueryHookResult = ReturnType<
    typeof useGetDivisionsQuery
>;
export type GetDivisionsLazyQueryHookResult = ReturnType<
    typeof useGetDivisionsLazyQuery
>;
export type GetDivisionsSuspenseQueryHookResult = ReturnType<
    typeof useGetDivisionsSuspenseQuery
>;
export type GetDivisionsQueryResult = ApolloReactCommon.QueryResult<
    GetDivisionsQuery,
    GetDivisionsQueryVariables
>;
export function refetchGetDivisionsQuery(
    variables: GetDivisionsQueryVariables
) {
    return { query: GetDivisionsDocument, variables: variables };
}
export const GetTenantsDocument = gql`
    query GetTenants {
        tenants {
            id
            code
            description
            isEnabled
            isAnalyticsEnabled
        }
    }
`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetTenantsQuery,
        GetTenantsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(
        GetTenantsDocument,
        options
    );
}
export function useGetTenantsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTenantsQuery,
        GetTenantsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTenantsQuery,
        GetTenantsQueryVariables
    >(GetTenantsDocument, options);
}
export function useGetTenantsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTenantsQuery,
              GetTenantsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTenantsQuery,
        GetTenantsQueryVariables
    >(GetTenantsDocument, options);
}
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<
    typeof useGetTenantsLazyQuery
>;
export type GetTenantsSuspenseQueryHookResult = ReturnType<
    typeof useGetTenantsSuspenseQuery
>;
export type GetTenantsQueryResult = ApolloReactCommon.QueryResult<
    GetTenantsQuery,
    GetTenantsQueryVariables
>;
export function refetchGetTenantsQuery(variables?: GetTenantsQueryVariables) {
    return { query: GetTenantsDocument, variables: variables };
}
export const GetUsageStatsDocument = gql`
    query GetUsageStats($includeLogins: Boolean) {
        usageStats(includeLogins: $includeLogins) {
            tenants {
                id
                tenantCode
                tenantName
                fyCode
                fyStart
                divisionCount
                missionCount
                teamCount
                missionOwners
                contributors
                viewers
                login7
                login30
                login90
                info
            }
            usersByTenant {
                tenantCode
                tenantName
                users {
                    name
                    email
                    lastLogin
                    isMissionOwner
                    isContributor
                    isViewer
                    loginDays
                    utcCreated
                    missionUtcCreated
                    missionCount
                    info
                    divisionNames
                    leaderOfTeamNames
                    teamNames
                }
            }
            error
        }
    }
`;

/**
 * __useGetUsageStatsQuery__
 *
 * To run a query within a React component, call `useGetUsageStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageStatsQuery({
 *   variables: {
 *      includeLogins: // value for 'includeLogins'
 *   },
 * });
 */
export function useGetUsageStatsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetUsageStatsQuery,
        GetUsageStatsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetUsageStatsQuery,
        GetUsageStatsQueryVariables
    >(GetUsageStatsDocument, options);
}
export function useGetUsageStatsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetUsageStatsQuery,
        GetUsageStatsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetUsageStatsQuery,
        GetUsageStatsQueryVariables
    >(GetUsageStatsDocument, options);
}
export function useGetUsageStatsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetUsageStatsQuery,
              GetUsageStatsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetUsageStatsQuery,
        GetUsageStatsQueryVariables
    >(GetUsageStatsDocument, options);
}
export type GetUsageStatsQueryHookResult = ReturnType<
    typeof useGetUsageStatsQuery
>;
export type GetUsageStatsLazyQueryHookResult = ReturnType<
    typeof useGetUsageStatsLazyQuery
>;
export type GetUsageStatsSuspenseQueryHookResult = ReturnType<
    typeof useGetUsageStatsSuspenseQuery
>;
export type GetUsageStatsQueryResult = ApolloReactCommon.QueryResult<
    GetUsageStatsQuery,
    GetUsageStatsQueryVariables
>;
export function refetchGetUsageStatsQuery(
    variables?: GetUsageStatsQueryVariables
) {
    return { query: GetUsageStatsDocument, variables: variables };
}
export const GetCurrenciesDocument = gql`
    query GetCurrencies($tenantId: ID!) {
        currencies(tenantId: $tenantId) {
            code
            symbol
            descr
        }
    }
`;

/**
 * __useGetCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetCurrenciesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetCurrenciesQuery,
        GetCurrenciesQueryVariables
    > &
        (
            | { variables: GetCurrenciesQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetCurrenciesQuery,
        GetCurrenciesQueryVariables
    >(GetCurrenciesDocument, options);
}
export function useGetCurrenciesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCurrenciesQuery,
        GetCurrenciesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetCurrenciesQuery,
        GetCurrenciesQueryVariables
    >(GetCurrenciesDocument, options);
}
export function useGetCurrenciesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetCurrenciesQuery,
              GetCurrenciesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetCurrenciesQuery,
        GetCurrenciesQueryVariables
    >(GetCurrenciesDocument, options);
}
export type GetCurrenciesQueryHookResult = ReturnType<
    typeof useGetCurrenciesQuery
>;
export type GetCurrenciesLazyQueryHookResult = ReturnType<
    typeof useGetCurrenciesLazyQuery
>;
export type GetCurrenciesSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrenciesSuspenseQuery
>;
export type GetCurrenciesQueryResult = ApolloReactCommon.QueryResult<
    GetCurrenciesQuery,
    GetCurrenciesQueryVariables
>;
export function refetchGetCurrenciesQuery(
    variables: GetCurrenciesQueryVariables
) {
    return { query: GetCurrenciesDocument, variables: variables };
}
export const ImporterBatchesDocument = gql`
    query ImporterBatches($tenantId: ID!) {
        importerBatches(tenantId: $tenantId) {
            batchId
            utcBatchDate
            updatedByUserId
            username
        }
    }
`;

/**
 * __useImporterBatchesQuery__
 *
 * To run a query within a React component, call `useImporterBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImporterBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImporterBatchesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useImporterBatchesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ImporterBatchesQuery,
        ImporterBatchesQueryVariables
    > &
        (
            | { variables: ImporterBatchesQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ImporterBatchesQuery,
        ImporterBatchesQueryVariables
    >(ImporterBatchesDocument, options);
}
export function useImporterBatchesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ImporterBatchesQuery,
        ImporterBatchesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ImporterBatchesQuery,
        ImporterBatchesQueryVariables
    >(ImporterBatchesDocument, options);
}
export function useImporterBatchesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              ImporterBatchesQuery,
              ImporterBatchesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ImporterBatchesQuery,
        ImporterBatchesQueryVariables
    >(ImporterBatchesDocument, options);
}
export type ImporterBatchesQueryHookResult = ReturnType<
    typeof useImporterBatchesQuery
>;
export type ImporterBatchesLazyQueryHookResult = ReturnType<
    typeof useImporterBatchesLazyQuery
>;
export type ImporterBatchesSuspenseQueryHookResult = ReturnType<
    typeof useImporterBatchesSuspenseQuery
>;
export type ImporterBatchesQueryResult = ApolloReactCommon.QueryResult<
    ImporterBatchesQuery,
    ImporterBatchesQueryVariables
>;
export function refetchImporterBatchesQuery(
    variables: ImporterBatchesQueryVariables
) {
    return { query: ImporterBatchesDocument, variables: variables };
}
export const GetIsImporterDocument = gql`
    query GetIsImporter($tenantId: ID!) {
        isImporter(tenantId: $tenantId)
    }
`;

/**
 * __useGetIsImporterQuery__
 *
 * To run a query within a React component, call `useGetIsImporterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsImporterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsImporterQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetIsImporterQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetIsImporterQuery,
        GetIsImporterQueryVariables
    > &
        (
            | { variables: GetIsImporterQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetIsImporterQuery,
        GetIsImporterQueryVariables
    >(GetIsImporterDocument, options);
}
export function useGetIsImporterLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetIsImporterQuery,
        GetIsImporterQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetIsImporterQuery,
        GetIsImporterQueryVariables
    >(GetIsImporterDocument, options);
}
export function useGetIsImporterSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetIsImporterQuery,
              GetIsImporterQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetIsImporterQuery,
        GetIsImporterQueryVariables
    >(GetIsImporterDocument, options);
}
export type GetIsImporterQueryHookResult = ReturnType<
    typeof useGetIsImporterQuery
>;
export type GetIsImporterLazyQueryHookResult = ReturnType<
    typeof useGetIsImporterLazyQuery
>;
export type GetIsImporterSuspenseQueryHookResult = ReturnType<
    typeof useGetIsImporterSuspenseQuery
>;
export type GetIsImporterQueryResult = ApolloReactCommon.QueryResult<
    GetIsImporterQuery,
    GetIsImporterQueryVariables
>;
export function refetchGetIsImporterQuery(
    variables: GetIsImporterQueryVariables
) {
    return { query: GetIsImporterDocument, variables: variables };
}
export const GetImporterTimestampsDocument = gql`
    query GetImporterTimestamps($tenantId: ID!, $batchId: ID) {
        importerTimestamps(tenantId: $tenantId, batchId: $batchId) {
            id
            batchId
            action
            utcBatchDate
            utcRolledBack
            table
            objectId
            sysStartTime
            version
            updatedByUserId
            username
        }
    }
`;

/**
 * __useGetImporterTimestampsQuery__
 *
 * To run a query within a React component, call `useGetImporterTimestampsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImporterTimestampsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImporterTimestampsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetImporterTimestampsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetImporterTimestampsQuery,
        GetImporterTimestampsQueryVariables
    > &
        (
            | { variables: GetImporterTimestampsQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetImporterTimestampsQuery,
        GetImporterTimestampsQueryVariables
    >(GetImporterTimestampsDocument, options);
}
export function useGetImporterTimestampsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetImporterTimestampsQuery,
        GetImporterTimestampsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetImporterTimestampsQuery,
        GetImporterTimestampsQueryVariables
    >(GetImporterTimestampsDocument, options);
}
export function useGetImporterTimestampsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetImporterTimestampsQuery,
              GetImporterTimestampsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetImporterTimestampsQuery,
        GetImporterTimestampsQueryVariables
    >(GetImporterTimestampsDocument, options);
}
export type GetImporterTimestampsQueryHookResult = ReturnType<
    typeof useGetImporterTimestampsQuery
>;
export type GetImporterTimestampsLazyQueryHookResult = ReturnType<
    typeof useGetImporterTimestampsLazyQuery
>;
export type GetImporterTimestampsSuspenseQueryHookResult = ReturnType<
    typeof useGetImporterTimestampsSuspenseQuery
>;
export type GetImporterTimestampsQueryResult = ApolloReactCommon.QueryResult<
    GetImporterTimestampsQuery,
    GetImporterTimestampsQueryVariables
>;
export function refetchGetImporterTimestampsQuery(
    variables: GetImporterTimestampsQueryVariables
) {
    return { query: GetImporterTimestampsDocument, variables: variables };
}
export const GetReportTemplatesDocument = gql`
    query GetReportTemplates($tenantId: ID!) {
        reports {
            templateReports(tenantId: $tenantId, isTemplate: true) {
                id
                title
                reportPeriodType
                reportPeriod
            }
        }
    }
`;

/**
 * __useGetReportTemplatesQuery__
 *
 * To run a query within a React component, call `useGetReportTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportTemplatesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetReportTemplatesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetReportTemplatesQuery,
        GetReportTemplatesQueryVariables
    > &
        (
            | { variables: GetReportTemplatesQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetReportTemplatesQuery,
        GetReportTemplatesQueryVariables
    >(GetReportTemplatesDocument, options);
}
export function useGetReportTemplatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetReportTemplatesQuery,
        GetReportTemplatesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetReportTemplatesQuery,
        GetReportTemplatesQueryVariables
    >(GetReportTemplatesDocument, options);
}
export function useGetReportTemplatesSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetReportTemplatesQuery,
              GetReportTemplatesQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetReportTemplatesQuery,
        GetReportTemplatesQueryVariables
    >(GetReportTemplatesDocument, options);
}
export type GetReportTemplatesQueryHookResult = ReturnType<
    typeof useGetReportTemplatesQuery
>;
export type GetReportTemplatesLazyQueryHookResult = ReturnType<
    typeof useGetReportTemplatesLazyQuery
>;
export type GetReportTemplatesSuspenseQueryHookResult = ReturnType<
    typeof useGetReportTemplatesSuspenseQuery
>;
export type GetReportTemplatesQueryResult = ApolloReactCommon.QueryResult<
    GetReportTemplatesQuery,
    GetReportTemplatesQueryVariables
>;
export function refetchGetReportTemplatesQuery(
    variables: GetReportTemplatesQueryVariables
) {
    return { query: GetReportTemplatesDocument, variables: variables };
}
export const GetMissionTemplatesReportsDocument = gql`
    query GetMissionTemplatesReports($tenantId: ID!, $missionId: ID) {
        reports {
            templateReports(
                tenantId: $tenantId
                isTemplate: false
                missionId: $missionId
            ) {
                id
                title
                reportDate
                reportPeriodType
                reportPeriod
                utcCompletedDate
                utcDataDate
                utcCreated
                utcDeleted
                views {
                    id
                    title
                }
                sections {
                    id
                    sequence
                    groups {
                        id
                        sequence
                    }
                    elements {
                        id
                        groupId
                        sequence
                        textContent
                        title
                        measureLinks {
                            id
                            sequence
                            measureId
                        }
                        taskLinks {
                            id
                            sequence
                            taskId
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useGetMissionTemplatesReportsQuery__
 *
 * To run a query within a React component, call `useGetMissionTemplatesReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionTemplatesReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionTemplatesReportsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      missionId: // value for 'missionId'
 *   },
 * });
 */
export function useGetMissionTemplatesReportsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionTemplatesReportsQuery,
        GetMissionTemplatesReportsQueryVariables
    > &
        (
            | {
                  variables: GetMissionTemplatesReportsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMissionTemplatesReportsQuery,
        GetMissionTemplatesReportsQueryVariables
    >(GetMissionTemplatesReportsDocument, options);
}
export function useGetMissionTemplatesReportsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionTemplatesReportsQuery,
        GetMissionTemplatesReportsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionTemplatesReportsQuery,
        GetMissionTemplatesReportsQueryVariables
    >(GetMissionTemplatesReportsDocument, options);
}
export function useGetMissionTemplatesReportsSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionTemplatesReportsQuery,
              GetMissionTemplatesReportsQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionTemplatesReportsQuery,
        GetMissionTemplatesReportsQueryVariables
    >(GetMissionTemplatesReportsDocument, options);
}
export type GetMissionTemplatesReportsQueryHookResult = ReturnType<
    typeof useGetMissionTemplatesReportsQuery
>;
export type GetMissionTemplatesReportsLazyQueryHookResult = ReturnType<
    typeof useGetMissionTemplatesReportsLazyQuery
>;
export type GetMissionTemplatesReportsSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionTemplatesReportsSuspenseQuery
>;
export type GetMissionTemplatesReportsQueryResult =
    ApolloReactCommon.QueryResult<
        GetMissionTemplatesReportsQuery,
        GetMissionTemplatesReportsQueryVariables
    >;
export function refetchGetMissionTemplatesReportsQuery(
    variables: GetMissionTemplatesReportsQueryVariables
) {
    return { query: GetMissionTemplatesReportsDocument, variables: variables };
}
export const GetTemplateReportDocument = gql`
    query GetTemplateReport($tenantId: ID!, $id: ID!) {
        reports {
            templateReport(tenantId: $tenantId, id: $id) {
                id
                title
                reportDate
                reportPeriodType
                reportPeriod
                utcCompletedDate
                utcDataDate
                utcUpdated
                version
                views {
                    id
                    title
                }
                sections {
                    id
                    templateReportViewId
                    title
                    sequence
                    availableElementTypes
                    layoutDirection
                    version
                    utcUpdated
                    groups {
                        id
                        sequence
                        layoutGrow
                        layoutDirection
                        version
                    }
                    elements {
                        id
                        groupId
                        sectionId
                        sourceTemplateReportSectionElementId
                        attachmentId
                        title
                        sequence
                        type
                        layoutGrow
                        textContent
                        measureLinks {
                            id
                            sequence
                            measureId
                        }
                        taskLinks {
                            id
                            sequence
                            taskId
                        }
                        columns {
                            id
                            columnName
                            sequence
                            cells {
                                id
                                rowIndex
                                content
                            }
                            version
                        }
                        utcUpdated
                        version
                    }
                }
                mission {
                    id
                    userId
                    missionStatement
                    title
                    owner
                    utcCreated
                    rights {
                        read
                        write
                        export
                        import
                    }
                    team {
                        id
                        code
                        leaderMission {
                            id
                            userId
                            username
                        }
                        division {
                            id
                            canUnpublishReport
                            financialYear {
                                id
                                startDate
                                endDate
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useGetTemplateReportQuery__
 *
 * To run a query within a React component, call `useGetTemplateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateReportQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateReportQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTemplateReportQuery,
        GetTemplateReportQueryVariables
    > &
        (
            | { variables: GetTemplateReportQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTemplateReportQuery,
        GetTemplateReportQueryVariables
    >(GetTemplateReportDocument, options);
}
export function useGetTemplateReportLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTemplateReportQuery,
        GetTemplateReportQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTemplateReportQuery,
        GetTemplateReportQueryVariables
    >(GetTemplateReportDocument, options);
}
export function useGetTemplateReportSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTemplateReportQuery,
              GetTemplateReportQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTemplateReportQuery,
        GetTemplateReportQueryVariables
    >(GetTemplateReportDocument, options);
}
export type GetTemplateReportQueryHookResult = ReturnType<
    typeof useGetTemplateReportQuery
>;
export type GetTemplateReportLazyQueryHookResult = ReturnType<
    typeof useGetTemplateReportLazyQuery
>;
export type GetTemplateReportSuspenseQueryHookResult = ReturnType<
    typeof useGetTemplateReportSuspenseQuery
>;
export type GetTemplateReportQueryResult = ApolloReactCommon.QueryResult<
    GetTemplateReportQuery,
    GetTemplateReportQueryVariables
>;
export function refetchGetTemplateReportQuery(
    variables: GetTemplateReportQueryVariables
) {
    return { query: GetTemplateReportDocument, variables: variables };
}
export const GetTemplateReportSectionDocument = gql`
    query GetTemplateReportSection($tenantId: ID!, $id: ID!) {
        reports {
            templateReportSection(tenantId: $tenantId, id: $id) {
                id
                title
                sequence
                availableElementTypes
                layoutDirection
                version
                utcUpdated
                groups {
                    id
                    sequence
                    layoutGrow
                    layoutDirection
                    version
                }
                elements {
                    id
                    groupId
                    sectionId
                    attachmentId
                    title
                    sequence
                    type
                    layoutGrow
                    textContent
                    measureLinks {
                        id
                        sequence
                        measureId
                    }
                    taskLinks {
                        id
                        sequence
                        taskId
                    }
                    columns {
                        id
                        columnName
                        sequence
                        cells {
                            id
                            rowIndex
                            content
                        }
                        version
                    }
                    utcUpdated
                    version
                }
            }
        }
    }
`;

/**
 * __useGetTemplateReportSectionQuery__
 *
 * To run a query within a React component, call `useGetTemplateReportSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateReportSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateReportSectionQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateReportSectionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetTemplateReportSectionQuery,
        GetTemplateReportSectionQueryVariables
    > &
        (
            | {
                  variables: GetTemplateReportSectionQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetTemplateReportSectionQuery,
        GetTemplateReportSectionQueryVariables
    >(GetTemplateReportSectionDocument, options);
}
export function useGetTemplateReportSectionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetTemplateReportSectionQuery,
        GetTemplateReportSectionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetTemplateReportSectionQuery,
        GetTemplateReportSectionQueryVariables
    >(GetTemplateReportSectionDocument, options);
}
export function useGetTemplateReportSectionSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetTemplateReportSectionQuery,
              GetTemplateReportSectionQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetTemplateReportSectionQuery,
        GetTemplateReportSectionQueryVariables
    >(GetTemplateReportSectionDocument, options);
}
export type GetTemplateReportSectionQueryHookResult = ReturnType<
    typeof useGetTemplateReportSectionQuery
>;
export type GetTemplateReportSectionLazyQueryHookResult = ReturnType<
    typeof useGetTemplateReportSectionLazyQuery
>;
export type GetTemplateReportSectionSuspenseQueryHookResult = ReturnType<
    typeof useGetTemplateReportSectionSuspenseQuery
>;
export type GetTemplateReportSectionQueryResult = ApolloReactCommon.QueryResult<
    GetTemplateReportSectionQuery,
    GetTemplateReportSectionQueryVariables
>;
export function refetchGetTemplateReportSectionQuery(
    variables: GetTemplateReportSectionQueryVariables
) {
    return { query: GetTemplateReportSectionDocument, variables: variables };
}
export const GetMissionMentorDocument = gql`
    query GetMissionMentor($promptName: String!, $prompt: String!) {
        missionMentor(promptName: $promptName, prompt: $prompt)
    }
`;

/**
 * __useGetMissionMentorQuery__
 *
 * To run a query within a React component, call `useGetMissionMentorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionMentorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionMentorQuery({
 *   variables: {
 *      promptName: // value for 'promptName'
 *      prompt: // value for 'prompt'
 *   },
 * });
 */
export function useGetMissionMentorQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMissionMentorQuery,
        GetMissionMentorQueryVariables
    > &
        (
            | { variables: GetMissionMentorQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMissionMentorQuery,
        GetMissionMentorQueryVariables
    >(GetMissionMentorDocument, options);
}
export function useGetMissionMentorLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMissionMentorQuery,
        GetMissionMentorQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMissionMentorQuery,
        GetMissionMentorQueryVariables
    >(GetMissionMentorDocument, options);
}
export function useGetMissionMentorSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMissionMentorQuery,
              GetMissionMentorQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMissionMentorQuery,
        GetMissionMentorQueryVariables
    >(GetMissionMentorDocument, options);
}
export type GetMissionMentorQueryHookResult = ReturnType<
    typeof useGetMissionMentorQuery
>;
export type GetMissionMentorLazyQueryHookResult = ReturnType<
    typeof useGetMissionMentorLazyQuery
>;
export type GetMissionMentorSuspenseQueryHookResult = ReturnType<
    typeof useGetMissionMentorSuspenseQuery
>;
export type GetMissionMentorQueryResult = ApolloReactCommon.QueryResult<
    GetMissionMentorQuery,
    GetMissionMentorQueryVariables
>;
export function refetchGetMissionMentorQuery(
    variables: GetMissionMentorQueryVariables
) {
    return { query: GetMissionMentorDocument, variables: variables };
}
export const MaiDocument = gql`
    subscription Mai($promptName: String!, $prompt: String!) {
        chat(promptName: $promptName, prompt: $prompt)
    }
`;

/**
 * __useMaiSubscription__
 *
 * To run a query within a React component, call `useMaiSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMaiSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaiSubscription({
 *   variables: {
 *      promptName: // value for 'promptName'
 *      prompt: // value for 'prompt'
 *   },
 * });
 */
export function useMaiSubscription(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        MaiSubscription,
        MaiSubscriptionVariables
    > &
        (
            | { variables: MaiSubscriptionVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        MaiSubscription,
        MaiSubscriptionVariables
    >(MaiDocument, options);
}
export type MaiSubscriptionHookResult = ReturnType<typeof useMaiSubscription>;
export type MaiSubscriptionResult =
    ApolloReactCommon.SubscriptionResult<MaiSubscription>;
export const GetMeasurePeriodDataDocument = gql`
    query GetMeasurePeriodData(
        $tenantId: ID!
        $measureId: ID!
        $forDateTime: DateTime
        $reportPeriodType: ReportPeriodTypes!
        $reportPeriod: Int
    ) {
        periodData {
            measurePeriodData(
                tenantId: $tenantId
                measureId: $measureId
                forDateTime: $forDateTime
                reportPeriodType: $reportPeriodType
                reportPeriod: $reportPeriod
            ) {
                measureId
                targetFormatted
                actualFormatted
                nextTargetFormatted
                statusValue
                arrowDirection
                arrowColour
                period
                nextPeriod
                previousPeriod
                noActualsAsOfDates
            }
        }
    }
`;

/**
 * __useGetMeasurePeriodDataQuery__
 *
 * To run a query within a React component, call `useGetMeasurePeriodDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasurePeriodDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasurePeriodDataQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      measureId: // value for 'measureId'
 *      forDateTime: // value for 'forDateTime'
 *      reportPeriodType: // value for 'reportPeriodType'
 *      reportPeriod: // value for 'reportPeriod'
 *   },
 * });
 */
export function useGetMeasurePeriodDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetMeasurePeriodDataQuery,
        GetMeasurePeriodDataQueryVariables
    > &
        (
            | { variables: GetMeasurePeriodDataQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetMeasurePeriodDataQuery,
        GetMeasurePeriodDataQueryVariables
    >(GetMeasurePeriodDataDocument, options);
}
export function useGetMeasurePeriodDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetMeasurePeriodDataQuery,
        GetMeasurePeriodDataQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetMeasurePeriodDataQuery,
        GetMeasurePeriodDataQueryVariables
    >(GetMeasurePeriodDataDocument, options);
}
export function useGetMeasurePeriodDataSuspenseQuery(
    baseOptions?:
        | ApolloReactHooks.SkipToken
        | ApolloReactHooks.SuspenseQueryHookOptions<
              GetMeasurePeriodDataQuery,
              GetMeasurePeriodDataQueryVariables
          >
) {
    const options =
        baseOptions === ApolloReactHooks.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetMeasurePeriodDataQuery,
        GetMeasurePeriodDataQueryVariables
    >(GetMeasurePeriodDataDocument, options);
}
export type GetMeasurePeriodDataQueryHookResult = ReturnType<
    typeof useGetMeasurePeriodDataQuery
>;
export type GetMeasurePeriodDataLazyQueryHookResult = ReturnType<
    typeof useGetMeasurePeriodDataLazyQuery
>;
export type GetMeasurePeriodDataSuspenseQueryHookResult = ReturnType<
    typeof useGetMeasurePeriodDataSuspenseQuery
>;
export type GetMeasurePeriodDataQueryResult = ApolloReactCommon.QueryResult<
    GetMeasurePeriodDataQuery,
    GetMeasurePeriodDataQueryVariables
>;
export function refetchGetMeasurePeriodDataQuery(
    variables: GetMeasurePeriodDataQueryVariables
) {
    return { query: GetMeasurePeriodDataDocument, variables: variables };
}
