import React, { useState } from 'react';
import {
    ActionButton,
    Stack,
    Shimmer,
    mergeStyleSets,
    Separator,
    ShimmerElementType,
    Label,
    Text,
    TextField,
    DefaultButton,
    IconButton,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';

import { useNavigate, useParams } from 'react-router-dom';
import { navigation } from '../../services/navigation';
import { useStateContext } from '../../services/contextProvider';
import MeasureNameEdit from '../Measure/components/MeasureNameEdit';
import {
    FrequencyPeriods,
    useGetMeasureQuery,
    useGetFinancialYearQuery,
    ValueTypes,
    useUpdateMeasureMutation,
    Measure,
    refetchGetMissionMeasuresQuery,
    MeasureTypes,
    ChartDisplay,
    PhaseType,
    Multipliers,
    StatusTypes,
    ChartType,
    TargetTypes,
    GetMeasureQuery,
    useGetMeasureValueFormulaQuery,
    refetchGetMeasureValueFormulaQuery,
    refetchGetMeasureValueHistoryQuery,
    refetchGetMeasureValueFormulaEvaluationQuery,
} from '../../data/types';
import { useThemes } from '../../hooks/useThemes';
import { useActiveView } from '../../hooks/useActiveView';
import VariableList, { RowData } from './components/VariableList';
import { useFeatures } from '../../hooks/useFeatures';
import { Access } from '../../data/extendedTypes';
import FunctionHelp from './components/FunctionHelp';
import MeasureSelect from './components/MeasureSelect';
import MeasureCalcTooltip from './components/MeasureCalcTooltip';
import {
    FormulaManager,
    MeasureLink,
    Variable,
} from './components/formulaUtils';

function notEmpty<T>(val: T | null | undefined): val is T {
    return val !== null && val != undefined;
}

export function MeasureFormulaEditor() {
    const { teamCode, missionId, measureId } = useParams();

    const {
        currentTenantId,
        currentRoles,
        currentTenantCode,
        currentFinancialYearCode,
    } = useStateContext();

    const { currentTheme } = useThemes();

    const features = useFeatures();

    const navigate = useNavigate();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const [selectedFunction, setSelectedFunction] = useState<
        string | undefined
    >('general');

    const [valueFormula, setValueFormula] = useState<
        string | undefined | null
    >();
    const [linkedMeasures, setLinkedMeasures] = useState<MeasureLink[]>([]);
    const [variables, setVariables] = useState<Variable[]>([]);
    const [lineCount, setLineCount] = useState<number>(4);
    const formulaManager = new FormulaManager();

    // Validating and saving of Value Formula:
    const [measure, setMeasure] = React.useState<Measure>();
    const [missionAccess, setMissionAccess] = React.useState<
        Access | undefined
    >();

    const [isValid, setIsValid] = React.useState(true);
    const [showSavedNotification, setShowSavedNotification] =
        React.useState(false);
    const [formErrorMessages, setFormErrorMessages] = React.useState({
        valueFormula: '',
    });

    // const [isSavingValues, setIsSavingValues] = useState<boolean>(false);

    // const [showTotals, { toggle: toggleShowTotals }] = useBoolean(false);
    // const [showCumulativeColumn, setShowCumulativeColumn] =
    //     useState<boolean>(true);
    // const [hideUpgradeDialog, { toggle: toggleUpgradeDialog }] =
    //     useBoolean(true);
    // const [hideUpgradePreviewDialog, { toggle: toggleUpgradePreviewDialog }] =
    //     useBoolean(true);
    // const [upgradeFrequency, setUpgradeFrequency] = useState<FrequencyPeriods>(
    //     FrequencyPeriods.None
    // );

    const [isSelectingMeasure, setIsSelectingMeasure] =
        useState<boolean>(false);
    // const [isSelectingMeasure2, setIsSelectingMeasure2] =
    //     useState<boolean>(false);

    useActiveView('Measure');

    const { data: fyData } = useGetFinancialYearQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const { data: measureData, loading: isMeasureLoading } = useGetMeasureQuery(
        {
            fetchPolicy: 'network-only',
            skip: !currentTenantId || !measureId,
            variables: {
                tenantId: currentTenantId || '',
                id: measureId || '',
            },
        }
    );

    const {
        data: formulaData,
        loading: isFormulaLoading,
        // refetch: _refetchFormula,
    } = useGetMeasureValueFormulaQuery({
        variables: {
            tenantId: currentTenantId || '',
            measureId: measureId || '',
        },
    });

    const handleUpdatedCompleted = async () => {
        setShowSavedNotification(true);
    };

    const [
        updateMeasure,
        { loading: isSavingMeasure, error: measureSaveError },
    ] = useUpdateMeasureMutation({
        onCompleted: handleUpdatedCompleted,
    });

    // const [fastUpdateMeasureAsOf, { error: saveAsOfError }] = useFastUpdateMeasureAsOfMutation();
    // const [recalculateMeasure] = useRecalculateMeasureMutation();

    const fyStartDate = fyData?.financialYear?.startDate.substring(0, 10);
    const fyEndDate = fyData?.financialYear?.endDate.substring(0, 10);

    const isLocked = (measureData?.measure?.tags || []).reduce(
        (value, tag) => value || tag.name === 'Locked',
        false
    );

    const allowEdit =
        measure &&
        missionAccess?.write === true &&
        (isAdmin || !isLocked) &&
        !measure.isLinked;

    const handleBackClick = () => {
        const path = navigation.getPathForParams({
            tenantCode: currentTenantCode || '',
            financialYearCode: currentFinancialYearCode || '',
            teamCode: teamCode,
            missionId: missionId,
            measureId: measureId,
            formulaEdit: false,
        });
        navigate(path, { replace: true });
    };

    const handleAddMeasure = (measure: Measure | null) => {
        setIsSelectingMeasure(false);

        type MissionProperty = {
            __typename: 'MissionQL';
            id: string | null;
            title: string | null;
            owner: string | null;
        };

        type MeasureProperty = {
            __typename: 'MeasureQL';
            id: string | null;
            name: string | null;
            frequencyPeriod: string | null;
            mission: MissionProperty | null;
        };

        const newMeasure: MeasureProperty = {
            __typename: 'MeasureQL',
            id: measure?.id ?? null,
            name: measure?.name ?? null,
            frequencyPeriod: measure?.frequencyPeriod ?? null,
            mission: {
                __typename: 'MissionQL',
                id: measure?.missionId ?? null,
                title: 'missionTitle',
                owner: 'missionOwner',
            },
        };

        const existingMeasures: MeasureProperty[] = linkedMeasures || [];

        const measures: MeasureProperty[] = [...existingMeasures, newMeasure];

        setLinkedMeasures(measures);
    };

    const validate = (): boolean => {
        const formErrorMessages = {
            valueFormula: '',
        };

        if (valueFormula) {
            const openBracketCount = (valueFormula.match(/\(/g) || []).length;
            const closeBracketCount = (valueFormula.match(/\)/g) || []).length;

            if (openBracketCount != closeBracketCount) {
                formErrorMessages.valueFormula = `Unequal amount of brackets found: ${openBracketCount} opening and ${closeBracketCount} closing brackets.`;
            }
        }

        const isValid = !formErrorMessages.valueFormula;

        setFormErrorMessages(formErrorMessages);
        setIsValid(isValid);

        return isValid;
    };

    const handleSave = async (): Promise<void> => {
        const isValid = validate();
        setShowSavedNotification(false);
        if (isValid) {
            const input = {
                ...measure,
                valueFormula: valueFormula ?? null,
            } as Measure;

            const variables = {
                tenantId: currentTenantId || '',
                input: input,
            };
            if (input.id) {
                await updateMeasure({
                    variables: variables,
                    refetchQueries: [
                        refetchGetMeasureValueFormulaQuery({
                            tenantId: currentTenantId || '',
                            measureId: measureId || '',
                        }),
                        refetchGetMeasureValueFormulaEvaluationQuery({
                            tenantId: currentTenantId || '',
                            measureId: measureId || '',
                        }),
                        refetchGetMeasureValueHistoryQuery({
                            tenantId: currentTenantId || '',
                            id: measureId || '',
                            historyHasActual: false,
                            historySkip: 0,
                            historyTake: 12,
                        }),
                    ],
                });
            } else {
                await updateMeasure({
                    variables: variables,
                    refetchQueries: [
                        refetchGetMeasureValueFormulaQuery({
                            tenantId: currentTenantId || '',
                            measureId: measureId || '',
                        }),
                        refetchGetMissionMeasuresQuery({
                            tenantId: currentTenantId || '',
                            missionId: missionId || '',
                        }),
                    ],
                });
            }
        }
    };

    const handleMeasureLoaded = (measureData?: GetMeasureQuery) => {
        const loadedMeasure = measureData?.measure;
        if (loadedMeasure) {
            setMissionAccess(loadedMeasure.mission?.rights);
            // setValueFormula(loadedMeasure.valueFormula);
            setMeasure({
                id: loadedMeasure.id,
                missionId: loadedMeasure.missionId,
                measureGroupId: loadedMeasure.measureGroupId,
                name: loadedMeasure.name,
                description: loadedMeasure.description,
                measureType: loadedMeasure.measureType,
                phaseType: loadedMeasure.phaseType,
                currency: loadedMeasure.currency
                    ? {
                          code: loadedMeasure.currency?.code,
                          descr: loadedMeasure.currency?.descr,
                          symbol: loadedMeasure.currency?.symbol,
                      }
                    : null,
                multiplier: loadedMeasure.multiplier,
                decimalPlaces: loadedMeasure.decimalPlaces,
                statusType: loadedMeasure.statusType,
                yellowStart: loadedMeasure.yellowStart,
                greenRange: loadedMeasure.greenRange,
                yellowRange: loadedMeasure.yellowRange,
                isStatusLimited: loadedMeasure.isStatusLimited,
                frequencyNumber: loadedMeasure.frequencyNumber,
                frequencyPeriod: loadedMeasure.frequencyPeriod,
                isLinked: loadedMeasure.isLinked,
                linkedFromMeasureId: loadedMeasure.linkedFromMeasureId,
                tags: loadedMeasure.tags,
                sequence: loadedMeasure.sequence,
                version: loadedMeasure.version,

                previousFYMeasureId: loadedMeasure.previousFYMeasureId,
                fullYearTarget: loadedMeasure.fullYearTarget,
                fullYearString: loadedMeasure.fullYearString,
                isFullYearTarget: loadedMeasure.isFullYearTarget,
                chartDisplay: loadedMeasure.chartDisplay,
                chartType: loadedMeasure.chartType,
                showForecast: loadedMeasure.showForecast,
                showFutureLook: loadedMeasure.showFutureLook,
                isCustom: loadedMeasure.isCustom,
                targetType: loadedMeasure.targetType,
                valueType: loadedMeasure.valueType,
                valueFormula: loadedMeasure.valueFormula,
            });
        }
    };

    React.useEffect(() => {
        setShowSavedNotification(false);
        if (!measureId) {
            setMeasure({
                id: null,
                missionId: missionId || '',
                measureGroupId: null,
                name: '',
                description: '',
                measureType: MeasureTypes.Numeric,
                phaseType: PhaseType.PeriodEnd,
                currency: null,
                multiplier: Multipliers.None,
                decimalPlaces: 0,
                statusType: StatusTypes.MoreThanTarget,
                yellowStart: 0.9,
                greenRange: 0,
                yellowRange: 0,
                isStatusLimited: true,
                sequence: 0, // New measure sequence
                version: '',
                frequencyNumber: 1,
                frequencyPeriod: FrequencyPeriods.Month,
                isLinked: false,
                linkedFromMeasureId: null,
                tags: [],

                previousFYMeasureId: null,
                fullYearTarget: null,
                fullYearString: null,
                isFullYearTarget: true,
                chartDisplay: ChartDisplay.ByFrequency,
                chartType: ChartType.Default,
                showForecast: false,
                showFutureLook: false,
                isCustom: features.Measures2023Default ? false : true,
                targetType: TargetTypes.FullYear,
                valueType: ValueTypes.Simple,
                valueFormula: null,
            });
        } else if (measureData) {
            handleMeasureLoaded(measureData);
        }
    }, [measureId, missionId, measureData, features.Measures2023Default]);

    React.useEffect(() => {
        if (!isFormulaLoading && formulaData) {
            const data = formulaData.measureValueFormula;
            setValueFormula(data?.formula || '');
            setLinkedMeasures(data?.measures ?? []);
            setVariables(data?.variables ?? []);

            formulaManager.setFormula(data?.formula || '');
            formulaManager.setVariables(
                formulaData.measureValueFormula?.variables
            );
            setLineCount(Math.max(formulaManager.lineCount() + 1, 4));
        }
    }, [isFormulaLoading, formulaData]);

    const referencedMeasures = formulaData?.measureValueFormula?.measures || [];

    const matchingFrequency = referencedMeasures.every(
        (r) => r.frequencyPeriod === measure?.frequencyPeriod
    );

    const invalidDataTypes = [
        MeasureTypes.Date,
        MeasureTypes.Gyr,
        MeasureTypes.None,
        MeasureTypes.OnOff,
        MeasureTypes.YesNo,
    ];
    const dataTypeWarnings: string[] = [];

    if (
        measure?.measureType &&
        invalidDataTypes.includes(measure?.measureType)
    ) {
        dataTypeWarnings.push(
            `Warning: This Measure has a datatype ${measure?.measureType} that is not supported in calculations`
        );
    }

    referencedMeasures.forEach((r) => {
        if (r?.measureType && invalidDataTypes.includes(r?.measureType)) {
            dataTypeWarnings.push(
                `Warning: Measure ${r.name} has a datatype ${r?.measureType} that is not supported in calculations`
            );
        }
    });

    const handleAddVariableDeclaration = (item?: RowData) => {
        formulaManager.setFormula(valueFormula);
        formulaManager.setMeasures(linkedMeasures);
        formulaManager.setVariables(variables);

        if (item?.variableName && item?.measureId) {
            formulaManager.addVariableDeclaration(
                item?.variableName,
                item?.measureId
            );
            setValueFormula(formulaManager.formula);
            setVariables(formulaManager.variables);
            setLineCount(Math.max(formulaManager.lineCount() + 1, 4));
        } else {
            setFormErrorMessages({
                valueFormula: 'Unrecognised Variable declaration',
            });
        }
    };

    const handleVariableRename = (oldName: string, newName: string) => {
        formulaManager.setFormula(valueFormula);
        formulaManager.setMeasures(linkedMeasures);
        formulaManager.setVariables(variables);

        formulaManager.renameVariable(oldName, newName);

        setValueFormula(formulaManager.formula);
        setVariables(formulaManager.variables);
        setLineCount(Math.max(formulaManager.lineCount() + 1, 4));
    };

    const tokens = {
        gapStack: {
            padding: 0,
            marginBottom: 32,
            childrenGap: 0,
        },
        default: {
            childrenGap: 8,
        },
    };

    const classNames = mergeStyleSets({
        contentItem: {
            paddingLeft: 16,
        },
        separator: {
            paddingLeft: 8,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralQuaternary}`,
        },
        messageBar: { marginTop: 12 },
        chartsTwoColumns: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(360px, 1fr))',
            gridGap: 16,
            margin: '16px 16px 0 16px',
        },
        tableOneColumn: {
            margin: '16px 16px 0 16px',
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerCommandBarContainer: {
            borderBottom: `solid 1px ${currentTheme.palette.neutralQuaternary}`,
        },
        rootContainer: {
            paddingTop: 16,
            paddingLeft: 16,
            paddingRight: 16,
        },
    });

    return (
        <>
            <Stack tokens={tokens.gapStack}>
                {/* Back Icon and Measure Text */}
                <div className={classNames.titleContainer}>
                    <ActionButton
                        iconProps={{
                            iconName: 'SkypeCircleArrow',
                        }}
                        styles={{
                            icon: {
                                fontSize: 28,
                            },
                        }}
                        onClick={handleBackClick}
                    />
                    <Shimmer
                        isDataLoaded={!isMeasureLoading}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                height: 32,
                            },
                        ]}
                    >
                        {measureData?.measure && (
                            <MeasureNameEdit
                                measure={measureData?.measure}
                                variant="xLarge"
                                isReadOnly={!allowEdit}
                            />
                        )}
                    </Shimmer>
                </div>

                {/* /Measure Command Bar */}
                {/* <div className={classNames.headerCommandBarContainer}>
                    <Shimmer
                        isDataLoaded={!isLoading && !!measure}
                        styles={{ dataWrapper: { height: 44, }, }}
                        shimmerElements={[ { type: ShimmerElementType.line, height: 44, }, ]}
                    >
                        {measure && (
                            <MeasureEditPageCommandBar
                                measure={measure}
                                isReadOnly={!allowEdit}
                                showTotals={showTotals}
                                toggleShowTotals={toggleShowTotals}
                                onAttachmentsClick={props.onAttachmentsClick}
                                onCommentsClick={props.onCommentsClick}
                                onEditMeasureClick={props.onEditMeasureClick}
                                onUpgradeClick={toggleUpgradeDialog}
                            />
                        )}
                    </Shimmer>
                </div>  */}

                {/* /Measure Top Area  */}

                <Separator className={classNames.separator}></Separator>

                <div className={classNames.rootContainer}>
                    <Stack tokens={tokens.default}>
                        <Stack horizontal>
                            <Text variant="xLarge">Formula Editor:</Text>
                            <MeasureCalcTooltip
                                measureId={measureId}
                                valueFormula={
                                    formulaData?.measureValueFormula?.formula
                                }
                            >
                                <IconButton
                                    iconProps={{
                                        iconName: 'Calculator',
                                    }}
                                />
                            </MeasureCalcTooltip>
                        </Stack>

                        <FunctionHelp
                            selectedFunction={selectedFunction}
                            setSelectedFunction={setSelectedFunction}
                        />

                        <Label>Formula Description:</Label>
                        <Text>
                            {formulaData?.measureValueFormula?.formulaPP}
                        </Text>
                        <Label>Value Formula:</Label>

                        <Shimmer
                            isDataLoaded={!isMeasureLoading && !!measure}
                            shimmerElements={[
                                {
                                    type: ShimmerElementType.line,
                                    height: 109,
                                },
                            ]}
                        >
                            <TextField
                                title="Value Formula"
                                placeholder="Enter formula"
                                multiline
                                rows={lineCount}
                                value={valueFormula ?? ''}
                                disabled={measure?.isLinked}
                                // errorMessage={formErrorMessages.valueFormula}
                                onChange={(e, newValue) => {
                                    setValueFormula(newValue);
                                    setShowSavedNotification(false);
                                }}
                            ></TextField>
                            {!isValid && (
                                <MessageBar
                                    className={classNames.messageBar}
                                    messageBarType={MessageBarType.error}
                                >
                                    <Text>
                                        {formErrorMessages.valueFormula}
                                    </Text>
                                </MessageBar>
                            )}
                            {measureSaveError?.message && (
                                <MessageBar
                                    className={classNames.messageBar}
                                    messageBarType={MessageBarType.error}
                                >
                                    <Text>{measureSaveError.message}</Text>
                                </MessageBar>
                            )}

                            {!matchingFrequency && (
                                <MessageBar
                                    className={classNames.messageBar}
                                    messageBarType={MessageBarType.warning}
                                >
                                    <Text>
                                        Warning: Please check that the update
                                        frequency of this measure matches that
                                        of its referenced measures. (Currently
                                        set to {measure?.frequencyPeriod}).
                                    </Text>
                                </MessageBar>
                            )}

                            {dataTypeWarnings.map((warning, index) => (
                                <MessageBar
                                    key={`type-warning-${index}`}
                                    className={classNames.messageBar}
                                    messageBarType={MessageBarType.warning}
                                >
                                    <Text>{warning}</Text>
                                </MessageBar>
                            ))}

                            <Stack
                                horizontal
                                tokens={{ childrenGap: 8 }}
                                style={{ marginTop: 12 }}
                            >
                                <DefaultButton
                                    iconProps={{
                                        iconName: isSavingMeasure
                                            ? 'Refresh'
                                            : showSavedNotification
                                              ? 'CheckMark'
                                              : 'Add',
                                    }}
                                    onClick={handleSave}
                                    disabled={isSavingMeasure}
                                >
                                    {showSavedNotification ? 'Saved' : 'Save'}
                                </DefaultButton>
                            </Stack>
                        </Shimmer>
                    </Stack>
                </div>

                <Stack
                    tokens={{ padding: 8, childrenGap: 8 }}
                    styles={{ root: { marginLeft: 8 } }}
                >
                    <Label>Measures</Label>
                    <Stack.Item
                        styles={{
                            root: {
                                overflowX: 'auto',
                            },
                        }}
                    >
                        <VariableList
                            measures={linkedMeasures}
                            variables={variables}
                            shimmerLines={3}
                            addVariableDeclaration={
                                handleAddVariableDeclaration
                            }
                            handleVariableRename={handleVariableRename}
                            loading={isSavingMeasure}
                        />
                    </Stack.Item>

                    {!isSelectingMeasure && (
                        <Stack.Item>
                            <DefaultButton
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => {
                                    setIsSelectingMeasure(!isSelectingMeasure);
                                }}
                            >
                                Add Measure
                            </DefaultButton>
                        </Stack.Item>
                    )}

                    {isSelectingMeasure && (
                        <MeasureSelect
                            fyStartDate={fyStartDate}
                            fyEndDate={fyEndDate}
                            selectMeasure={handleAddMeasure}
                            excludeMeasureIds={[
                                measureId,
                                ...referencedMeasures.map((m) => m.id),
                            ].filter(notEmpty)}
                        />
                    )}

                    {/* {!isSelectingMeasure && !isSelectingMeasure2 && (
                        <Stack.Item>
                            <DefaultButton
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => { setIsSelectingMeasure2(!isSelectingMeasure2) }}
                            >
                                Add Measure (2)
                            </DefaultButton>
                        </Stack.Item>
                    )} */}

                    {/* {isSelectingMeasure2 && (
                        <Stack
                            tokens={{ padding: 8 }}
                            styles={{ root: { height: '100%' } }}
                        >
                            <AdvanceCard>
                                <AdvanceCard.Item>
                                    <MissionFilterBar
                                        filters={filters}
                                        onFiltersChanged={handleFiltersChanged}
                                        searchBoxPlaceholder={
                                            'Filter by mission or keyword'
                                        }
                                    />
                                </AdvanceCard.Item>
                                <AdvanceCard.Item>
                                    <MeasureFlatList
                                        onMeasureClick={function (
                                            missionId: string,
                                            measureId: string
                                        ): void {
                                            console.log(
                                                'measureClick',
                                                missionId,
                                                measureId
                                            );
                                        }}
                                        {...props}
                                        measures={measuresData?.measures}
                                        measuresLoading={isMeasuresLoading}
                                        defaultColumns={[
                                            'displaySequence',
                                            'missionName',
                                            'name',
                                            'target',
                                            'actual',
                                            'status',
                                        ]}
                                        initialSort={[
                                            'isLeader',
                                            'mission.sequence',
                                        ]}
                                        initialSortOrders={['desc', 'asc']}
                                        filters={filters}
                                        onRefreshClick={handleRefreshClick}
                                        isRefreshing={isRefetching}
                                    />
                                </AdvanceCard.Item>
                            </AdvanceCard>

                            <Stack
                                horizontal
                                tokens={{ childrenGap: 8 }}
                                styles={{ root: { marginTop: 8 } }}
                            >
                                {/* <DefaultButton
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={() => { props.selectMeasure(measureCardPreviewMeasure) }}
                                >
                                    Add
                                </DefaultButton> * /}
                                <DefaultButton
                                    iconProps={{ iconName: 'Cancel' }}
                                    onClick={() => { setIsSelectingMeasure2(false); }}
                                >
                                    Cancel
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    )} */}
                </Stack>
            </Stack>
        </>
    );
}
